import Util from '@/logic/Util'
import type { ElementMaps, TagName } from '@/types/state'
import { Mapping } from '@/Util/mapping/Mapping'

export class AllChildrenSelected {
  private static areAllChildrenSelected (
    elementType: TagName,
    elementMountLogId: string,
    parentPath: string,
    selectedPaths: Set<string>,
    allChildrenSelectedPaths: Set<string>,
    elementMaps: ElementMaps,
  ): boolean {
    const numericId = Mapping.numericIdByMountLogId[elementMountLogId]
    const path = `${(parentPath ? `${parentPath}/` : '') + elementType}:${numericId}`
    const childTypes = Util.getChildTypeForRealData(elementType)

    let allChildrenSelected = childTypes.length ? true : selectedPaths.has(path)

    for (const childType of childTypes) {
      const childMountLogIdsType = `${
        childType[0]?.toLocaleLowerCase() + childType.substring(1)
      }MountLogs` as keyof BaseMountLog
      const childMountLogIds =
        (elementMaps[`${elementType}MountLog` as keyof ElementMaps] as Record<string, BaseMountLog>)[elementMountLogId][
          childMountLogIdsType
        ] as unknown as string[] ?? []

      let allChildrenSelectedForChildType = true

      for (const childMountLogId of childMountLogIds) {
        if (
          !AllChildrenSelected.areAllChildrenSelected(
            childType,
            childMountLogId,
            path,
            selectedPaths,
            allChildrenSelectedPaths,
            elementMaps,
          )
        ) {
          allChildrenSelected = false
          allChildrenSelectedForChildType = false
        }
      }

      if (allChildrenSelectedForChildType) {
        allChildrenSelectedPaths.add(`${path}/${childType}-Selector`)
      }
    }

    if (allChildrenSelected) {
      allChildrenSelectedPaths.add(path)
    }

    return allChildrenSelected
  }

  public static generate (selectedPaths: Set<string>, elementMaps: ElementMaps) {
    const allChildrenSelectedPaths = new Set<string>()
    const { SegmentGroupMountLog } = elementMaps

    for (const segmentGroupMountLogId in SegmentGroupMountLog) {
      AllChildrenSelected.areAllChildrenSelected(
        'SegmentGroup',
        segmentGroupMountLogId,
        '',
        selectedPaths,
        allChildrenSelectedPaths,
        elementMaps,
      )
    }

    return allChildrenSelectedPaths
  }
}
