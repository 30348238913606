import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import * as FilterActions from '@/store/filter/actions'
import { DefaultState } from '@/types/state'

const Button = styled.button<{ $active?: boolean }>`${({ $active }) =>
  css`
  border:           none;
  background-color: transparent;
  padding-left:     0;
  color:            ${$active ? '#BB1B1B' : 'inherit'};
  
  &:hover {
    height: 10px;
    color:  ${$active ? '#A71A1A' : '#CCCCCC'};
  }
  
  &:focus {
    outline: 0;
  }
  
  &:disabled {
    color:          #777777;
    pointer-events: none;
  }
`}`

const connector = connect((state: DefaultState) => ({
  term: state.filter.term,
}), {
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  elementType: string
  value: string
  name: string
  disabled: boolean
  t(key: string, params?: Record<string, unknown>): string
}

export class FilterButton extends Component<Props> {
  private readonly handleFilterClick = (event: any) => {
    const { value, setTerm, name, elementType } = this.props

    const type = elementType === 'SegmentGroupSupportPoints' ? 'SupportPoint' : elementType

    setTerm(`${type}#${name}=${value}`, event.ctrlKey || event.metaKey, true)
  }
  
  public override render () {
    const { value, elementType, term, name, disabled, t } = this.props

    const typePart = (term ?? '').split(' ').filter(part => part.includes(elementType)) ?? []
    const isFilterActive = Boolean(
      elementType && typePart.length && typePart.join(' ').includes(`${name.substring(1)}=${value}`),
    )

    return (
      <Button
        title={t('specific.filterButton')}
        $active={isFilterActive}
        disabled={disabled}
        onClick={this.handleFilterClick}
      >
        <FontAwesomeIcon icon={faFilter} />
      </Button>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(FilterButton)
