import { DefaultState } from '@/types/state'

export default class Executables {
  public static getAllowedExecutables (state: DefaultState) {
    const { application: { main: { currentSimulationCase } }, data: { executableDefinitions } } = state

    const isRealData = Boolean(currentSimulationCase.blueprintId)

    return executableDefinitions?.filter((definition) => Boolean(definition.realData) === isRealData) ?? []
  }
}
