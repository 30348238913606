import type { FilterableElementType } from '@/types/filter'
import type { ElementMaps } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import FilterHandler, { type Filter, type FilterFunction } from './FilterHandler'
import Util from './Util'

export class CompareFilterHandler {
  private static filterCache: Record<string, { timestamp: number, data: Record<string, FilterableElementType> }> = {}

  private static filteredElementsByCaseId: {
    [caseId: string]: {
      [term: string]: {
        [path: string]: FilterableElementType
      }
    }
  } = {}

  private static handleCompareElement<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    term: string,
    filters: Filter<Slot, MountLog> | Filter<Slot, MountLog>[],
    path: string,
    type: FilterableElementType,
    element: any,
    caseId: string,
    elementMaps: ElementMaps,
  ) {
    let hit = false

    if (term.includes('@') && CompareFilterHandler.filteredElementsByCaseId[caseId]?.[term]?.[path]) {
      hit = true
    }

    const parentInfo = path ? Util.getParentInfo(path) : null
    // eslint-disable-next-line max-len
    const isParentVisible = Boolean(
      CompareFilterHandler.filteredElementsByCaseId[caseId]?.[term]?.[parentInfo?.path ?? ''],
    )
    const arrayOfFilters = filters instanceof Array ? filters : [ filters ]

    arrayOfFilters.forEach((filterElement, index) => {
      const allowed = index && filters instanceof Array
        ? filters[index - 1]?.type !== type
        : true
      const isChild = index && filters instanceof Array
        ? (FilterHandler.CHILDREN as any)[filters[index - 1]?.type ?? '']?.includes(type)
        : false

      if (isParentVisible && allowed && isChild && filterElement.type === '*') {
        hit = true

        return
      }

      if (filterElement.type !== type) {
        return
      }

      if (isParentVisible || index === 0) {
        hit = FilterHandler.handleLevel(filterElement, path, element, elementMaps)
      }
    })

    if (hit) {
      if (!CompareFilterHandler.filteredElementsByCaseId[caseId]) {
        CompareFilterHandler.filteredElementsByCaseId[caseId] = {}
      }

      if (!CompareFilterHandler.filteredElementsByCaseId[caseId][term]) {
        CompareFilterHandler.filteredElementsByCaseId[caseId][term] = {}
      }

      CompareFilterHandler.filteredElementsByCaseId[caseId][term][path] = type
    }

    return hit
  }

  private static applyFiltersToCompareElement (
    tokens: (FilterFunction | string)[],
    path: string,
    type: FilterableElementType,
    element: any,
    hitElements: Record<string, FilterableElementType>,
    elementMaps: ElementMaps,
  ) {
    if (!CompareFilterHandler.applyTokensToElement(tokens, path, type, element, elementMaps)) {
      return
    }

    hitElements[path] = type
  }

  private static applyTokensToElement (
    tokens: (FilterFunction | string)[],
    path: string,
    type: FilterableElementType,
    element: any,
    elementMaps: ElementMaps,
  ) {
    const stack: FilterFunction[] = []

    tokens.forEach(token => {
      if (token === '&&') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) =>
          filter1(path, type, element, elementMaps) && filter2(path, type, element, elementMaps)
        )
      }
      else if (token === '||') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) =>
          filter1(path, type, element, elementMaps) || filter2(path, type, element, elementMaps)
        )
      }
      else {
        if (typeof token === 'string') {
          return
        }

        // is a filter
        stack.push(token)
      }
    })

    return stack[0]?.(path, type, element, elementMaps)
  }

  private static prepareFilters (
    term: string,
    caseId: string,
  ): (FilterFunction | string)[] {
    // e.g. Nozzle#passln_coord=[CurrentPassLnCoord+1-1]
    let preparedTerm = term.replace(FilterHandler.variableRegEx, FilterHandler.handleVariables)

    preparedTerm = preparedTerm.replace(/(\[[^\]]{3,}\])/g, FilterHandler.handleCalculate)

    return CompareFilterHandler.tokenizeExpression(preparedTerm, caseId)
  }

  private static tokenizeExpression (expression: string, caseId: string) {
    const precedence = {
      '(': 1,
      ')': 1,
      '||': 2,
      '&&': 3,
    }

    const filters: (FilterFunction | string)[] = []
    const filterStack: string[] = []

    // eslint-disable-next-line no-useless-escape
    const tokens = FilterHandler.getTokens(expression)

    if (!tokens) {
      return []
    }

    tokens.forEach(token => {
      if (token === '||' || token === '&&') {
        while (
          filterStack.length > 0 &&
          (precedence as any)[filterStack[filterStack.length - 1] ?? ''] >= precedence[token]
        ) {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.push(token)
      }
      else if (token === '(') {
        filterStack.push(token)
      }
      else if (token === ')') {
        while (filterStack[filterStack.length - 1] !== '(') {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.pop()
      }
      else {
        const deconstructedFilters = FilterHandler.getFilter(token)

        filters.push((path: string, type: FilterableElementType, element: any, elementMaps) =>
          CompareFilterHandler.handleCompareElement(
            token,
            deconstructedFilters as any,
            path,
            type,
            element,
            caseId,
            elementMaps,
          )
        )
      }
    })

    while (filterStack.length > 0) {
      const nextFilter = filterStack.pop()

      if (nextFilter) {
        filters.push(nextFilter)
      }
    }

    return filters
  }

  public static getFilteredElementsPerCompareCaseId = (
    elementMaps: ElementMaps,
    caseId: string,
    term: string,
  ) => {
    if (term && !FilterHandler.isValidFilterTerm(term)) {
      return {}
    }

    // TODO: this does not always work, we need to include filter variables in the cache key
    const filterKey = term ?? 'AllElements'

    // potential cache hit
    if (CompareFilterHandler.filterCache[filterKey]) {
      const { timestamp, data } = CompareFilterHandler.filterCache[filterKey]

      // cache hit
      if (Date.now() - timestamp < 100) {
        return data
      }

      // cache too old - delete the cache
      delete CompareFilterHandler.filterCache[filterKey]
    }

    // cache miss - calculate the filters

    const hitElements: Record<string, FilterableElementType> = {}
    const filters = term ? CompareFilterHandler.prepareFilters(term, caseId) : []
    const numericMap = Mapping.numericIdByCaseIdAndMountLogId[caseId]
    const elementPathByMountLogId = Mapping.elementPathByMountLogIdByCaseId[caseId]

    if (!filters.length || !numericMap || !elementPathByMountLogId) {
      return {}
    }

    const filterTypes = term ? FilterHandler.getFilterElementTypes(term) : []

    FilterHandler.elementTypeInfoArray.forEach(elementTypeInfo => {
      const { type } = elementTypeInfo

      if (!filterTypes.includes(type) && !filterTypes.includes('*')) {
        return
      }

      const currentMountLogMaps = elementMaps[`${type}MountLog`] ?? {}

      for (const mountLogId in currentMountLogMaps) {
        const mountLog = currentMountLogMaps[mountLogId]

        const fullElement = ElementMapsUtil.getFullCasterElementByMountLog(type, mountLog, elementMaps)

        const path = elementPathByMountLogId[mountLog.id]

        CompareFilterHandler.applyFiltersToCompareElement(
          filters,
          path,
          type as FilterableElementType,
          fullElement,
          hitElements,
          elementMaps,
        )
      }
    })

    // sensor points
    FilterHandler.sensorPointInfoArray.forEach(sensorPointInfo => {
      if (!filterTypes.includes('SensorPoint')) {
        return
      }

      const { mountLogType, type } = sensorPointInfo
      const currentMountLogMaps = elementMaps[mountLogType] ?? {}

      for (const mountLogId in currentMountLogMaps) {
        const mountLog = currentMountLogMaps[mountLogId]

        const fullElement = ElementMapsUtil.getFullCasterElementByMountLog(type, mountLog, elementMaps)
        const path = elementPathByMountLogId[mountLog.id]

        CompareFilterHandler.applyFiltersToCompareElement(
          filters,
          path,
          'SensorPoint',
          fullElement,
          hitElements,
          elementMaps,
        )
      }
    })

    // TODO: no data points here?

    for (const dataLineMountLogId in elementMaps.DataLineMountLog) {
      const dataLineMountLog = elementMaps.DataLineMountLog[dataLineMountLogId]
      const fullElement = ElementMapsUtil.getFullCasterElementByMountLog('DataLine', dataLineMountLog, elementMaps)
      const numericId = numericMap[dataLineMountLog.id]
      const path = `DataLine:${numericId}`

      CompareFilterHandler.applyFiltersToCompareElement(
        filters,
        path,
        'DataLine',
        fullElement,
        hitElements,
        elementMaps,
      )
    }

    // TODO: no coolingLoop here?
    // TODO: no airLoop here?

    CompareFilterHandler.filterCache[filterKey] = {
      timestamp: Date.now(),
      data: hitElements,
    }

    return hitElements
  }
}
