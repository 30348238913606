import hoistStatics from 'hoist-non-react-statics'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import IpcManager from '@/IpcManager'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'
import { displayTime } from '@/Util/time'

import BaseDialog from './BaseDialog'
import { LoginDialog } from './LoginDialog'
import Button from '../specific/SubmitButton'
import { Form, Text } from '../visualization/dashboard/Dialogs/DialogStyles'

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  hideCloseButton?: boolean
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  loading: boolean
}

export class UsageTimeExceededDialog extends Component<Props, State> {
  @Identifiable('UsageTimeExceededDialog') public static readonly NAME: string

  private intervalId: number | undefined

  public override state: State = {
    loading: false,
  }

  private readonly handleTryUnlockApp = () => {
    const { loading } = this.state

    if (loading) {
      return
    }

    const { closeDialog, t } = this.props

    this.setState({ loading: true })

    const { intervalStartedAt } = window.usageTimeInfo ?? {}

    this.intervalId = window.setInterval(() => {
      const { intervalStartedAt: newIntervalStartedAt, usageTimeExceeded } = window.usageTimeInfo ?? {}

      if (intervalStartedAt !== newIntervalStartedAt) {
        clearInterval(this.intervalId)

        this.setState({ loading: false })

        if (!usageTimeExceeded) {
          closeDialog(UsageTimeExceededDialog.NAME)

          return
        }

        enqueueSnackbar(t('usageTimeExceededDialog.noNewUsageTime'), { variant: 'info' })
      }
    }, 1000)

    IpcManager.electron.send('tryUnlockApp')
  }

  private readonly handleShowLogin = () => {
    const { loading } = this.state

    if (loading) {
      return
    }

    const { closeDialog, openDialog } = this.props

    closeDialog(UsageTimeExceededDialog.NAME)
    openDialog(LoginDialog.NAME)
  }
  
  public override render () {
    const { loading } = this.state
    const { t } = this.props

    const { usageTimeLimit } = window.usageTimeInfo ?? {}

    return (
      <BaseDialog
        title={t('usageTimeExceededDialog.title')}
        icon='pe-7s-door-lock'
        header={t('usageTimeExceededDialog.header')}
        small
        hideCloseButton
      >
        <Form>
          <Text>
            {t('usageTimeExceededDialog.message', { usageTimeLimit: displayTime(usageTimeLimit * 60, true) })}
          </Text>
          <br />
          <Button onClick={this.handleTryUnlockApp} $isLoading={loading}>
            <div>
              <i className='pe-7s-key' style={{ fontSize: '23px', transform: 'translate(0,-6px)' }} />
            </div>
            <span>{t('usageTimeExceededDialog.tryUnlock')}</span>
          </Button>
          <Button onClick={this.handleShowLogin} $isLoading={loading}>
            <div className='cut'>
              <i className='pe-7s-angle-right-circle' />
            </div>
            <span>{t('usageTimeExceededDialog.loginInstead')}</span>
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('login'), connector)(UsageTimeExceededDialog)

export default hoistStatics(composedComponent, UsageTimeExceededDialog)
