import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'

import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import {
  Ball,
  InnerCircle,
  Loading,
  LoadingCircle,
  LoadingContainer,
  MiddleCircle,
  Onveda,
  OuterCircle,
  Title,
} from './LoadingScreenStyles'
import onvedaLogo from '../../icon/onvedaLogo.png'

const connector = connect((state: DefaultState) => ({
  loadingStatus: state.application.main.loadingStatus,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  t: Translation
}

export class LoadingScreen extends Component<Props> {
  public override render () {
    const { loadingStatus, t } = this.props

    return (
      <Loading>
        <LoadingContainer>
          <LoadingCircle>
            <Ball src='images/waiting-1.svg' alt='' />
            <InnerCircle src='images/waiting-2.svg' $loadingStatus={loadingStatus} alt='' />
            <MiddleCircle src='images/waiting-3.svg' $loadingStatus={loadingStatus} alt='' />
            <OuterCircle src='images/waiting-4.svg' $loadingStatus={loadingStatus} alt='' />
          </LoadingCircle>
          <Title>
            {
              loadingStatus
                ? (
                  <div>
                    {t('specific.loading.loadingText')}
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </div>
                )
                : t('specific.loading.default')
            }
          </Title>
          <Onveda>
            {t('specific.loading.powered')}
            <img src={onvedaLogo} alt='Onveda' />
          </Onveda>
        </LoadingContainer>
      </Loading>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(LoadingScreen)
