import hoistStatics from 'hoist-non-react-statics'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Input from '@/react/specific/Input'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { Button, Dialog, DialogBackground, Form, Header, Text, Title } from './DialogStyles'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  viewId: state.visualization.viewId,
  currentDashboard: state.visualization.currentDashboard,
}), {
  addPlotTile: VisualizationActions.addPlotTile,
  showDerivePlotDialog: VisualizationActions.showDerivePlotDialog,
  savePlotConfig: VisualizationActions.savePlotConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string
  name: string
}

export class AddDerivedPlotDialog extends Component<Props, State> {
  @Identifiable('AddDerivedPlotDialog') public static readonly NAME: string

  public override state: State = {
    name: 'New Plot',
  }

  private readonly handleClose = () => {
    const { name } = this.state
    const { showDerivePlotDialog, savePlotConfig, plotConfigs } = this.props
    const plotConfigIds = Object.keys(plotConfigs)
    const plotConfigId = plotConfigIds[plotConfigIds.length - 1]

    savePlotConfig({
      id: plotConfigId,
      name,
    })
    showDerivePlotDialog(false)
  }

  private readonly handleSubmit = () => {
    const { name } = this.state
    const { addPlotTile, showDerivePlotDialog, plotConfigs, viewId, currentDashboard, savePlotConfig } = this.props
    const plotConfigIds = Object.keys(plotConfigs)
    const plotConfigId = plotConfigIds[plotConfigIds.length - 1]

    addPlotTile(viewId, currentDashboard[viewId], { configId: plotConfigId, name, type: 'text' })
    savePlotConfig({ id: plotConfigId, name })
    showDerivePlotDialog(false)
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  private readonly setFocus = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  }
  
  public override render () {
    const { t } = this.props
    const { name } = this.state

    return (
      <div>
        <DialogBackground />
        <Dialog $height='auto' $half>
          <Header title={t('addDerivedPlotDialog.title')}>
            <Title>{t('addDerivedPlotDialog.label')}</Title>
          </Header>
          <Form>
            <Text>
              {t('addDerivedPlotDialog.message')}
              <br />
              <br />
              {t('addDerivedPlotDialog.info')}
            </Text>
            <br />
            <Input
              label={t('addDerivedPlotDialog.tileName.label')}
              title={t('addDerivedPlotDialog.tileName.title')}
              name='name'
              type='text'
              value={name}
              onChange={this.handleInput}
            />
            <Button onClick={this.handleClose}>
              <span>{t('addDerivedPlotDialog.dismiss')}</span>
            </Button>
            <Button onClick={this.handleSubmit} ref={this.setFocus}>
              <div className='cut'>
                <i className='pe-7s-plus' />
              </div>
              <span>{t('addDerivedPlotDialog.button')}</span>
            </Button>
          </Form>
        </Dialog>
      </div>
    )
  }
}

const composedComponent = compose<any>(withTranslation('visualization'), connector)(AddDerivedPlotDialog)

export default hoistStatics(composedComponent, AddDerivedPlotDialog)
