import { enqueueSnackbar } from 'notistack'

import { allVerified } from '@/logic/case-verification'
import CommandUtil from '@/react/visualization/CommandUtil'
import MatrixUtil from '@/react/visualization/MatrixUtil'
import ApiClient from '@/store/apiClient'

import Logic from '.'
import { ProjectMatrixDialog } from '..'
import { ConfigureDialog } from '../ConfigureDialog'
import InlineEditor from '../InlineEditor'

type selectionPos = { i: number, j: number }

type SteelGrades = {
  defaultSteelGrades: Array<any>
  customSteelGrades: Array<any>
}

export default class Handlers {
  public static handleClose = (event?: any) => {
    const { closeDialog, setLastScrollPosition } = Logic.dialogInstance.props

    if (event !== undefined) {
      setLastScrollPosition(0)
    }

    closeDialog(ProjectMatrixDialog.NAME)
  }

  public static handleSimulateMultiple = (ids: Array<string>) => {
    const { currentProject, caseVerifications, setCurrentProject } = Logic.dialogInstance.props

    Logic.dialogInstance.setState({ simulateLoading: true, simulateError: '' })

    const simulationCaseIds = ids.filter(id => {
      const simulationCase = currentProject.simulationCases.find((simCase: SimulationCase) => simCase.id === id)

      return (
        !simulationCase?.simulationStartedAt &&
        simulationCase &&
        allVerified(simulationCase?.id, caseVerifications, 'default') // FIXME: get selected catalog
      )
    })

    ApiClient
      .post(`${'Network.URI(deprecated)'}/simulation_case/simulate_multiple`, { data: { simulationCaseIds } })
      .then(({ project }) => {
        setCurrentProject(project)
        // TODO: toast
        Logic.dialogInstance.setState({ simulateLoading: false })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        // TODO: toast
        Logic.dialogInstance.setState({ simulateLoading: false, simulateError: error.message })
      })
  }

  public static handleConfigure = () => {
    const { openDialog, setSelections } = Logic.dialogInstance.props

    setSelections({})
    openDialog(ConfigureDialog.NAME)
  }

  public static handleStopSelected = (ids: Array<string>) => {
    const { currentProject, setCurrentProject } = Logic.dialogInstance.props

    Logic.dialogInstance.setState({ stopSimulationLoading: true, stopSimulationError: '' })

    const simulationCaseIds = ids.filter(id => {
      const simulationCase = currentProject
        .simulationCases
        .find((simCase: SimulationCase) => simCase.id === id) ??
        { simulationStartedAt: undefined, simulationDataReceivedAt: undefined }

      return Boolean(simulationCase.simulationStartedAt) && !simulationCase.simulationDataReceivedAt
    })

    ApiClient
      .post(`${'Network.URI(deprecated)'}/simulation_case/stop_multiple`, { data: { simulationCaseIds } })
      .then(({ project }) => {
        if (!project || !Object.keys(project).length) {
          throw new Error('No project given')
        }

        setCurrentProject(project)
        // TODO: toast
        Logic.dialogInstance.setState({ stopSimulationLoading: false })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        // TODO: toast
        Logic.dialogInstance.setState({ stopSimulationLoading: false, stopSimulationError: error.message })
      })
  }

  public static handleResetSelected = (ids: Array<string>) => {
    const { currentProject, setCurrentProject } = Logic.dialogInstance.props

    Logic.dialogInstance.setState({ resetSimulationLoading: true, resetSimulationError: '' })

    const simulationCaseIds = ids.filter(id => {
      const simulationCase = currentProject
        .simulationCases
        .find((simCase: SimulationCase) => simCase.id === id) ?? { simulationDataReceivedAt: undefined }

      return Boolean(simulationCase.simulationDataReceivedAt)
    })

    ApiClient
      .post(`${'Network.URI(deprecated)'}/simulation_case/reset_multiple`, { data: { simulationCaseIds } })
      .then(({ project }) => {
        setCurrentProject(project)
        // TODO: toast
        Logic.dialogInstance.setState({ resetSimulationLoading: false })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        // TODO: toast
        Logic.dialogInstance.setState({ resetSimulationLoading: false, resetSimulationError: error.message })
      })
  }

  public static handleDataEditor = (editorProps: any, selectedCells: any, selectedCellData: any) => {
    let newSelectedCellData = selectedCellData
    let newSelectedCells = selectedCells

    if (selectedCells) {
      const sI = selectedCells.start.i
      const sJ = selectedCells.start.j
      const eI = selectedCells.end.i
      const eJ = selectedCells.end.j

      const fixedSelectedCells = {
        start: {
          i: sI >= 0 ? sI : (eI ?? 0),
          j: sJ >= 0 ? sJ : (eJ ?? 0),
        },
        end: {
          i: eI >= 0 ? eI : (sI ?? 0),
          j: eJ >= 0 ? eJ : (sJ ?? 0),
        },
      }

      newSelectedCellData = []
      newSelectedCells = null

      const { start: { i: rowStart, j: column }, end: { i: rowEnd } } = fixedSelectedCells
      const start = Math.min(rowStart, rowEnd)
      const end = Math.max(rowStart, rowEnd)
      const { grid } = Logic.dialogInstance.props

      for (let row = start; row <= end; row++) {
        const cell = grid[row][column]

        if (!cell.readOnly) {
          const { id } = grid[row].find(cell => cell.key === 'simulation_case')?.data ?? {}

          newSelectedCellData.push({ simulationCaseId: id, cell })
        }
      }
    }

    if (!editorProps) {
      Logic.dialogInstance.setState({
        editorProps: null,
      })

      return null
    }

    if (Logic.dialogInstance.state.openEditor) {
      Logic.dialogInstance.setState({
        editorProps,
        selectedCells: newSelectedCells,
        selectedCellData: newSelectedCellData,
      })

      return <span>{editorProps.value}</span>
    }

    return (
      <InlineEditor
        {...editorProps}
        commands={Logic.dialogInstance.state.commands}
        selectedCells={newSelectedCells}
        selectedCellData={newSelectedCellData}
      />
    )
  }

  public static handleSelect = ({ start, end }: { start: selectionPos, end: selectionPos }) => {
    const { start: oldStart } = Logic.dialogInstance.state.selectedCells

    Logic.dialogInstance.setState({ selectedCells: { start: { ...(oldStart ?? {}), ...start }, end } })
  }

  public static readonly handlePaste = async (event: ClipboardEvent) => {
    event.preventDefault()
    event.stopImmediatePropagation()

    const { selectedCells: { start, end }, commands } = Logic.dialogInstance.state
    const {
      grid,
      columns,
      currentProject,
      currentSimulationCase,
      setCurrentProject,
      setCurrentSimulationCase,
      t,
    } = Logic.dialogInstance.props

    if (!start || !end) {
      enqueueSnackbar(t(`${Logic.T}.message.paste.noSelection`), { autoHideDuration: 3000, variant: 'warning' })

      return
    }

    if (!event.clipboardData || !event.clipboardData.getData) {
      enqueueSnackbar(t(`${Logic.T}.message.paste.error`), { autoHideDuration: 3000, variant: 'error' })

      return
    }

    enqueueSnackbar(t(`${Logic.T}.message.paste.success`), { autoHideDuration: 3000, variant: 'success' })

    const startPos = { i: Math.min(start.i, end.i), j: Math.min(start.j, end.j) }
    let data: any = event.clipboardData.getData('text/plain')
    let _error = null
    let hadChanges = false

    data = data.trim().split('\n').map((row: string) => row.split('\t'))

    const dataToSave: Record<string, any> = {}
    const updatedCells: Record<string, any> = {}

    const header: Array<string> = columns.map((col: any) => typeof col.label === 'string' ? col.label : col.label.title)

    if (header.includes(data[0][0])) {
      data.shift()
    }

    const simulationCaseData = []
    const simulationCaseCells = []
    let project = currentProject

    for (let y = 0; y < data.length; y++) {
      const rowPos = y + startPos.i
      const row = grid[rowPos]

      if (!row || !row.length || !row.find(cell => cell.key === 'simulation_case')) {
        // TODO: remove log when problem is solved
        // eslint-disable-next-line no-console, max-len
        console.log(
          'No row found for ' +
          `'y + startPos.i' = ${y} + ${startPos.i} | grid.length = ${grid.length} | data.length = ${data.length}`,
        )

        continue
      }

      const { id } = row.find(cell => cell.key === 'simulation_case')?.data ?? {}
      let steelGrades: SteelGrades | undefined

      if (row.find((cell, i) => i >= startPos.j && i < startPos.j + data[y].length && cell.name === 'init_material')) {
        // TODO: add error handling!!!
        steelGrades = await CommandUtil.getSteelGrades(id)
      }

      const { defaultSteelGrades, customSteelGrades } = steelGrades ?? {}

      for (let x = 0; x < data[y].length; x++) {
        const colPos = x + startPos.j
        const cell = row[colPos]

        if (!cell || cell.readOnly) {
          if (cell.editType === 'simulationCaseName') {
            simulationCaseCells.push({ key: `${rowPos}_${colPos}`, value: 'not-pasted' })

            continue
          }

          ;(updatedCells ?? {} as any)[`${rowPos}_${colPos}`] = 'not-pasted'

          continue
        }

        const value = data[y][x].trim()

        if (cell.value === value || value === '') {
          if (cell.editType === 'simulationCaseName') {
            simulationCaseCells.push({ key: `${rowPos}_${colPos}`, value: 'pasted-same' })

            continue
          }

          ;(updatedCells ?? {} as any)[`${rowPos}_${colPos}`] = 'pasted-same'

          continue
        }

        if (cell.editType === 'simulationCaseName') {
          simulationCaseData.push({ simulationCaseId: id, name: value })
          simulationCaseCells.push({ key: `${rowPos}_${colPos}`, value: 'pasted' })

          continue
        }

        const command = CommandUtil.getCommandByName(cell.name, commands)
        const rawParameter = value.split(';')
        let parameter
        let shortName

        if (cell.name === 'init_material') {
          const info = CommandUtil.getSteelGradeInfo(
            rawParameter[0],
            command,
            defaultSteelGrades ?? [],
            customSteelGrades ?? [],
          )

          if (!info) {
            if (updatedCells) {
              updatedCells[`${rowPos}_${colPos}`] = 'not-pasted'
            }

            continue
          }

          parameter = info.parameter
          shortName = info.shortName
        }
        else {
          parameter = CommandUtil.applyParameterList(command, rawParameter)
        }

        dataToSave[id] = dataToSave[id] ?? {}
        dataToSave[id][cell.name] = {
          parameter,
          shortName,
        }

        updatedCells[`${rowPos}_${colPos}`] = 'pasted'
      }
    }

    if (simulationCaseData.length) {
      try {
        project = await MatrixUtil.updateSimulationCaseNames(
          simulationCaseData,
          project,
          currentSimulationCase,
          setCurrentProject,
          setCurrentSimulationCase,
        )

        hadChanges = true

        simulationCaseCells.forEach(simulationCaseCell => {
          updatedCells[simulationCaseCell.key] = simulationCaseCell.value
        })
      }
      catch (error: any) {
        _error = error

        simulationCaseCells.forEach(simulationCaseCell => {
          updatedCells[simulationCaseCell.key] = 'not-pasted'
        })
      }
    }

    try {
      if (Object.keys(dataToSave).length) {
        await ApiClient
          .patch(`${'Network.URI(deprecated)'}/visualization_command/update_multiple`, { data: { data: dataToSave } })

        const _project = CommandUtil.updateProjectDataWithCommandData(project, dataToSave)

        setCurrentProject(_project)

        hadChanges = true
      }

      if (_error) {
        throw _error
      }

      if (!hadChanges) {
        enqueueSnackbar(t(`${Logic.T}.message.paste.notSaved`), { autoHideDuration: 3000, variant: 'warning' })
      }
      else {
        enqueueSnackbar(
          t(`${Logic.T}.message.paste.saveSuccess`),
          { autoHideDuration: 3000, variant: 'success' },
        )
      }

      Logic.dialogInstance.setState({ updatedCells })

      setTimeout(() => Logic.dialogInstance.setState({ updatedCells: {} }), 3000)
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)

      enqueueSnackbar(t(`${Logic.T}.message.paste.saveError`), { autoHideDuration: 3000, variant: 'error' })
    }
  }

  public static handleCopy = (event: ClipboardEvent) => {
    event.preventDefault()
    event.stopImmediatePropagation()

    const { selectedCells: { start, end } } = Logic.dialogInstance.state
    const { grid, columns, t } = Logic.dialogInstance.props
    const startPos = { i: Math.min(start.i, end.i), j: Math.min(start.j, end.j) }
    const endPos = { i: Math.max(start.i, end.i), j: Math.max(start.j, end.j) }
    const data = []
    const header = []

    for (let y = startPos.i; y <= endPos.i; y++) {
      const row = []

      for (let x = startPos.j; x <= endPos.j; x++) {
        row.push(typeof grid[y][x].value === 'string' ? grid[y][x].value : grid[y][x].value.title)

        if (!data.length) {
          header.push(typeof columns[x].label === 'string' ? columns[x].label : columns[x].label.title)
        }
      }

      data.push(row.join('\t'))
    }

    data.unshift(header.join('\t'))

    if (event.clipboardData && event.clipboardData.setData) {
      event.clipboardData.setData('text/plain', data.join('\n'))

      enqueueSnackbar(t(`${Logic.T}.message.copy.success`), { autoHideDuration: 3000, variant: 'success' })

      return
    }

    enqueueSnackbar(t(`${Logic.T}.message.copy.error`), { autoHideDuration: 3000, variant: 'error' })
  }
}
