/* eslint-env browser */

import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { Component, createRef } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { getCaseVerifications } from '@/api/case-verification'
import { getNozzleCatalogs } from '@/api/nozzle-catalog'
import { useConfig } from '@/config'
import { isVerified } from '@/logic/case-verification'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import CasterColumn from './CasterColumn'
import Logic from './Logic'
import ProcessParameters from './ProcessParameters'
import SimulationCaseComponent from './SimulationCase'
import { Wrapper } from './Styles'
import VisualizationColumn from './VisualizationColumn'

const T = 'projectDataDialog'

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  catalogList: state.data.catalogList,
  caseVerifications: state.application.main.caseVerifications,
}), {
  logout: ApplicationActions.logout,
  setAppState: ApplicationActions.setAppState,
  openDialog: ApplicationActions.openDialog,
  setRequiredCommandFiles: ApplicationActions.setRequiredCommandFiles,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  updateCurrentProjectContainer: ApplicationActions.updateCurrentProjectContainer,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  setError: ErrorActions.setError,
  resetErrors: ErrorActions.resetErrors,
  setCatalogList: DataActions.setCatalogList,
  setCaseVerifications: ApplicationActions.setCaseVerifications,
  resetReducer: DataActions.resetReducer,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

export class ProjectDataDialog extends Component<Props> {
  @Identifiable('ProjectDataDialog') public static readonly NAME: string

  private readonly processParametersButtonRef = createRef()

  public override async componentDidMount () {
    Logic.init(this)

    const { currentSimulationCase, setCurrentSimulationCase, setCaseVerifications } = this.props

    ApiClient
      .get(
        `${useConfig().apiBaseURL}/cases/${currentSimulationCase.id}`,
        { params: { withCurrentCaster: true } },
      )
      .then(({ case_ }) => {
        if (!case_) {
          throw new Error('No case found')
        }

        setCurrentSimulationCase(case_)
      })
      .catch((_response) => {
        // TODO: handle error
        // console.log(response)
      })

    await this.handleGetCatalogs()

    setCaseVerifications(await getCaseVerifications(currentSimulationCase.id) ?? [])

    hotkeys('Escape', this.handleClose)
  }

  public override componentDidUpdate () {
    Logic.init(this)
  }
  
  public override componentWillUnmount (): void {
    const { resetErrors } = this.props

    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
    resetErrors()
  }

  // TODO: add snackbar
  private readonly handleGetCatalogs = async () => {
    const { currentSimulationCase, setCurrentCatalogId, t, catalogList, setCatalogList } = this.props
    let catalogs = catalogList

    try {
      if (!catalogs?.length) {
        catalogs = await getNozzleCatalogs(currentSimulationCase.id) ?? []

        setCatalogList(catalogs)
      }

      if (!catalogs?.length) {
        return
      }

      const catalogSelectors = Logic.getSelectors(
        catalogs,
        'id',
        'fileName',
        { value: t(`${T}.caster.catalog.default`), disabled: true },
      )

      setCurrentCatalogId(String(catalogSelectors[1]?.key))
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  private readonly handleClose = (isClick?: any) => {
    const { appState, closeDialog, visualizationMetaInformation, caseVerifications, currentSimulationCase } = this.props
    const { config } = visualizationMetaInformation?.[appState] ?? {}

    if (isClick && appState === AppState.ParamDashboard && config) {
      const isParametersVerified = isVerified(currentSimulationCase.id, caseVerifications, VerificationType.Parameters)

      if (
        isParametersVerified &&
        this.processParametersButtonRef &&
        this.processParametersButtonRef.current &&
        (this.processParametersButtonRef.current as any).props
      ) {
        ;(this.processParametersButtonRef.current as any).props.onClick()

        return
      }
    }

    closeDialog(ProjectDataDialog.NAME)
  }

  private readonly handleAddCatalog = (catalog: CaseFile | 'default') => {
    const { setCatalogList, setCurrentCatalogId } = this.props

    const newCatalogList = [ ...this.props.catalogList ]

    if (catalog !== 'default') {
      newCatalogList.unshift(catalog)
    }

    setCurrentCatalogId(catalog === 'default' ? 'default' : catalog.id)
    setCatalogList(newCatalogList)
  }

  private readonly handleChange = (usedAppState: AppState, event: any) => {
    const { setCurrentCatalogId, setVisualizationMetaInformation, setError } = this.props
    const { name, value } = event.target

    setError(name)

    if (value === 'add') {
      switch (name) {
        case 'catalog':
          Logic.handleUploadCasterCatalog(this.handleAddCatalog, setError)
          break
        case 'config':
          Logic.handleUploadVisualizationConfig(setVisualizationMetaInformation, setError, usedAppState)
          break
        default:
      }
    }
    else {
      switch (name) {
        case 'catalog':
          setCurrentCatalogId(value)
          break
        case 'schema':
        case 'data':
        case 'config':
          setVisualizationMetaInformation(name, value, usedAppState)
          break
        default:
      }
    }
  }

  private readonly handleChangeCaster = this.handleChange.bind(this, AppState.Caster)

  private readonly handleChangeParamDashboard = this.handleChange.bind(this, AppState.ParamDashboard)

  private readonly handleChangeResultDashboard = this.handleChange.bind(this, AppState.ResultDashboard)

  private readonly handleDelete = (event: any, type: string, key: string) => {
    const {
      updateCurrentProjectContainer,
      currentSimulationCase,
      appState,
      setAppState,
      visualizationMetaInformation,
    }: {
      updateCurrentProjectContainer: any
      currentSimulationCase: SimulationCase
      appState: 0 | 1 | 2
      setAppState: (state: AppState) => void
      visualizationMetaInformation: any
    } = event.props // TODO: this is not good!
    let name: string | null = null
    let data: any = null
    let namespace = 'visualization'

    // FIXME: none of these lists exist on the new Case type!
    switch (type) {
      case 'schema':
        name = 'visualizationSchemaList'
        data = ((currentSimulationCase as any)[name] as VisualizationSchema[]).filter(val => val.schemaId !== key)
        break
      case 'config':
        name = 'visualizationConfigList'
        data = ((currentSimulationCase as any)[name] as VisualizationConfig[]).filter(val => val.id !== key)
        break
      case 'data':
        name = 'visualizationDataList'
        data = ((currentSimulationCase as any)[name] as any[]).filter(val => val.dataId !== key)
        break
      case 'catalog':
        namespace = 'caster'
        name = 'casterCatalogList'
        data = ((currentSimulationCase as any)[name] as CasterCatalog[]).filter(val => val.catalogId !== key)
        break
      default:
        return null
    }

    const { config } = visualizationMetaInformation?.[appState] ?? {}

    if (type === 'config' && config === key) {
      setAppState(AppState.Caster)
    }

    ApiClient
      .del(`${'Network.URI(deprecated)'}/${namespace}_${type}/${key}`)
      .then(() => updateCurrentProjectContainer(name, data))
      // eslint-disable-next-line no-console
      .catch((error: Error) => console.log(error))
  }

  private readonly handleDeleteSchema = this.handleDelete.bind('schema', this)

  private readonly handleDeleteConfig = this.handleDelete.bind('config', this)

  private readonly handleDeleteData = this.handleDelete.bind('data', this)

  private readonly handleDeleteCatalog = this.handleDelete.bind('catalog', this)
  
  public override render () {
    const {
      t,
      featureFlags,
      currentProject,
      appState,
      currentSimulationCase,
      visualizationMetaInformation,
    } = this.props
    const casterViewer = FeatureFlags.canViewCaster(featureFlags)
    const canViewResultVisualization = FeatureFlags.canViewResultDashboard(featureFlags)

    const { config, data } = visualizationMetaInformation?.[appState] ?? {}

    return (
      <BaseDialog
        id={DialogID.ProjectData.ID}
        title={currentProject.description || t(`${T}.title`)}
        icon='pe-7s-folder'
        header={currentProject.name || t(`${T}.header`)}
        onClose={this.handleClose.bind(this, true)}
        hideCloseButton={
          (appState === AppState.Caster && !currentSimulationCase.currentCasterId) ||
          (appState === AppState.ParamDashboard && !config) ||
          (appState === AppState.ResultDashboard && (!data || data === 'default' || !config))
        }
      >
        <SimulationCaseComponent />
        <Wrapper>
          {
            casterViewer &&
            (
              <CasterColumn
                onClose={this.handleClose}
                onChange={this.handleChangeCaster}
                onDeleteConfig={this.handleDeleteConfig}
                onDeleteCatalog={this.handleDeleteCatalog}
              />
            )
          }
          <ProcessParameters
            onClose={this.handleClose}
            onChange={this.handleChangeParamDashboard}
            onDeleteConfig={this.handleDeleteConfig}
            processParametersButtonRef={this.processParametersButtonRef}
          />
          {
            canViewResultVisualization &&
            (
              <VisualizationColumn
                onClose={this.handleClose}
                onChange={this.handleChangeResultDashboard}
                onDeleteConfig={this.handleDeleteConfig}
                onDeleteData={this.handleDeleteData}
                onDeleteSchema={this.handleDeleteSchema}
              />
            )
          }
        </Wrapper>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(ProjectDataDialog)

export default hoistStatics(composedComponent, ProjectDataDialog)
