import type { CreateValid, DataState } from '@/types/data'

export enum DataActionsEnum {
  ACTION_RESET_CHANGES = 'data/ACTION_RESET_CHANGES',
  ACTION_RESET_ALL = 'data/ACTION_RESET_ALL',
  ACTION_SAVE_ELEMENT_REQUEST = 'data/ACTION_SAVE_ELEMENT_REQUEST',
  ACTION_SAVE_ELEMENT_SUCCESS = 'data/ACTION_SAVE_ELEMENT_SUCCESS',
  ACTION_SAVE_ELEMENT_ERROR = 'data/ACTION_SAVE_ELEMENT_ERROR',
  ACTION_CREATE_ELEMENT_REQUEST = 'data/ACTION_CREATE_ELEMENT_REQUEST',
  ACTION_CREATE_ELEMENT_SUCCESS = 'data/ACTION_CREATE_ELEMENT_SUCCESS',
  ACTION_CREATE_ELEMENT_ERROR = 'data/ACTION_CREATE_ELEMENT_ERROR',
  ACTION_SAVE_CATALOG = 'data/ACTION_SAVE_CATALOG',
  ACTION_SET_CATALOG_ELEMENT = 'data/ACTION_SET_CATALOG_ELEMENT',
  ACTION_SELECT_EDIT_DATA = 'data/ACTION_SELECT_EDIT_DATA',
  ACTION_SET_ELEMENTS = 'data/ACTION_SET_ELEMENTS',
  ACTION_SET_PARENT_PATH = 'data/ACTION_SET_PARENT_PATH',
  ACTION_ADD_DIRTY_PATH = 'data/ACTION_ADD_DIRTY_PATH',
  ACTION_CLEAR_DIRTY_PATHS = 'data/ACTION_CLEAR_DIRTY_PATHS',
  ACTION_ADD_DIRTY_DELETE_PATH = 'data/ACTION_ADD_DIRTY_DELETE_PATH',
  ACTION_ADD_PENDING_DELETE_LIST = 'data/ACTION_ADD_PENDING_DELETE_LIST',
  ACTION_CLEAR_DIRTY_DELETE_PATHS = 'data/ACTION_CLEAR_DIRTY_DELETE_PATHS',
  ACTION_SET_ACTIVE_EDIT_TAB = 'data/ACTION_SET_ACTIVE_EDIT_TAB',
  ACTION_EDIT_CHANGES = 'data/ACTION_EDIT_CHANGES',
  ACTION_VALIDATION_INPUT = 'data/ACTION_VALIDATION_INPUT',
  ACTION_EDIT_VALUES = 'data/ACTION_EDIT_VALUES',
  ACTION_UNDO_ELEMENT_CHANGES = 'data/ACTION_UNDO_ELEMENT_CHANGES',
  ACTION_RESET_GAP_WARNINGS = 'data/ACTION_RESET_GAP_WARNINGS',
  ACTION_OVERWRITE_ALL_REQUEST = 'data/ACTION_OVERWRITE_ALL_REQUEST',
  ACTION_OVERWRITE_ALL_SUCCESS = 'data/ACTION_OVERWRITE_ALL_SUCCESS',
  ACTION_OVERWRITE_ALL_ERROR = 'data/ACTION_OVERWRITE_ALL_ERROR',
  ACTION_MERGE_NEW_REQUEST = 'data/ACTION_MERGE_NEW_REQUEST',
  ACTION_MERGE_NEW_SUCCESS = 'data/ACTION_MERGE_NEW_SUCCESS',
  ACTION_MERGE_NEW_ERROR = 'data/ACTION_MERGE_NEW_ERROR',
  ACTION_SET_ADDITIONAL_DATA_REQUEST = 'data/ACTION_SET_ADDITIONAL_DATA_REQUEST',
  ACTION_SET_ADDITIONAL_DATA_SUCCESS = 'data/ACTION_SET_ADDITIONAL_DATA_SUCCESS',
  ACTION_SET_ADDITIONAL_DATA_ERROR = 'data/ACTION_SET_ADDITIONAL_DATA_ERROR',
  ACTION_SET_CURRENT_CATALOG_ID = 'data/ACTION_SET_CURRENT_CATALOG_ID',
  ACTION_SET_CATALOG_LIST = 'data/ACTION_SET_CATALOG_LIST',
  ACTION_SET_UPDATE_NOTIFICATIONS = 'data/ACTION_SET_UPDATE_NOTIFICATIONS',
  ACTION_APPLY_UPDATES = 'data/ACTION_APPLY_UPDATES',
  ACTION_SET_LIVE_MODE = 'data/ACTION_SET_LIVE_MODE',
  ACTION_APPLY_CHANGES_REQUEST = 'data/ACTION_APPLY_CHANGES_REQUEST',
  ACTION_APPLY_CHANGES_SUCCESS = 'data/ACTION_APPLY_CHANGES_SUCCESS',
  ACTION_APPLY_CHANGES_ERROR = 'data/ACTION_APPLY_CHANGES_ERROR',
  ACTION_REMOVE_DELETE_PATHS = 'data/ACTION_REMOVE_DELETE_PATHS',
  ACTION_GET_EXECUTABLE_DEFINITIONS_REQUEST = 'data/ACTION_GET_EXECUTABLE_DEFINITIONS_REQUEST',
  ACTION_GET_EXECUTABLE_DEFINITIONS_SUCCESS = 'data/ACTION_GET_EXECUTABLE_DEFINITIONS_SUCCESS',
  ACTION_GET_EXECUTABLE_DEFINITIONS_ERROR = 'data/ACTION_GET_EXECUTABLE_DEFINITIONS_ERROR',
  ACTION_EXECUTE_EXECUTABLE_DEFINITION_REQUEST = 'data/ACTION_EXECUTE_EXECUTABLE_DEFINITION_REQUEST',
  ACTION_EXECUTE_EXECUTABLE_DEFINITION_SUCCESS = 'data/ACTION_EXECUTE_EXECUTABLE_DEFINITION_SUCCESS',
  ACTION_EXECUTE_EXECUTABLE_DEFINITION_ERROR = 'data/ACTION_EXECUTE_EXECUTABLE_DEFINITION_ERROR',
  ACTION_SET_EXECUTION_STATE = 'data/ACTION_SET_EXECUTION_STATE',
  ACTION_SET_MANY_EXECUTION_STATES = 'data/ACTION_SET_MANY_EXECUTION_STATES',
  ACTION_SET_EXECUTION_DATA = 'data/ACTION_SET_EXECUTION_DATA',
  ACTION_SET_NEW_ELEMENTS_TO_DRAW = 'data/ACTION_SET_NEW_ELEMENTS_TO_DRAW',
}

export const initialState: DataState = {
  parsedJsonData: {},
  massValues: {},
  massForm: {},
  editElements: {},
  editElementsInitial: {},
  catalogElement: {},
  loopCounter: {},
  parentPath: '',
  catalog: [],
  catalogList: [],
  dirtyPaths: [],
  dirtyDeletePaths: [],
  pendingDeleteList: [],
  selectedPaths: new Set<string>(),
  allPaths: new Set<string>(),
  allChildrenSelectedPaths: new Set<string>(),
  hidePaths: [],
  activeEditTab: 0,
  hasChanges: false,
  loading: false,
  hasEditChanges: undefined,
  editValues: {} as any,
  createValid: {} as CreateValid,
  additionalData: {},
  gapWarnings: 0,
  currentCatalogId: undefined,
  updatesCount: 0,
  updates: [],
  liveMode: false,
  executableDefinitions: [],
  executionStateDictionary: {},
  newCopiedElementsToDraw: false,
  execution: null,
}
