import type { ElementMaps, MountLogMapKey } from '@/types/state'

function addToModules (
  element: BaseMountLog & { module: string },
  type: MountLogMapKey,
  modules: Record<string, Module>,
) {
  const inModules = element.module.split(' ')

  for (const module of inModules) {
    if (!modules[module]) {
      modules[module] = {
        [type]: {
          [element.id]: 1 as const,
        },
      }
    }
    else if (!modules[module][type]) {
      modules[module][type] = {
        [element.id]: 1 as const,
      }
    }
    else {
      modules[module][type][element.id] = 1 as const
    }
  }
}

export enum moduleActions {
  ACTION_ADD_MODULES = 'MODULES/ACTION_ADD_MODULES',
  DELETE = 'MODULES/DELETE',
  UPDATE = 'MODULES/UPDATE',
}

export function updateModules (data: ElementMaps) {
  const modules: Record<string, Module> = {}

  const elementMapsKeys = Object.keys(data) as (keyof ElementMaps)[]

  elementMapsKeys.forEach(type => {
    if (!type.endsWith('MountLog') || !data[type]) {
      return
    }

    Object.values(data[type]).forEach((element: BaseMountLog) => {
      if ('module' in element && element.module && typeof element.module === 'string') {
        addToModules(element as BaseMountLog & { module: string }, type as MountLogMapKey, modules)
      }
    })
  })

  return {
    type: moduleActions.ACTION_ADD_MODULES,
    modules,
  }
}

export function addModules (modules: Record<string, Module>) {
  return {
    type: moduleActions.ACTION_ADD_MODULES,
    modules,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const modulesReducers: Record<string, Function | undefined> = {
  [moduleActions.ACTION_ADD_MODULES]: (state: any, action: { modules: Record<string, Module> }) => ({
    ...action.modules,
  }),
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = modulesReducers[action.type]

  return handler ? handler(state, action) : state
}
