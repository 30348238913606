import { StrandSides } from '@/types/elements/enum'
import { ElementMaps, ElementName, MountLogMapKey, TagName } from '@/types/state'

import { Mapping } from './Mapping'
import { ElementMapsUtil } from '../ElementMapsUtil'

export type NumericIdByMountLogId = Record<string, number>

export type MountLogIdByTypeAndNumericId = Record<TagName, Record<number, string>>

type NumericMountLogMapKey = Exclude<
  MountLogMapKey,
  | 'CoolingZoneMountLog'
  | 'LoopAssignmentMountLog'
  | 'MoldBCAreaMountLog'
  | 'MoldFaceMountLog'
  | 'MoldMountLog'
  | 'PasslineMountLog'
  | 'PasslineSectionMountLog'
  | 'StrandGuideMountLog'
>

type Context = {
  elementMaps: ElementMaps
  numericIdByMountLogId: NumericIdByMountLogId
  mountLogIdByTypeAndNumericId: MountLogIdByTypeAndNumericId
  dataPointIdCounter: number
  sensorPointIdCounter: number
}

export class NumericIdMapping {
  private static readonly dataPointMountLogKeys: MountLogMapKey[] = [
    'RollerDataPointMountLog',
    'RollerBodyDataPointMountLog',
    'SegmentDataPointMountLog',
    'StrandDataPointMountLog',
    'MoldFaceDataPointMountLog',
  ]

  private static readonly sensorPointMountLogKeys: MountLogMapKey[] = [
    'RollerSensorPointMountLog',
    'RollerBodySensorPointMountLog',
    'SegmentSensorPointMountLog',
  ]

  private static readonly ignoreMountLogTypes: MountLogMapKey[] = [
    'CoolingZoneMountLog',
    'LoopAssignmentMountLog',
    'MoldMountLog',
    'MoldBCAreaMountLog',
    'MoldFaceMountLog',
    'PasslineMountLog',
    'PasslineSectionMountLog',
    'StrandGuideMountLog',
  ]

  private static readonly sideOrder = [
    StrandSides.Fixed,
    StrandSides.Loose,
    StrandSides.Left,
    StrandSides.Right,
  ]

  private static buildType (context: Context, mountLogMapKey: NumericMountLogMapKey, mountLogs: BaseMountLog[]) {
    const isDataPoint = NumericIdMapping.dataPointMountLogKeys.includes(mountLogMapKey)
    const isSensorPoint = NumericIdMapping.sensorPointMountLogKeys.includes(mountLogMapKey)

    const tagName = !isDataPoint && !isSensorPoint
      ? mountLogMapKey.replace('MountLog', '') as TagName
      : (
        isDataPoint
          ? 'DataPoint'
          : 'SensorPoint'
      )

    context.mountLogIdByTypeAndNumericId[tagName] = context.mountLogIdByTypeAndNumericId[tagName] ?? {}

    for (let i = 0; i < mountLogs.length; i++) {
      const mountLogId = mountLogs[i].id
      const numericId = !isDataPoint && !isSensorPoint
        ? i
        : (
          isDataPoint
            ? context.dataPointIdCounter++
            : context.sensorPointIdCounter++
        )

      context.numericIdByMountLogId[mountLogId] = numericId
      context.mountLogIdByTypeAndNumericId[tagName][numericId] = mountLogId
    }
  }

  // order by passlineCoord but first comes the fixed side, then the loose side, then left, then right
  private static buildRoller (context: Context) {
    const { elementMaps } = context
    const { RollerMountLog, RollerSlot } = elementMaps
    const sortedRollerMountLogs = Object
      .values(RollerMountLog ?? {})
      .toSorted((a, b) => {
        const sideA = RollerSlot[a.slotId]?.side
        const sideB = RollerSlot[b.slotId]?.side
        const sideAIndex = !sideA ? -1 : NumericIdMapping.sideOrder.indexOf(sideA)
        const sideBIndex = !sideB ? -1 : NumericIdMapping.sideOrder.indexOf(sideB)

        if (sideAIndex === sideBIndex) {
          const slotA = ElementMapsUtil.getSlotByMountLog('Roller', a, elementMaps)
          const slotB = ElementMapsUtil.getSlotByMountLog('Roller', b, elementMaps)

          return (slotA?.passlineCoord ?? 0) - (slotB?.passlineCoord ?? 0)
        }

        return sideAIndex - sideBIndex
      })

    NumericIdMapping.buildType(context, 'RollerMountLog', sortedRollerMountLogs)
  }

  public static build (elementMaps: ElementMaps, caseId?: string) {
    const context: Context = {
      elementMaps,
      numericIdByMountLogId: {},
      mountLogIdByTypeAndNumericId: {} as MountLogIdByTypeAndNumericId,
      dataPointIdCounter: 0,
      sensorPointIdCounter: 0,
    }

    for (const key in elementMaps) {
      const mapKey = key as NumericMountLogMapKey

      if (!mapKey.endsWith('MountLog') || NumericIdMapping.ignoreMountLogTypes.includes(mapKey)) {
        continue
      }

      if (mapKey === 'RollerMountLog') {
        NumericIdMapping.buildRoller(context)

        continue
      }

      const mountLogMap = elementMaps[mapKey]
      const elementName = mapKey.replace('MountLog', '') as ElementName
      const sortedMountLogs = Object
        .keys(mountLogMap)
        .toSorted((a, b) => {
          const slotA = ElementMapsUtil.getSlotByMountLog(elementName, mountLogMap[a], elementMaps)
          const slotB = ElementMapsUtil.getSlotByMountLog(elementName, mountLogMap[b], elementMaps)

          return (slotA?.passlineCoord ?? 0) - (slotB?.passlineCoord ?? 0)
        })
        .map(id => mountLogMap[id])

      NumericIdMapping.buildType(context, mapKey, sortedMountLogs)
    }

    if (!caseId) {
      Mapping.numericIdByMountLogId = context.numericIdByMountLogId
      Mapping.mountLogIdByTypeAndNumericId = context.mountLogIdByTypeAndNumericId

      return
    }

    Mapping.numericIdByCaseIdAndMountLogId[caseId] = context.numericIdByMountLogId
    Mapping.mountLogIdByCaseIdAndTypeAndNumericId[caseId] = context.mountLogIdByTypeAndNumericId
  }
}
