import Stats from 'stats.js'
import * as THREE from 'three'

import { PassedData } from '@/react/Caster'
import { isTarget } from '@/Util/ElementUtil'

import TextureUtil from './logic/TextureUtil'
import AxisView from './views/AxisView'
import MainView from './views/MainView'
import SectionView from './views/SectionView'
import UIView from './views/UIView'
import UiViewHandlers from './views/UIView/UiViewHandlers'
import ViewsView from './views/ViewsView'

export type Views = {
  mainView?: MainView
  sectionView?: SectionView
  axisView?: AxisView
  viewsView?: ViewsView
  uiView?: UIView
}

export default class ThreeBase {
  public static readonly FullFPS = 60

  public static readonly LowFPS = 5

  public isInitialized = false

  private skipRendering = false

  private fpsTarget = ThreeBase.LowFPS

  private rate = 1000 / this.fpsTarget

  private stats: Stats | null = null

  public views: Views = {}

  private renderer?: THREE.WebGLRenderer

  private bgColor?: number | string

  public container?: any

  private width?: number

  private height?: number

  private lastFrame?: number

  private frameId?: number

  private resizeTimeout: number | null = null

  private scrollTimeout: number | null = null

  public kill () {
    if (this.stats && this.stats.dom) {
      this.container.removeChild(this.stats.dom)
    }
  }

  public init (container: any) {
    this.isInitialized = true

    this.container = container

    this.width = -1
    this.height = -1

    // const start = Date.now()

    this.rate = 1000 / this.fpsTarget
    this.lastFrame = 0
    this.skipRendering = false
    // this.lastFrameTime = start

    this.renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: 'high-performance' })
    this.renderer.toneMapping = THREE.LinearToneMapping
    this.renderer.autoClear = false
    this.bgColor = 0x000000
    this.renderer.setClearColor(this.bgColor)

    // add event listeners to handle mouse leave
    this.renderer.domElement.addEventListener('mouseleave', this.handleRendererMouseLeave, false)

    TextureUtil.init()

    this.views = {}
    this.views.mainView = new MainView(this.renderer, this.views)
    this.views.sectionView = new SectionView(this.renderer, this.views)
    this.views.axisView = new AxisView(this.renderer, this.views)
    this.views.viewsView = new ViewsView(this.renderer, this.views)
    this.views.uiView = new UIView(this.renderer, this.views)

    this.handleResize()

    this.renderer.domElement.setAttribute('draggable', 'false')

    this.container.appendChild(this.renderer.domElement)

    if (window.meta?.DEV) {
      this.stats = new Stats()

      this.stats.dom.style.position = 'absolute' // TODO: verify that works, changed domElement to dom
      this.stats.dom.style.left = '0px'
      this.stats.dom.style.bottom = '0px'

      this.container.appendChild(this.stats.dom)
    }

    setTimeout(this.start.bind(this), 1)
  }

  public setFpsTarget (fps: number) {
    this.fpsTarget = fps > 0 ? fps : 1
    this.rate = 1000 / this.fpsTarget
  }

  public updateSegments (segmentPaths: string[], newName: string) {
    this.views.mainView?.updateSegments(segmentPaths, newName)
  }

  public setData (data: PassedData) { // TODO: verify if only change is mouse position
    this.views.sectionView?.setData(data)
    this.views.mainView?.setData(data)
    this.views.uiView?.setData(data)
  }

  public mount () {
    window.addEventListener('resize', this.handleResize, false)

    window.addEventListener('pointerdown', this.handleMouseDown, false)
    window.addEventListener('pointerup', this.handleMouseUp, false)
    window.addEventListener('pointermove', this.handleMouseMove, false)

    document.addEventListener('mouseenter', this.handleMouseEnter, false)
    document.addEventListener('mouseleave', this.handleMouseLeave, false)

    window.addEventListener('wheel', this.handleMouseWheel, false)

    window.addEventListener('keydown', this.handleKeyDown, false)
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  public unmount () {
    this.stop()

    window.removeEventListener('resize', this.handleResize, false)

    window.removeEventListener('pointerdown', this.handleMouseDown, false)
    window.removeEventListener('pointerup', this.handleMouseUp, false)
    window.removeEventListener('pointermove', this.handleMouseMove, false)

    document.removeEventListener('mouseenter', this.handleMouseEnter, false)
    document.removeEventListener('mouseleave', this.handleMouseLeave, false)

    window.removeEventListener('wheel', this.handleMouseWheel, false)

    window.removeEventListener('keydown', this.handleKeyDown, false)
    window.removeEventListener('keyup', this.handleKeyUp, false)

    this.renderer?.domElement.removeEventListener('mouseleave', this.handleRendererMouseLeave, false)

    this.container.removeChild(this.renderer?.domElement)

    Object.values(this.views).forEach((view: any) => view.unmount())
    Object.keys(this.views).forEach(key => {
      delete this.views[key as keyof Views]
    })
  }

  public handleResize = () => {
    if (this.container) {
      const { width = 0, height = 0 } = this
      const { clientWidth, clientHeight } = this.container

      if (width > 0 && height > 0 && clientWidth === width && clientHeight === height) {
        return
      }

      this.width = clientWidth
      this.height = clientHeight

      this.setFpsTarget(ThreeBase.FullFPS)

      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout)
      }

      Object.values(this.views ?? {}).forEach((view: any) => view.resize(clientWidth, clientHeight))

      this.renderer?.setSize(clientWidth, clientHeight)

      this.resizeTimeout = window.setTimeout(() => {
        this.setFpsTarget(ThreeBase.LowFPS)
        this.resizeTimeout = null
      }, 500)
    }
  }

  private readonly handleMouseDown = (event: any) => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }

    if (isTarget(event, this.container)) {
      this.setFpsTarget(ThreeBase.FullFPS)
    }
    else {
      this.setFpsTarget(0)
    }

    const { clientX, clientY } = event
    const { x, y } = this.container.getBoundingClientRect()
    const mouseOnCanvas = new THREE.Vector2(clientX - x, clientY - y)

    const views: any = Object.values(this.views ?? {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleMouseDown(event, mouseOnCanvas) === false) {
        break
      }
    }
  }

  private readonly handleMouseUp = (event: any) => {
    this.setFpsTarget(ThreeBase.LowFPS)

    const { clientX, clientY } = event
    const { x, y } = this.container.getBoundingClientRect()
    const mouseOnCanvas = new THREE.Vector2(clientX - x, clientY - y)

    const views: any = Object.values(this.views ?? {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleMouseUp(event, mouseOnCanvas) === false) {
        break
      }
    }
  }

  private readonly handleMouseMove = (event: MouseEvent) => {
    const { clientX, clientY, buttons } = event

    if (buttons !== 0 && this.fpsTarget !== ThreeBase.FullFPS && isTarget(event, this.container)) {
      this.setFpsTarget(ThreeBase.FullFPS)
    }

    const { x, y } = this.container.getBoundingClientRect()
    const mouseOnCanvas = new THREE.Vector2(clientX - x, clientY - y)

    const views: any = Object.values(this.views ?? {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleMouseMove(event, mouseOnCanvas) === false) {
        break
      }
    }
  }

  private readonly handleMouseEnter = (_event: any) => {
    this.setFpsTarget(ThreeBase.LowFPS)
  }

  private readonly handleMouseLeave = (_event: any) => {
    this.setFpsTarget(0)
  }

  private readonly handleRendererMouseLeave = (_event: any) => {
    const views: any = Object.values(this.views ?? {}).reverse()

    for (let i = 0; i < views.length; i++) {
      views[i].handleMouseLeave?.()
    }
  }

  private readonly handleMouseWheel = (event: any) => {
    if (isTarget(event, this.container)) {
      this.setFpsTarget(ThreeBase.FullFPS)
    }

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }

    this.scrollTimeout = window.setTimeout(() => {
      this.setFpsTarget(ThreeBase.LowFPS)

      this.scrollTimeout = null
    }, 300)
  }

  private readonly handleKeyDown = (event: any) => {
    const views: any = Object.values(this.views ?? {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleKeyDown(event) === false) {
        break
      }
    }
  }

  private readonly handleKeyUp = (event: any) => {
    const views: any = Object.values(this.views ?? {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleKeyUp(event) === false) {
        break
      }
    }
  }

  public readonly toggleStrandBending = () => {
    if (this.views.mainView) {
      this.views.mainView.applyCurve = !this.views.mainView.applyCurve
      this.views.mainView.updateTransforms()
      this.views.sectionView?.setView(undefined, true)
    }
  }

  public jumpToFiltered (isCenterButton = false) {
    if (isCenterButton) {
      this.views.mainView?.jumpToFiltered()

      return
    }

    if (!this.views.uiView) {
      return
    }

    UiViewHandlers.handleJumpToSection(this.views.uiView)
  }

  public jumpToFilter (term: string, callback?: () => void, isJumpToSectionView = false) {
    this.views.mainView?.jumpToFilter(term, callback, isJumpToSectionView)
  }

  private start () {
    if (!this.frameId) {
      this.frameId = window.requestAnimationFrame(this.animate.bind(this))
    }
  }

  private stop () {
    if (this.frameId) {
      window.cancelAnimationFrame(this.frameId)
    }
  }

  private animate (elapsed: number) {
    this.stats?.begin()

    this.frameId = window.requestAnimationFrame(this.animate.bind(this))

    const frame = (0.5 + (elapsed / (this.rate || 1))) | 0

    if (this.skipRendering || frame === this.lastFrame) {
      return
    }

    this.lastFrame = frame

    Object.values(this.views ?? {}).forEach((view: any) => view.animate(elapsed))

    this.renderScene()

    this.stats?.end()
  }

  private readonly renderScene = () => {
    this.renderer?.clear()

    Object.values(this.views ?? {}).forEach((view: any) => view.render())
  }

  public cleanViews (viewsToBeCleaned: string[]) {
    Object.values(this.views ?? {}).forEach((view: any) => {
      if (!viewsToBeCleaned.includes(view.className)) {
        return
      }

      if (view.scene) {
        this.clearThree(view.scene)
        view.clickableObjects.length = 0
      }

      if (view.renderer) {
        view.renderer.renderLists.dispose()
      }
    })
  }

  private clearThree (obj: any) {
    const avoidedElements = [ 'AmbientLight', 'DirectionalLightGroup', 'sectionPlaneFolded', 'SectionPlane' ]

    for (const child of obj.children) {
      if (!avoidedElements.includes(child.name)) {
        this.clearThree(child)
        obj.remove(child)
      }
    }

    if (obj.geometry) {
      obj.geometry.dispose()
    }

    if (obj.material) {
      // in case of map, bumpMap, normalMap, envMap ...
      Object.keys(obj.material).forEach(prop => {
        if (!obj.material[prop]) {
          return
        }

        if (obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function') {
          obj.material[prop].dispose()
        }
      })

      if (obj.material.dispose) {
        obj.material.dispose()
      }
    }
  }
}
