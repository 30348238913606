import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import TimeUtil from '@/logic/TimeUtil'
import Button from '@/react/components/Button'
import { DefaultState } from '@/types/state'

import Logic, { T } from './Logic'
import type { Props, State } from './Logic'
import { ButtonWrapper, Change, Content, EditContent, Spinner, Wrapper } from './styles'

const connector = connect(({ application: { main }, data }: DefaultState) => ({
  catalogList: data.catalogList,
  currentSimulationCase: main.currentSimulationCase,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

export class EditBoxWrapper extends Component<Props & PropsFromRedux, State> {
  public override state: State = {
    content: '',
    editContent: '',
    change: {},
    isEditing: false,
    savePending: false,
    loading: false,
  }
  
  public override componentDidMount (): void {
    this.handleLoadData()
  }
  
  public override componentDidUpdate (prevProps: Props, prevState: State) {
    const { isEditing } = this.state
    const { configId, onChildState } = this.props

    if (prevState.isEditing !== isEditing) {
      onChildState({ isEditing }) // FIXME: TypeError: onChildState is not a function
    }

    if (configId !== prevProps.configId) {
      this.handleLoadData()
    }
  }

  private readonly handleLoadData = () => {
    const { configId, currentSimulationCase, t } = this.props

    this.setState({ loading: true })

    setTimeout(() => {
      Logic
        .getContent(configId, currentSimulationCase.id)
        .then(({ content, change }) => this.setState({ content, change, editContent: content, loading: false }))
        .catch(() => {
          this.setState({ loading: false })

          enqueueSnackbar(
            t(`${T}.loading.error`),
            { autoHideDuration: 2000, variant: 'error', key: 'editable_file_loading_error', preventDuplicate: true },
          )
        })
    }, 0)
  }

  private readonly handleEdit = () => {
    const { currentSimulationCase, configId, catalogList } = this.props
    const isFrozen = Logic.isFrozen(currentSimulationCase)
    const isOnlyViewable = Logic.isOnlyViewable(currentSimulationCase, configId, catalogList)

    if (!isFrozen && !isOnlyViewable) {
      this.setState({ isEditing: true })
    }
  }

  private readonly handleChange = (event: any) => {
    const { value } = event.target

    this.setState({ editContent: value })
  }

  private readonly handleSave = () => {
    const { editContent } = this.state
    const { configId, currentSimulationCase, t } = this.props

    this.setState({ savePending: true })

    Logic
      .patchContent(configId, currentSimulationCase.id, editContent)
      .then(({ change }) => {
        this.setState({ savePending: false, isEditing: false, content: editContent, change })

        enqueueSnackbar(t(`${T}.save.success`), { autoHideDuration: 2000, variant: 'success' })
      })
      .catch(() => {
        this.setState({ savePending: false })

        enqueueSnackbar(t(`${T}.save.error`), { autoHideDuration: 2000, variant: 'error' })
      })
  }

  private readonly handleCancel = () => {
    const { content } = this.state

    this.setState({ isEditing: false, editContent: content })
  }

  private readonly handleWheel = (event: any) => {
    event.stopPropagation()
  }
  
  public override render () {
    const { content, editContent, change, isEditing, savePending, loading } = this.state
    const { t, currentSimulationCase, configId, tileId, catalogList } = this.props
    const { authorName, createdAt } = change ?? {}
    const isFrozen = Logic.isFrozen(currentSimulationCase)
    const isOnlyViewable = Logic.isOnlyViewable(currentSimulationCase, configId, catalogList)

    if (loading) {
      return (
        <Wrapper>
          <Spinner disableShrink />
        </Wrapper>
      )
    }

    if (!isEditing || isFrozen || isOnlyViewable) {
      return (
        <Wrapper>
          <Content
            onWheel={this.handleWheel}
            id={`editable_content_${tileId}`}
            $isFrozen={isFrozen || isOnlyViewable}
            onDoubleClick={this.handleEdit}
            title={!isFrozen ? (isOnlyViewable ? '' : t(`${T}.content.edit`)) : t(`${T}.content.view`)}
          >
            {content}
          </Content>
          <Change>
            {
              createdAt
                ? t(`${T}.changed.message`, { user: authorName, dateTime: TimeUtil.getDisplayDateTime(createdAt) })
                : (!isFrozen && !isOnlyViewable ? t(`${T}.changed.not_yet`) : t(`${T}.changed.view`))
            }
          </Change>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <EditContent onWheel={this.handleWheel} onChange={this.handleChange} disabled={savePending}>
          {editContent}
        </EditContent>
        <ButtonWrapper>
          <Button onClick={this.handleSave} title={t(`${T}.content.save.title`)} disabled={savePending} half>
            {t(`${T}.content.save.label`)}
          </Button>
          <Button onClick={this.handleCancel} title={t(`${T}.content.cancel.title`)} disabled={savePending} half>
            {t(`${T}.content.cancel.label`)}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(EditBoxWrapper)
