import { faPencilAlt, faReply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import FilterButton from '@/react/specific/FilterButton'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import InputField from './InputField'
import NumberField from './NumberField'
import SelectorField from './SelectorField'
import { FormLabel, FromField, InlineButton } from '../InputStyles'

const connector = connect((state: DefaultState) => ({
  term: state.filter.term,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

const NoFilterSpacer = styled.div`
display: inline-block;
width: 20px;
text-align: center;
`

interface Props extends PropsFromRedux {
  onlyPositiveNumbers?: boolean
  hideValue?: boolean
  error?: boolean
  hasFilter?: boolean
  disabled?: boolean
  name: string
  title?: string
  elementType: string
  value: string
  label: any
  type: 'number' | 'select' | 'text'
  min?: number
  max?: number
  step?: number
  decimals?: number
  options: Array<any>
  onChange: (e: any) => void
  handleUndo: (event: any) => void
  t: Translation
  key: any
  elementKey?: string
  onCommentChange?: (typeAndKey: { type?: string, key?: string }, e: any) => void
  isComparingCasters?: boolean
}

type State = {
  noMass?: boolean
}

export class Input extends Component<Props, State> {
  private textInput: any = null

  public override state: State = {
    noMass: true,
  }

  public static getDerivedStateFromProps (nextProps: Props) {
    return {
      noMass: nextProps.error,
    }
  }

  public override componentDidMount () {
    this.setState({
      noMass: this.props.error,
    })
  }

  private readonly handleInputFocus = () => {
    this.focusTextInput()
  }

  private readonly handleCommentChange = (e: any) => {
    const { onCommentChange, elementType, elementKey } = this.props

    if (onCommentChange) {
      onCommentChange({ type: elementType, key: elementKey }, e)
    }
  }

  private readonly getInput = () => {
    const {
      type,
      min,
      max,
      step,
      decimals,
      value,
      label,
      disabled,
      options,
      name,
      hideValue,
      onChange,
      t,
      onCommentChange,
      isComparingCasters,
      onlyPositiveNumbers,
    } = this.props
    const { noMass } = this.state
    const mass = noMass === false
    const massValue = mass ? '' : value
    const placeholder = (mass && !hideValue) ? 'Multi-Value' : (typeof label === 'string' ? label : name)

    if (isComparingCasters) {
      return (
        <InputField
          isDisabled
          massValue={hideValue ? undefined : massValue}
          placeholder={placeholder}
          onChange={!onCommentChange ? onChange : this.handleCommentChange}
          name={t(name)}
          inputRef={this.setTextInputRef}
          style={
            {
              maxWidth: '57px',
              paddingRight: '3px',
              marginRight: '10px',
            }
          }
        />
      )
    }

    switch (type) {
      case 'select':
        return (
          <SelectorField
            isDisabled={disabled}
            title={hideValue ? '' : t(massValue)}
            massValue={hideValue ? '' : (massValue ?? '').toString()}
            selectors={hideValue ? [] : options}
            onChange={onChange}
            name={t(name)}
            style={{ maxWidth: '75px' }}
          />
        )
      case 'number':
        return (
          <NumberField
            isDisabled={disabled}
            massValue={hideValue ? undefined : mass ? '' : value}
            placeholder={placeholder}
            onChange={onChange}
            mass={hideValue ? undefined : mass}
            value={hideValue ? undefined : value}
            name={t(name)}
            inputRef={this.setTextInputRef}
            style={{ maxWidth: '75px' }}
            onlyPositiveNumbers={onlyPositiveNumbers}
            min={min}
            max={max}
            step={step}
            decimals={decimals}
          />
        )
      default:
        return (
          <InputField
            isDisabled={disabled}
            massValue={hideValue ? undefined : massValue}
            placeholder={placeholder}
            onChange={!onCommentChange ? onChange : this.handleCommentChange}
            name={t(name)}
            inputRef={this.setTextInputRef}
            style={{ maxWidth: '75px' }}
          />
        )
    }
  }

  private readonly setTextInputRef = (element: any) => {
    this.textInput = element
  }

  private readonly focusTextInput = () => {
    if (this.textInput) {
      this.textInput.focus()
      this.textInput.select()
    }
  }

  public override render () {
    const { noMass } = this.state
    const {
      label,
      hasFilter,
      elementType,
      name,
      value,
      type,
      disabled,
      handleUndo,
      t,
      isComparingCasters,
    } = this.props

    return (
      <FromField
        style={
          {
            maxWidth: isComparingCasters ? '217px' : '275px',
            minWidth: isComparingCasters ? '217px' : '275px',
          }
        }
      >
        {
          hasFilter
            ? (
              <FilterButton
                elementType={elementType}
                value={value}
                name={name}
                disabled={noMass === false}
              />
            )
            : <NoFilterSpacer />
        }
        {
          label &&
          (
            <FormLabel title={typeof label === 'object' ? null : label}>
              {label}
            </FormLabel>
          )
        }
        {this.getInput()}
        {
          !isComparingCasters &&
          (
            <div>
              <InlineButton
                onClick={this.handleInputFocus}
                disabled={type === 'select' || disabled}
                title={t('input.select')}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </InlineButton>
              <InlineButton onClick={handleUndo} value={t(name)} disabled={disabled} title={t('input.reset')}>
                <FontAwesomeIcon icon={faReply} />
              </InlineButton>
            </div>
          )
        }
      </FromField>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(Input)
