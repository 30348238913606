import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import Util from '@/logic/Util'
import { ViewLogic } from '@/react/visualization/dashboard/ViewLogic'
import CalcUtil from '@/Util/CalcUtil'

import type { Props } from '.'
import Bar from './fast/Bar'
import Helpers from './Helpers'

export default class logic {
  private static didCreate = false

  public static isInRect = (
    pointX: number,
    pointY: number,
    {
      x,
      y,
      width,
      height,
    }: DOMRect,
  ): boolean => (pointX > x && pointX < x + width) && (pointY > y && pointY < y + height)

  public static connect (
    prevProps: Props | Record<string, never>,
    props: Props,
    setData: (hasNoData: boolean, result?: any) => void,
    handleMouseOver: (data: any) => void,
    handleMouseOut: () => void,
    handleMouseDown: (e: any) => void,
    handleMouseUp: () => void,
    hasNoData: boolean,
  ): void {
    const {
      tileId,
      xRange,
      length,
      // valueRange,
      // frequency,
      configId,
      plotConfigs,
      data,
      tileConfigs,
      shapeIds,
      dynamicData,
      dynamicDataList,
      isDynamicData,
      isMergedDynamicData,
      elementMaps,
    } = props

    // TODO: maybe we can compare parts like layout, data, etc. instead of whole props and make smaller updates
    if (!isEqual(props, prevProps)) {
      logic.didCreate = false
    }

    if (logic.didCreate) {
      return
    }

    logic.didCreate = true

    const tileConfig = tileConfigs[tileId]

    if (!tileConfig) {
      return
    }

    const { configIds } = plotConfigs[tileConfig.configId] ?? {}
    let plotConfigIds = configId ? [ configId ] : []

    if (configIds) {
      plotConfigIds = cloneDeep(configIds)
    }

    if (shapeIds) {
      plotConfigIds.push(...shapeIds)
    }

    const plot: any = document.getElementById(`plot_${tileId}`)

    if (!plot) {
      return
    }

    plot.addEventListener('pointermove', (event: any) => {
      if (tileConfig.type === 'pie') {
        return
      }

      const { clientX, clientY } = event
      const plotRect = plot?.querySelector('.nsewdrag')?.getBoundingClientRect()

      if (!plotRect) {
        return
      }

      const prevOverState = plot.getAttribute('data-prev-over-state') === 'true'
      const overState = logic.isInRect(clientX, clientY, plotRect)

      if (prevOverState !== overState) {
        plot.setAttribute('data-prev-over-state', overState)

        if (!overState) {
          handleMouseOut()
        }
      }

      if (overState) {
        if (plot.hoverDataInterval) {
          clearInterval(plot.hoverDataInterval)
        }

        plot.hoverDataIntervalCounter = 0
        plot.hoverDataIntervalX = plot._hoverdata?.[0]?.x

        plot.hoverDataInterval = setInterval(() => {
          plot.hoverDataIntervalCounter++

          if (
            (plot._hoverdata && plot.hoverDataIntervalX !== plot._hoverdata[0].x) ||
            plot.hoverDataIntervalCounter > 100
          ) {
            clearInterval(plot.hoverDataInterval)

            handleMouseOver(plot._hoverdata)
          }
        }, 10)
      }
    }, true)

    plot.addEventListener('pointerout', () => {
      plot.setAttribute('data-prev-over-state', false)

      handleMouseOut()
    }, true)

    Util.AddDelegatedEventListener('', 'pointerdown', `#plot_${tileId}`, handleMouseDown)
    document.addEventListener('pointerup', handleMouseUp, true)

    const coordinateIds: string[] = []
    const configs: Array<any> = Object.values(plotConfigs)

    plotConfigIds.forEach(plotConfigId => {
      const conf = plotConfigs[plotConfigId]

      if (!conf) {
        return
      }

      const coordinateConfigs = configs.filter(config =>
        config.group === conf.group && (/^xCoordinates_/.test(config.key) || /^yCoordinates_/.test(config.key))
      )

      coordinateConfigs.forEach(config => {
        if ((config && !coordinateIds.includes(config.id))) {
          coordinateIds.push(config.id)
        }
      })
    })

    plotConfigIds.push(...coordinateIds)

    const dataRange = [ ...(xRange ?? []) ]

    const nullRange = [ 0, 0 ]

    dataRange[1] = Number(Math.min((xRange ?? nullRange)[1], Number(length)) || (xRange ?? nullRange)[1])

    if ((plotConfigIds || plotConfigIds === 0) && !(plotConfigIds instanceof Array)) {
      plotConfigIds = [ plotConfigIds ]
    }

    if (isMergedDynamicData && dynamicDataList) {
      const definitions: {
        id: string
        length: number
        xRange?: number[]
        bytes: any
        data: any[]
        isDataLine?: boolean
        lineIndex?: number
        comparisonLineIndex?: number
        isVerticalLine?: boolean
        isMergedDataLine?: boolean
      }[] = plotConfigIds
        .filter(pId => !plotConfigs[pId]?.isMergedDynamicDataSource)
        .map(pId => {
          const config = plotConfigs[pId] ?? {}
          const values = (data?.[config.group] ?? {})[config.key] ?? []
          const newData = [ ...values ]

          return {
            id: pId,
            length: values.length,
            bytes: null,
            data: newData,
          }
        })

      shapeIds
        ?.filter(shapeId => (shapeId !== 'passlineCoord'))
        .forEach(shapeId => {
          const config = plotConfigs[shapeId]

          if (!config) {
            return
          }

          const [ type, attrY ] = config.selectedY.split('|')

          const elements = ViewLogic.getDynamicElementsFromConfig(elementMaps, config, {}, type)
          const data = ViewLogic.getShapeDynamicData(elements, elementMaps, attrY)

          definitions.push({
            id: shapeId,
            length: 0,
            bytes: null,
            data,
            isVerticalLine: true,
          })
        })

      const configIds: string[] = plotConfigs[configId ?? '']?.configIds ?? []
      const amountOfConfigs = configIds.length

      for (let i = 0; i < amountOfConfigs; i++) {
        const actualConfig = plotConfigs[configIds[i]]

        // if (actualConfig.selectedX.includes('DataLine') || actualConfig.selectedY.includes('DataLine')) {
        const lines = dynamicDataList[i]
        const amountOfLines = lines?.length ?? 0

        for (let j = 0; j < amountOfLines; j++) {
          if (!Array.isArray(lines[j])) {
            continue
          }

          if (Array.isArray(lines[j][0])) {
            for (let k = 0; k < lines[j].length; k++) {
              const line = lines[j][k]

              definitions.push({
                id: actualConfig.id,
                length: 10,
                xRange: dataRange,
                bytes: null,
                data: line?.map((el: any) => el.y) ?? [],
                isMergedDataLine: true,
                lineIndex: j,
                comparisonLineIndex: j,
                // data: dynamicDataList ? dynamicDataList[i]?.map(el => el.y) ?? [] : [],
                isVerticalLine: actualConfig.isVerticalLine,
              })
            }
          }
          else {
            definitions.push({
              id: actualConfig.id,
              length: dataRange ? Math.max(dataRange[0] - dataRange[1], 2) : 0,
              xRange: dataRange,
              bytes: null,
              data: lines[j]?.map((el: any) => el.y) ?? [],
              isDataLine: true,
              lineIndex: j,
              comparisonLineIndex: j,
              // data: dynamicDataList ? dynamicDataList[i]?.map(el => el.y) ?? [] : [],
              isVerticalLine: actualConfig.isVerticalLine,
            })
          }
        }
      }

      setData(
        hasNoData,
        {
          tileId,
          definitions,
        },
      )

      return
    }

    if (isDynamicData && dynamicData && !props.isMultiLinePlot) {
      const definitions: any[] = plotConfigIds
        .filter(pId => plotConfigs[pId]?.group === 'dynamicDataSource')
        .map(pId => ({
          id: pId,
          length: dataRange ? Math.max(dataRange[0] - dataRange[1], 2) : 0,
          xRange: dataRange,
          bytes: null,
          data: dynamicData.map(el => el.y),
          isVerticalLine: plotConfigs[pId]?.isVerticalLine ?? false,
        }))

      definitions.push(
        ...plotConfigIds
          .filter(pId => plotConfigs[pId]?.group !== 'dynamicDataSource')
          .map(pId => {
            const config = plotConfigs[pId] ?? {}
            const values = (data?.[config.group] ?? {})[config.key] ?? []
            const newData = [ ...values ]

            return {
              id: pId,
              length: values.length,
              bytes: null,
              data: newData,
            }
          }),
      )

      setData(
        hasNoData,
        { tileId, definitions },
      )

      return
    }

    if (isDynamicData && dynamicData && props.isMultiLinePlot) {
      const definitions: any = []
      const amountOfLines = dynamicData.length

      for (let i = 0; i < amountOfLines; i++) {
        definitions.push({
          id: plotConfigs[plotConfigIds[0]].id,
          // length: dataRange[0] - dataRange[1],
          length: 1,
          xRange: [ 0, 0 ],
          bytes: null,
          data: dynamicData[i],
          comparisonLineIndex: i,
        })
      }

      shapeIds
        ?.filter(shape => (shape !== 'passlineCoord'))
        .forEach(shapeId => {
          const config = plotConfigs[shapeId]

          if (!config) {
            return
          }

          const [ type, attrY ] = config.selectedY.split('|')

          const elements = ViewLogic.getDynamicElementsFromConfig(elementMaps, config, {}, type)
          const data = ViewLogic.getShapeDynamicData(elements, elementMaps, attrY)

          definitions.push({
            id: shapeId,
            length: 0,
            bytes: null,
            data,
            isVerticalLine: true,
          })
        })

      setData(
        hasNoData,
        { tileId, definitions },
      )

      return
    }

    // offline file only
    if (data && Object.keys(data).length) {
      const definitions = plotConfigIds.map(pId => {
        const config = plotConfigs[pId]
        const values = (data?.[config.group] ?? {})[config.key] ?? []
        const newData = [ ...values ]

        return {
          id: pId,
          length: values.length,
          bytes: null,
          data: newData,
        }
      })

      setData(
        hasNoData,
        { tileId, definitions },
      )
    }
    else {
      setData(hasNoData)
    }
  }

  public static getLayout ({
    type,
    xDomain,
    yDomain,
    flipAxes,
    tileId,
    tileConfigs,
    width,
    height,
    xValues,
    yValueRange,
  }: any) {
    let layout: any = {
      autosize: true,
      margin: {
        l: 40,
        r: 40,
        b: 40,
        t: 20,
        pad: 0,
      },
      // eslint-disable-next-line camelcase
      paper_bgcolor: 'rgba(0,0,0,0)',
      // eslint-disable-next-line camelcase
      plot_bgcolor: 'rgba(0,0,0,0)',
      hoverinfo: 'all',
      hovermode: 'x',
      barmode: 'group',
      xaxis: {
        showgrid: false,
        showline: true,
        zeroline: false,
        mirror: 'ticks',
        color: '#424d58',
        gridcolor: '#424d58',
        linecolor: '#424d58',
        ticks: 'inside',
        ticklen: 8,
        tickwidth: 1,
        tickcolor: '#424d58',
        tickfont: {
          color: '#a2a6a9',
        },
      },
      showlegend: false,
      legend: {
        font: {
          color: '#a2a6a9',
        },
      },
      yaxis: {
        showgrid: false,
        showline: true,
        zeroline: false,
        mirror: 'ticks',
        color: '#424d58',
        gridcolor: '#424d58',
        linecolor: '#424d58',
        ticks: 'inside',
        ticklen: 8,
        tickwidth: 1,
        tickcolor: '#424d58',
        tickfont: {
          color: '#a2a6a9',
        },
      },
    }

    let { min: xMin, max: xMax } = CalcUtil.getMinMax(xDomain)
    let { min: yMin, max: yMax } = CalcUtil.getMinMax(yDomain)
    const { min: yVMin, max: yVMax } = CalcUtil.getMinMax(yValueRange)

    if (!xValues) {
      return layout
    }

    xMin = xMin || xMin === 0 ? Number(xMin) : xValues[0]
    xMax = xMax || xMax === 0 ? Number(xMax) : xValues[xValues.length - 1]

    yMin = yMin || yMin === 0 ? Number(yMin) : yVMin
    yMax = yMax || yMax === 0 ? Number(yMax) : yVMax

    if (xMin === xMax) {
      xMax += 1
    }

    if (yMin === yMax) {
      yMax += 1
    }

    switch (type) {
      case 'area':
      case 'line':
      case 'bar':
        {
          let xR = [ xMin, xMax ]

          if (type === 'bar') {
            const offset = (xMax - xMin) * Bar.PLOT_PADDING_FACTOR

            xR = [ xMin - offset, xMax + offset ]
          }

          const yR = [ yMin, yMax ]
          const rangeY = !flipAxes ? yR : xR

          layout = {
            ...layout,
            xaxis: {
              ...(!flipAxes ? layout.xaxis : layout.yaxis),
              range: !flipAxes ? xR : yR,
            },
            yaxis: {
              ...(!flipAxes ? layout.yaxis : layout.xaxis),
              range: rangeY,
            },
          }

          const { additionalYAxes } = tileConfigs[tileId]
          const otherAxis = !flipAxes ? 'x' : 'y'

          if (additionalYAxes && additionalYAxes.length) {
            const prefix = flipAxes ? 'x' : 'y'

            const newAxis = Helpers.getNewAxis(flipAxes, yR, prefix)

            const newAnnotation = Helpers.getNewAnnotation()

            const axisWidth = !flipAxes ? 0.06 * (1 / (width / 1200)) : 0.06 * (1 / (height / 900))
            const offset = !flipAxes ? 0.015 : 0.01
            const maxIndex = additionalYAxes.length - 1
            const axisStart = 1 - (maxIndex * axisWidth) - offset - (axisWidth * 0.3)

            layout[`${otherAxis}axis`].domain = [ 0, axisStart ]
            layout.annotations = layout.annotations ?? []

            additionalYAxes.forEach((
              { title, color, yDomainMin: rawYDMin, yDomainMax: rawYDMax, showLine }: {
                title: string
                color: string
                yDomainMin: number
                yDomainMax: number
                showLine: boolean
              },
              index: number,
            ) => {
              const position = axisStart + axisWidth * index
              const yDomainMin = rawYDMin !== undefined && String(rawYDMin).length ? Number(rawYDMin) : rangeY[0]
              const yDomainMax = rawYDMax !== undefined && String(rawYDMax).length ? Number(rawYDMax) : rangeY[1]

              layout[`${prefix}axis${index + 2}`] = {
                ...newAxis,
                title: !flipAxes || index === maxIndex ? title : '',
                showgrid: showLine ?? false,
                color: color ?? '#a2a6a9',
                tickcolor: color ?? '#a2a6a9',
                tickfont: {
                  color: color ?? '#a2a6a9',
                },
                range: [ yDomainMin, yDomainMax ],
                position,
                barmode: 'group',
              }

              if (flipAxes && index < maxIndex) {
                layout.annotations.push({
                  ...newAnnotation,
                  y: 1 - (1 - position) * 0.4,
                  text: `<span style="font-weight: normal">${title}</span>`,
                })
              }
            })
          }
          else {
            layout[`${otherAxis}axis`].domain = [ 0, 1 ]
          }
        }

        break
      default:
    }

    return layout
  }

  public static getData ({
    type,
    xRange,
    configId,
    configIds,
    shapeIds,
    valueRange,
    flipAxes,
  }: Props) {
    let data = []
    const length = [ configId, ...(configIds ?? []), ...(shapeIds ?? []) ].length

    const areValuesDates = (xRange as any)?.[0] instanceof Date
    const xDiff = !areValuesDates ? Math.ceil(Math.abs(Number(xRange?.[1] ?? 1) - Number(xRange?.[0] ?? 0))) : 1
    const vDiff = !areValuesDates ? Math.abs((valueRange?.[1] ?? 0) - (valueRange?.[0] ?? 0)) : 1

    switch (type) {
      case 'area':
      case 'line':
      case 'bar':
        {
          const x = Array(xDiff || 1).fill(0).map((v, i) => i)
          const y = Array(xDiff || 1).fill(0)

          const singleLineData: any = {
            y: !flipAxes ? y : x,
            x: !flipAxes ? x : y,
            mode: 'lines',
            line: {
              width: 1,
            },
            marker: {},
            hoverinfo: String(Number(length) > 10 ? 'x+text' : 'x+y+text'),
            orientation: !flipAxes ? 'v' : 'h',
            fill: 'none',
          }

          if (type === 'area') {
            singleLineData.fill = !flipAxes ? 'tozeroy' : 'tozerox'
          }
          else if (type === 'bar') {
            singleLineData.type = 'bar'
          }

          data = Array(length).fill(singleLineData)
        }

        break
      case 'contour':
        {
          const z = Array(1).fill(Array(xDiff).fill(0))

          data = [ {
            z,
            colorscale: Helpers.colorscale,
            contours: {
              start: Math.min(valueRange?.[0] ?? 0, valueRange?.[1] ?? 0),
              end: Math.max(valueRange?.[0] ?? 0, valueRange?.[1] ?? 0),
              size: vDiff * (1 / Helpers.colorscale.length),
            },
            showscale: false,
            type: 'contour',
          } ]
        }

        break
      default:
    }

    return data
  }

  public static getLineStyle (
    color: string,
    index: number,
  ) {
    const mod = index % 4

    switch (mod) {
      case 1:
      case 2:
        return { color: logic.lightenDarkenColor(color, 50 * mod) }
      case 3:
        return { color, dash: 'dashdot' }
      case 0:
        return { color, dash: 'dot' }
      default:
        return { color }
    }
  }

  public static lightenDarkenColor (hexColor: string, adjustment: number) {
    if (!hexColor?.length) {
      return ''
    }

    let usePound = false

    if (hexColor[0] === '#') {
      hexColor = hexColor.slice(1)
      usePound = true
    }

    const num = parseInt(hexColor, 16)

    let r = (num >> 16) + adjustment

    if (r > 255) {
      r = 255
    }
    else if (r < 0) {
      r = 0
    }

    let b = ((num >> 8) & 0x00FF) + adjustment

    if (b > 255) {
      b = 255
    }
    else if (b < 0) {
      b = 0
    }

    let g = (num & 0x0000FF) + adjustment

    if (g > 255) {
      g = 255
    }
    else if (g < 0) {
      g = 0
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  }

  public static getConfigWithPermissions (config: any, permissions: any) {
    const newConfig = { ...config }

    if (!permissions) {
      return config
    }

    if (!permissions.PlotSettings_ZoomIn) {
      config.modeBarButtonsToRemove.push('zoomIn2d')
    }

    if (!permissions.PlotSettings_ZoomOut) {
      config.modeBarButtonsToRemove.push('zoomOut2d')
    }

    if (!permissions.PlotSettings_ResetZoom) {
      config.modeBarButtonsToRemove.push('resetScale2d')
    }

    if (!permissions.PlotSettings_Download_PlotPNG) {
      config.modeBarButtonsToRemove.push('toImage')
    }

    return newConfig
  }
}
