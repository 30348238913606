import * as THREE from 'three'
import { Vector3 } from 'three'

import Util from '@/three/logic/Util'

import { SetValuesData } from './BaseObject'
import ThreeDataPoint from './DataPoint'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'

export default class ThreeDataLine extends ThreeDataPoint<DataLineSlot, DataLineMountLog> {
  private readonly show3D = true

  private static readonly dataLineMaterial = new THREE.LineBasicMaterial({
    color: '#eb4034',
    linewidth: 2.5,
    transparent: true,
    opacity: 0.3,
  })

  private static readonly dataLineSelectedMaterial = new THREE.LineBasicMaterial({ color: '#7eda41', linewidth: 2.5 })

  private xCoords: number[]

  private readonly index = 0

  protected override readonly type: string = 'DataLine'

  public constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent, clickableObjects, tooltipObjects)

    this.xCoords = []
  }

  protected override internalSetValues (data: SetValuesData<DataLineSlot, DataLineMountLog>): void {
    const { elementData } = data as SetValuesData<DataLineSlot, DataLineMountLog>

    if (elementData.ref) {
      this.xCoords = (elementData as any)[elementData.xCoords ?? '']?.trim()?.split(' ')?.map(Number) ?? []
    }
    else {
      this.xCoords = elementData.xCoords?.trim()?.split(' ')?.map(Number) ?? []
    }

    // FIXME: @c.bentele where does show3D come from?
    // if (elementData.show3D !== undefined && elementData.show3D === '0') {
    //   this.show3D = false
    // }

    this.wCoord = elementData.widthCoord ?? 0

    if (!this.objects.line) {
      const thickness = (elementData.thicknessCoord ?? 0) / 1000

      this.getCurve(thickness, elementData.id)
      this.getStraightLine(thickness, elementData.id)
    }

    // eslint-disable-next-line camelcase
    elementData.passlineCoord = isNaN(this.xCoords[0]) ? 0 : this.xCoords[0] ?? 0
    // FIXME: @c.bentele where does index come from?
    // this.index = elementData.index ?? 0

    super.internalSetValues(data as any)
  }

  public override updateTransform (): void {
    super.updateTransform()

    const { line, straightLine } = this.objects

    if (!line || !straightLine) {
      // eslint-disable-next-line no-console
      console.warn('No line or straightLine')

      return
    }

    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    newSectionRotation.x = -Util.RAD90

    if (PasslineCurve.shouldBeStraight) {
      line.visible = false
      straightLine.visible = true
    }
    else {
      line.visible = true
      straightLine.visible = false
    }

    if (this.thickness >= Mold.thickness) {
      // Loose side
      // newRotation.set(Util.RAD180, Util.RAD180, 0)
    }

    line.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
    straightLine.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    const { sensorPoint } = this.objects

    const indexPositionMovement = this.index * this.size

    const { normal } = PasslineCurve.getInfoAtPlCoord(this.plCoord ?? 0, this.sectionDetail ? true : undefined)

    if (this.wCoord >= Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on left
      sensorPoint.position.add(new Vector3(0, 0, indexPositionMovement))
      // newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceLeft.z - this.thickness))
    }
    else if (this.wCoord <= -Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on right side
      sensorPoint.position.sub(new Vector3(0, 0, indexPositionMovement))
      // newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z - this.thickness))
    }
    else if (this.thickness <= 0) {
      // Fixed side
      sensorPoint.position.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(indexPositionMovement))
      // newPosition.set(-position.z + size / 2, position.y + 0.00001, this.wCoord)
    }
    else if (this.thickness >= Mold.thickness) {
      // Loose side
      sensorPoint.position.add(new Vector3(normal.z, -normal.y, normal.x).setLength(indexPositionMovement))
    }
  }

  public override setSelected (isSelected: boolean): void {
    const { straightLine, line } = this.objects

    if (!line || !straightLine || !(line instanceof THREE.Line) || !(straightLine instanceof THREE.Line)) {
      // eslint-disable-next-line no-console
      console.warn('No line or straightLine')

      return
    }

    super.setSelected(isSelected)

    if (line) {
      line.material = isSelected ? ThreeDataLine.dataLineSelectedMaterial : ThreeDataLine.dataLineMaterial
    }

    if (straightLine) {
      straightLine.material = isSelected ? ThreeDataLine.dataLineSelectedMaterial : ThreeDataLine.dataLineMaterial
    }
  }

  protected override getTooltips (pointData: any, path: string) {
    const { sensorPoint } = this.objects

    const keys = Object.keys(pointData).filter(key => !this.hiddenKeysInTooltip.includes(key))
    const { id } = Util.getElementInfo(path)
    const { side } = this.container.userData

    // FIXME: if parent is not Segment, but Roller/RollerBody/... the side is not set

    // const s = side === StrandSides.Loose || side === StrandSides.Right ? -1 : 1

    const pos = new THREE.Vector3(0, 0, 0)
    const name = `tooltipMarkerHeight_${side}_${id}`

    Util.createTooltipMarker(
      sensorPoint,
      this.tooltipObjects,
      name,
      pos,
      (
        <div>
          <b>{sensorPoint.userData.type}: {id}</b>
          <br />
          <br />
          {
            keys.map(key => (
              <div key={key}>
                {key.replace(/^_/, '')}: {
                  Array.isArray(pointData[key])
                    ? `${pointData[key].length} values`
                    : key[0] !== 'y'
                      ? pointData[key]
                      : `${Util.getNumberOfValues(pointData[key])} values`
                }
              </div>
            ))
          }
        </div>
      ),
    )

    const marker = sensorPoint.getObjectByName(`TooltipMarker_${name}`)
    const snap = sensorPoint.getObjectByName(`TooltipMarkerSnap_${name}`)

    if (!marker || !snap || !(snap instanceof THREE.Mesh)) {
      // eslint-disable-next-line no-console
      console.warn('No marker or snap')

      return
    }

    marker.visible = false
    snap.material.visible = false

    snap.position.copy(new THREE.Vector3(0, 0, 0))
    snap.rotation.copy(new THREE.Euler(0, 0, Util.RAD90, 'XYZ'))
  }

  public override show (): void {
    if (this.isHidden || !this.show3D) {
      return
    }

    Object.values(this.objects).forEach((object: any) => {
      const name = object.name

      if (name.includes('StraightLine')) {
        object.visible = PasslineCurve.shouldBeStraight

        return
      }
      else if (name.includes('Curve')) {
        object.visible = !PasslineCurve.shouldBeStraight

        return
      }

      object.visible = true
    })

    this.container.visible = true

    if (this.parent) {
      this.parent.show()
    }
  }
  // TODO: handle lines that have t < 0 , t > Mold.thickness

  private getCurve (thickness: number, id: string) {
    const { xCoords } = this
    const points: Vector3[] = []

    const { LooseSide, NarrowFaceLeft, NarrowFaceRight } = Mold.sideDistance

    xCoords.filter(x => !isNaN(x)).forEach(x => {
      // TODO: handle lines that are longer than the caster
      // if x > PasslineCurve.plHeight use x?make logs
      const { position, normal } = PasslineCurve.getInfoAtPlCoord((x ?? 0) / 1000)

      const pointPositionX = -position.z
      const pointPositionY = position.y
      const pointPositionZ = position.x + this.wCoord / 1000
      const point = new Vector3(pointPositionX, pointPositionY, pointPositionZ)

      if (this.wCoord >= Mold.width / 2 && thickness > 0 && thickness < Mold.thickness) {
        // place on left side
        const curveDirection = new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceLeft.z - thickness)

        point.add(curveDirection)
      }
      else if (this.wCoord <= Mold.width / 2 && thickness > 0 && thickness < Mold.thickness) {
        // place on right side
        const curveDirection = new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z - thickness)

        point.add(curveDirection)
      }
      else if (thickness >= Mold.thickness) {
        // TODO: add normal
        // - 0.001 because if it's only LooseSide.z line is barely visible
        const curveDirection = new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - 0.001)

        point.sub(curveDirection)
      }

      points.push(point)
    })

    // -position.y
    const geometry = new THREE.BufferGeometry().setFromPoints(points)

    const line = new THREE.Line(geometry, ThreeDataLine.dataLineMaterial)

    line.name = `Curve-${id}`
    Util.addOrReplace(this.container, line)

    this.objects.line = line
  }

  private getStraightLine (thickness: number, id: string) {
    const { xCoords } = this
    const points: Vector3[] = []

    const { LooseSide, NarrowFaceLeft, NarrowFaceRight } = Mold.sideDistance

    xCoords.filter(x => !isNaN(x)).forEach(x => {
      const { position, normal } = PasslineCurve.getInfoAtPlCoord(x ?? 0, true)

      const pointPositionX = -position.z
      const pointPositionY = position.y
      const pointPositionZ = position.x + this.wCoord / 1000
      const point = new Vector3(pointPositionX, pointPositionY, pointPositionZ)

      if (this.wCoord >= Mold.width / 2 && thickness > 0 && thickness < Mold.thickness) {
        // place on left side
        const curveDirection = new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceLeft.z - thickness)

        point.add(curveDirection)
      }
      else if (this.wCoord <= Mold.width / 2 && thickness > 0 && thickness < Mold.thickness) {
        // place on right side
        const curveDirection = new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z - thickness)

        point.add(curveDirection)
      }
      else if (thickness >= Mold.thickness) {
        // TODO: add normal
        // - 0.001 because if it's only LooseSide.z line is barely visible
        const curveDirection = new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - 0.001)

        point.sub(curveDirection)
      }

      points.push(point)
    })

    // -position.y
    const geometry = new THREE.BufferGeometry().setFromPoints(points)

    const straightLine = new THREE.Line(geometry, ThreeDataLine.dataLineMaterial)

    straightLine.name = `StraightLine-${id}`

    straightLine.visible = false
    Util.addOrReplace(this.container, straightLine)

    this.objects.straightLine = straightLine
  }
}
