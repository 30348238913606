import { getTemporalData, TemporalDataResponse, TypeAndIdNeededData } from '@/api/temporal-data'
import { getCurrentDashboardEntry } from '@/App/util'

import { Props } from './index'
export default class TemporalDataHandler {
  public static async getTemporalData (props: Props) {
    const {
      plotConfigs,
      setTemporalData,
      temporalData,
    } = props

    const plotIds = this.getPlotIds(props)

    if (!plotIds) {
      return
    }

    const neededData = this.getNeededDataArray(plotConfigs, plotIds, temporalData)

    if (!neededData.length) {
      return
    }

    const mountLogsTypeArray = (await getTemporalData(neededData)) ?? []
    const data = TemporalDataHandler.mapMountLogsToTemporalData(mountLogsTypeArray, neededData)

    setTemporalData(data)
  }

  public static async getTemporalDataForSpecificFilter (setTemporalData: (data: any) => void, filter: string) {
    const neededData: TypeAndIdNeededData[] = []

    const neededDataString = this.getNeededDataStringByFilter(filter)

    if (!neededDataString) {
      return
    }

    neededData.push(neededDataString)

    const mountLogsTypeArray = (await getTemporalData(neededData)) ?? []
    const data = this.mapMountLogsToTemporalData(mountLogsTypeArray, neededData)

    setTemporalData(data)
  }

  private static mapMountLogsToTemporalData (mountLogsTypeArray: TemporalDataResponse, neededData: string[]) {
    const data = {} as any

    const mountLogsPerRealDataUUID: Record<string, any[]> = {}

    for (const mountLogType of mountLogsTypeArray) {
      for (const mountLog of mountLogType) {
        const realDataUUID = mountLog.realDataUUID

        if (!realDataUUID) {
          continue
        }

        if (!mountLogsPerRealDataUUID[realDataUUID]) {
          mountLogsPerRealDataUUID[realDataUUID] = []
        }

        mountLogsPerRealDataUUID[realDataUUID].push(mountLog)
      }
    }

    Object.entries(mountLogsPerRealDataUUID).forEach(([ realDataUUID, mountLogs ]) => {
      data[realDataUUID] = mountLogs
    })

    //  go through all needed data, if it is not in the data object, add it with an empty array
    for (const needed of neededData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [ , realDataUUID ] = needed.split('|')

      if (!data[realDataUUID]) {
        data[realDataUUID] = []
      }
    }

    return data
  }

  private static getPlotIds (props: Props) {
    const { currentDashboard, viewsObject, tileConfigs } = props
    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)

    if (!viewId || !dashboardId) {
      return
    }

    const tileIds: string[] = viewsObject[viewId]?.dashboards?.[dashboardId]?.tileIds

    if (!tileIds?.length) {
      return
    }

    return tileIds.map(tileId => tileConfigs[tileId]?.configId).filter(Boolean)
  }

  private static getNeededDataStringByFilter (
    filter: string,
    temporalData?: TemporalDataState,
  ): TypeAndIdNeededData | null {
    const [ type, rest = '' ] = filter.split('#')
    const [ , realDataUUID ] = rest.split('realDataUUID=')

    if (!realDataUUID || (temporalData?.[realDataUUID])) {
      return null
    }

    return `${type}|${realDataUUID}` as TypeAndIdNeededData
  }

  private static getNeededDataArray (
    plotConfigs: any,
    plotIds: string[],
    temporalData: TemporalDataState,
  ): TypeAndIdNeededData[] {
    const neededData: TypeAndIdNeededData[] = []

    for (const plotId of plotIds) {
      const plotConfig = plotConfigs[plotId] ?? {}

      const { filter, selectedY, selectedX } = plotConfig

      if (selectedX?.includes('dataOverTime') && filter?.includes('realDataUUID')) {
        const [ type ] = selectedY.split('|')
        const realDataUUID = filter.split('realDataUUID=')[1]

        if (temporalData[realDataUUID]) {
          continue
        }

        const info = `${type}|${realDataUUID}` as TypeAndIdNeededData

        neededData.push(info)
      }
    }

    return neededData
  }
}
