import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import PlaybackWindow from '@/react/casterDataServer/PlaybackWindow'
import HistoryDetailDialog from '@/react/dialogs/HistoryDetailDialog'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'

import CasterHandleDataDialog from './caster/CasterHandleDataDialog'
import CasterDataServerDialog from './CasterDataServerDialog'
import EditSegmentGroupDetailFilterDialog from './EditSegmentGroupDetailFilterDialog'
import DownloadXMLDialog from './executables/DownloadXMLDialog'
import ExecutableDialog from './executables/ExecutableDialog'
import LoginDialog from './LoginDialog'
import CreateCommandFileDialog from './project/CreateCommandFileDialog'
import CreateRealDataCaseDialog from './project/CreateRealDataCaseDialog'
import CreateRealDataDialog from './project/CreateRealDataDialog'
import EditConfigDialog from './project/EditConfigDialog'
import EditProjectDialog from './project/EditProjectDialog'
import EditSimulationCaseDialog from './project/EditSimulationCaseDialog'
import InputDataDialog from './project/InputDataDialog'
import ManageDynamicDataSourcesDialog from './project/ManageDynamicDataSourcesDialog'
import NewProjectDialog from './project/NewProjectDialog'
import NewSimulationCaseDialog from './project/NewSimulationCaseDialog'
import OpenProjectDialog from './project/OpenProjectDialog'
import ProjectDataDialog from './project/ProjectDataDialog'
import SelectCatalogDialog from './project/ProjectDataDialog/SelectCatalogDialog'
import RealDataElementDetailsDialog from './project/RealDataElementDetailsDialog'
import SelectDashboardDialog from './project/SelectDashboardDialog'
import ShareProjectDialog from './project/ShareProjectDialog'
import ProjectMatrixDialog from './ProjectMatrixDialog'
import CloneDialog from './ProjectMatrixDialog/CloneDialog'
import ConfigureDialog from './ProjectMatrixDialog/ConfigureDialog'
import DeleteDialog from './ProjectMatrixDialog/DeleteDialog'
import DuplicateSimulationCaseDialog from './ProjectMatrixDialog/DuplicateSimulationCaseDialog'
import ResetSimulationDialog from './ProjectMatrixDialog/ResetSimulationDialog'
import SimulateDialog from './ProjectMatrixDialog/SimulateDialog'
import StopSimulationDialog from './ProjectMatrixDialog/StopSimulationDialog'
import SelectCompareCasterTimeDialog from './SelectCompareCasterTimeDialog'
import UsageTimeExceededDialog from './UsageTimeExceededDialog'

const connector = connect((state: DefaultState) => ({
  openAppDialogs: state.application.main.openAppDialogs,
  authenticationData: state.application.main.authenticationData,
  isConsistencyCheck: state.util.isConsistencyCheck,
}), {
  clearOpenAppDialogs: ApplicationActions.clearOpenAppDialogs,
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  login?: boolean
}

class DialogManager extends Component<Props> {
  public override componentDidUpdate (): void {
    const { openAppDialogs, clearOpenAppDialogs } = this.props

    // see if openAppDialogs includes any of the dialog names, if not, clear the openAppDialogs
    const dialogNames = [
      UsageTimeExceededDialog.NAME,
      CasterHandleDataDialog.NAME,
      SelectCatalogDialog.NAME,
      CreateCommandFileDialog.NAME,
      LoginDialog.NAME,
      EditProjectDialog.NAME,
      ShareProjectDialog.NAME,
      NewSimulationCaseDialog.NAME,
      EditSimulationCaseDialog.NAME,
      EditConfigDialog.NAME,
      ManageDynamicDataSourcesDialog.NAME,
      SelectDashboardDialog.NAME,
      ExecutableDialog.NAME,
      DownloadXMLDialog.NAME,
      NewProjectDialog.NAME,
      OpenProjectDialog.NAME,
      SelectCompareCasterTimeDialog.NAME,
      CasterDataServerDialog.NAME,
      CreateRealDataCaseDialog.NAME,
      CreateRealDataDialog.NAME,
      RealDataElementDetailsDialog.NAME,
      EditSegmentGroupDetailFilterDialog.NAME,
      HistoryDetailDialog.NAME,
      ConfigureDialog.NAME,
      CloneDialog.NAME,
      DeleteDialog.NAME,
      SimulateDialog.NAME,
      SimulateDialog.ALLNAME,
      StopSimulationDialog.NAME,
      ResetSimulationDialog.NAME,
      DuplicateSimulationCaseDialog.NAME,
      ProjectMatrixDialog.NAME,
      InputDataDialog.NAME,
      ProjectDataDialog.NAME,
      PlaybackWindow.NAME,
    ]

    if (openAppDialogs.length && !dialogNames.some(name => openAppDialogs.includes(name))) {
      clearOpenAppDialogs()
    }
  }

  public override render () {
    const { openAppDialogs, login } = this.props

    if (openAppDialogs.includes(UsageTimeExceededDialog.NAME)) {
      return <UsageTimeExceededDialog />
    }

    // CASTER DIALOGS

    if (openAppDialogs.includes(CasterHandleDataDialog.NAME)) {
      return <CasterHandleDataDialog />
    }

    if (openAppDialogs.includes(SelectCatalogDialog.NAME)) {
      return <SelectCatalogDialog />
    }

    // APPLICATION DIALOGS

    if (openAppDialogs.includes(CreateCommandFileDialog.NAME)) {
      return <CreateCommandFileDialog />
    }

    if (Boolean(login) || openAppDialogs.includes(LoginDialog.NAME)) {
      return <LoginDialog hideCloseButton={login} />
    }

    if (openAppDialogs.includes(EditProjectDialog.NAME)) {
      return <EditProjectDialog />
    }

    if (openAppDialogs.includes(ShareProjectDialog.NAME)) {
      return <ShareProjectDialog />
    }

    if (openAppDialogs.includes(NewSimulationCaseDialog.NAME)) {
      return <NewSimulationCaseDialog />
    }

    if (openAppDialogs.includes(EditSimulationCaseDialog.NAME)) {
      return <EditSimulationCaseDialog />
    }

    if (openAppDialogs.includes(EditConfigDialog.NAME)) {
      return <EditConfigDialog />
    }

    if (openAppDialogs.includes(ManageDynamicDataSourcesDialog.NAME)) {
      return <ManageDynamicDataSourcesDialog />
    }

    if (openAppDialogs.includes(SelectDashboardDialog.NAME)) {
      return <SelectDashboardDialog />
    }

    if (openAppDialogs.includes(ExecutableDialog.NAME)) {
      return <ExecutableDialog />
    }

    if (openAppDialogs.includes(DownloadXMLDialog.NAME)) {
      return <DownloadXMLDialog />
    }

    if (openAppDialogs.includes(NewProjectDialog.NAME)) {
      return <NewProjectDialog />
    }

    if (openAppDialogs.includes(OpenProjectDialog.NAME)) {
      return <OpenProjectDialog />
    }

    if (openAppDialogs.includes(SelectCompareCasterTimeDialog.NAME)) {
      return <SelectCompareCasterTimeDialog />
    }

    if (openAppDialogs.includes(CasterDataServerDialog.NAME)) {
      return <CasterDataServerDialog />
    }

    if (openAppDialogs.includes(CreateRealDataCaseDialog.NAME)) {
      return <CreateRealDataCaseDialog />
    }

    if (openAppDialogs.includes(CreateRealDataDialog.NAME)) {
      return <CreateRealDataDialog />
    }

    if (openAppDialogs.includes(RealDataElementDetailsDialog.NAME)) {
      return <RealDataElementDetailsDialog />
    }

    if (openAppDialogs.includes(EditSegmentGroupDetailFilterDialog.NAME)) {
      return <EditSegmentGroupDetailFilterDialog />
    }

    if (openAppDialogs.includes(HistoryDetailDialog.NAME)) {
      return <HistoryDetailDialog />
    }

    let additionalMatrixDialog

    if (openAppDialogs.includes(ConfigureDialog.NAME)) {
      additionalMatrixDialog = <ConfigureDialog />
    }

    if (openAppDialogs.includes(CloneDialog.NAME)) {
      additionalMatrixDialog = <CloneDialog />
    }

    if (openAppDialogs.includes(DeleteDialog.NAME)) {
      additionalMatrixDialog = <DeleteDialog />
    }

    if (openAppDialogs.includes(SimulateDialog.NAME) || openAppDialogs.includes(SimulateDialog.ALLNAME)) {
      additionalMatrixDialog = <SimulateDialog all={openAppDialogs.includes(SimulateDialog.ALLNAME)} />
    }

    if (openAppDialogs.includes(StopSimulationDialog.NAME)) {
      additionalMatrixDialog = <StopSimulationDialog />
    }

    if (openAppDialogs.includes(ResetSimulationDialog.NAME)) {
      additionalMatrixDialog = <ResetSimulationDialog />
    }

    if (openAppDialogs.includes(DuplicateSimulationCaseDialog.NAME)) {
      additionalMatrixDialog = <DuplicateSimulationCaseDialog />
    }

    if (openAppDialogs.includes(ProjectMatrixDialog.NAME)) {
      return (
        <div>
          <ProjectMatrixDialog />
          {additionalMatrixDialog}
        </div>
      )
    }

    let additionalDataDialog

    if (openAppDialogs.includes(InputDataDialog.NAME)) {
      additionalDataDialog = <InputDataDialog />
    }

    if (openAppDialogs.includes(ProjectDataDialog.NAME)) {
      return (
        <div>
          <ProjectDataDialog />
          {additionalDataDialog}
        </div>
      )
    }

    if (openAppDialogs.includes(PlaybackWindow.NAME)) {
      return <PlaybackWindow />
    }

    return null
  }
}

export default compose<any>(connector)(DialogManager)
