import moment from 'moment'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { NetworkStatus } from '@/api/network-event'
import { DefaultState } from '@/types/state'

import {
  Label,
  SecondLabel,
  StatusBarWrapper,
  StatusTile,
} from './styles'

const connector = connect((state: DefaultState) => ({
  networkStatus: state.application.main.networkStatus,
  authenticationData: state.application.main.authenticationData,
}), {})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  time: moment.Moment
}

class StatusBar extends Component<Props, State> {
  private interval?: number

  public override state: State = {
    time: moment(),
  }
  
  public override componentDidMount () {
    this.interval = window.setInterval(() => {
      this.setState({
        time: moment(),
      })
    }, 1000)
  }
  
  public override componentWillUnmount () {
    clearInterval(this.interval)
  }
  
  public override render () {
    const {
      networkStatus,
      authenticationData,
      t,
    } = this.props
    const { time } = this.state

    return (
      <StatusBarWrapper>
        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-display1' />
            <Label>
              {t('dashboard.mode.heading')}
            </Label>
            <SecondLabel $color='#18b497'>
              {t('dashboard.mode.secondHeading')}
            </SecondLabel>
          </div>
        </StatusTile>

        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-clock' />
            <Label>
              {time.format('HH:mm')}
            </Label>
            <SecondLabel $color='#1d94e3'>
              {time.format('DD/MM/YYYY')}
            </SecondLabel>
          </div>
        </StatusTile>

        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-id' />
            <Label>
              {t('dashboard.user.label')}
            </Label>
            <SecondLabel $color='#1d94e3'>
              {authenticationData?.name}
            </SecondLabel>
          </div>
        </StatusTile>

        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-global' />
            <Label>
              {t('dashboard.server.label')}
            </Label>
            <SecondLabel $color={networkStatus === NetworkStatus.Connected ? '#18b497' : '#e84c3d'}>
              {networkStatus}
            </SecondLabel>
          </div>
        </StatusTile>
      </StatusBarWrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(StatusBar)
