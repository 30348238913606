import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { setVisualizationConfig } from '@/api/visualization-config'
import FeatureFlags from '@/react/FeatureFlags'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'

// TODO: no up for second no down for 2nd to last
const OrderActions = styled.div`${() =>
  css`
  display: none;
  position: absolute;
  right: 7px;
  top: 7px;
  padding: 0px;
  font-size: 16px;
  width: 25px;
  justify-content: space-between;

  svg {
    cursor: pointer;

    &:hover {
      color: #349fe6;
    }
  }
`}`

type ViewTabProps = { $active: boolean, $hidden?: boolean, $canEdit: boolean }

const ViewTab = styled.div<ViewTabProps>`${({ $active, $hidden, $canEdit }) =>
  css`
  display: ${$hidden ? 'none' : 'inline-block'};
  cursor:  pointer;
  background-color: ${$active ? '#474b4e' : '#212427'};
  border-left: 2px solid ${$active ? '#349fe6' : '#212427'};
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: white;
  font-size: 14px;
  transition: width .3s;
  white-space: nowrap;
  text-align: left;
  overflow: hidden;
  user-select: none;
  position: relative;

  a {
    text-decoration: none;
    outline: none;
  }

  .short {
    display: inline-block;
    vertical-align: top;
    pointer-events: none;
    padding-left: 10px;
  }

  .name {
    display: none;
    pointer-events: none;
    font-size: 14px;
  }

  &:hover {
    width: auto;
    max-width: 100%;
    border-bottom-right-radius: 7px;

    .name {
      display: inline-block;
      margin-left: 20px;
      max-width: calc(100% - 35px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
    }
  }

  ${
  $canEdit && css`
    &:not(:first-of-type):not(:last-of-type):hover {
      padding-right: 30px;

      ${OrderActions} {
        display: flex;
      }
    }
  `
}

  &:nth-last-of-type(2) svg:last-of-type,
  &:nth-of-type(2) svg:first-of-type {
    pointer-events: none;
    opacity: .5;
  }

  &:not(:first-of-type):hover {
    border-top-right-radius: 7px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #383838;
  }

  &:last-of-type {
    border-bottom-right-radius: 7px;
    font-size: 22px;
    
    i {
      line-height: 30px;
      pointer-events: none;
      padding-left: 3px;
      vertical-align: top;
    }
  }

  &:hover {
    background-color: ${$active ? '#474b4e' : '#31353a'};
    border-left: 2px solid ${$active ? '#349fe6' : '#474b4e'};
  }
`}`

const ViewTabWrapper = styled.div`${() =>
  css`
  display: flex;
  position: absolute;
  right: -30px;
  top: 50px;
  flex-direction: column;
  align-items: flex-start;
  width: 30px;

  &:hover {
    width: 500px;
    right: -500px;
  }
`}`

const connector = connect((state: DefaultState) => ({
  currentSimpleDashboardTabIndex: state.application.main.currentSimpleDashboardTabIndex,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  viewsObject: state.visualization.viewsObject,
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  appState: state.application.main.appState,
}), {
  setSimpleDashboardTabIndex: ApplicationActions.setSimpleDashboardTabIndex,
  changeTabs: VisualizationActions.changeTabs,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  children: any // TODO: fix type
  t(key: string, params?: Record<string, unknown>): string
}

class TabView extends React.Component<Props> {
  private readonly handleTabClick = (event: any) => {
    const { setSimpleDashboardTabIndex } = this.props
    const rawIndex = event.target.getAttribute('data-index')

    if (rawIndex === null || rawIndex === undefined) {
      return
    }

    const index = Number(rawIndex)

    setSimpleDashboardTabIndex(index)
  }

  private readonly handleClickUp = (index: number, event: any) => {
    event.preventDefault()

    const { viewsObject, changeTabs, currentSimpleDashboardTabIndex, children: rawChildren } = this.props
    const viewIds = Object.keys(viewsObject)
    const viewId = viewIds[0]
    const { dashboards } = viewsObject[viewId]
    const keys = Object.keys(dashboards)
    const data = dashboards[keys[index]]

    changeTabs(index - 1, index, viewId, keys[index], data)

    const children = React.Children.toArray(rawChildren)

    // jump to non existing tab!
    this.handleTabClick({ target: { getAttribute: () => children.length + 1 } })

    setTimeout(this.saveConfig.bind(this, index, index - 1, currentSimpleDashboardTabIndex), 100)
  }

  private readonly handleClickDown = (index: number, event: any) => {
    event.preventDefault()

    const { viewsObject, changeTabs, currentSimpleDashboardTabIndex, children: rawChildren } = this.props
    const viewIds = Object.keys(viewsObject)
    const viewId = viewIds[0]
    const { dashboards } = viewsObject[viewId]
    const keys = Object.keys(dashboards)
    const data = dashboards[keys[index]]

    changeTabs(index + 1, index, viewId, keys[index], data)

    const children = React.Children.toArray(rawChildren)

    // jump to non existing tab!
    this.handleTabClick({ target: { getAttribute: () => children.length + 1 } })

    setTimeout(this.saveConfig.bind(this, index, index + 1, currentSimpleDashboardTabIndex), 100)
  }

  private readonly saveConfig = async (oldIndex: number, newIndex: number, currentSimpleDashboardTabIndex: number) => {
    const { visualizationMetaInformation, viewsObject, plotConfigs, tileConfigs } = this.props

    await setVisualizationConfig(
      visualizationMetaInformation?.[AppState.Caster]?.config,
      { viewsObject, plotConfigs, tileConfigs },
    )

    if ((oldIndex + 1) === currentSimpleDashboardTabIndex) {
      this.handleTabClick({ target: { getAttribute: () => newIndex + 1 } })
    }
    else if ((newIndex + 1) === currentSimpleDashboardTabIndex) {
      this.handleTabClick({ target: { getAttribute: () => oldIndex + 1 } })
    }
  }

  private readonly canEdit = () => {
    const { featureFlags, visualizationMetaInformation, appState } = this.props

    return FeatureFlags.canEditCasterDashboard(featureFlags, visualizationMetaInformation, appState)
  }
  
  public override render () {
    const { children: rawChildren, currentSimpleDashboardTabIndex, t } = this.props
    const children: any = React.Children.toArray(rawChildren)

    return (
      <div>
        <ViewTabWrapper data-ignore-intersect>
          {
            children.map((element: any, index: number) => (
              <ViewTab
                key={index}
                $active={index === currentSimpleDashboardTabIndex}
                data-index={index}
                onClick={this.handleTabClick}
                $hidden={element.props.hide}
                $canEdit={this.canEdit()}
              >
                {
                  typeof element.props.title === 'string'
                    ? <span className='short'>{t(element.props.title)}</span>
                    : element.props.title
                }
                <span className='name'>{element.props.tooltip}</span>
                {
                  this.canEdit() &&
                (
                  <OrderActions>
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      title='Move Tab Up'
                      onClick={this.handleClickUp.bind(this, index - 1)}
                    />
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      title='Move Tab Down'
                      onClick={this.handleClickDown.bind(this, index - 1)}
                    />
                  </OrderActions>
                )
                }
              </ViewTab>
            ))
          }
        </ViewTabWrapper>
        {children[currentSimpleDashboardTabIndex]}
      </div>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(TabView)
