import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import Input from '@/react/specific/Input'
import { Translation } from '@/types/translation'
import { PlotConfigMap } from '@/types/visualization'

import { Button, Form } from '../DialogStyles'

type Props = {
  onInput: (e: any) => void
  onSubmit: (e?: any) => void
  t: Translation
  name: string
  type: string
  selectedId: string
  allConfigIds: Array<any>
  allFiles: Array<any>
  allDataSources: Array<any>
  typeSelectors: Array<any>
  plotConfigs: PlotConfigMap
}

class EditBox extends Component<Props> {
  public override componentDidMount () {
    hotkeys('Enter', this.handleEnter)
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleEnter)
  }

  private readonly handleEnter = () => this.props.onSubmit()

  public override render () {
    const {
      onInput: handleInput,
      onSubmit: handleSubmit,
      name,
      selectedId,
      allConfigIds,
      allFiles,
      allDataSources,
      typeSelectors,
      type,
      t,
      plotConfigs,
    } = this.props

    const sortedSelectors = [ ...allConfigIds, ...allFiles, ...allDataSources ]
      .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    const selectedSelector = sortedSelectors.find(({ key }) => key === selectedId)

    const selectedPlotConfig = plotConfigs[selectedSelector.key]

    let isTablePlot = false

    if (selectedPlotConfig?.selectedY?.endsWith('|all')) {
      isTablePlot = true
    }

    const realTypeSelectors = isTablePlot ? [ { key: 'table', value: 'Table' } ] : typeSelectors

    return (
      <Form>
        <Input
          label={t('addPlotDialog.tileName.label')}
          title={t('addPlotDialog.tileName.title')}
          name='name'
          type='text'
          value={name}
          autoFocus
          onChange={handleInput}
        />
        <Input
          label={t('addPlotDialog.configId.label')}
          title={t('addPlotDialog.configId.title')}
          name='plotConfigId'
          type='select'
          value={selectedId}
          selectors={sortedSelectors}
          onChange={handleInput}
        />
        <Input
          label={t('addPlotDialog.type.label')}
          title={t('addPlotDialog.type.title')}
          name='type'
          type='select'
          value={isTablePlot ? 'table' : type ?? typeSelectors[0].key}
          selectors={realTypeSelectors}
          onChange={handleInput}
          data-tile
        />
        <Button
          onClick={handleSubmit}
          disabled={!selectedId}
          title={t('addPlotDialog.button.title')}
        >
          <div className='cut'>
            <i className='pe-7s-check' />
          </div>
          <span>{t('addPlotDialog.button.label')}</span>
        </Button>
      </Form>
    )
  }
}

export default compose<any>(withTranslation('visualization'))(EditBox)
