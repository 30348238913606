import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import * as ApplicationActions from '@/store/application/main/actions'
import { Translation } from '@/types/translation'

import { SimulateDialog } from '../SimulateDialog'

const T = 'projectMatrixDialog'

const connector = connect(null, {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  simulateLoading: boolean
  simulationRunning: boolean
  isNotSimulated: boolean
  setSelections: any // TODO: remove
  t: Translation
}

class SimulateAll extends Component<Props> {
  private readonly handleClick = () => {
    const { openDialog } = this.props

    openDialog(SimulateDialog.ALLNAME)
  }

  private readonly getTitle = () => {
    const { simulateLoading, simulationRunning, isNotSimulated, t } = this.props

    if (simulateLoading) {
      return t(`${T}.titles.simulateAll.loading`)
    }

    if (simulationRunning) {
      return t(`${T}.titles.simulateAll.running`)
    }

    if (!isNotSimulated) {
      return t(`${T}.titles.simulateAll.none`)
    }

    return t(`${T}.titles.simulateAll.default`)
  }
  
  public override render () {
    // restProps should not contain currentProject
    const { simulateLoading, simulationRunning, isNotSimulated, ...restProps } = this.props
    const disabled = simulateLoading || simulationRunning || !isNotSimulated

    return (
      <Button {...restProps} title={this.getTitle()} disabled={disabled} onClick={this.handleClick}>
        <FontAwesomeIcon icon={faBars} />
      </Button>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(SimulateAll)
