import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import { getReferenceDate } from '@/store/timestamps'
import * as UtilActions from '@/store/util/actions'
import ThreeManager from '@/three/ThreeManager'
import type { CasterElementNames, CreateValid, EditElements } from '@/types/data'
import type { FilterControlDefinition } from '@/types/filter'
import type { DefaultState, ElementMaps } from '@/types/state'

import { PlaybackWindow } from './casterDataServer/PlaybackWindow'
import { ProjectDataDialog } from './dialogs/project/ProjectDataDialog'
import FeatureFlags from './FeatureFlags'
import UpdatesDisplay from './UpdatesDisplay'

// import isEqual from 'lodash/isEqual'

const CasterFrame = styled.div<{
  $CasterTree: boolean
  $CasterDialog: boolean
  $CasterDashboard: boolean
  $blur: boolean
  $currentDashboardWidth: number
  $currentCasterDialogWidth: number
}>`${({
  $CasterTree,
  $CasterDialog,
  $CasterDashboard,
  $blur,
  $currentDashboardWidth,
  $currentCasterDialogWidth,
}: any) =>
  css`
  position: absolute;
  top: 50px;
  right: ${$CasterDialog ? '335px' : '0'};
  bottom: 0;
  left: ${$CasterTree ? ($CasterDashboard ? `${$currentDashboardWidth}px` : '280px') : '0'};
  filter: blur(${$blur ? '5px' : '0'});
  overflow: hidden;
  background-color: #000000;
  text-align: center;
  width: calc(100vw - ${$CasterDialog ? `${$currentCasterDialogWidth || 335}px` : '0px'} -
  ${$CasterTree ? ($CasterDashboard ? `${$currentDashboardWidth}px` : '280px') : '0px'});

  > canvas {
    pointer-events: all !important;
  }
`}`

interface TooltipContainerProps {
  $mousePos: {
    x: number
    y: number
  }
}

const TooltipContainer = styled.div.attrs<TooltipContainerProps>(({ $mousePos }) => ({
  style: {
    top: `${$mousePos.y}px`,
    left: `${$mousePos.x}px`,
  },
}))<TooltipContainerProps>`
  position: absolute;
  text-align: left;
  color: #FFFFFF;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  pointer-events: none;
  z-index: 200;
`

const Tooltip = styled.div<{ key: string, $position: Positions }>(({ $position }) => {
  let transform = ''

  switch ($position) {
    case 'top':
      transform = 'translate(-50%, -20px)'
      break
    case 'right':
      transform = 'translate(20px, -50%)'
      break
    case 'bottom':
      transform = 'translate(-50%, 20px)'
      break
    case 'left':
      transform = 'translate(-20px, -50%)'
      break
  }

  // eslint-disable-next-line no-implicit-coercion -- without the outer template string, the highlighting breaks...
  return `${css`
    transform: ${transform};
    display: inline-block;
    background-color: rgba(100, 100, 100, 0.95);
    padding: 10px;
    border: 1px solid rgba(150, 150, 150, 0.95);
    border-radius: 5px;
    margin: 5px;
    white-space: nowrap;
    user-select: none;
  `}`
})

const connector = connect(({
  data,
  application,
  filter,
  visualization,
  util,
  timestamps,
  ...remainingState
}: DefaultState) => ({
  additionalData: data.additionalData,
  amountOfComparisonCasterColumns: visualization.amountOfComparisonCasterColumns,
  appState: application.main.appState,
  createValid: data.createValid,
  currentCasterDialogWidth: visualization.currentCasterDialogWidth,
  currentDashboardWidth: visualization.currentDashboardWidth,
  currentProject: application.main.currentProject,
  currentSimpleDashboardTabIndex: application.main.currentSimpleDashboardTabIndex,
  currentSimulationCase: application.main.currentSimulationCase,
  dirtyDeletePaths: data.dirtyDeletePaths,
  dirtyPaths: data.dirtyPaths,
  editElements: data.editElements,
  editValues: data.editValues,
  featureFlags: FeatureFlags.getRealFeatureFlags({ application } as DefaultState),
  filterControlDefinitions: filter.filterControlDefinitions,
  hasChanges: data.hasChanges,
  hidePaths: data.hidePaths,
  isLoggedIn: FeatureFlags.isLoggedIn({ application } as DefaultState),
  newCopiedElementsToDraw: data.newCopiedElementsToDraw,
  openDialogs: application.main.openDialogs,
  parentPath: data.parentPath,
  rollerChildren: util.rollerChildren,
  selectedPaths: data.selectedPaths,
  target: filter.target,
  term: filter.term,
  termDisabled: filter.termDisabled,
  tooltip: application.main.tooltip,
  updatesCount: data.updatesCount,
  timestamps,
  loadingStatus: application.main.loadingStatus,
  openAppDialogs: application.main.openAppDialogs,
  ...getElementMapsObject(remainingState as DefaultState),
}), {
  clearDirtyPaths: DataActions.clearDirtyPaths,
  openDialog: ApplicationActions.openDialog,
  removeDeletePaths: DataActions.removeDeletePaths,
  resetTarget: FilterActions.resetTarget,
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
  setNewCopiedElementsToDraw: DataActions.setNewCopiedElementsToDraw,
  setRollerVisible: UtilActions.setRollerVisible,
  setTerm: FilterActions.setTerm,
  setTooltip: ApplicationActions.setTooltip,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isNewCaster: boolean
  redraw: boolean
  blur: boolean
}

type State = {
  mousePos: { x: number, y: number }
}

export type PassedData = {
  additionalData: any
  amountOfComparisonCasterColumns: number
  clearDirtyPaths: typeof DataActions.clearDirtyPaths
  createValid: CreateValid
  dirtyDeletePaths: string[]
  dirtyPaths: string[]
  editElements: EditElements
  // eslint-disable-next-line @typescript-eslint/ban-types
  editValues: Record<CasterElementNames | 'General', Object>
  elementMaps: ElementMaps
  featureFlags: Record<string, boolean>
  filterControlDefinitions: FilterControlDefinition[]
  hasChanges: boolean
  hidePaths: string[]
  isNewCaster: boolean
  newCopiedElementsToDraw: boolean
  parentPath: string
  redraw: boolean
  removeDeletePaths: typeof DataActions.removeDeletePaths
  resetTarget: typeof FilterActions.resetTarget
  rollerChildren: number
  selectedPaths: Set<string>
  setSelectedElementPaths: (selectedData?: any, multiSelect?: boolean, massSelect?: boolean) => void
  setLoadingStatus: typeof ApplicationActions.setLoadingStatus
  setNewCopiedElementsToDraw: typeof DataActions.setNewCopiedElementsToDraw
  setRollerVisible: typeof UtilActions.setRollerVisible
  setTerm: typeof FilterActions.setTerm
  setTooltip: typeof ApplicationActions.setTooltip
  target: string
  term: string
  termDisabled: boolean
  tooltip: Record<'SectionView' | 'UIView', Tooltip[]>
  referenceCasterDate: Date
}

class CasterContainer extends Component<Props, State> {
  private prevAppState: AppState | null = null

  private mountRef: any

  public override state: State = {
    mousePos: { x: 0, y: 0 },
  }

  public override componentDidMount () {
    ThreeManager.base.mount()

    window.addEventListener('pointermove', this.setMousePos, true)
  }

  public override shouldComponentUpdate (nextProps: Props) {
    // FIXME: if this is used the tooltips don't move when the mouse moves, so we need to find a way to update them
    // FIXME: there might be more side effects that are not yet known!
    // if (isEqual(this.props, nextProps)) {
    //   return false
    // }

    const elementMaps = getElementMapsObject(nextProps)

    const {
      additionalData,
      amountOfComparisonCasterColumns,
      clearDirtyPaths,
      createValid,
      dirtyDeletePaths,
      dirtyPaths,
      editElements,
      editValues,
      featureFlags,
      filterControlDefinitions,
      hasChanges,
      hidePaths,
      isNewCaster,
      newCopiedElementsToDraw,
      parentPath,
      redraw,
      removeDeletePaths,
      resetTarget,
      rollerChildren,
      selectedPaths,
      setSelectedElementPaths,
      setLoadingStatus,
      setNewCopiedElementsToDraw,
      setRollerVisible,
      setTerm,
      setTooltip,
      target,
      term,
      termDisabled,
      tooltip,
      timestamps,
    } = nextProps

    const data = {
      additionalData,
      amountOfComparisonCasterColumns,
      clearDirtyPaths,
      createValid,
      dirtyDeletePaths,
      dirtyPaths,
      editElements,
      editValues,
      elementMaps,
      featureFlags,
      filterControlDefinitions,
      hasChanges,
      hidePaths,
      isNewCaster,
      newCopiedElementsToDraw,
      parentPath,
      redraw,
      removeDeletePaths,
      resetTarget,
      rollerChildren,
      selectedPaths,
      setSelectedElementPaths,
      setLoadingStatus,
      setNewCopiedElementsToDraw,
      setRollerVisible,
      setTerm,
      setTooltip,
      target,
      term,
      termDisabled,
      tooltip,
      referenceCasterDate: getReferenceDate(timestamps),
    }

    // TODO: could verify if props changed
    ThreeManager.base.setData(data)

    return true
  }

  public override componentDidUpdate () {
    const {
      currentProject,
      currentSimulationCase,
      openDialog,
      appState,
      isLoggedIn,
      featureFlags,
      loadingStatus,
    } = this.props

    if (
      this.prevAppState !== appState &&
      appState === AppState.Caster &&
      isLoggedIn &&
      currentProject?.id &&
      currentSimulationCase?.id &&
      !loadingStatus && // true when caster is loading
      !FeatureFlags.usesSlimVersion(featureFlags)
    ) {
      openDialog(ProjectDataDialog.NAME)
    }

    if (this.prevAppState !== appState) {
      this.prevAppState = appState
    }
  }

  public override componentWillUnmount () {
    ThreeManager.base.unmount()
    ThreeManager.killBase()

    window.removeEventListener('pointermove', this.setMousePos, true)
  }

  private readonly setMountRef = (mountRef: any) => {
    if (this.mountRef) {
      return
    }

    this.mountRef = mountRef

    ThreeManager.init(this.mountRef)
    ThreeManager.base.init(this.mountRef)
  }

  // Tried to use event as MouseEvent type but there were missing properties
  private readonly setMousePos = (event: MouseEvent): any => {
    if (!this.mountRef) {
      return
    }

    this.setState({
      mousePos: {
        x: event.clientX,
        y: event.clientY - 30,
      },
    })
  }

  public override render () {
    const { mousePos } = this.state
    const {
      openDialogs,
      tooltip,
      blur,
      updatesCount,
      isLoggedIn,
      currentSimpleDashboardTabIndex,
      currentDashboardWidth,
      currentCasterDialogWidth,
      featureFlags,
      openAppDialogs,
    } = this.props

    const tooltips = Object.values(tooltip ?? {}).reduce((list: Tooltip[], current) => [
      ...list,
      ...current,
    ], [])

    const allowedDialogs = [ PlaybackWindow.NAME ]
    const filteredOpenAppDialogs = openAppDialogs.filter(dialog => !allowedDialogs.includes(dialog))

    return (
      <>
        <CasterFrame
          ref={this.setMountRef}
          $blur={blur}
          $CasterTree={openDialogs.includes('CasterTree')}
          $CasterDialog={openDialogs.includes('CasterDialog') || openDialogs.includes('PartsWarehouse')}
          $CasterDashboard={currentSimpleDashboardTabIndex > 0}
          $currentDashboardWidth={Math.min(currentDashboardWidth ?? 500, window.innerWidth - 390)}
          $currentCasterDialogWidth={currentCasterDialogWidth}
        >
          {isLoggedIn && FeatureFlags.canToggleLiveMode(featureFlags) && <UpdatesDisplay updatesCount={updatesCount} />}
        </CasterFrame>
        {
          tooltips && tooltips.length > 0 && !filteredOpenAppDialogs.length && (
            <TooltipContainer $mousePos={mousePos}>
              {tooltips.map(({ tooltip, position }) => <Tooltip key={uuid()} $position={position}>{tooltip}</Tooltip>)}
            </TooltipContainer>
          )
        }
      </>
    )
  }
}

export default compose<any>(connector)(CasterContainer)
