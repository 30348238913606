import cloneDeep from 'lodash/cloneDeep'

import ThreeUtil from '@/three/logic/Util'
import type { ElementMaps, ElementName, MountLogMapKey, SlotMapKey, TagName } from '@/types/state'

import { Mapping } from './mapping/Mapping'

export class ElementMapsUtil {
  public static getMountLogMapByTagName (elementMaps: ElementMaps, tagName: TagName) {
    if (tagName !== 'SensorPoint' && tagName !== 'DataPoint') {
      return elementMaps[`${tagName}MountLog`]
    }

    if (tagName === 'SensorPoint') {
      return {
        ...elementMaps.RollerSensorPointMountLog,
        ...elementMaps.RollerBodySensorPointMountLog,
        ...elementMaps.SegmentSensorPointMountLog,
      }
    }

    // DataPoint
    return {
      ...elementMaps.RollerDataPointMountLog,
      ...elementMaps.RollerBodyDataPointMountLog,
      ...elementMaps.SegmentDataPointMountLog,
      ...elementMaps.StrandDataPointMountLog,
      ...elementMaps.MoldFaceDataPointMountLog,
    }
  }

  public static getSlotMapByTagName (elementMaps: ElementMaps, tagName: TagName) {
    if (tagName !== 'SensorPoint' && tagName !== 'DataPoint') {
      return elementMaps[`${tagName}Slot`]
    }

    if (tagName === 'SensorPoint') {
      return {
        ...elementMaps.RollerSensorPointSlot,
        ...elementMaps.RollerBodySensorPointSlot,
        ...elementMaps.SegmentSensorPointSlot,
      }
    }

    // DataPoint
    return {
      ...elementMaps.RollerDataPointSlot,
      ...elementMaps.RollerBodyDataPointSlot,
      ...elementMaps.SegmentDataPointSlot,
      ...elementMaps.StrandDataPointSlot,
      ...elementMaps.MoldFaceDataPointSlot,
    }
  }

  public static getFullCasterElement<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    slot: Slot,
    mountLog: MountLog,
    numericId: number,
  ): FullCasterElement<Slot, MountLog> {
    return {
      ...slot,
      ...mountLog,
      id: numericId,
      mountLogId: mountLog.id,
    }
  }

  public static getSlotMapName (elementName: ElementName) {
    return `${elementName}Slot` as SlotMapKey
  }

  public static getSlotByMountLog<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    elementName: ElementName,
    mountLog: MountLog,
    elementMaps: ElementMaps,
  ): Slot | null {
    const slotMap = elementMaps[ElementMapsUtil.getSlotMapName(elementName)]

    if (!slotMap) {
      return null
    }

    return slotMap[mountLog.slotId] as Slot ?? null
  }

  public static getMountLogMapName (elementName: ElementName) {
    return `${elementName}MountLog` as MountLogMapKey
  }

  public static getMountLogBySlot<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    elementName: ElementName,
    slot: Slot,
    elementMaps: ElementMaps,
  ): MountLog | null {
    const mountLogMap = elementMaps[ElementMapsUtil.getMountLogMapName(elementName)]

    if (!mountLogMap) {
      return null
    }

    // TODO: this is slow maybe we can create a mapping for this when we load the data
    for (const mountLogId in mountLogMap) {
      const mountLog = mountLogMap[mountLogId]

      if (mountLog && mountLog.slotId === slot.id) {
        return mountLog as unknown as MountLog
      }
    }

    return null
  }

  public static getMountLogById<MountLog extends BaseMountLog> (
    elementName: ElementName,
    mountLogId: string,
    elementMaps: ElementMaps,
  ): MountLog | null {
    const mountLogMap = elementMaps[ElementMapsUtil.getMountLogMapName(elementName)]
    const mountLog = mountLogMap?.[mountLogId] as unknown as MountLog | undefined

    return mountLog ?? null
  }

  public static getSlotById<Slot extends BaseSlot> (
    elementName: ElementName,
    slotId: string,
    elementMaps: ElementMaps,
  ): Slot | null {
    const slotMap = elementMaps[ElementMapsUtil.getSlotMapName(elementName)]
    const slot = slotMap?.[slotId] as Slot | undefined

    return slot ?? null
  }

  public static getFullCasterElementByMountLog<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    elementName: ElementName,
    mountLog: MountLog,
    elementMaps: ElementMaps,
  ): FullCasterElement<Slot, MountLog> | null {
    const slot = ElementMapsUtil.getSlotByMountLog<Slot, MountLog>(elementName, mountLog, elementMaps)

    if (!slot) {
      return null
    }

    const numericId = Mapping.numericIdByMountLogId[mountLog.id] ?? -1

    return ElementMapsUtil.getFullCasterElement<Slot, MountLog>(slot, mountLog, numericId)
  }

  public static getFullCasterElementByMountLogId<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    elementName: ElementName,
    mountLogId: string,
    elementMaps: ElementMaps,
  ): FullCasterElement<Slot, MountLog> | null {
    const mountLog = ElementMapsUtil.getMountLogById<MountLog>(elementName, mountLogId, elementMaps)

    if (!mountLog) {
      return null
    }

    return ElementMapsUtil.getFullCasterElementByMountLog<Slot, MountLog>(elementName, mountLog, elementMaps)
  }

  public static getElementName (tagName: TagName, parentTagName: TagName): ElementName {
    if (tagName === 'DataPoint' || tagName === 'SensorPoint') {
      return parentTagName + tagName as ElementName
    }

    return tagName as ElementName
  }

  public static getTagName (elementName: ElementName): TagName {
    if (elementName.endsWith('DataPoint')) {
      return 'DataPoint'
    }

    if (elementName.endsWith('SensorPoint')) {
      return 'SensorPoint'
    }

    return elementName as TagName
  }

  public static getMountLogByPath<MountLog extends BaseMountLog> (
    path: string,
    elementMaps: ElementMaps,
  ): MountLog | null {
    const { type, id } = ThreeUtil.getElementInfo(path)
    const { type: parentType } = ThreeUtil.getParentInfo(path)
    const elementName = ElementMapsUtil.getElementName(type, parentType ?? 'Strand')
    const mountLogId = Mapping.mountLogIdByTypeAndNumericId[type][id]

    return ElementMapsUtil.getMountLogById<MountLog>(elementName, mountLogId, elementMaps)
  }

  public static getSlotByPath<Slot extends BaseSlot> (
    path: string,
    elementMaps: ElementMaps,
  ): Slot | null {
    const mountLog = ElementMapsUtil.getMountLogByPath(path, elementMaps)

    if (!mountLog) {
      return null
    }

    const { type } = ThreeUtil.getElementInfo(path)
    const { type: parentType } = ThreeUtil.getParentInfo(path)
    const elementName = ElementMapsUtil.getElementName(type, parentType ?? 'Strand')

    return ElementMapsUtil.getSlotByMountLog<Slot, BaseMountLog>(elementName, mountLog, elementMaps)
  }

  public static getFullCasterElementByPath<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    path: string,
    elementMaps: ElementMaps,
    clone = false,
  ): FullCasterElement<Slot, MountLog> | null {
    const mountLog = ElementMapsUtil.getMountLogByPath<MountLog>(path, elementMaps)

    if (!mountLog) {
      return null
    }

    const { type } = ThreeUtil.getElementInfo(path)
    const { type: parentType } = ThreeUtil.getParentInfo(path)
    const elementName = ElementMapsUtil.getElementName(type, parentType ?? 'Strand')

    const fullElement = ElementMapsUtil.getFullCasterElementByMountLog<Slot, MountLog>(
      elementName,
      mountLog,
      elementMaps,
    )

    if (clone) {
      return cloneDeep(fullElement)
    }

    return fullElement
  }
}
