import { initialState, MatrixAction, MatrixActionsEnum } from './consts'
import { DataActionsEnum } from '../data/consts'

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  [MatrixActionsEnum.ACTION_SET_COLUMNS]: (state: MatrixState, { columns }: MatrixAction) => ({
    ...state,
    columns,
  }),

  [MatrixActionsEnum.ACTION_SET_SELECTIONS]: (state: MatrixState, { selections }: MatrixAction) => ({
    ...state,
    selections,
  }),

  [MatrixActionsEnum.ACTION_SET_GRID]: (state: MatrixState, { grid }: MatrixAction) => ({
    ...state,
    grid,
  }),

  [MatrixActionsEnum.ACTION_SET_CLONE_SIMULATION_CASE_ID]: (state: MatrixState, { id }: MatrixAction) => ({
    ...state,
    cloneSimulationCaseId: id,
  }),

  [MatrixActionsEnum.ACTION_UPDATE_RESULT_DATA]: (state: MatrixState, { data }: MatrixAction) => ({
    ...state,
    resultData: {
      ...state.resultData,
      ...data,
    },
  }),

  [MatrixActionsEnum.ACTION_SET_SCROLL_POSITION]: (state: MatrixState, { lastScrollTopPosition }: MatrixAction) => ({
    ...state,
    lastScrollTopPosition,
  }),

  [DataActionsEnum.ACTION_RESET_ALL]: (state: MatrixState) => ({
    ...initialState,
    lastScrollTopPosition: state.lastScrollTopPosition,
  }),
}

export default function (state = initialState, action: MatrixAction) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}
