import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

export const FormWrapper = styled.div`
  display: inline-block;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 20px;
  left: 0;
  right: 0;
  padding-right: 20px;
  bottom: 20px;
  top: 0;
  border-top: 1px solid orange;
`
export const Loading = styled(ReactLoading)`${({ theme }) =>
  css`
  color: ${theme.primary.font}
  max-width: 50px;
  max-height: 50px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`}`

export const Label = styled.div`
  line-height: 38px;
  margin-top: 17px;
  margin-right: 10px;
  min-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Command = styled.div`${({ theme }) =>
  css`
  min-width: 90px;
  line-height: 38px;
  margin-top: 17px;
  font-weight: 500;
  color: ${theme.primary.main};
`}`

const BaseElement = styled.i`
  &:not(:last-of-type) {
    margin-left: -10px;
  }
`

export const IconButton = styled(BaseElement)<{ $gray?: boolean }>`${({ $gray }) =>
  css`
  line-height: 38px;
  margin: 17px 10px 0;
  font-size: 20px;
  cursor: pointer;

  ${
    $gray && css`
    opacity: 0.25;
    pointer-events: none;
  `
  }
`}`

export const InputWrapper = styled.div<{ $fullscreen?: boolean }>`${({ $fullscreen }) =>
  css`
  ${!$fullscreen && 'display: flex;'}
  flex-wrap: wrap;
  margin-top: -10px;

  > div {
    display: flex;

    > div {
      /* width bugfix */
      width: 0;
    }
  }

  > div:first-child {
    flex: 2;
    margin-bottom: -20px;
  }

  > div:not(:first-child) {
    flex: 1 1 auto;
    min-width: 184px;
    overflow: hidden;

    > div:first-child {
      flex: 1
    }

    > div:not(:first-child) {
      flex: 2
    }

    > i:not(:last-of-type) {
      margin-left: 0;
    }

    > div:not(:last-of-type) {
      margin-right: 7px;
    }

    .largeText input {
      font-size: 32px;
      font-weight: 500;
      padding: 0;
    }

    > div > div:not(.largeText) input {
      padding: 7px 0 7px;
    }

    .disabled > div {
      background-color: transparent;
    }
  }
`}`

export const Warning = styled.div`${({ theme }) =>
  css`
  color: ${theme.colors.swatch17};
  margin-top: 5px;
  font-size: 14px;
  text-align: right;
`}`
