import TWEEN from '@tweenjs/tween.js'
import isEqual from 'lodash/isEqual'
import * as THREE from 'three'
import { PerspectiveCamera, Vector2 } from 'three'

import Verify from '@/logic/Util'
import type { PassedData } from '@/react/Caster'
import LodUtil from '@/three/logic/LodUtil'
import Util from '@/three/logic/Util'
import type { ElementCache } from '@/three/objects'
import { SetValuesData } from '@/three/objects/BaseObject'
import CoordinateAxes from '@/three/objects/CoordinateAxes'
import ThreeMold from '@/three/objects/Mold'
import PasslineCurve from '@/three/objects/PasslineCurve'
import { Views } from '@/three/ThreeBase'
import { StrandSides } from '@/types/elements/enum'
import type { ElementMaps } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'

import CalculationUtil from './CalculationUtil'
import CameraHandlers from './CameraHandlers'
import DrawHandlers from './DrawHandlers'
import EventHandlers from './EventHandlers'
import Getters from './Getters'
import JumpHandlers from './JumpHandlers'
import MainHandlers from './MainHandlers'
import PhantomHandler from './PhantomElementHandler'
import UpdateTransformHandler from './UpdateTransformHandler'
import BaseView from '../BaseView'

export const CAMERA_X_ANGLE = 35 // 25
export const CAMERA_Y_ANGLE = 40 // 35

export default class MainView extends BaseView {
  public constructor (renderer: THREE.WebGLRenderer, views: Partial<Views>, splitMode = 0, isSectionView = false) {
    super(renderer, views)

    MainView.staticClassName = 'MainView'
    this.className = 'MainView'

    this.splitMode = splitMode
    this.sectionViewExpanded = true
    this.sectionDetail = isSectionView
    this.sceneReady = false
    this.isRedrawing = false

    this.reset()

    this.deleteList = []
    this.selection = []
    this.plHeight = 0
    this.spacer = 0.5

    this.largestNozzle = 0
    this.widestNozzle = 0
    this.largestNarrowNozzle = 0
    this.widestNarrowNozzle = 0

    this.largestRoller = 0
    this.widestRoller = 0
    this.largestNarrowRoller = 0
    this.widestNarrowRoller = 0

    this.font = Util.fontRobotoMedium
    this.fontMaterial = new THREE.MeshBasicMaterial({ color: '#565c61' })
    this.labelLineMaterial = new THREE.LineBasicMaterial({ color: '#3f454a' })

    this.perspectiveCamera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000)
    this.perspectiveCamera.position.z = 4
    this.orthographicCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.1, 1000)

    this.oldCameraPosition = this.perspectiveCamera.position.clone()

    this.camera = this.perspectiveCamera
    this.setupControls()
    this.setCameraPosition = CameraHandlers.setCameraPosition

    this.ambientLight = new THREE.AmbientLight(0xffffff, 1)
    this.ambientLight.name = 'AmbientLight'
    this.scene.add(this.ambientLight)

    this.light = new THREE.DirectionalLight(0xffffff, 3)
    this.light.position.set(1, 1, 0)

    // this.scene.add(new THREE.DirectionalLightHelper(this.light, 1, 0x00ff00))

    this.light2 = new THREE.DirectionalLight(0xffffff, 3)
    this.light2.position.set(-1, -1, 0)

    // this.scene.add(new THREE.DirectionalLightHelper(this.light2, 1, 0xff0000))

    this.lightGroup = new THREE.Group()
    this.lightGroup.name = 'DirectionalLightGroup'

    this.lightGroup.add(this.light)
    this.lightGroup.add(this.light2)

    this.lightGroup.position.copy(this.camera.position).normalize()

    this.scene.add(this.lightGroup)

    const planeGeometry = new THREE.PlaneGeometry(2, 4, 1)
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: '#00b0b2',
      side: THREE.DoubleSide, // !
    })

    this.sectionPlane = new THREE.Mesh(planeGeometry, planeMaterial)
    this.sectionPlane.name = 'SectionPlane'
    this.sectionPlane.rotateX(-Util.RAD90)
    this.sectionPlane.visible = false
    this.scene.add(this.sectionPlane)

    // TODO: remove old code
    // const geometry = new (THREE as any).Geometry() // TODO: is this used?

    // geometry.vertices.push(
    //   new THREE.Vector3(-10, 0, -1),
    //   new THREE.Vector3(25, 0, -1),
    // )
  }

  public override className

  public splitMode

  public sectionViewExpanded

  public sectionDetail

  public sceneReady

  public isRedrawing

  public deleteList: any[]

  public selection: any[]

  public plHeight

  public spacer

  public largestNozzle: number

  public widestNozzle: number

  public largestNarrowNozzle: number

  public widestNarrowNozzle: number

  public largestRoller: number

  public widestRoller: number

  public largestNarrowRoller: number

  public widestNarrowRoller: number

  public font: any

  public fontMaterial: THREE.Material

  public labelLineMaterial

  public override camera: any

  public perspectiveCamera: PerspectiveCamera | null = null

  public orthographicCamera: THREE.OrthographicCamera

  public oldCameraPosition

  public setCameraPosition

  private readonly ambientLight: THREE.AmbientLight

  private readonly light: THREE.DirectionalLight

  private readonly light2: THREE.DirectionalLight

  private readonly lightGroup: THREE.Group

  public sectionPlane

  public term? = ''

  public containerList: any

  public elementList: ElementCache = {}

  public phantomElementList: ElementCache = {}

  public sideLabels: any

  public selectedElements: any

  public applyCurve = true

  public controls: any

  public dataChanged?: boolean

  public amountOfComparisonCasterColumns = 0

  public elementMaps: ElementMaps = {
    Caster: null,
    PasslineSlot: {},
    PasslineMountLog: {},
    PasslineSectionSlot: {},
    PasslineSectionMountLog: {},
    MoldSlot: {},
    MoldMountLog: {},
    MoldBCAreaSlot: {},
    MoldBCAreaMountLog: {},
    MoldFaceSlot: {},
    MoldFaceMountLog: {},
    AirLoopSlot: {},
    AirLoopMountLog: {},
    CoolingLoopSlot: {},
    CoolingLoopMountLog: {},
    CoolingZoneSlot: {},
    CoolingZoneMountLog: {},
    LoopAssignmentSlot: {},
    LoopAssignmentMountLog: {},
    SegmentGroupSlot: {},
    SegmentGroupMountLog: {},
    SegmentSlot: {},
    SegmentMountLog: {},
    SupportPointSlot: {},
    SupportPointMountLog: {},
    RollerBodySlot: {},
    RollerBodyMountLog: {},
    RollerBearingSlot: {},
    RollerBearingMountLog: {},
    NozzleSlot: {},
    NozzleMountLog: {},
    RollerSlot: {},
    RollerMountLog: {},
    StrandGuideSlot: {},
    StrandGuideMountLog: {},
    DataLineSlot: {},
    DataLineMountLog: {},
    SegmentSensorPointSlot: {},
    SegmentSensorPointMountLog: {},
    RollerSensorPointSlot: {},
    RollerSensorPointMountLog: {},
    RollerBodySensorPointSlot: {},
    RollerBodySensorPointMountLog: {},
    MoldFaceDataPointSlot: {},
    MoldFaceDataPointMountLog: {},
    RollerBodyDataPointSlot: {},
    RollerBodyDataPointMountLog: {},
    RollerDataPointSlot: {},
    RollerDataPointMountLog: {},
    SegmentDataPointSlot: {},
    SegmentDataPointMountLog: {},
    StrandDataPointSlot: {},
    StrandDataPointMountLog: {},
    CCElement: {},
    ChangeItem: {},
  }

  public setSelectedElementPaths?: (
    selectedData?: string[] | string,
    multiSelect?: boolean,
    massSelect?: boolean,
    clearFirst?: boolean,
  ) => void

  public clearDirtyList?: () => void

  public dirtyList?: string[]

  public hideList?: string[]

  public resetTarget?: () => void

  public rollerChildren?: unknown

  public isNewCaster?: boolean

  public featureFlags?: Record<string, boolean>

  public redraw?: boolean

  private setRollerVisible?: (mode: number) => void

  public setLoadingStatus?: (status: boolean) => void

  private target?: any

  public removeDeletePaths?: (paths: string[]) => void

  public passlineCurve?: PasslineCurve

  public mold?: ThreeMold

  public hasChanges?: boolean

  public additionalData?: any

  public tweenActive?: boolean

  public perspectiveControls?: any

  public orthographicControls?: any

  public cameraTween?: any

  public caster?: THREE.Group

  public setTerm?: any

  public coordinate?: CoordinateAxes

  public coordinateStraight?: CoordinateAxes

  public gridHelper?: THREE.GridHelper

  public center2d?: Coord

  public editElements?: any

  public parentPath?: string

  public phantoms?: THREE.Group

  public sectionPlaneFolded?: THREE.Mesh

  public referenceCasterDate?: Date

  public override reset () {
    super.reset()

    this.term = ''
    this.containerList = {}
    this.elementList = {}
    this.phantomElementList = {}
    this.sideLabels = {}
    this.selectedElements = []
    this.applyCurve = true
    this.largestNozzle = 0
    this.largestNarrowNozzle = 0
    this.widestNozzle = 0
    this.widestNarrowNozzle = 0
    this.largestRoller = 0
    this.largestNarrowRoller = 0
    this.widestRoller = 0
    this.widestNarrowRoller = 0
    this.deleteList = []
    this.selection = []
    this.plHeight = 0
    delete this.additionalData
  }

  public setCenter () {
    if (!this.controls || !this.camera) {
      return
    }

    const center = new THREE.Vector3(0, this.controls.target.y, 0)
    const position = this.camera.position

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    CameraHandlers.tweenCamera(this, position, center, undefined, undefined, 500, () => {})
  }

  public setView (side: number) {
    if (!this.camera) {
      return
    }

    const calculated = CalculationUtil.calcSetView(
      side,
      this.camera,
      this.controls,
      this.plHeight,
    )

    if (!calculated) {
      return
    }

    const { position, center, xAngle, yAngle } = calculated

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    CameraHandlers.tweenCamera(this, position, center, xAngle, yAngle, 500, () => {})
  }

  public setData (data: PassedData) {
    // TODO: could verify if data changed

    this.dataChanged = !isEqual(this.elementMaps.Caster ?? {}, data.elementMaps.Caster ?? {})
    this.elementMaps = data.elementMaps
    this.referenceCasterDate = data.referenceCasterDate
    this.setSelectedElementPaths = data.setSelectedElementPaths
    this.clearDirtyList = data.clearDirtyPaths
    this.dirtyList = data.dirtyPaths
    this.hideList = data.dirtyDeletePaths // since we want to hide on delete we are not using data.hidePaths here
    this.resetTarget = data.resetTarget
    this.rollerChildren = data.rollerChildren || 2
    this.isNewCaster = data.isNewCaster
    this.featureFlags = data.featureFlags
    this.redraw = data.redraw
    this.setRollerVisible = data.setRollerVisible
    this.setLoadingStatus = data.setLoadingStatus
    this.target = data.target
    this.removeDeletePaths = data.removeDeletePaths
    this.amountOfComparisonCasterColumns = data.amountOfComparisonCasterColumns

    PhantomHandler.setPhantomData(this, data)

    DrawHandlers.handleRedrawView(this, data)

    this.hasChanges = data.hasChanges

    MainHandlers.updateCasterData(this, data)
  }

  public updateTransforms () {
    const SegmentGroupMap = this.elementMaps.SegmentGroupSlot
    const sections = ElementsUtil.getPasslineSectionsByDate(this.elementMaps, this.referenceCasterDate)
    const { moldSlot, moldMountLog } = ElementsUtil
      .getMoldAndMoldMountLogByDate(this.elementMaps)

    if (
      !moldSlot || !moldMountLog || !Verify
        .hasElementType(SegmentGroupMap) || !sections || !this
        .referenceCasterDate
    ) {
      return
    }

    this.passlineCurve?.setData(sections, this.clickableObjects, this.applyCurve)

    const moldData = ElementMapsUtil.getFullCasterElement(moldSlot, moldMountLog, 1)

    this.mold?.setValues({
      elementData: moldData as unknown as SetValuesData<MoldSlot, MoldMountLog>['elementData'],
      path: '',
      isDeleted: false,
      isHidden: false,
      isPhantom: false,
      view: this,
    })
    this.passlineCurve?.drawStrand()

    UpdateTransformHandler.updateTransformDrawableElementsAndPhantoms(
      this.elementList,
      this.phantomElementList,
      this.elementMaps,
    )

    UpdateTransformHandler.updateTransformNewPhantoms(this.phantomElementList)

    UpdateTransformHandler.updateTransformSegments(
      this.containerList.Segment,
      this.elementList.Segment,
    )
    UpdateTransformHandler.updateTransformDataPoints(this.containerList.Strand, this.elementList.DataPoint)
    UpdateTransformHandler.updateTransformDataLines(this.containerList.Strand, this.elementList.DataLine)

    if (!this.sectionDetail) {
      MainHandlers.handleNoSectionDetail(this)
    }
  }

  public updateRoller (displayType: number) {
    const rollerObject = this.elementList.Roller ?? {}
    const rollerPaths = Object.keys(rollerObject)

    for (let i = 0; i < rollerPaths.length; i++) {
      rollerObject[rollerPaths[i]!]?.setVisibility(displayType)
    }
  }

  public override resize (width?: number, height?: number) {
    if (width !== undefined && height !== undefined) {
      const calc = CalculationUtil.calcResize(this.sectionViewExpanded, this.splitMode, height, width)

      this.viewport = {
        x: calc.x,
        y: calc.y,
        width: calc.nWidth,
        height: calc.nHeight,
      }
    }

    if (this.camera) {
      this.camera.aspect = this.viewport.width / this.viewport.height
      this.camera.updateProjectionMatrix()
    }
  }

  public override animate (elapsed: number) {
    if (!this.camera) {
      return
    }

    const { position } = this.camera

    TWEEN.update(elapsed)

    if (isEqual(this.oldCameraPosition, position)) {
      return
    }

    if (
      this.target &&
      this.resetTarget &&
      !this.tweenActive &&
      this.oldCameraPosition.clone().sub(position).length() > 0
    ) {
      this.resetTarget()
    }

    this.oldCameraPosition = position.clone()

    this.lightGroup.position.copy(position).normalize()
    this.lightGroup.lookAt(new THREE.Vector3(0, 0, 0))

    LodUtil.handleLoDs(this.camera, this.elementList, this.phantomElementList)

    if (!this.sectionDetail) {
      this.controls.update()
    }
  }

  public override handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    const intersects = super.handleMouseUp(event, mouseOnCanvas)

    this.selection = []

    if (intersects.length) {
      MainHandlers.handleIntersects(this, intersects)
    }

    if (this.selection.length) {
      const multiSelect = event.ctrlKey || event.metaKey

      this.setSelectedElementPaths?.(this.selection, multiSelect)
    }

    if (event.shiftKey && this.controls && this.className === 'MainView') {
      this.controls.enabled = true
    }

    return intersects
  }

  // Filter
  public jumpToFiltered () {
    if (this.resetTarget) {
      this.resetTarget()
    }

    if (!this.containerList || !this.containerList.Segment) {
      return
    }

    if (!this.term || this.term === 'r' || this.term === 'n') {
      this.jumpToFirst(true, true)

      return
    }

    const segmentList = Object.values(this.containerList.Segment)
    const segments = segmentList.filter((segment: any) => segment.visible)

    JumpHandlers.jumpToSegments(this, segments)

    this.views?.sectionView?.setView()
  }

  // Crosshairs
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public jumpToFilter (term: string, callback = () => {}, isJumpToSectionView = false) {
    const { elementMaps, referenceCasterDate: date } = this

    const segments = date
      ? Getters.getSegmentsFromSegmentGroup(
        elementMaps,
        term,
        this.containerList,
      )
      : []

    JumpHandlers.jumpToSegments(this, segments, callback, true, isJumpToSectionView)
  }

  public jumpToFirst (playAnimation = true, isDefaultView = false) {
    if (!this.containerList || !this.containerList.Segment) {
      return
    }

    const segmentList = Object
      .values(this.containerList.Segment)
      .filter((segment: any) => (
        segment.userData.side !== StrandSides.Left ||
        segment.userData.side !== StrandSides.Right
      ))
      .sort((a: any, b: any) => a.userData.plMinCoord - b.userData.plMinCoord)
    const segments = segmentList.slice(0, 1) // FIXME: why do this?

    JumpHandlers.jumpToSegments(this, segments, undefined, playAnimation)

    if (!isDefaultView) {
      this.views?.sectionView?.setView()
    }
  }

  public override handleKeyDown (event: any) {
    if (!this.sceneReady || event.target.tagName === 'INPUT') {
      return
    }

    if (event.shiftKey && !this.views?.uiView?.mouseDown && this.controls) {
      this.controls.enabled = false
    }

    EventHandlers.handleKeyCode(this, event)
  }

  public override handleKeyUp (event: any) {
    if (!event.shiftKey && this.controls) {
      this.controls.enabled = true
    }
  }

  public handleSelection (start: Vector2, end: Vector2) {
    if (!this.camera) {
      return
    }

    const { min, max } = CalculationUtil.calcSelection(this.viewport, start, end)

    const elementPaths = Getters.getSelectedElementsPaths(this.camera, min, max, this.clickableObjects)

    this.setSelectedElementPaths?.(elementPaths, true)
  }

  public updateSegments (segmentPaths: string[], newName: string) {
    const { Segment } = this.elementList

    if (!Segment) {
      return
    }

    for (let i = 0; i < segmentPaths.length; i++) {
      const segment = Segment[segmentPaths[i]!]

      if (segment) {
        segment.updateName(newName)
      }
    }
  }

  public toggleRollerVisibility () {
    this.setSelectedElementPaths?.()

    Util.RollerMode = Util.RollerMode === 1 ? 2 : 1
    this.updateRoller(Util.RollerMode)

    if (Util.RollerMode === 2) {
      MainHandlers.reloadFilteredElements(this)
    }

    this.setRollerVisible?.(Util.RollerMode)
  }

  public setupControls (center = false) {
    if (this.sectionDetail) {
      return
    }

    if (!this.perspectiveControls) {
      MainHandlers.setupPerspectiveControls(this)

      // if (this.perspectiveControls) { // to render when user moves the camera
      //   this.perspectiveControls.addEventListener('change', () => ThreeManager.base.renderScene())
      // }
    }

    if (!this.orthographicControls) {
      MainHandlers.setupOrthographicControls(this)
    }

    if (center) {
      this.orthographicControls.target.copy(center)
    }

    this.perspectiveControls.enabled = true
    this.orthographicControls.enabled = false

    this.controls = this.perspectiveControls
    this.controls.update()
  }

  public override unmount () {
    if (this.perspectiveControls) {
      this.perspectiveControls.dispose()
    }

    if (this.orthographicControls) {
      this.orthographicControls.dispose()
    }

    this.clickableObjects = []
    this.containerList = {}
    super.unmount()
  }
}
