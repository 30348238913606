import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import cloneDeep from 'lodash/cloneDeep'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { Container, Draggable } from 'react-smooth-dnd'
import { compose } from 'redux'
import { ThemeProvider } from 'styled-components'

import Input from '@/react/specific/Input'
import StyleConfig from '@/react/visualization/dashboard/config/StyleConfig'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Actions from './Actions'
import {
  Content,
  DeriveContainer,
  DeriveIcon,
  Dialog,
  DialogBackground,
  DragNDrop,
  DropContainer,
  Header,
  HeaderTitle,
  I,
  InnerText,
  List,
  ListItem,
  ListTitle,
  Title,
} from './Styles'

const T = 'projectMatrixDialog.configureDialog'

const connector = connect((state: DefaultState) => ({
  darkTheme: state.application.main.darkTheme,
  currentProject: state.application.main.currentProject,
  columns: state.matrix.columns,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  availableData: {
    [key: string]: any
    resultKeys: any
    commands: any
  }
  mappedColumns: Array<string>
  dataLoaded: boolean
}

export class ConfigureDialog extends Component<Props, State> {
  @Identifiable('ConfigureDialog') public static readonly NAME: string

  public override state: State = {
    availableData: {
      resultKeys: {},
      commands: {},
    },
    mappedColumns: [],
    dataLoaded: false,
  }
  
  public override componentDidMount () {
    const { currentProject } = this.props

    ApiClient
      .get(`${'Network.URI(deprecated)'}/project/matrix_columns/${currentProject.id}`)
      .then(({ resultKeys, commands, order }) => {
        const { matrixColumns } = currentProject
        const existingMatrixColumns = order.filter((columnKeyRaw: any) => {
          const [ columnKey ] = columnKeyRaw.split('_derived_')

          return Boolean(((matrixColumns ?? {}) as any)[columnKey])
        })

        this.setState({
          availableData: {
            resultKeys,
            commands,
          },
          mappedColumns: existingMatrixColumns,
          dataLoaded: true,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })

    hotkeys('Escape', this.handleClose)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleLoadColumns = (columnOrder: any) => {
    this.setState({
      mappedColumns: columnOrder,
    })
  }

  private readonly handleClose = () => {
    const { mappedColumns, availableData: { resultKeys, commands }, dataLoaded } = this.state
    const { currentProject, closeDialog, setCurrentProject } = this.props

    if (!dataLoaded) {
      closeDialog(ConfigureDialog.NAME)

      return
    }

    const columns = mappedColumns.reduce((cols, mappedColumn) => {
      const [ column ] = mappedColumn.split('_derived_')

      return {
        ...cols,
        [column]: resultKeys[column] || commands[column],
      }
    }, {})

    ApiClient
      .post(
        `${'Network.URI(deprecated)'}/project/matrix_columns/${currentProject.id}`,
        { data: { columns, order: mappedColumns } },
      )
      .then(() => {
        const project = cloneDeep(currentProject)

        project.matrixColumns = cloneDeep(columns)
        project.matrixColumnOrder = mappedColumns

        setCurrentProject(project)

        closeDialog(ConfigureDialog.NAME)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  private readonly handleDropCard = (event: any) => {
    const { payload, addedIndex, removedIndex } = event
    const { id, derivedIndex } = payload
    const { mappedColumns } = this.state
    const sortedMappedColumns = [ ...mappedColumns ]

    let key = id

    if (derivedIndex !== undefined) {
      key += `_derived_${derivedIndex}`
    }

    if (removedIndex !== null) {
      sortedMappedColumns.splice(removedIndex, 1)
    }

    if (addedIndex !== null) {
      sortedMappedColumns.splice(addedIndex, 0, key)
    }

    if (removedIndex === null && mappedColumns.includes(key)) {
      return
    }

    this.setState({
      mappedColumns: sortedMappedColumns,
    })
  }

  private readonly handleDelete = (event: any) => {
    const { mappedColumns } = this.state
    const { id } = event.target

    let newMappedColumns: string[] = []

    if (id !== 'all') {
      newMappedColumns = [ ...mappedColumns ]

      newMappedColumns.splice(mappedColumns.indexOf(id), 1)
    }

    this.setState({
      mappedColumns: newMappedColumns,
    })
  }

  private readonly handleDerive = (event: any) => {
    const { mappedColumns } = this.state
    const { id } = event.target
    const newMappedColumns = [ ...mappedColumns ]
    const index = mappedColumns.findIndex(column => column === id)

    newMappedColumns.splice(index, 1, `${id}_derived_0`)

    this.setState({ mappedColumns: newMappedColumns })
  }

  private readonly handleChangeDerivedIndex = (key: string, event: any) => {
    const { mappedColumns } = this.state
    const [ id ] = key.split('_derived_')
    const { value } = event.target

    const newMappedColumns = [ ...mappedColumns ]
    const index = mappedColumns.findIndex(column => column === key)

    newMappedColumns.splice(index, 1, `${id}_derived_${value}`)

    this.setState({ mappedColumns: newMappedColumns })
  }

  private readonly handleSortValues = (columnA: any, columnB: any) => {
    const { name: nameA } = columnA
    const { name: nameB } = columnB

    if (nameA < nameB) {
      return -1
    }

    if (nameA > nameB) {
      return 1
    }

    return 0
  }

  private readonly handleSortEntries = (category: string, columnKeyA: any, columnKeyB: any) => {
    const { availableData } = this.state
    const columnA = ((availableData[category] ?? {}) as any)[columnKeyA]
    const columnB = ((availableData[category] ?? {}) as any)[columnKeyB]

    return this.handleSortValues(columnA, columnB)
  }

  private readonly getData = (key: string) => {
    const { availableData } = this.state
    const [ column, derivedIndex ] = key.split('_derived_')

    return {
      ...(availableData.resultKeys[column] ?? availableData.commands[column] ?? {}),
      derivedIndex: derivedIndex ? Number(derivedIndex) : undefined,
    }
  }
  
  public override render () {
    const { availableData, mappedColumns } = this.state
    const { darkTheme, t } = this.props

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <div>
          <DialogBackground />
          <Dialog>
            <div>
              <Header title={t(`${T}.title`)}>
                <I className='pe-7s-menu' $left />
                <HeaderTitle>{t(`${T}.header`)}</HeaderTitle>
                <I className='pe-7s-close' onClick={this.handleClose} title={t(`${T}.close`)} />
              </Header>
              <Content>
                <DragNDrop>
                  <Title title={t(`${T}.availableColumns.title`)}>
                    {t(`${T}.availableColumns.header`)}
                  </Title>
                  <List>
                    {
                      Object.keys(availableData).map((category, index) => (
                        <div key={index}>
                          <ListTitle>{t(`${T}.group.${category}`)}</ListTitle>
                          <Container
                            groupName='1'
                            behaviour='copy'
                            getChildPayload={
                              (i: number) =>
                                Object
                                  .values(availableData[category])
                                  .sort(this.handleSortValues)[i]
                            }
                            dragHandleSelector='.list_item_drag_handle'
                            render={
                              () => (
                                <>
                                  {
                                    Object
                                      .keys(availableData[category])
                                      .sort(this.handleSortEntries.bind(this, category))
                                      .map((columnKey, index) => {
                                        const { name } = availableData[category][columnKey]

                                        return (
                                          <Draggable
                                            key={`${columnKey}_${index}`}
                                            // id={columnKey} //TODO: is it needed?
                                            className='list_item_drag_handle'
                                            render={
                                              () => (
                                                <ListItem $editMode={false}>
                                                  <InnerText>
                                                    {name}
                                                  </InnerText>
                                                </ListItem>
                                              )
                                            }
                                          />
                                        )
                                      })
                                  }
                                </>
                              )
                            }
                          />
                        </div>
                      ))
                    }
                  </List>
                </DragNDrop>
                <DragNDrop $float='right'>
                  <Title style={{ marginLeft: '-20px' }} title={t(`${T}.assignedColumns.title`)}>
                    {t(`${T}.assignedColumns.header`)}
                    <Actions onUpload={this.handleLoadColumns} />
                  </Title>
                  <DropContainer>
                    <List $dropContainer>
                      <Container
                        groupName='1'
                        getChildPayload={(i: number) => this.getData(mappedColumns[i])}
                        onDrop={this.handleDropCard}
                        style={{ height: '95%' }}
                        lockAxis='y'
                        render={
                          () => (
                            <>
                              {
                                mappedColumns.map((key, index) => {
                                  const { name, xValues, derivedIndex, length } = this.getData(key)
                                  const isDerived = derivedIndex !== undefined

                                  if (!name) {
                                    return null
                                  }

                                  return (
                                    <Draggable
                                      key={index}
                                      render={
                                        () => (
                                          <>
                                            <ListItem>
                                              <InnerText $derived={isDerived}>
                                                {name}
                                              </InnerText>
                                              {
                                                isDerived &&
                                          (
                                            <DeriveContainer>
                                              <Input
                                                name='derivedIndex'
                                                type='select'
                                                title={t(`${T}.derivedIndex.title`)}
                                                value={derivedIndex}
                                                selectors={
                                                  xValues && xValues.length > 0
                                                    ? xValues.map((xValue: any, index: number) => ({
                                                      key: String(index),
                                                      value: `x = ${xValue.toFixed(2)}`,
                                                    }))
                                                    : Array(length).fill(0).map((_: any, i: number) => ({
                                                      key: String(i),
                                                      value: `index = ${i}`,
                                                    }))
                                                }
                                                onChange={this.handleChangeDerivedIndex.bind(this, key)}
                                                noMarginTop
                                              />
                                            </DeriveContainer>
                                          )
                                              }
                                              {
                                                !isDerived && ((xValues && xValues.length > 1) || length) && (
                                                  <DeriveIcon
                                                    className='pe-7s-settings'
                                                    id={key}
                                                    title={t(`${T}.derive`)}
                                                    onClick={this.handleDerive}
                                                    $size='16px'
                                                  />
                                                )
                                              }
                                              <I
                                                className='pe-7s-close'
                                                id={key}
                                                title={t(`${T}.delete`)}
                                                onClick={this.handleDelete}
                                                $size='25px'
                                              />
                                            </ListItem>
                                          </>
                                        )
                                      }
                                    />
                                  )
                                })
                              }
                            </>
                          )
                        }
                      />
                    </List>
                  </DropContainer>
                </DragNDrop>
              </Content>
            </div>
          </Dialog>
        </div>
      </ThemeProvider>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(ConfigureDialog)

export default hoistStatics(composedComponent, ConfigureDialog)
