import { request } from '@/api'

const PATH = 'projects'

export function updateProject (projectId: string, data: Partial<Project>): Promise<void> {
  const errorText = 'Failed to update project' // TODO: translate message

  return request('patch', `${PATH}/${projectId}`, errorText, { data }) as any
}

export function getProject (projectId: string): Promise<Project> {
  const errorText = 'Failed to get project' // TODO: translate message

  return request('get', `${PATH}/${projectId}`, errorText) as any
}

export function updateProjectAdditionalData (projectId: string, data: any) {
  const errorText = 'Failed to update project additional data' // TODO: translate message

  return request('patch', `${PATH}/${projectId}/additional-data`, errorText, { data }) as any
}
