import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { PlaybackWindow } from '@/react/casterDataServer/PlaybackWindow'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import { ProjectMatrixDialog } from '@/react/dialogs/ProjectMatrixDialog'
import FeatureFlags from '@/react/FeatureFlags'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'

// import AppSwitch from './components/AppSwitch'

const Wrapper = styled.div<{ $showProjectName?: string }>`${({ $showProjectName }) =>
  css`
  white-space: nowrap;
  color: #dddddd;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 30px;
  padding-left: 10px;
  height: 32px;
  ${$showProjectName && css`border-left: 1px solid #373f49;`}
`}`

const Project = styled.div`${() =>
  css`
  display: inline-block;
  vertical-align: top;
`}`

const I = styled.i<{ disabled?: boolean }>`${({ disabled }) =>
  css`
  font-size: 22px;
  top: 4px;
  margin-right: 6px;
  position: relative;
  font-weight: 600;
  color: #dddddd;
  cursor: pointer;

  &:hover {
    color: ${disabled ? '#dddddd' : '#a2a6a9'};
  }
`}`

const IconContainer = styled.div`${() =>
  css`
  display: inline-block;
  margin: 0 6px;
`}`

const T = 'projectActions'

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  openAppDialogs: state.application.main.openAppDialogs,
  authenticationData: state.application.main.authenticationData,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isConsistencyCheck: state.util.isConsistencyCheck,
  currentProjectCasesMetadata: state.application.main.currentProjectCasesMetadata,
}), {
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
  switchProject: ApplicationActions.switchProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

class ProjectActions extends Component<Props> {
  private readonly handleProjectSettingsClick = () => {
    const { openDialog } = this.props

    this.handleCloseMatrix()

    openDialog(ProjectDataDialog.NAME)
  }

  private readonly handleCloseMatrix = () => {
    const { openAppDialogs, closeDialog } = this.props

    if (openAppDialogs.includes(ProjectMatrixDialog.NAME)) {
      closeDialog(ProjectMatrixDialog.NAME)

      return true
    }

    return false
  }

  private readonly handleProjectMatrixClick = () => {
    const { openDialog } = this.props

    if (!this.handleCloseMatrix()) {
      openDialog(ProjectMatrixDialog.NAME)
    }
  }
  
  public override render () {
    const {
      authenticationData,
      currentProject,
      currentSimulationCase,
      featureFlags,
      openAppDialogs,
      t,
      isConsistencyCheck,
      currentProjectCasesMetadata,
    } = this.props
    const showProjectName = authenticationData && currentProject && currentProject.id
    const allowedDialogs = [ PlaybackWindow.NAME ]
    const filteredOpenAppDialogs = openAppDialogs.filter(dialog => !allowedDialogs.includes(dialog))
    const disabled = Boolean(filteredOpenAppDialogs.length)

    let caseInfo = ''

    if (currentProject && currentProject.id) {
      // FIXME: project has no simulationCases
      const caseNumber = currentProjectCasesMetadata
        .findIndex(metadata => metadata.id === currentSimulationCase.id) + 1

      caseInfo = `C${caseNumber}(R) - ${currentSimulationCase.name}`
    }

    const slimVersion = FeatureFlags.usesSlimVersion(featureFlags)

    return (
      <Wrapper $showProjectName={showProjectName}>
        {
          authenticationData && currentProject && currentProject.id && (
            <Project>
              {currentProject.name ?? ''}&nbsp;/&nbsp;
              {caseInfo ?? ''}&nbsp;&nbsp;
              {
                FeatureFlags.canViewRealDataBadges(featureFlags) && (
                  currentSimulationCase.blueprintId
                    ? <span className='badge real-data'>{t('label.realData')}</span>
                    : <span className='badge blueprint'>{t('label.blueprint')}</span>
                )
              }
              {
                !slimVersion &&
                !isConsistencyCheck &&
                (
                  <IconContainer>
                    <I
                      title={t(`${T}.project.data`)}
                      className='pe-7s-folder'
                      onClick={!disabled ? this.handleProjectSettingsClick : () => null}
                      disabled={disabled}
                    />
                    <I
                      title={t(`${T}.project.matrix`)}
                      className='pe-7s-menu'
                      onClick={!disabled ? this.handleProjectMatrixClick : () => null}
                      disabled={disabled}
                    />
                  </IconContainer>
                )
              }
            </Project>
          )
        }
        {/* TODO: we don't need this atm, and in the future all dashboards will be used in the SimpleDashboard */}
        {/* {!slimVersion && !isConsistencyCheck && FeatureFlags.canViewCaster(featureFlags) && <AppSwitch />} */}
      </Wrapper>
    )
  }
}

export default compose<any>(withTranslation('titlebar'), connector)(ProjectActions)
