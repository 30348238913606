import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import { AuthDataStore } from '@/logic/auth-data-store'
import { Info } from '@/logic/Info'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import { DefaultState } from '@/types/state'
import { parseJWT } from '@/Util/authUtil'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from './BaseDialog'
import { UsageTimeExceededDialog } from './UsageTimeExceededDialog'
import Input from '../specific/Input'
import Button from '../specific/SubmitButton'
import { Error, Form, Text } from '../visualization/dashboard/Dialogs/DialogStyles'

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
  openAppDialogs: state.application.main.openAppDialogs,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setAuthenticationData: ApplicationActions.setAuthenticationData,
  setAppState: ApplicationActions.setAppState,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  hideCloseButton?: boolean
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: boolean | string | null | undefined
  userName: string
  password: string
  authError: string | null | undefined
  authErrorParams: string | null | undefined
  loading: boolean
}

export class LoginDialog extends Component<Props, State> {
  @Identifiable('LoginDialog') public static readonly NAME: string

  public override state: State = {
    userName: '',
    password: '',
    authError: null,
    authErrorParams: null,
    loading: false,
    isLocalLogin: false,
    hasOfflineCredentials: false,
  }

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.handleTryLoginOnLoad()
      })
    })
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(LoginDialog.NAME)
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  private readonly handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit(false)
    }
  }

  private readonly handleTryLoginOnLoad = () => {
    const isMySMSLogin = this.handleMySMSLogin()

    if (isMySMSLogin) {
      return
    }

    this.setState({ loading: true })

    const tokenData = AuthDataStore.get()

    if (!tokenData) {
      this.setState({ loading: false })

      return
    }

    const jwtData = parseJWT(tokenData.accessToken)

    if (jwtData.exp > Date.now() / 1000 + 5) {
      this
        .handleAPIAuthResult(tokenData)
        .then(() => {
          // do nothing
        })
        .catch(() => {
          // do nothing
        })

      return
    }

    AuthDataStore.clear()

    Info.removeRecentlyUsedInfo()

    this.setState({ loading: false })
  }

  private readonly handleAuthSuccess = (data: AuthData) => {
    const {
      setAuthenticationData,
      setAppState,
    } = this.props

    setAuthenticationData(data)

    if (!data?.featureFlags?.Caster__View) {
      setAppState(AppState.ResultDashboard)
    }

    this.setState({ loading: false })

    IpcManager.internal.send('loadDefaultCase', data?.featureFlags)
  }

  private readonly handleAPIAuthResult = async (data: AuthResult) => {
    // TODO: maybe we can get rid of featureFlags in the AuthResult sice we get permission-data from the API later on
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { featureFlags: _, ...tokenData } = data

    // TODO: maybe save cookie (token) with .domain!?
    AuthDataStore.set(tokenData)

    // TODO: handle token expiration and refresh!

    if (window.location.search.includes('session_state=')) {
      // using requestAnimationFrame to make sure the state is updated before the redirect
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const { href } = window.location

          window.history.replaceState({}, document.title, href.substring(0, href.indexOf('?')))
        })
      })
    }

    // TODO: handle error
    const permissionData = await ApiClient.get(`${useConfig().apiBaseURL}/users/permission-data`) as PermissionData

    const featureFlagsObj = permissionData.featureFlags.reduce((acc, flag) => {
      acc[flag] = true

      return acc
    }, {} as Record<string, boolean>)

    const jwtData = parseJWT(data.accessToken)

    this.handleAuthResult({
      id: jwtData.sub,
      name: jwtData.username,
      featureFlags: featureFlagsObj ?? {},
      time: jwtData.iat,
      groups: permissionData.groups,
      casterLoadTime: null,
      userType: 'jwt',
      executablesJSON: permissionData.executablesJSON,
      casterDashboardConfigs: permissionData.casterDashboardConfigJSONs,
      defaultCasterDashboardConfig: permissionData.defaultCasterDashboardConfigJSON,
      filterControlsJSON: permissionData.filterControlsJSON,
    })
  }

  private readonly handleAuthResult = (data: AuthData) => {
    if (!data || data.error) {
      const error = (data ? data.error : 'NoData') ?? 'NoError'

      this.setState({ authError: error, loading: false })

      return
    }

    this.handleAuthSuccess(data)
  }

  private readonly handleSubmit = (isLocalLogin: boolean) => {
    this.setState({ isLocalLogin, loading: true })

    if (isLocalLogin) {
      if (window.usageTimeInfo && window.usageTimeInfo.usageTimeExceeded) {
        const { openDialog, closeDialog, openAppDialogs } = this.props

        if (!openAppDialogs.includes(UsageTimeExceededDialog.NAME)) {
          closeDialog(LoginDialog.NAME)
          openDialog(UsageTimeExceededDialog.NAME)
        }

        return
      }

      // TODO: is this correct? Is the local "user" checked before this happens?
      this.handleAuthResult({
        id: 'localUserId',
        name: 'localUser',
        featureFlags: IpcManager.featureFlags ?? {},
        time: Date.now(),
        groups: [],
        casterLoadTime: null,
        userType: 'local',
      })

      return
    }

    const { t } = this.props
    const { userName, password } = this.state

    if (!userName || !password) {
      const authErrorParams = !userName ? 'Username' : 'Password'

      this.setState({ authError: 'empty', authErrorParams, loading: false })

      return
    }

    ApiClient
      .post(`${useConfig().apiBaseURL}/auth/login`, { data: { username: userName, password } })
      .then(this.handleAPIAuthResult)
      .then(() => {
        // do nothing
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.error(error)

        if (error.body?.statusCode === 401) {
          this.setState({ authError: 'invalid', loading: false })

          return
        }

        enqueueSnackbar(t('loginDialog.loginError'), { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  private readonly handleMySMSLogin = () => {
    const { t } = this.props
    const params = new URLSearchParams(window.location.search)
    const sessionState = params.get('session_state')
    const code = params.get('code')

    if (!sessionState || !code) {
      if (document.referrer.includes(useConfig().mySMSReferrer)) {
        location.href = this.getMySMSLoginURL()
      }

      return false
    }

    this.setState({ loading: true })

    const apiURL = `${useConfig().apiBaseURL}/auth/authenticate/${code}/${sessionState}/${this.getRedirectURI()}`

    ApiClient
      .get(apiURL)
      .then(this.handleAPIAuthResult)
      .then(() => {
        // do nothing
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.error(error)

        this.setState({ loading: false })

        enqueueSnackbar(t('loginDialog.loginWithMySMSError'), { variant: 'error' })
      })

    return true
  }

  private readonly handleOpenExternalMySMS = () => {
    IpcManager.electron.send('openExternal', this.getMySMSLoginURL())
  }

  private readonly setFocus = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  }

  private readonly getRedirectURI = () => {
    if (!window.isElectron) {
      return encodeURIComponent(useConfig().mySMSRedirectURL)
    }

    // TODO: find a way to get deep links working with electron on linux, for testing purposes
    // currently the following is working: Electron Version on Windows, Online Version (in Browser) in DEV and PROD
    return encodeURIComponent('caster-app://open')
  }

  private readonly getMySMSLoginURL = () => {
    const { keycloakURL, keycloakClientID } = useConfig()

    const url = `${keycloakURL}?client_id=${keycloakClientID}&response_type=code`

    return `${url}&redirect_uri=${this.getRedirectURI()}`
  }

  public override render () {
    const { userName, password, authError, authErrorParams, loading } = this.state
    const { hideCloseButton, t } = this.props

    return (
      <BaseDialog
        title={t('loginDialog.title')}
        icon='pe-7s-user'
        header={t('loginDialog.header')}
        onClose={this.handleClose}
        hideCloseButton={hideCloseButton}
        small
      >
        <Form>
          <Text>
            {t('loginDialog.message')}
          </Text>
          <br />
          {
            authError && (
              <Error>
                {t(`error.${authError}`, { value: authErrorParams })}
                <br />
              </Error>
            )
          }
          <Input
            label={t('loginDialog.userName')}
            title={t('loginDialog.userName')}
            name='userName'
            type='text'
            value={userName}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            inputRef={this.setFocus}
          />
          <Input
            label={t('loginDialog.password')}
            title={t('loginDialog.password')}
            name='password'
            type='password'
            value={password}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button onClick={this.handleSubmit.bind(this, false)} $isLoading={loading}>
            <div className='cut'>
              <i className='pe-7s-angle-right-circle' />
            </div>
            <span>{t('loginDialog.login')}</span>
          </Button>
          {
            this.state.hasOfflineCredentials &&
            (
              <Button onClick={this.handleSubmit.bind(this, true)} $isLoading={loading}>
                <div className='cut'>
                  <i className='pe-7s-angle-right-circle' />
                </div>
                <span>{t('loginDialog.loginLocally')}</span>
              </Button>
            )
          }
          {
            !window.isElectron &&
            (
              <a href={this.getMySMSLoginURL()} onClick={() => this.setState({ loading: true })}>
                <Button $isLoading={loading}>
                  <div className='cut'>
                    <i className='pe-7s-angle-right-circle' />
                  </div>
                  <span>{t('loginDialog.loginWithMySMS')}</span>
                </Button>
              </a>
            )
          }
          {
            window.isElectron &&
            (
              <Button onClick={this.handleOpenExternalMySMS} $isLoading={loading}>
                <div className='cut'>
                  <i className='pe-7s-angle-right-circle' />
                </div>
                <span>{t('loginDialog.loginWithMySMS')}</span>
              </Button>
            )
          }
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('login'), connector)(LoginDialog)

export default hoistStatics(composedComponent, LoginDialog)
