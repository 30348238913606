import { type IconDefinition } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, FormControl, MenuItem, Select } from '@mui/material'
import { withTheme } from '@mui/styles'
import { Component, isValidElement } from 'react'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import tinycolor from 'tinycolor2'

import Delete from '@/react/specific/Delete'

const MdFormControl = styled(FormControl)`${({ disabled }) =>
  css`
  width: 100%;
  ${disabled && css`cursor: not-allowed;`}

  > div {
    ${disabled && css`pointer-events: none;`}
  }
`}`

const Badge = styled.div`${({ theme }) =>
  css`
  margin: 0 5px;
  border: 1px solid ${theme.colors.swatch13};
  border-radius: 5px;
  font-size: 14px!important;
  padding: 0 5px;
  color: ${theme.colors.swatch13};
  text-transform: capitalize;
`}`

const ActionsWrapper = styled.div`
  display: none;
  align-items: center;
`

type OptionProps = { $notRemovable?: boolean, $spaceBetween?: boolean }

const Option = styled(MenuItem)<OptionProps>`${({ theme, $notRemovable, $spaceBetween }) =>
  css`
  display: flex;
  ${$spaceBetween && 'justify-content: space-between !important;'}

  span {
    ${!$spaceBetween && 'flex: 15;'};
  }

  div.default-actions {
    display: flex;
    flex: 1;
    ${!$notRemovable && 'display: inline-flex !important;'};
    font-size: 24px;
    margin: -10px 0;

    > * {
      margin-top: 4px;
    }
  }

  &[data-selected-helper="true"] ${Badge} {
    color: ${theme.colors.swatch15};
  }

  &[role='option'] {
    ${ActionsWrapper} {
      display: flex;
    }
  }
`}`

const Actions = styled.div<{ $nextToCheckbox: boolean }>`${({ theme, $nextToCheckbox }) =>
  css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  padding: ${$nextToCheckbox ? '0 15px' : '0 0 0 15px'};
  margin: -3px 0;

  .action {
    padding-top: 2px;
    font-size: 17px;

    &:hover {
      color: ${theme.primary.light}
    }
  }
`}`

const StyledCheckbox = styled(Checkbox)`
  height: 10px;
  width: 10px;
  padding-right: 5px;
  > span:hover {
    color: rgb(33 218 184 / 80%) !important;
    background-color: transparent !important;
  }
`

const OptGroup = styled(Option as any)`${({ theme, disabled }) =>
  css`
  outline: none;
  font-family: 'Roboto', sans-serif;
  border-top: 1px solid ${theme.primary.font}!important;
  padding-left: 16px!important;
  font-weight: 600!important;
  ${disabled && css`pointer-events: none !important;` as any}
  
  span {
    padding-left: 5px;
  }
  
  & ~ ${Option} {
    padding-left: 30px;
  }
`}`

const Default = styled.div<{ $active: boolean }>`${({ theme, $active }) =>
  css`
  margin: 0 5px;
  ${$active ? 'color: #e0b60f;' : ''}

  &:hover {
    color: ${$active ? tinycolor('#e0b60f').darken(10).toString() : theme.primary.light}
  }
`}`

const Download = styled.div`${({ theme }) =>
  css`
  margin: 0 5px;
  
  &:hover {
    color: ${theme.primary.light}
  }
`}`

const Edit = styled.div`${({ theme }) =>
  css`
  margin: 0 5px;
  
  &:hover {
    color: ${theme.primary.light}
  }
`}`

export type Action = {
  key: string
  title: string
  icon: IconDefinition
  condition?: (id: string) => boolean
}

type Props = {
  name: string
  value?: string[] | boolean | number | string
  selectors: Array<any>
  onChange?: (e: any) => void
  onEdit?: (e: any) => void
  onDelete?: (name: string, key: string) => void
  onDownload?: (key: string) => void
  onSetDefault?: (key: string) => void
  theme?: any
  classes?: any
  noDeleteCurrent?: boolean
  noDeleteGiven?: Array<string>
  half?: boolean
  oneThird?: boolean
  twoThirds?: boolean
  disabled?: boolean
  error?: string
  title?: string // TODO: is this used?
  placeholder?: string // TODO: is this used?
  multiple?: boolean
  checkbox?: boolean
  onCheckboxClick?: (e: any, comparisonCasterName: string) => void
  renderValue?: any
  selectedIds?: string[]
  optionStyles?: any
  spaceBetween?: boolean
  MenuProps?: any
  actions?: Action[]
  onActionClick?: (key: Action['key'], id: string) => void
}

class SelectPlot extends Component<Props> {
  private readonly handleEdit = (key: string) => {
    const { onEdit } = this.props

    if (onEdit) {
      onEdit(key)
    }
  }

  private readonly handleDelete = (key: string) => {
    const { onChange, name, onDelete } = this.props

    if (onDelete) {
      onDelete(name, key)
    }

    if (onChange) {
      onChange({ target: { name, value: '' } })
    }
  }

  private readonly handleDownload = (key: string) => {
    const { onDownload } = this.props

    if (onDownload) {
      onDownload(key)
    }
  }

  private readonly handleSetDefault = (key: string) => {
    const { onSetDefault } = this.props

    if (onSetDefault) {
      onSetDefault(key)
    }
  }

  private readonly getIsChecked = (ids: any[], option: any) =>
    Boolean(ids.includes(option.key ?? option) || option.checked) 

  private readonly getTooltipText = (option: Record<string, string> | boolean | string): string => {
    if (option instanceof Object) {
      const title = option.title ?? option.value

      if (isValidElement(title)) {
        return ''
      }

      return String(title)
    }

    if (isValidElement(option)) {
      return ''
    }

    return String(option)
  }

  private readonly getLabel = (option: Record<string, string> | boolean | string) => {
    if (option instanceof Object) {
      if (isValidElement(option.value)) {
        return option.value
      }

      return String(option.value)
    }

    if (isValidElement(option)) {
      return option
    }

    return String(option)
  }

  public override render () {
    const {
      value,
      selectors,
      name,
      error,
      noDeleteCurrent,
      noDeleteGiven,
      checkbox,
      selectedIds,
      optionStyles,
      spaceBetween,
      actions,
      onActionClick,
      onChange,
      onDelete,
      onEdit,
      onDownload,
      onSetDefault,
      onCheckboxClick,
      ...restProps
    } = this.props

    let groups = selectors.map(selector => selector.group)

    groups = groups.filter((group, index) => groups.indexOf(group) === index)

    return (
      <MdFormControl error={Boolean(error)} disabled={restProps.disabled}>
        <Select
          onChange={onChange}
          name={name}
          displayEmpty
          error={Boolean(error)}
          value={value}
          sx={{ fieldset: { border: 'none' } }}
          {...restProps}
        >
          {
            groups && groups.length && groups[0]
              ? groups.map(group => {
                if (group !== 'disabled') {
                  const Groupname = (
                    <OptGroup key={group} disabled>
                      {group}
                    </OptGroup>
                  )

                  // TODO: translate badge
                  const Options = selectors.filter(selector => selector.group === group).map((option, index) => (
                    <Option
                      key={index}
                      value={option.key || option}
                      title={this.getTooltipText(option)}
                      disabled={option.disabled ?? false}
                      data-selected-helper={value === (option.key || option)}
                    >
                      <span>{this.getLabel(option)}</span>
                      {
                        option.badge && (
                          <div style={{ display: 'none' }}>
                            <Badge>{option.badge}</Badge>
                          </div>
                        )
                      }
                    </Option>
                  ))

                  return [ Groupname, ...Options ]
                }
                else {
                  return selectors.filter(selector => selector.group === group).map((option) => (
                    <Option
                      key={option.key || option}
                      value={option.key || option}
                      title={this.getTooltipText(option)}
                      disabled
                      data-selected-helper={value === (option.key || option)}
                    >
                      <span>{this.getLabel(option)}</span>
                      {
                        option.badge && (
                          <div style={{ display: 'none' }}>
                            <Badge>{option.badge}</Badge>
                          </div>
                        )
                      }
                    </Option>
                  ))
                }
              })
              : selectors.map((option) => (
                <Option
                  key={option.key || option}
                  value={option.key || option}
                  disabled={option.disabled}
                  $notRemovable={option.notRemovable}
                  style={optionStyles ?? {}}
                  $spaceBetween={spaceBetween}
                  title={option.title ?? ''}
                >
                  <span>{this.getLabel(option)}</span>
                  {
                    !option.hideActions && (
                      <ActionsWrapper onClick={event => event.stopPropagation()}>
                        <div className='default-actions'>
                          {
                            onSetDefault && (
                              <Default
                                onClick={() => this.handleSetDefault(option.key)}
                                title={option.isDefault ? 'Remove default' : 'Set as default'}
                                $active={option.isDefault}
                              >
                                <i className='pe-7s-pin' />
                              </Default>
                            )
                          }
                          {
                            !option.notRemovable && onDelete && (
                              <Delete
                                disabled={
                                  (Boolean(noDeleteCurrent) && value === (option.key ?? option)) ||
                                  (noDeleteGiven && noDeleteGiven.includes(option.key ?? option))
                                }
                                onClick={() => this.handleDelete(option.key)}
                              />
                            )
                          }
                          {
                            onDownload && (
                              <Download onClick={() => this.handleDownload(option.key)}>
                                <i className='pe-7s-cloud-download' />
                              </Download>
                            )
                          }
                          {
                            !option.notEditable && onEdit &&
                            (
                              <Edit onClick={() => this.handleEdit(option.key)}>
                                <i className='pe-7s-pen' />
                              </Edit>
                            )
                          }
                        </div>
                        {
                          actions &&
                          (
                            actions.filter(action => !action.condition || action.condition(option.key)).length > 0 && (
                              <Actions
                                onClick={event => event.stopPropagation()}
                                $nextToCheckbox={Boolean(checkbox && Array.isArray(value))}
                              >
                                {
                                  actions.map((action) => (
                                    (!action.condition || action.condition(option.key)) && (
                                      <div
                                        className='action'
                                        key={action.key}
                                        onClick={() => onActionClick && onActionClick(action.key, option.key)}
                                        title={action.title}
                                      >
                                        <FontAwesomeIcon icon={action.icon} />
                                      </div>
                                    )
                                  ))
                                }
                              </Actions>
                            )
                          )
                        }
                        {
                          checkbox && Array.isArray(value) && (
                            <StyledCheckbox
                              disabled={option.disabled}
                              checked={this.getIsChecked(selectedIds ?? [], option)}
                              onChange={(e) => onCheckboxClick?.(e.target.checked, option.key)}
                              onClick={event => event.stopPropagation()}
                            />
                          )
                        }
                      </ActionsWrapper>
                    )
                  }
                </Option>
              ))
          }
        </Select>
      </MdFormControl>
    )
  }
}

export default compose<any>(withTheme)(SelectPlot)
