import Util from '@/logic/Util'
import type { MatchHashElement, NodeElement } from '@/react/TreeViewNodes'
import type { ElementMaps, ElementName, SensorPointParent, TagName } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import CalculateHelperFunctions from '../context/form/functions/CalculateHelperFunctions'

type Context = {
  elementMaps: ElementMaps
  elementsArray: NodeElement[]
  shownPaths: string[]
  matchHash: Record<string, MatchHashElement>
  showAll: boolean
}

type ParentInfo = {
  path: string
  mountLogId: string
  type: TagName
  numericId: number
}

export class TreeViewBuilder {
  public static buildTree (
    elementMaps: ElementMaps,
    elementsArray: NodeElement[],
    shownPaths: string[],
    matchHash: Record<string, MatchHashElement>,
    showAll = false,
  ) {
    const context: Context = { elementMaps, elementsArray, shownPaths, matchHash, showAll }

    TreeViewBuilder.buildSegmentGroup(context, 0)
  }

  // Private methods

  private static buildType (
    parentType: TagName,
    parentInfo: ParentInfo,
    type: TagName,
    context: Context,
    depth: number,
  ): void {
    switch (type) {
      case 'Segment':
        TreeViewBuilder.buildSegment(parentInfo, context, depth)
        break
      case 'Nozzle':
        TreeViewBuilder.buildNozzle(parentInfo, context, depth)
        break
      case 'Roller':
        TreeViewBuilder.buildRoller(parentInfo, context, depth)
        break
      case 'RollerBody':
        TreeViewBuilder.buildRollerBody(parentInfo, context, depth)
        break
      case 'RollerBearing':
        TreeViewBuilder.buildRollerBearing(parentInfo, context, depth)
        break
      case 'SensorPoint':
        TreeViewBuilder.buildSensorPoint(parentType as SensorPointParent, parentInfo, context, depth)
        break
    }
  }

  private static getMountLogMapByType<T extends BaseMountLog> (type: ElementName, context: Context) {
    return context.elementMaps[`${type}MountLog`] as unknown as Record<string, T>
  }

  private static buildChildren (
    type: TagName,
    parentInfo: ParentInfo,
    context: Context,
    depth: number,
  ): void {
    const childTypes = Util.getChildType(type)

    for (const childType of childTypes) {
      TreeViewBuilder.buildType(type, parentInfo, childType, context, depth + 1)
    }
  }

  private static hasChildren<T extends BaseMountLog> (type: TagName, mountLog: T): boolean {
    const childTypes = Util.getChildType(type)

    for (const childType of childTypes) {
      const childIdsKey = `${childType[0]?.toLocaleLowerCase() + childType.substring(1)}MountLogs` as keyof T

      if ((mountLog[childIdsKey] as string[])?.length) {
        return true
      }
    }

    return false
  }

  private static getSortedMountLogs<T extends BaseMountLog> (
    type: ElementName,
    parentInfo: ParentInfo | null,
    context: Context,
  ): T[] {
    const mountLogs = TreeViewBuilder.getMountLogMapByType<T>(type, context)

    let filteredMountLogs = null

    if (parentInfo) {
      // since parentInfo is for parents and Data-/SensorPoints cannot be parents we can safely cast here
      const parentMountLogs = TreeViewBuilder.getMountLogMapByType(parentInfo.type as ElementName, context)
      const parentMountLog = parentMountLogs[parentInfo.mountLogId]
      const tagName = ElementMapsUtil.getTagName(type)
      const childIdsKey = `${tagName[0]?.toLocaleLowerCase() + tagName.substring(1)}MountLogs`

      filteredMountLogs = (parentMountLog[childIdsKey as keyof BaseMountLog] as any as string[])
        ?.map(id => mountLogs[id]) ?? []
    }
    else {
      filteredMountLogs = Object.values(mountLogs)
    }

    return CalculateHelperFunctions.sortedMountLogsBySlot(
      type,
      filteredMountLogs,
      context.elementMaps,
      CalculateHelperFunctions.byPasslineCoordAsc,
    )
  }

  private static getElement<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    type: ElementName,
    mountLog: MountLog,
    context: Context,
  ): FullCasterElement<Slot, MountLog> | null {
    const numericId = Mapping.numericIdByMountLogId[mountLog.id] ?? null
    const elementSlot = ElementMapsUtil.getSlotByMountLog<Slot, MountLog>(type, mountLog, context.elementMaps)

    if (numericId === null || !elementSlot) {
      return null
    }

    return ElementMapsUtil.getFullCasterElement<Slot, MountLog>(elementSlot, mountLog, numericId)
  }

  private static prepareBuildContainer (
    type: TagName,
    parentInfo: ParentInfo,
    context: Context,
    depth: number,
  ): void {
    const elementName = ElementMapsUtil.getElementName(type, parentInfo.type)
    const sortedMountLogs = TreeViewBuilder.getSortedMountLogs(elementName, parentInfo, context)

    TreeViewBuilder.buildContainer(elementName, parentInfo, sortedMountLogs.length, context, depth)

    for (const mountLog of sortedMountLogs) {
      TreeViewBuilder.buildElement(type, mountLog, parentInfo, context, depth + 1)
    }
  }

  private static buildContainer (
    type: ElementName,
    parentInfo: ParentInfo,
    childCount: number,
    context: Context,
    depth: number,
  ) {
    if (!childCount) {
      return
    }

    const parentNameAndId = `${parentInfo.type}:${parentInfo.numericId}`
    const selectorPath = `${type}Selector-${parentNameAndId}`
    const shouldShow = depth < 2 || context.showAll

    context.matchHash[parentNameAndId].children.push(selectorPath)

    context.elementsArray.push({
      name: 'selector',
      nameAndId: selectorPath,
      element: {},
      depth,
      hasChildren: true,
      fullPath: parentInfo.path,
      amountOfChildren: childCount,
    })

    context.matchHash[selectorPath] = {
      children: [],
      parentTypeAndId: parentNameAndId,
      selected: false,
      show: shouldShow,
      showChildren: type === 'Segment' ? shouldShow : false,
    }

    if (shouldShow) {
      context.shownPaths.push(selectorPath)
    }
  }

  private static buildElement<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    type: TagName,
    mountLog: MountLog,
    parentInfo: ParentInfo | null,
    context: Context,
    depth: number,
  ): void {
    // parentInfo is only null for SegmentGroup
    const elementName = ElementMapsUtil.getElementName(type, parentInfo?.type ?? '' as TagName)
    const element = TreeViewBuilder.getElement<Slot, MountLog>(elementName, mountLog, context)

    if (element === null) {
      return
    }

    const nameAndId = `${type}:${element.id}`
    const path = parentInfo ? `${parentInfo.path}/${nameAndId}` : nameAndId
    const parentNameAndId = parentInfo ? `${parentInfo.type}:${parentInfo.numericId}` : ''
    const selectorPath = `${elementName}Selector-${parentNameAndId}`
    const hasChildren = TreeViewBuilder.hasChildren(type, mountLog)
    const shouldShow = hasChildren && (depth < 3 || context.showAll)

    context.elementsArray.push({
      name: type === 'SegmentGroup' || type === 'Segment' || type.endsWith('SensorPoint')
        ? element.name ?? nameAndId
        : nameAndId,
      nameAndId,
      element,
      depth,
      fullPath: path,
      hasChildren,
    })

    context.matchHash[selectorPath]?.children?.push(nameAndId)

    context.matchHash[nameAndId] = {
      children: [], // children will be added later
      parentTypeAndId: selectorPath,
      selected: false,
      show: shouldShow,
      showChildren: type.endsWith('SensorPoint') ? false : (hasChildren && (depth < 2 || context.showAll)),
    }

    if (shouldShow) {
      context.shownPaths.push(nameAndId)
    }

    if (!hasChildren) {
      return
    }

    const newParentInfo: ParentInfo = { path, type, numericId: element.id, mountLogId: mountLog.id }

    TreeViewBuilder.buildChildren(type, newParentInfo, context, depth)
  }

  private static buildSegmentGroup (context: Context, depth: number): void {
    const sortedSegmentGroupMountLogs = TreeViewBuilder.getSortedMountLogs<SegmentGroupMountLog>(
      'SegmentGroup',
      null,
      context,
    )

    for (const segmentGroupMountLog of sortedSegmentGroupMountLogs) {
      TreeViewBuilder.buildElement('SegmentGroup', segmentGroupMountLog, null, context, depth)
    }
  }

  private static readonly buildSegment = TreeViewBuilder.prepareBuildContainer.bind(null, 'Segment')

  private static readonly buildNozzle = TreeViewBuilder.prepareBuildContainer.bind(null, 'Nozzle')

  private static readonly buildRoller = TreeViewBuilder.prepareBuildContainer.bind(null, 'Roller')

  private static readonly buildRollerBody = TreeViewBuilder.prepareBuildContainer.bind(null, 'RollerBody')

  private static readonly buildRollerBearing = TreeViewBuilder.prepareBuildContainer.bind(null, 'RollerBearing')

  private static buildSensorPoint (
    parentType: SensorPointParent,
    parentInfo: ParentInfo,
    context: Context,
    depth: number,
  ) {
    const elementName = ElementMapsUtil.getElementName('SensorPoint', parentType)
    const sortedMountLogs = TreeViewBuilder.getSortedMountLogs(elementName, parentInfo, context)

    TreeViewBuilder.buildContainer(elementName, parentInfo, sortedMountLogs.length, context, depth)

    for (const sensorPointMountLog of sortedMountLogs) {
      TreeViewBuilder.buildElement('SensorPoint', sensorPointMountLog, parentInfo, context, depth + 1)
    }
  }
}
