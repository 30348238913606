import { faReply, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import FeatureFlags, { Permissions } from '@/react/FeatureFlags'
import Button from '@/react/specific/Button'
import type { EditElements } from '@/types/data'
import type { CasterDialogElementType } from '@/types/filter'
import type { ElementMaps } from '@/types/state'

const Action = styled.div<{ $disabled?: boolean, $viewer?: boolean }>`${({ $disabled, $viewer }) =>
  css`
  background:  ${$disabled ? '#474b4e' : '#BB1B1B'};
  color:       ${$disabled ? '#999999' : '#FFFFFF'};
  position:    relative;
  min-height:  25px;
  font-size:   1em;
  margin:      ${$viewer ? '0 5px 15px' : '10px 5px'};
  padding:     0.25em 1em;
  border:      2px solid ${$disabled ? '#474b4e' : '#BB1B1B'};
  outline:     none;
  cursor:      ${$disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  transition:  .2s;
  flex:        1;

  svg {
    position:  absolute;
    top:       50%;
    left:      50%;
    transform: translate(-50%,-50%);
  }
  &:hover {
    transition: .2s;
    border:     ${$disabled ? '2px solid #474b4e' : '2px solid #A71A1A'};
    background: ${$disabled ? '' : '#A71A1A'};
  }
`}`

const FlexDiv = styled.div`
  flex: 25;
  display: flex;
`

const ButtonsBox = styled.div`
  display:         flex;
  flex-wrap:       nowrap;
  flex-direction:  row;
  justify-content: space-between;

  > *:only-child > button {
    width: 100%;
  }
`

const copyExcludedTypes = [
  'General',
  'SegmentGroup',
  'SegmentGroupSupportPoints',
  'SupportPoint',
  'DataLine',
  'DataPoint',
]

const deleteExcludedTypes = [
  'General',
  'SegmentGroup',
  'SegmentGroupSupportPoints',
  'SupportPoint',
  'DataLine',
  'DataPoint',
]

const applyExcludedTypes = [
  'DataLine',
  'DataPoint',
  'SensorPoint',
]

type Props = {
  type: CasterDialogElementType
  hideActions: boolean
  isLocked: boolean
  loading: any
  hasEditChanges: boolean
  isComparingCasters: boolean
  invalidWidth: boolean
  isDeleted: boolean
  path: string
  paths: string[]
  t: (key: string) => string
  createValid: string
  featureFlags: Permissions
  error: Record<string, string>
  elementMaps: ElementMaps
  allPaths?: string[]
  editElements: EditElements
  handleMirrorElements: () => void
  onSave: () => void
  onCreateOrCopy: () => void
  onDelete: () => void
  onUndo: (event: any) => void
}

const FormActions = ({
  type,
  hideActions,
  isLocked,
  loading,
  hasEditChanges,
  isComparingCasters,
  invalidWidth,
  isDeleted,
  path,
  createValid,
  featureFlags,
  error,
  allPaths,
  editElements,
  t,
  handleMirrorElements,
  onSave: handleSave,
  onCreateOrCopy,
  onDelete: handleDelete,
  onUndo: handleUndo,
}: Props) => {
  const supportPointsValuesValid = () => {
    if (type !== 'SegmentGroupSupportPoints') { // FIXME: SegmentGroupSupportPoints may not be provieded correctly
      return true
    }

    let valid = true

    for (const path of allPaths ?? []) {
      if (!editElements[path]) {
        valid = false

        break
      }

      const { shimPropose: propose } = editElements[path]

      if (propose === '' || propose === undefined) {
        valid = false

        break
      }
    }

    return valid
  }

  const handleSavePermissions = () => {
    if (type !== 'SensorPoint') {
      return FeatureFlags.canEditElement(type, featureFlags)
    }

    return false
  }

  const canEditElement = FeatureFlags.canEditElement(type, featureFlags)

  return (
    <ButtonsBox>
      {
        !hideActions && !isLocked && handleSavePermissions() && !applyExcludedTypes.includes(type) && (
          <FlexDiv>
            <Button
              onClick={handleSave}
              disabled={
                isComparingCasters ||
              (!path && type !== 'General') ||
              !hasEditChanges ||
              invalidWidth ||
              !supportPointsValuesValid()
              }
              loading={loading?.[type] && loading?.[type]?.apply}
              error={Boolean(error['caster/apply'])}
              value={t('formBuilder.apply')}
              title={t(`formBuilder.${isDeleted ? 'applyDeletion' : 'applyChanges'}`)}
            />
            {
              !copyExcludedTypes.includes(type) &&
            (
              <Button
                onClick={onCreateOrCopy}
                value={t(`formBuilder.${path ? 'copy' : 'create'}`)}
                error={Boolean(error[`caster/${path ? 'copy' : 'create'}${type}`])}
                loading={loading?.[type] && loading?.[type]?.clone}
                disabled={
                  Boolean(isComparingCasters) || path
                    ? !hasEditChanges
                    : !(hasEditChanges && createValid)
                }
              />
            )
            }
            {
              type === 'Nozzle' &&
          (
            <Button
              onClick={handleMirrorElements}
              value={t('formBuilder.mirror.label')}
              title={t('formBuilder.mirror.title')}
              loading={
                loading
                  ?.[type]
                  ?.mirror
              }
              error={Boolean(error[`caster/mirror${type}`])}
              disabled={Boolean(isComparingCasters) || !path}
            />
          )
            }
            {
              !deleteExcludedTypes.includes(type) &&
          (
            <Action
              onClick={path ? handleDelete : () => null}
              $disabled={isComparingCasters || !path}
              title={t(`formBuilder.${isDeleted ? 'restore' : 'mark'}`)}
            >
              {isDeleted ? <FontAwesomeIcon icon={faUndo} /> : <FontAwesomeIcon icon={faTrash} />}
            </Action>
          )
            }
          </FlexDiv>
        )
      }
      {
        (
          !hideActions &&
        (
          (type === 'Nozzle' && !FeatureFlags.canEditNozzle(featureFlags)) ||
          (
            (type === 'Roller' || type === 'RollerBody' || type === 'RollerBearing') &&
            !FeatureFlags.canEditRoller(featureFlags)
          ) ||
          (
            type === 'General' &&
            !FeatureFlags.canEditMold(featureFlags)
          )
        )
        ) &&
      (
        <Action
          onClick={handleUndo}
          $viewer={!canEditElement}
          $disabled={
            isComparingCasters ||
            !hasEditChanges ||
            (hasEditChanges && isDeleted)
          }
          title={t('formBuilder.reset')}
        >
          <FontAwesomeIcon icon={faReply} />
        </Action>
      )
      }
    </ButtonsBox>
  )
}

export default FormActions
