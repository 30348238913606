import { type RefObject, createRef } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import styled from 'styled-components'

export type ResizeData = {
  ref: RefObject<HTMLElement>
  width: number | null
  height: number | null
}

type Props = {
  className?: string
  skipOnMount?: boolean
  onResize?: (data: ResizeData) => void
  [key: string]: any
}

const Container = styled.div`
`

export const ResizeDetector: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { children, className, skipOnMount, onResize, ...restProps } = props
  const targetRef = createRef<HTMLDivElement>()

  useResizeDetector({
    targetRef,
    onResize: ({ width, height }) => onResize?.({ ref: targetRef, width, height }),
    skipOnMount,
  })

  return (
    <Container ref={targetRef} className={className} {...restProps}>
      {children}
    </Container>
  )
}
