import {
  type IconDefinition,
  faCircle,
  faEdit,
  faObjectGroup,
  faSquare,
} from '@fortawesome/free-regular-svg-icons'
import {
  faCircle as fasCircle,
  faBullseye,
  faCaretDown,
  faGripLines,
  faList,
  faMapMarker,
  faSquareFull,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import FeatureFlags from '@/react/FeatureFlags/index'
import { DefaultState } from '@/types/state'

import { Header, IconBox, Lock, NameBox, Trigger } from './TypeHeaderStyles'

const connector = connect((state: DefaultState) => ({
  selectedPaths: state.data.selectedPaths,
  authenticationData: state.application.main.authenticationData,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  caseLocks: state.application.main.caseLocks,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  title: string
  type: string
  isExpanded: boolean
  isSelected: boolean
  onClick: (e: any) => void
  t(key: string, params?: Record<string, unknown>): string
}

type State = void

export class TypeHeader extends Component<Props, State> {
  private static readonly icons: Record<string, IconDefinition> = {
    General: faMapMarker,
    Elements: faList,
    SegmentGroup: faSquare,
    Segment: faSquare,
    SupportPoint: faSquareFull,
    Nozzle: faCaretDown,
    Roller: faCircle,
    RollerBody: fasCircle,
    RollerBearing: faBullseye,
    SensorPoint: faSquareFull,
    DataPoint: faSquareFull,
    DataLine: faGripLines,
    ConsistencyCheck: faEdit,
    Modules: faObjectGroup,
  }

  public override render () {
    const {
      type,
      isExpanded,
      isSelected,
      onClick: handleClick,
      title,
      authenticationData,
      featureFlags,
      caseLocks,
      t,
    } = this.props
    const canEdit = FeatureFlags.canEditElement(type, featureFlags)
    const { isLocked } = FeatureFlags.getLockedStatus(type, authenticationData, caseLocks)

    const lockedInfo = t('header.noPermissions', { type })
    // FIXME: if needed get username and show it
    // const lockedInfo = isLocked
    //   ? lockedBy
    //     ? t('header.lockedBy', { type, user: lockedBy })
    //     : t('header.noPermissions', { type })
    //   : t('header.noPermissions', { type })

    return (
      <Header $isSelected={isSelected} title={(!canEdit || isLocked) ? lockedInfo : title || type}>
        <IconBox $isChild={![ 'General', 'Elements', 'Modules', 'ConsistencyCheck' ].includes(type)}>
          <FontAwesomeIcon icon={TypeHeader.icons[type]} />
        </IconBox>
        <NameBox $pad={20} onClick={handleClick}>
          {type}
        </NameBox>
        {
          (!canEdit || isLocked) && type !== 'Modules' && type !== 'Elements' && (
            <Lock title={lockedInfo}>
              <i className='pe-7s-lock' />
            </Lock>
          )
        }
        <Trigger onClick={handleClick}>
          <i className={`pe-7s-angle-${isExpanded ? 'down' : 'right'}`} />
        </Trigger>
      </Header>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(TypeHeader)
