import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { useConfig } from '@/config'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import DataActions from '@/store/data/actions'
import { getElementTypeURL } from '@/store/data/logic'
import { getElementMapsObject } from '@/store/elements/logic'
import { getReferenceDate } from '@/store/timestamps'
import { DEFINITION } from '@/store/type/consts'
import { setCompareCasterInformation } from '@/store/visualization/actions'
import Util from '@/three/logic/Util'
import { CasterElementNames } from '@/types/data'
import type { CasterDialogElementType } from '@/types/filter'
import type { DefaultState, TagName } from '@/types/state'
import type { CompareCasterInformation } from '@/types/visualization'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'

import FormActions from './FormActions'
import FormBuilderInput from './FormBuilderInput'
import { FormBuilderUtil } from './FormBuilderUtil'
import Section from '../../Section'
// import CommentInput from '../Input/CommentInput'
import CompareCasterInput from '../Input/CompareCasterInput'
import MirrorAndRepeat from '../MirrorAndRepeat'
import NozzleCatalog from '../NozzleCatalog'

const ErrorMessage = styled.p`${() =>
  css`
  color: #FF0;
  margin: 5px auto;
  padding-left: 24px;
`}`

const Form = styled.div`
  padding: 10px 10px 0 10px;
`

const ComparisonCasterTitle = styled.div`
  display: flex;
  justify-content: center;
  min-width: 77px;
`

const ComparisonCasterTitlesContainer = styled.div`
  display: flex;
  margin-left: 150px;
`

const connector = connect((state: DefaultState) => ({
  editElements: state.data.editElements,
  editElementsInitial: state.data.editElementsInitial,
  dirtyDeletePaths: state.data.dirtyDeletePaths,
  hidePaths: state.data.hidePaths,
  catalogElement: state.data.catalogElement,
  hasEditChanges: state.data.hasEditChanges,
  editValues: state.data.editValues,
  createValid: state.data.createValid,
  loading: state.loading.loading,
  error: state.application.error,
  authenticationData: state.application.main.authenticationData,
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentProjectCasesMetadata: state.application.main.currentProjectCasesMetadata,
  ccElement: state.CCElement,
  viewsObject: state.visualization.viewsObject,
  comparisonCasters: state.ComparisonCasters,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  currentDashboard: state.visualization.currentDashboard,
  timestamps: state.timestamps,
  caseLocks: state.application.main.caseLocks,
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  compareCasterInformation: state.visualization.compareCasterInformation,
  selectedPaths: state.data.selectedPaths,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  elementMaps: getElementMapsObject(state),
}), {
  setCompareCasterInformation,
  setElements: DataActions.setElements,
  setParentPath: DataActions.setParentPath,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  type: CasterDialogElementType
  path: string
  paths: string[]
  allPaths?: string[]
  hideActions?: boolean
  target: any[]
  filterValue: any
  selectedParentIds: { Segment: number, SegmentGroup: number }
  onSave: (uuidsHash: any) => void
  onCreateOrCopy: () => void
  massForm: boolean
  onInput: ({ target }: { target: { name: string, value: any } }) => void
  onDeleteElement: () => void
  onUndo: (event: any) => void
  direction: string
  onPatternInput: (event: any) => void
  onMirrorElements: () => void
  onPatternUndo: (event: any) => void
  copies: number
  copyMode: string
  offset: number
  gapOffset: number
  onPatternApply: () => void
  t(key: string[] | string, params?: Record<string, unknown>): string
  isComparingCasters?: boolean
}

type State = {
  // state goes here
}

export class FormBuilder extends PureComponent<Props> {
  public override state: State = {}

  public constructor (props: Props) {
    super(props)

    const { paths, setElements, setParentPath } = props

    if (paths) {
      setElements(paths)
      setParentPath()
    }
  }

  public override componentDidMount () {
    this.handleUpdateCompareCasterInformation()
  }

  public override componentDidUpdate (prevProps: Props) {
    const {
      paths,
      setElements,
      setParentPath,
      selectedComparisonCaseIds,
    } = this.props

    const { selectedComparisonCaseIds: prevSelectedComparisonIds } = prevProps

    if (paths && !isEqual(paths, prevProps.paths)) {
      setElements(paths)
      setParentPath()
    }

    // comparing the length of the selected comparison case ids is enough to know if the selected ids have changed
    const selectedComparisonCaseIdsChanged = selectedComparisonCaseIds.length !== prevSelectedComparisonIds.length

    if (
      paths.length &&
      (!isEqual(paths, prevProps.paths) || selectedComparisonCaseIdsChanged)
    ) {
      this.handleUpdateCompareCasterInformation()
    }
  }

  private readonly handleUpdateCompareCasterInformation = () => {
    const { type } = this.props

    if (type === 'DataLine' || type === 'DataPoint') {
      return this.handleUpdateTypesThatRequireName()
    }

    if (type === 'SupportPoint') {
      return this.handleUpdateSupportPointsCompareData()
    }

    if (type === 'SegmentGroupSupportPoints') {
      // fetching of data is handled in the SegmentGroup component
      return
    }

    this.handleUpdateTypesThatRequirePositionAndSide()
  }

  private readonly handleUpdateTypesThatRequirePositionAndSide = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      type,
      timestamps,
      currentSimulationCase,
      elementMaps,
    } = this.props

    const positionInfos = []

    for (const path of paths) {
      const element = ElementMapsUtil.getFullCasterElementByPath(path, elementMaps)
      const segmentPath = path.split('/').slice(0, 2).join('/')
      const segment = ElementMapsUtil.getFullCasterElementByPath<SegmentSlot, SegmentMountLog>(segmentPath, elementMaps)

      if (!element || !segment) {
        continue
      }

      const { passlineCoord, widthCoord } = element

      positionInfos.push(`${segment.side}_${passlineCoord}_${widthCoord}`)
    }

    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      elementsToBeRequestedPerCase[caseId] = []

      for (const positionInfo of positionInfos) {
        const element = compareCasterInformation[caseId]?.[type as CasterElementNames]?.[positionInfo]

        if (!element) {
          elementsToBeRequestedPerCase[caseId].push(positionInfo)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length) {
      const baseURL = useConfig().apiBaseURL
      const caseId = currentSimulationCase.id
      const casterId = elementMaps.Caster?.id
      const elementTypeURL = getElementTypeURL(type as TagName)
      const comparePath = 'compare'

      const requestURL = `${baseURL}/${elementTypeURL}/${comparePath}`

      const data = await ApiClient
        .post(requestURL, {
          params: { caseId, casterId },
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      this.setNewCompareCasterInformation(data, type as CasterElementNames, compareCasterInformation)
    }
  }

  private readonly handleUpdateTypesThatRequireName = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      type,
      timestamps,
      currentSimulationCase,
      elementMaps,
    } = this.props

    const names = paths
      .map(path => ElementMapsUtil.getFullCasterElementByPath(path, elementMaps))
      .filter(element => element)
      .map((element) => element!.name)

    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      elementsToBeRequestedPerCase[caseId] = []

      for (const name of names) {
        if (!name) {
          continue
        }

        const element = compareCasterInformation[caseId]?.[type as CasterElementNames]?.[name]

        if (!element) {
          elementsToBeRequestedPerCase[caseId].push(name)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length) {
      const baseURL = useConfig().apiBaseURL
      const caseId = currentSimulationCase.id
      const casterId = elementMaps.Caster?.id
      const elementTypeURL = getElementTypeURL(type as TagName)
      const comparePath = 'compare'

      const requestURL = `${baseURL}/${elementTypeURL}/${comparePath}`

      const data = await ApiClient
        .post(requestURL, {
          params: { caseId, casterId },
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      this.setNewCompareCasterInformation(data, type as CasterElementNames, compareCasterInformation)
    }
  }

  private readonly handleUpdateSupportPointsCompareData = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      type,
      timestamps,
      currentSimulationCase,
      elementMaps,
    } = this.props

    const elements = []

    for (const path of paths) {
      const element = ElementMapsUtil.getFullCasterElementByPath(path, elementMaps)
      const segmentGroupPath = Util.getParentInfo(path)?.path
      const segmentGroup = ElementMapsUtil.getFullCasterElementByPath<SegmentGroupSlot, SegmentGroupMountLog>(
        segmentGroupPath,
        elementMaps,
      )

      if (!element || !segmentGroup) {
        continue
      }

      element.passlineCoord = segmentGroup.passlineCoord

      elements.push(element)
    }

    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      elementsToBeRequestedPerCase[caseId] = []

      for (const element of elements) {
        const { passlineCoord, name } = element
        const elementKey = `${name}_${passlineCoord}`
        const supportPoint = compareCasterInformation[caseId]?.[type as CasterElementNames]?.[elementKey]

        if (!supportPoint) {
          elementsToBeRequestedPerCase[caseId].push(elementKey)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length) {
      const baseURL = useConfig().apiBaseURL
      const caseId = currentSimulationCase.id
      const casterId = elementMaps.Caster?.id
      const elementTypeURL = getElementTypeURL(type as TagName)
      const comparePath = 'compare'

      const requestURL = `${baseURL}/${elementTypeURL}/${comparePath}`

      const data = await ApiClient
        .post(requestURL, {
          params: { caseId, casterId },
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      this.setNewCompareCasterInformation(data, type as CasterElementNames, compareCasterInformation)
    }
  }

  private readonly handleSave = () => {
    const { onSave } = this.props

    // TODO: for SegmentGroupSupportPoints we need to filter out the ones that do not have changes

    onSave(this.state)
  }

  private readonly handleChangeItem = (type: string, key: string, comment: string) => {
    // make a new hash for each element with the uuid as key and rest of object as value
    const newState: any = {}

    if (type === undefined || key === undefined) {
      return
    }

    if (!newState[type]) {
      newState[type] = {}
    }

    newState[type][key] = comment

    this.setState(newState)
  }

  private readonly setNewCompareCasterInformation = (
    data: any,
    type: CasterElementNames,
    compareCasterInformation: CompareCasterInformation,
  ) => {
    const { setCompareCasterInformation } = this.props

    if (!data || !Object.keys(data).length) {
      return
    }

    const newCompareCasterInformation = cloneDeep(compareCasterInformation)
    const caseIds = Object.keys(data)

    for (const caseId of caseIds) {
      if (!newCompareCasterInformation[caseId]) {
        newCompareCasterInformation[caseId] = {} as any
      }

      if (!newCompareCasterInformation[caseId][type]) {
        newCompareCasterInformation[caseId][type] = {}
      }

      newCompareCasterInformation[caseId][type] = {
        ...newCompareCasterInformation[caseId][type],
        ...data[caseId],
      }
    }

    setCompareCasterInformation(newCompareCasterInformation)
  }

  private readonly getCCWarning = (key: string, comments: any, justReturnBoolean = false) => {
    if (!comments[key] || !comments[key].text) {
      return
    }

    if (justReturnBoolean) {
      return true
    }

    return (
      <div
        style={
          {
            width: 'fit-content',
            margin: '3px',
            padding: '6px',
            paddingLeft: '23px',
            color: '#fcc203',
          }
        }
      >
        {comments[key].text}
      </div>
    )
  }

  private readonly getCCComments = (categories: any) => {
    const { ccElement, path, type, elementMaps } = this.props
    let { paths } = this.props

    if (!paths.length) {
      paths = [ path ]
    }

    if (!paths.length || !ccElement) {
      return {}
    }

    const keysWithCCErrors: any = {}

    const keys: string[] = []

    Object.values(categories ?? {}).forEach((cat: any) => {
      cat?.forEach((key: string) => keys.push(key))
    })

    keys.forEach(key => {
      paths.forEach(path => {
        if (!path) {
          return
        }

        const { id } = Util.getElementInfo(path)
        const element = (elementMaps as any)[type][id]

        const elementKeysWithUUID = Object
          .keys(element ?? {})
          .filter(key => key.includes('uuid'))
          .map(key => key.replace('_uuid', ''))

        if (!elementKeysWithUUID.includes(key)) {
          return {}
        }

        const ccElements = Object.keys(ccElement ?? {})

        for (let i = 0; i < ccElements.length; i++) {
          if (ccElements[i].includes(element[`${key}_uuid`])) {
            if (!keysWithCCErrors[key]) {
              keysWithCCErrors[key] = {
                text: ccElement[ccElements[i]].text,
                n: 1,
                uuid: ccElement[ccElements[i]].uuid,
              }
            }
            else if (keysWithCCErrors[key].uuid === ccElement[ccElements[i]].uuid) {
              keysWithCCErrors[key].n++
            }
          }
        }
      })
    })

    Object.keys(keysWithCCErrors ?? {}).forEach(key => {
      if (keysWithCCErrors[key].n !== paths.length) {
        delete keysWithCCErrors[key]
      }
    })

    return keysWithCCErrors
  }

  // function that returns an input of type text if editElements[key] is not equal to editElementsInitial[key]
  private readonly getChangeItemInput = (_key: string) => {
    // FIXME: this needs to be fixed when Consistency Check is used again

    // const { type, editElements, editElementsInitial, paths, elementMaps } = this.props

    // if (type === 'CCElement' || key.includes('_uuid')) {
    //   return null
    // }

    // const someValueChanged = paths.some(path => {
    //   if (!path || !editElements[path] || !editElements[path][key]) {
    //     return false
    //   }

    //   // if actual value is different than the initial value return true
    //   return editElements[path][key] !== editElementsInitial[path][key]
    // })

    // const keyUUID = `${key}_uuid`

    // // use paths.some to get the first path that has a value for the key keyUUID
    // const someElementHasKey = paths.some(path => {
    //   if (!path) {
    //     return false
    //   }

    //   const { id } = Util.getElementInfo(path)

    //   if (!id) {
    //     return false
    //   }

    //   const element = (elementMaps as any)[type][id]

    //   if (!element) {
    //     return false
    //   }

    //   return element[keyUUID] !== undefined
    // })

    // if (someValueChanged && someElementHasKey) {
    //   return <CommentInput placeholder='Comment' type={type} elementKey={key} onChange={this.handleChangeItem} />
    // }
  }

  // TODO: we could improve this, we do too many calculations, we could get the item only 1 time
  private readonly getComparisonCasterInputs = (def: any, key: string) => {
    const {
      type,
      path,
      paths,
      currentProjectCasesMetadata,
      currentSimulationCase,
      amountOfComparisonCasterColumns,
      compareCasterInformation,
      selectedComparisonCaseIds,
      featureFlags,
      elementMaps,
      t,
    } = this.props

    if (
      !selectedComparisonCaseIds.length ||
      !Object.keys(compareCasterInformation).length ||
      !amountOfComparisonCasterColumns ||
      !paths.length
    ) {
      return null
    }

    const compareCasterInputs: any = []
    const canViewAttributes = FormBuilderUtil.canViewAttributes(type, paths, featureFlags)
    const selectedElements = paths.map(path => {
      const el = ElementMapsUtil.getFullCasterElementByPath(path, elementMaps) as any

      if (!el) {
        return null
      }

      const segmentPath = paths[0].split('/').slice(0, 2).join('/')
      const segment = ElementMapsUtil.getFullCasterElementByPath(segmentPath, elementMaps)

      el.side = type !== 'SensorPoint'
        ? (segment as any)?.side
        : ElementsUtil.getSensorPointSide(el, elementMaps)

      return el
    })

    const currentProjectCaseIds = currentProjectCasesMetadata.map(caseMetadata => caseMetadata.id)

    const selectedCasesInOrder = currentProjectCaseIds
      .filter(caseId => selectedComparisonCaseIds.includes(caseId))
      .filter(caseId => caseId !== currentSimulationCase.id)

    for (let i = 0; i < amountOfComparisonCasterColumns; i++) {
      let value: number | string | undefined
      const caseId = selectedCasesInOrder[i]
      let compareElement: any
      const multipleValuesString = t('allInOne.multiValue')

      // move this to render function
      if (type === 'DataLine' || type === 'DataPoint') {
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          compareElement = Util.getCompareElementByName(element.name, caseId, type, compareCasterInformation)

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }
      else if (type === 'SegmentGroupSupportPoints' || type === 'SupportPoint') { // both are support points
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          const segmentGroupPath = Util.getParentInfo(paths[0])?.path
          const { passlineCoord } = ElementMapsUtil.getFullCasterElementByPath(segmentGroupPath, elementMaps) ?? {}

          if (passlineCoord === undefined || passlineCoord === null || isNaN(passlineCoord)) {
            continue
          }

          compareElement = Util.getCompareSupportPointByNameAndSegmentGroupPasslineCoord(
            element,
            passlineCoord,
            caseId,
            compareCasterInformation,
          )

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }
      else if (type === 'SegmentGroup') {
        // per passlineCoord
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          compareElement = Util.getCompareSegmentGroupByPassLnCoord(element, caseId, compareCasterInformation)

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }
      else {
        for (const element of selectedElements) {
          if (value === multipleValuesString) {
            break
          }

          compareElement = Util.getCompareElementByWidthAndPassLnCoordAndSide(
            element,
            element.side,
            caseId,
            type as CasterElementNames,
            compareCasterInformation,
          )

          if (!compareElement) {
            continue
          }

          compareElement = { ...compareElement, ...(compareElement.additionalData ?? {}) }

          delete compareElement.additionalData

          if (value === undefined && compareElement[key] !== undefined) {
            value = compareElement[key]
          }
          else if (value !== compareElement[key]) {
            value = multipleValuesString
          }
        }
      }

      const newCompareCasterInput = (
        <CompareCasterInput
          key={`${key}${i}`}
          name={key}
          value={
            path && def.generate
            // FIXME: This is a temporary fix, we need to find a way to generate the value for compare casters
              ? undefined
            // ? elementMaps ? def.generate(elementKeysObject, elementMaps) : undefined
              : value
          }
          elementType={type}
          // FIXME: This is a temporary fix, we need to find a way to generate the value for compare casters
          // error={massForm ? this.handleMassValue(key, (elementKeysObject as any)[key], true, elementMaps) : true}
          path={path}
          hideValue={!canViewAttributes}
        />
      )

      compareCasterInputs.push(newCompareCasterInput)
    }

    return (
      <div style={{ display: 'flex' }}>
        {compareCasterInputs.map((compareCasterInput: any) => compareCasterInput)}
      </div>
    )
  }

  private readonly getComparisonCasterColumnTitles = () => {
    const {
      amountOfComparisonCasterColumns,
      type,
      currentSimulationCase,
      currentProjectCasesMetadata,
      selectedComparisonCaseIds,
    } = this.props

    if (type === 'General' || !amountOfComparisonCasterColumns) {
      return null
    }

    const caseIds = currentProjectCasesMetadata.map(caseMetadata => caseMetadata.id)
    let comparisonCasterCounter = 1
    const comparisonCasterTitles = caseIds
      .map(() => `C${comparisonCasterCounter++}`)

    const shownComparisonCasters = []
    const currentCaseIdIndex = caseIds.findIndex(caseId => currentSimulationCase.id === caseId)

    shownComparisonCasters.push(`C${currentCaseIdIndex + 1}(R)`)

    for (let i = 0; i < caseIds.length; i++) {
      const caseId = caseIds[i]

      if (selectedComparisonCaseIds.includes(caseId)) {
        shownComparisonCasters.push(comparisonCasterTitles[i])
      }
    }

    return (
      <ComparisonCasterTitlesContainer>
        {
          shownComparisonCasters
            .slice(0, amountOfComparisonCasterColumns + 1)
            .map(title => (
              <ComparisonCasterTitle key={title}>
                {title}
              </ComparisonCasterTitle>
            ))
        }
      </ComparisonCasterTitlesContainer>
    )
  }

  private readonly getInvalidWidthValueMessage = () => {
    const { editValues, elementMaps } = this.props
    const { moldSlot, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(elementMaps)

    if (!moldMountLog || !moldSlot || !editValues.General || !editValues.General.width) {
      return null
    }

    if (moldMountLog.widthMin && moldMountLog.widthMin > editValues.General.width) {
      return <ErrorMessage>Invalid width, min value is: {moldMountLog.widthMin}</ErrorMessage>
    }

    if (moldMountLog.widthMax && moldMountLog.widthMax < editValues.General.width) {
      return <ErrorMessage>Invalid width, max value is: {moldMountLog.widthMax}</ErrorMessage>
    }
  }

  public override render () {
    const {
      filterValue,
      type,
      path,
      hideActions,
      editElements,
      dirtyDeletePaths,
      onCreateOrCopy,
      onDeleteElement: handleDeleteElement,
      loading,
      hasEditChanges,
      onUndo: handleUndo,
      paths,
      target,
      direction,
      onPatternInput: handlePatternInput,
      onPatternUndo: handlePatternUndo,
      copies,
      copyMode,
      offset,
      gapOffset,
      onMirrorElements: handleMirrorElements,
      createValid,
      onPatternApply,
      error,
      authenticationData,
      isComparingCasters,
      caseLocks,
      featureFlags,
      massForm,
      elementMaps,
      t,
    } = this.props

    const definition = DEFINITION[type]

    let elementKeysObject = {}

    if (path) {
      for (const singlePath of paths) {
        elementKeysObject = {
          ...elementKeysObject,
          ...(editElements[singlePath]?.additionalData ?? {}),
          ...(editElements[singlePath] ?? {}),
        }
      }
    }

    const element = path
      ? elementKeysObject
      : Object.keys(definition.fields).reduce((acc, key) => ({
        ...acc,
        [key]: filterValue[key],
      }), {}) as any

    const additionalData = element.additionalData

    delete element.additionalData

    const elementKeys = Object
      .keys({
        ...element,
        ...additionalData,
        ...definition.fields,
      })
      .filter(key => (
        !definition.fields[key] || // if not in definition -> "other" attributes
        ( // handle hidden
          typeof definition.fields[key].hidden !== 'function'
            ? !definition.fields[key].hidden // boolean | undefined
            : !definition.fields[key].hidden(this.props) // function
        )
      ))

    const categories = elementKeys.reduce((cat, key) => {
      const { category } = definition.fields[key] || { category: 0 }

      return {
        ...cat,
        [category]: [
          ...((cat as any)[category] ?? []), // TODO: Fix type
          key,
        ],
      }
    }, {})

    const categoryList = Object.keys(categories)

    const passLn = []

    Object.values(editElements).forEach(element => passLn.push(Number(element.passlineCoord)))

    const isDeleted = dirtyDeletePaths.includes(path)

    const { isLocked } = FeatureFlags.getLockedStatus(type, authenticationData, caseLocks)

    // FIXME: this needs to be fixed when Consistency Check is used again
    const comments = {} // this.getCCComments(categories)

    const invalidWidthMessage = this.getInvalidWidthValueMessage()

    return (
      <Form>
        {
          categoryList.map(category => (
            <Section
              key={category}
              name={
                typeof definition.categories[category] === 'function'
                  ? definition.categories[category](this.props)
                  : t([
                    `formBuilder.categories.${definition.categories[category]}.label`,
                    'formBuilder.categories.default',
                  ])
              }
              title={
                typeof definition.categories[category] === 'function'
                  ? definition.categories[category](this.props, true)
                  : t(`formBuilder.categories.${definition.categories[category]}.title`)
              }
              spaceForComparisonCasterTitle={isComparingCasters && type !== 'General'}
            >
              {this.getComparisonCasterColumnTitles()}
              {
                (categories as any)[category]
                  .sort()
                  .map((key: any, index: number) =>
                    (def => (
                      <div
                        key={`cat${index}`}
                        style={
                          this.getCCWarning(key, comments, true)
                            ? {
                              borderLeft: '8px solid #fcc203',
                              borderBottom: '1px solid #fcc203',
                              marginLeft: '-8px',
                            }
                            : undefined
                        }
                      >
                        <div style={{ display: 'flex', height: '31px' }}>
                          <FormBuilderInput
                            def={def}
                            element={element}
                            attribute={key}
                            path={path}
                            type={type}
                            massForm={massForm}
                            paths={paths}
                            onInput={this.props.onInput}
                            onUndo={handleUndo}
                            isComparingCasters={isComparingCasters}
                            hidePaths={this.props.hidePaths}
                            selectedParentIds={this.props.selectedParentIds}
                          />
                          {type !== 'General' && this.getComparisonCasterInputs(def, key)}
                        </div>
                        {type === 'General' && path && invalidWidthMessage}
                        {/* {this.getChangeItemInput(key)} */}
                        {this.getCCWarning(key, comments)}
                      </div>
                    ))(definition.fields[key] ?? {}))
              }
            </Section>
          ))
        }
        {type === 'Nozzle' && !isComparingCasters && <NozzleCatalog path={path} type={type} />}
        {
          (
            (type === 'Nozzle' && FeatureFlags.canEditNozzle(featureFlags)) ||
            (type === 'Roller' && FeatureFlags.canEditRoller(featureFlags))
          ) &&
          (
            <MirrorAndRepeat
              type={type}
              paths={paths}
              target={target}
              direction={direction}
              copyMode={copyMode}
              handleInput={handlePatternInput}
              handleUndo={handlePatternUndo}
              copies={copies}
              offset={offset}
              gapOffset={gapOffset}
              handlePatternApply={onPatternApply}
            />
          )
        }
        <FormActions
          hideActions={Boolean(hideActions)}
          isComparingCasters={Boolean(isComparingCasters)}
          type={type}
          loading={loading}
          hasEditChanges={Boolean(hasEditChanges?.[type])}
          createValid={(createValid as any)?.[type]}
          error={error}
          isDeleted={isDeleted}
          isLocked={isLocked}
          editElements={editElements}
          elementMaps={elementMaps}
          featureFlags={featureFlags}
          onDelete={handleDeleteElement}
          onSave={this.handleSave}
          handleMirrorElements={handleMirrorElements}
          onCreateOrCopy={onCreateOrCopy}
          onUndo={handleUndo}
          invalidWidth={Boolean(invalidWidthMessage)}
          path={path}
          paths={paths}
          allPaths={this.props.allPaths}
          t={t}
        />
      </Form>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(FormBuilder)
