import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, type ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import { getProject } from '@/api/project'
import { createGroupShare, deleteGroupShare, getShares, type ProjectGroupShare } from '@/api/share'
import { DialogID } from '@/react/driver/DriverID'
import Input from '@/react/specific/Input'
import { Form, Text } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'

const ShareContainer = styled.div`
  margin-top: 15px;

  > div {
    padding-top: 0;
  }
`

const T = 'shareProjectDialog'

const connector = connect((state: DefaultState) => ({
  params: state.application.main.params,
  authenticationData: state.application.main.authenticationData,
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  error: string
  loading: boolean
  groupShares: ProjectGroupShare[]
  project: Project | null
}

export class ShareProjectDialog extends Component<Props, State> {
  @Identifiable('ShareProjectDialog') public static readonly NAME: string

  private didInit = false

  public override state: State = {
    error: '',
    loading: false,
    groupShares: [],
    project: null,
  }
  
  public override componentDidMount () {
    this.handleInit()
    hotkeys('Escape', this.handleClose)
  }
  
  public override async componentDidUpdate () {
    await this.handleInit()
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleInit = async () => {
    const { params } = this.props
    const { projectId } = params ?? {}

    if (!this.didInit && projectId) {
      this.didInit = true

      try {
        const [ shares, project ] = await Promise.all([ getShares(projectId), getProject(projectId) ])

        this.setState({
          groupShares: shares?.groupShares ?? [],
          project,
          loading: false,
        })
      }
      catch (error: any) {
        this.setState({
          error: error.message,
          loading: false,
        })
      }
    }
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(ShareProjectDialog.NAME)
  }

  private readonly handleToggleShare = async (groupId: string) => {
    const { groupShares } = this.state
    const { params, t } = this.props
    const { projectId } = params ?? {}

    const share = groupShares.find(({ groupId: id }) => groupId === id)

    try {
      if (!share) {
        const newShare = await createGroupShare(projectId, groupId)

        if (!newShare) {
          enqueueSnackbar(t(`${T}.createShare.error`), { autoHideDuration: 3000, variant: 'error' })

          return
        }

        this.setState({
          groupShares: [ ...groupShares, newShare ],
        })

        enqueueSnackbar(t(`${T}.createShare.success`), { autoHideDuration: 3000, variant: 'success' })

        return
      }

      await deleteGroupShare(share.id)

      this.setState({
        groupShares: groupShares.filter(({ id }) => id !== share.id),
      })

      enqueueSnackbar(t(`${T}.removeShare.success`), { autoHideDuration: 3000, variant: 'success' })
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)

      enqueueSnackbar(t(`${T}.removeShare.error`), { autoHideDuration: 3000, variant: 'error' })
    }
  }
  
  public override render () {
    const { error, loading, groupShares, project } = this.state
    const { t, authenticationData } = this.props

    const groups = authenticationData.groups.filter(({ name }) => !name?.toLocaleLowerCase()?.includes('admin'))

    return (
      <BaseDialog
        id={DialogID.ShareProjectDialog.ID}
        title={t(`${T}.title`)}
        icon='pe-7s-share'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>{t(`${T}.message`, { projectName: project?.name })}</Text>
          <ShareContainer>
            {
              !loading && groups.map(({ id, name }) => (
                <Input
                  key={id}
                  id={`${DialogID.ShareProjectDialog.ShareCheckbox}_${name.toLowerCase()}`}
                  name='shareProject'
                  type='checkbox'
                  label={name}
                  title={name}
                  value={groupShares.findIndex(({ groupId }) => groupId === id) !== -1}
                  onChange={this.handleToggleShare.bind(this, id)}
                />
              ))
            }
          </ShareContainer>
          {loading && <p>{t(`${T}.loading`)}</p>}
          {error && <p>{error}</p>}
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(ShareProjectDialog)

export default hoistStatics(composedComponent, ShareProjectDialog)
