import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import type { ChangeEvent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Input from '@/react/specific/Input'
import VisUtil from '@/react/visualization/VisUtil'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import { CheckListHeader, FormWrapper, IButton, InputWrapper } from '../../Dialogs/DialogStyles'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  data: visualization.data,
}), {
  savePlotConfig: VisualizationActions.savePlotConfig,
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean
  t: Translation
}

type State = {
  plotConfig: any
  tileConfig: any
}

class EditTab extends Component<Props, State> {
  private tileConfigTimeout?: number

  private plotConfigTimeout?: number

  public constructor (props: Props) {
    super(props)

    const { plotConfigs, editDialogConfigId, currentTileId, tileConfigs } = props

    this.state = {
      plotConfig: currentTileId ? plotConfigs[editDialogConfigId] : {},
      tileConfig: currentTileId ? { ...tileConfigs[currentTileId] } : {},
    }
  }
  
  public override componentDidUpdate (prevProps: Props, prevState: State) {
    const { saveTileConfig, savePlotConfig } = this.props
    const { tileConfig, plotConfig } = this.state

    if (!isEqual(prevState.tileConfig, tileConfig ?? {})) {
      clearTimeout(this.tileConfigTimeout)

      this.tileConfigTimeout = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        delete this.tileConfigTimeout
      }, 100)
    }

    if (!isEqual(plotConfig, prevState.plotConfig)) {
      clearTimeout(this.plotConfigTimeout)

      this.plotConfigTimeout = window.setTimeout(() => {
        savePlotConfig(plotConfig)
        delete this.plotConfigTimeout
      }, 100)
    }
  }

  public override componentWillUnmount () {
    const { saveTileConfig, savePlotConfig } = this.props
    const { tileConfig, plotConfig } = this.state

    if (this.tileConfigTimeout) {
      clearTimeout(this.tileConfigTimeout)
      saveTileConfig(tileConfig)
      delete this.tileConfigTimeout
    }

    if (this.plotConfigTimeout) {
      clearTimeout(this.plotConfigTimeout)
      savePlotConfig(plotConfig)
      delete this.plotConfigTimeout
    }
  }

  private readonly handleRemoveConfigId = (event: any) => {
    const { id } = event.target
    const { plotConfig, tileConfig } = this.state
    const configIds = [ ...plotConfig.configIds ]
    const label = { ...tileConfig.label }

    configIds.splice(configIds.indexOf(id), 1)

    delete label[id]

    this.setState({
      tileConfig: { ...tileConfig, label: { ...label } },
      plotConfig: { ...plotConfig, configIds },
    })
  }

  private readonly handleAddConfigId = (): void => {
    const { plotConfigs } = this.props
    const { plotConfig } = this.state
    const configIds = [ ...plotConfig.configIds ]

    configIds.push(Object.keys(plotConfigs).find(plotConfigId => !configIds.includes(plotConfigId)))

    this.setState({
      plotConfig: {
        ...plotConfig,
        configIds,
      },
    })
  }

  private readonly handlePlotLabel = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const { tileConfig } = this.state
    const { label } = tileConfig

    this.setState({
      tileConfig: {
        ...tileConfig,
        label: {
          ...label,
          [name]: value,
        },
      },
    })
  }

  private readonly handleInputDataTile = (event: any) => {
    const { tileConfig } = this.state
    const { value, name } = event.target

    this.setState({
      tileConfig: {
        ...tileConfig,
        [name]: value,
      },
    })
  }
  
  public override render () {
    const { plotConfig, tileConfig } = this.state
    const {
      plotConfigs,
      fullscreen,
      t,
      data,
    } = this.props

    const { configIds } = plotConfig
    const { label, configId } = tileConfig

    const allConfigIds = VisUtil.getConfigSelectors(data, plotConfigs)
    const allConfigIdsNoMerged = allConfigIds
      .filter(configSelector => configSelector.group !== VisUtil.GROUP_MERGED)
      .sort((a, b) => {
        if (typeof a.value !== 'string' || typeof b.value !== 'string') {
          return 0
        }

        return a.value.toLowerCase().localeCompare(b.value.toLowerCase())
      })

    return (
      <FormWrapper $fullscreen={fullscreen}>
        <CheckListHeader>{t('plotConfig.labelAndPlot')}</CheckListHeader>
        <div>
          <hr />
          {
            configIds && configIds.length > 0
              ? configIds.map((plotConfigId: string, index: number) => (
                <div key={plotConfigId}>
                  <InputWrapper $fullscreen={fullscreen}>
                    <Input
                      label={t('plotConfig.editLabel.label')}
                      title={t('plotConfig.editLabel.title')}
                      name={plotConfigId}
                      type='text'
                      value={label?.[plotConfigId] ?? ''}
                      onChange={this.handlePlotLabel}
                    />
                    <Input
                      label={t('plotConfig.configId.label')}
                      title={t('plotConfig.configId.title')}
                      name={plotConfigId}
                      type='select'
                      value={plotConfigId}
                      selectors={allConfigIdsNoMerged}
                      onChange={this.handleInputDataTile}
                    // disabled={fullscreen}
                    />
                  </InputWrapper>
                  {
                    configIds.length > 1 &&
                  (
                    <IButton
                      className='pe-7s-less'
                      onClick={this.handleRemoveConfigId}
                      id={plotConfigId}
                      title={t('plotConfig.removeValue')}
                    />
                  )
                  }
                  {configIds.length - 1 > index && <hr />}
                </div>
              ))
              : (
                <div key={configId}>
                  <Input
                    label={t('plotConfig.editLabel.label')}
                    title={t('plotConfig.editLabel.title')}
                    name={configId}
                    type='text'
                    value={label?.[configId] ?? ''}
                    onChange={this.handlePlotLabel}
                  />
                  <Input
                    label={t('plotConfig.configId.label')}
                    title={t('plotConfig.configId.title', { selected: configId })}
                    name={configId}
                    type='select'
                    value={configId}
                    selectors={allConfigIdsNoMerged}
                    disabled
                    readonly
                  />
                  {configIds && <hr />}
                </div>
              )
          }
          {configIds && <IButton className='pe-7s-plus' onClick={this.handleAddConfigId} title={t('plotConfig.add')} />}
        </div>
      </FormWrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(EditTab)
