import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { withStyles } from '@mui/styles'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { Translation } from '@/types/translation'

const Wrapper = styled.div<{ $isVerified?: boolean, $disabled?: boolean }>`${({ theme, $isVerified, $disabled }) =>
  css`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.swatch3};

  label > span:nth-of-type(2) {
    color: ${$disabled ? theme.colors.swatch19 : ($isVerified ? '#629953' : '#ff4949')};
    user-select: none;
  }
`}`

export const InlineLabel = styled.div`${({ theme }) =>
  css`
  color: ${theme.mainFontColor};
  user-select: none;
  font-size: 14px;
  margin-top: 17px;
  margin-bottom: 10px;
  display: inline-block;
`}`

const GreenCheckbox = withStyles({
  root: {
    color: '#ff3a3a',
    '&$checked': {
      color: '#629953',
    },
  },
  checked: {},
})((props: any) => <Checkbox color='default' {...props} />)

const T = 'verifyButton'

type Props = {
  label: string
  title: string
  isVerified: boolean
  disabled: boolean
  onChange: any
  t: Translation
}

export class VerifyButton extends Component<Props> {
  public override render () {
    const { label, title, isVerified, disabled, onChange, t } = this.props

    return (
      <Wrapper $isVerified={isVerified} $disabled={disabled}>
        <InlineLabel>{label}</InlineLabel>
        <FormControlLabel
          value='start'
          control={<GreenCheckbox checked={isVerified} disabled={disabled || isVerified} onChange={onChange} />}
          label={isVerified ? t(`${T}.verified`) : t(`${T}.verify`)}
          title={title}
          labelPlacement='start'
        />
      </Wrapper>
    )
  }
}

export default compose<any>(withTranslation('application'))(VerifyButton)
