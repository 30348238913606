import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import cloneDeep from 'lodash/cloneDeep'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Button from '@/react/components/Button/index'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { FixedButton } from '@/react/dialogs/DialogStyles'
import { Form, List, Text, TextBody } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

const T = 'projectMatrixDialog.deleteDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  selections: state.matrix.selections,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  error: string
  loading: boolean
}

export class DeleteDialog extends Component<Props, State> {
  @Identifiable('DeleteDialog') public static readonly NAME: string

  public override state: State = {
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    hotkeys('Enter', this.handleDeleteSubmit)
    hotkeys('Escape', this.handleClose)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleDeleteSubmit)
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(DeleteDialog.NAME)
  }

  private readonly handleDeleteSubmit = () => {
    const {
      currentProject,
      currentSimulationCase,
      setCurrentProject,
      setCurrentSimulationCase,
    } = this.props

    const selectedSimulationCases = this.getSelectedNotRunningSimulationCases()
    const ids = selectedSimulationCases.map(({ simulationCase }) => simulationCase.id)

    this.setState({ loading: true })

    ApiClient
      .del(`${'Network.URI(deprecated)'}/simulation_case/delete`, { data: { simulationCaseIds: ids } })
      .then(() => {
        const project = cloneDeep(currentProject)

        project.simulationCases = project
          .simulationCases
          .filter(simulationCase => !ids.includes(simulationCase.id))

        if (ids.includes(currentSimulationCase.id)) {
          setCurrentSimulationCase(project.simulationCases[0])
        }

        setCurrentProject(project)

        const content = document.querySelector('.data-grid-container')?.parentNode

        if (content) {
          ;(content as any).scrollTop = 0
        }

        this.handleClose()
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        this.setState({ loading: false, error: 'ERROR' })
      })
  }

  private readonly getSelectedNotRunningSimulationCases = () => {
    const { currentProject, selections } = this.props

    return currentProject
      .simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => (
        ((selections ?? {}) as any)[simulationCase.id] &&
        !(simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt)
      ))
  }
  
  public override render () {
    const { error, loading } = this.state
    const { t } = this.props

    const simulationCases = this.getSelectedNotRunningSimulationCases()

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-trash'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${T}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                simulationCases.map(({ num, simulationCase }) => (
                  <li key={simulationCase.id}>{num}. {simulationCase.name}</li>
                ))
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleDeleteSubmit}
              error={error}
              loading={loading}
              icon='pe-7s-trash'
              isRef
            >
              {t(`${T}.delete`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(DeleteDialog)

export default hoistStatics(composedComponent, DeleteDialog)
