import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import FeatureFlags from '@/react/FeatureFlags'
import { Button, Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import DataActions from '@/store/data/actions'
import * as LoadingActions from '@/store/LoadingStore'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'
import { CasterColumnLogic } from '../project/ProjectDataDialog/CasterColumnLogic'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  closeDialog: ApplicationActions.closeDialog,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  openDialog: ApplicationActions.openDialog,
  resetReducer: DataActions.resetReducer,
  setAppState: ApplicationActions.setAppState,
  setError: ErrorActions.setError,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

export class CasterHandleDataDialog extends Component<Props> {
  @Identifiable('CasterHandleDataDialog') public static readonly NAME: string
  
  public override componentDidMount () {
    hotkeys('Escape', this.handleClose as any)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose as any)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CasterHandleDataDialog.NAME)
  }

  private readonly handleButtonPress = (action: 'mergeNozzles' | 'replaceCaster' | 'replaceNozzles') => {
    const { closeDialog, setLoadingStatus } = this.props

    CasterColumnLogic.handleUploadCasterData(action, this.props as any, true, setLoadingStatus)
    closeDialog(CasterHandleDataDialog.NAME)
  }

  private readonly handleButtonPressMergeNozzles = this.handleButtonPress.bind(this, 'mergeNozzles')

  private readonly handleButtonPressReplaceNozzles = this.handleButtonPress.bind(this, 'replaceNozzles')

  private readonly handleButtonPressReplaceCaster = this.handleButtonPress.bind(this, 'replaceCaster')
  
  public override render () {
    const { t } = this.props

    return (
      <BaseDialog
        title={t('casterHandleDataDialog.title')}
        icon='pe-7s-folder'
        header={t('casterHandleDataDialog.header')}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Button onClick={this.handleButtonPressMergeNozzles}>
            <div>
              <i className='pe-7s-shuffle' />
            </div>
            <span>{t('casterHandleDataDialog.mergeNozzles')}</span>
          </Button>
          <Button onClick={this.handleButtonPressReplaceNozzles}>
            <div>
              <i className='pe-7s-refresh-2' />
            </div>
            <span>{t('casterHandleDataDialog.replaceNozzles')}</span>
          </Button>
          <Button onClick={this.handleButtonPressReplaceCaster}>
            <div>
              <i className='pe-7s-loop' />
            </div>
            <span>{t('casterHandleDataDialog.replaceCaster')}</span>
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(CasterHandleDataDialog)

export default hoistStatics(composedComponent, CasterHandleDataDialog)
