import { Mesh } from 'three'

import FeatureFlags from '@/react/FeatureFlags'
import ThreeUtil from '@/three/logic/Util'
import ThreeManager from '@/three/ThreeManager'
import { StrandSides } from '@/types/elements/enum'
import type { ElementMaps } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import UIView from '.'
import CalculationUtil from './CalculationUtil'
import DrawHandlers from './DrawHandlers'
import Getters from './Getters'
import SectionView from '../SectionView'

export default class UiViewHandlers {
  public static handleElementCount (view: UIView, elementMaps: ElementMaps) {
    const {
      nozzleCount,
      rollerCount,
      dataPointCount,
    } = Getters.getNozzleRollerAndDataPointCount(elementMaps)

    if (!nozzleCount && !rollerCount && !dataPointCount) {
      UiViewHandlers.handleNoElements(view)
    }
    else {
      view.sectionViewDisabled = false

      view.scrollableElements.Roller = Boolean(rollerCount) && FeatureFlags.canJumpOverRollers(view.featureFlags)
      view.scrollableElements.Nozzle = Boolean(nozzleCount) && FeatureFlags.canJumpOverNozzles(view.featureFlags)
      view.scrollableElements.DataPoint = Boolean(dataPointCount) &&
        FeatureFlags.canJumpOverDataPoints(view.featureFlags)

      const amountOfScrollableElements = view.getAmountOfJumpableElements()

      if (
        view.views.sectionView &&
        view.views.sectionView.jumpOver !== null &&
        !view.scrollableElements[view.views.sectionView.jumpOver]
      ) {
        const nextElement = this.getNextScrollableElement(view.views.sectionView, view)

        UiViewHandlers.handleSwitchScrollElement(view, nextElement)
      }

      if (amountOfScrollableElements === 1) {
        view.switchScrollElementDisabled = true
      }
      else {
        view.switchScrollElementDisabled = false
      }
    }
  }

  public static handleJumpToSection (view: UIView) {
    const mainView = view.views.mainView

    if (!mainView) {
      return
    }

    const currentSegmentGroupId = SectionView.currentSegmentGroup.id
    const segmentPaths = Object
      .values(Mapping.elementPathByMountLogId)
      .filter(path => path.includes('/Segment:'))
    const currentSegmentGroupPath = `SegmentGroup:${currentSegmentGroupId}/`

    // find max 4 paths that include 'SegmentGroup:${currentSegmentGroupId}'
    const segmentsPathsInCurrentSegmentGroup: string[] = []

    for (let i = 0; i < segmentPaths.length; i++) {
      const path = segmentPaths[i]

      if (!path) {
        continue
      }

      if (path.includes(currentSegmentGroupPath)) {
        segmentsPathsInCurrentSegmentGroup.push(path)
      }

      if (segmentsPathsInCurrentSegmentGroup.length === 4) {
        break
      }
    }

    if (!segmentsPathsInCurrentSegmentGroup.length) {
      return
    }

    const segments = segmentsPathsInCurrentSegmentGroup
      .map(path => ElementMapsUtil.getFullCasterElementByPath<SegmentSlot, SegmentMountLog>(path, view.elementMaps))

    const segmentSideHash: any = {}

    // search for the looseSide segment first, if not found, search for the fixedSide segment, if not, any
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i]
      const segmentPath = segmentsPathsInCurrentSegmentGroup[i]

      if (!segment || !segment.side || !segmentPath) {
        continue
      }

      const { id } = ThreeUtil.getElementInfo(segmentPath)

      segmentSideHash[segment?.side] = id
    }

    if (segmentSideHash[StrandSides.Loose] !== undefined) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash[StrandSides.Loose]}`,
        undefined,
        true,
      )
    }
    else if (segmentSideHash[StrandSides.Fixed] !== undefined) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash[StrandSides.Fixed]}`,
        undefined,
        true,
      )
    }
    else if (segmentSideHash[StrandSides.Left] !== undefined) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash[StrandSides.Left]}`,
        undefined,
        true,
      )
    }
    else if (segmentSideHash[StrandSides.Right] !== undefined) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash[StrandSides.Right]}`,
        undefined,
        true,
      )
    }
  }

  private static handleNoElements (view: UIView) {
    view.sectionViewHandler(false)

    view.sectionViewDisabled = true
  }

  public static updateButton (button: any, width: number, height: number) {
    const { align, x, y, width: bWidth, height: bHeight } = button.userData

    const posX = width / 2
    const posY = height / 2
    let dirX = 1
    let dirY = 1

    switch (align) {
      case 'tr': // top right
        break
      case 'tl': // top left
        dirX = -1
        break
      case 'br': // bottom right
        dirY = -1
        break
      case 'bl': // bottom left
        dirX = -1
        dirY = -1
        break
      default:
        break
    }

    const pos = CalculationUtil.calcUpdatedButtonPos(posX, posY, bWidth, bHeight, dirX, dirY, x, y)

    button.position.set(pos.x, pos.y, pos.z)
  }

  public static hideSelectionRect (selectionRect: Mesh) {
    selectionRect.visible = false
    selectionRect.scale.x = 0.00001
    selectionRect.scale.y = 0.00001
  }

  public static handleSwitchScrollElement (view: UIView, jumpOverOverride?: JumpOverOptions | null) {
    if (!view.views || !view.views.sectionView) {
      return
    }

    // Order: 1-Nozzle  2-Roller  3-DataPoint

    if (jumpOverOverride !== undefined) {
      view.views.sectionView.jumpOver = jumpOverOverride
    }
    else if (!view.switchScrollElementDisabled) {
      view.views.sectionView.jumpOver = this.getNextScrollableElement(view.views.sectionView, view)
    }

    view.views.sectionView?.updateMinAndMaxPasslineCoordinates()
    view.minPasslineCoord = view.views.sectionView.minPasslineCoord
    view.maxPasslineCoord = view.views.sectionView.maxPasslineCoord
    DrawHandlers.drawUpDownButtons(view)

    if (!view.views.sectionView.jumpOver) {
      return
    }

    if (view.switchScrollElement) {
      view.switchScrollElement.material = view.buttonMaterials.jumpOver[view.views.sectionView.jumpOver]
    }
  }

  private static getNextScrollableElement (sectionView: SectionView, uiView: UIView) {
    if (!sectionView.jumpOver) {
      return null
    }

    const scrollableElements = uiView.scrollableElements
    const order = [ 'Roller', 'Nozzle', 'DataPoint' ]
    const currentIndex = order.indexOf(sectionView.jumpOver)

    let counter = 1

    while (counter < order.length) {
      const nextIndex = (currentIndex + counter) % order.length
      const nextElement = order[nextIndex] as JumpOverOptions

      if (scrollableElements[nextElement as JumpOverOptions]) {
        if (sectionView.sectionPlaneFolded) {
          sectionView.sectionPlaneFolded.visible = true
        }

        if (sectionView.views.mainView) {
          sectionView.views.mainView.sectionPlane.visible = true
        }

        return nextElement
      }

      counter++
    }

    return sectionView.jumpOver
  }

  public static updateCamera (view: UIView, width: number, height: number) {
    if (!view.camera) {
      return
    }

    view.camera.left = width / -2
    view.camera.right = width / 2
    view.camera.top = height / 2
    view.camera.bottom = height / -2

    view.camera.updateProjectionMatrix()
  }
}
