import { enqueueSnackbar } from 'notistack'

import { getGroupVisualizationConfigFile, getVisualizationConfig } from '@/api/visualization-config'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import Util from '@/logicHandlers/ServerLogic/actions/Util'
import { CasterHandleDataDialog } from '@/react/dialogs/caster/CasterHandleDataDialog'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import { AppState } from '@/store/application/main/consts'
import ThreeManager from '@/three/ThreeManager'

import type { Props } from './CasterColumn'

export class CasterColumnLogic {
  public static async handleLoadCasterDashboardConfigById (
    props: Props,
    dashboardConfigId: string,
    tabIndex: string,
  ) {
    const {
      setConfig,
      setDataSources,
      setSimpleDashboardTabIndex,
      setVisualizationMetaInformation,
      appState,
      authenticationData,
    } = props

    if (!FeatureFlags.canViewCasterDashboard(props.featureFlags)) {
      return
    }

    if (authenticationData.casterDashboardConfigs?.find(config => config.id === dashboardConfigId)) {
      const configObject = authenticationData.casterDashboardConfigs.find(config => config.id === dashboardConfigId)
      const visualizationConfig = await getGroupVisualizationConfigFile(configObject?.id ?? '')

      if (!visualizationConfig) {
        return
      }

      const { viewsObject, plotConfigs, tileConfigs } = visualizationConfig as any

      if (!viewsObject || !plotConfigs || !tileConfigs) {
        // eslint-disable-next-line no-console
        console.error('Error opening dashboard, file could be malformed')

        return
      }

      setConfig({
        viewsObject,
        plotConfigs,
        tileConfigs,
      })

      setDataSources(visualizationConfig.dataSources ?? [])
      setSimpleDashboardTabIndex(1)
      setVisualizationMetaInformation('config', dashboardConfigId, appState)

      return
    }

    const visualizationConfig = await getVisualizationConfig(dashboardConfigId, { emptySelectedComparisonCasters: 1 })

    if (!visualizationConfig) {
      // this.setState({ loading: { openVis: false } })

      return
    }

    // FIXME: dashboard configs?
    // if (!dashboardConfigId.startsWith('group-')) {
    //   const config = currentSimulationCase.visualizationConfigList.find(config => config.id === dashboardConfigId)

    //   if (!config) {
    //     return
    //   }
    // }

    setVisualizationMetaInformation('config', dashboardConfigId, appState)

    setConfig(visualizationConfig.data)
    setDataSources(visualizationConfig.dataSources ?? [])

    const tabIndexNumber = Number(tabIndex)

    if (tabIndex === '' || isNaN(tabIndexNumber)) {
      setSimpleDashboardTabIndex(1)

      return
    }

    let numberOfTabs = 0
    const viewsObject = visualizationConfig.data.viewsObject ?? {}
    const currentViewObject = viewsObject[(Object.keys(viewsObject) ?? [])[0]] ?? {}

    numberOfTabs = (Object.keys(currentViewObject.dashboards ?? {})).length

    setSimpleDashboardTabIndex(numberOfTabs ? (tabIndexNumber > numberOfTabs ? 1 : tabIndexNumber) : 0)
  }

  public static openCaster (props: Props) {
    const { currentSimulationCase, onClose } = props

    IpcManager.send('loadCurrentCaster', currentSimulationCase)

    if (onClose) {
      onClose()
    }
  }

  public static handleUploadCasterData (
    action: 'mergeNozzles' | 'replaceCaster' | 'replaceNozzles' = 'replaceCaster',
    props: Props,
    _force = false,
    setLoadingStatus?: (loading: boolean) => void,
  ): void {
    const {
      currentSimulationCase,
      setCurrentSimulationCase,
      resetReducer,
      resetAllElements,
      setError,
      setFileUploadLoadingStatus,
      closeDialog,
      featureFlags,
      setAppState,
    } = props

    // FIXME: we currently only allow uploading a new caster, merge is not yet implemented
    // if (!force && currentSimulationCase.currentCaster) {
    //   openDialog(CasterHandleDataDialog.NAME)

    //   return
    // }

    closeDialog(CasterHandleDataDialog.NAME)

    if (!currentSimulationCase || !currentSimulationCase.id) {
      return
    }

    Util
      .openUploadFileDialog(
        '.xml',
        `/import/caster-xml/${currentSimulationCase.id}`,
        'patch',
        (formData: any) => {
          setAppState(AppState.Caster)
          IpcManager.both.send('setLoading', null, true, 'VisualizationIsLoading')
          resetReducer(false, true)
          resetAllElements()
          setError('CasterData')
          setFileUploadLoadingStatus('CasterDataIsLoading', true)

          formData.append('action', action)
          ThreeManager.cleanViews([ 'MainView', 'SectionView' ])
        },
        setLoadingStatus,
      )
      .then(() => {
        // setCurrentSimulationCase(simulationCase)

        setError('CasterData')
        setFileUploadLoadingStatus('CasterDataIsLoading', false)

        if (FeatureFlags.usesSlimVersion(featureFlags)) {
          CasterColumnLogic.openCaster(props)

          return
        }

        // FIXME: cleanup, put this in a separate function, network folder ...
        ApiClient
          .get(
            `${useConfig().apiBaseURL}/cases/${currentSimulationCase.id}`,
            { params: { withCurrentCaster: true } },
          )
          .then(({ case_ }) => {
            if (!case_) {
              throw new Error('No case found')
            }

            setCurrentSimulationCase(case_)
          })
          .catch((_response) => {
            // TODO: handle error
            // console.log(response)
          })
          .finally(() => {
            if (setLoadingStatus) {
              setLoadingStatus(false)
            }
          })
      })
      .catch(({ status }) => {
        if (setLoadingStatus) {
          setLoadingStatus(false)
        }

        enqueueSnackbar(
          'Error uploading xml file, please upload another',
          { variant: 'error', autoHideDuration: 4000 },
        )
        setError('CasterData', status)
        setFileUploadLoadingStatus('CasterDataIsLoading', false)
      })
  }
}
