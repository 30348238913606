/* eslint-env browser */

import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { v4 as uuid } from 'uuid'

import { createCaseVerification } from '@/api/case-verification'
import { getVisualizationConfig, getVisualizationConfigs, uploadVisualizationConfig } from '@/api/visualization-config'
import IpcManager from '@/IpcManager'
import { isVerified } from '@/logic/case-verification'
import Button from '@/react/components/Button'
import InfoMarker from '@/react/components/InfoMarker'
import VerifyButton from '@/react/components/VerifyButton'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState, VerificationType } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import * as VisualizationActions from '@/store/visualization/actions'
// TODO: move this file or its contents
import { DefaultState } from '@/types/state'
import { ArrayOfTranslations, Translation } from '@/types/translation'

import Logic from './Logic'
import { Hr, Label, Title } from './Styles'
import { EditConfigDialog } from '../EditConfigDialog'
import { Spacer } from '../OpenProjectDialog/Styles'

const T = 'projectDataDialog.processingParameters'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  darkTheme: state.application.main.darkTheme,
  currentCatalogId: state.data.currentCatalogId,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  error: state.application.error,
  uploadLoading: state.loading.uploadLoading,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  caseVerifications: state.application.main.caseVerifications,
  catalogList: state.data.catalogList,
}), {
  resetReducer: DataActions.resetReducer,
  openDialog: ApplicationActions.openDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setEditConfigId: ApplicationActions.setEditConfigId,
  setAppState: ApplicationActions.setAppState,
  setError: ErrorActions.setError,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setConfig: VisualizationActions.setConfig,
  addCaseVerification: ApplicationActions.addCaseVerification,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onClose: (isClick?: any) => void
  onChange: (event: any) => { type: string, name: string, value: string } | void
  onDeleteConfig: (type: string, key: string) => null | undefined
  processParametersButtonRef: any
  t: ArrayOfTranslations & Translation
}

type State = {
  loading: any
  selectedConfig: string | null
  configs: VisualizationConfig[]
}

export class ProcessParameters extends Component<Props, State> {
  public override state: State = {
    loading: {
      startProcessParameters: false,
      resetProcessParameters: false,
      openVis: false,
    },
    selectedConfig: null,
    configs: [] as VisualizationConfig[],
  }

  public override async componentDidMount () {
    const { visualizationMetaInformation, currentProject: { id } } = this.props

    const configs = await getVisualizationConfigs(id, AppState.ParamDashboard, { nameOnly: true }) ?? []

    this.setState({
      selectedConfig: visualizationMetaInformation?.[AppState.ParamDashboard]?.config ?? configs[0]?.id ?? null,
      configs,
    })
  }

  private readonly handleVerifyCasterData = async () => {
    // FIXME: doesnt work inmediately, it needs a reload with ctrl + r | does this still happen?

    const { currentProject, currentSimulationCase, addCaseVerification } = this.props

    const verification = await createCaseVerification(
      currentProject.id,
      currentSimulationCase.id,
      VerificationType.Caster,
      true,
    )

    if (!verification) {
      this.setState({
        loading: { openCaster: false },
        // error: response.error.status,
      })

      return
    }

    addCaseVerification(verification)
  }

  private readonly handleVerifyCatalogData = async () => {
    // FIXME: had to comment because i was reworking catalogs
    const { currentProject, currentSimulationCase, currentCatalogId, addCaseVerification } = this.props
    const selectedCatalog = currentCatalogId // ??
    // currentSimulationCase.casterCatalogList?.[0]?.catalogId ?? 'default'

    const verification = await createCaseVerification(
      currentProject.id,
      currentSimulationCase.id,
      VerificationType.Catalog,
      true,
      selectedCatalog,
    )

    if (!verification) {
      this.setState({
        loading: { openCaster: false },
        // error: response.error.status,
      })

      return
    }

    addCaseVerification(verification)
  }

  private readonly handleVerifyProcessingParameters = async () => {
    const { currentProject, currentSimulationCase, addCaseVerification } = this.props

    const verification = await createCaseVerification(
      currentProject.id,
      currentSimulationCase.id,
      VerificationType.Parameters,
      true,
    )

    if (!verification) {
      this.setState({
        loading: { startProcessParameters: false },
        // error: response.error.status,
      })

      return
    }

    addCaseVerification(verification)
  }

  private readonly handleDownloadConfig = async (key: string) => {
    const { configs } = this.state
    // FIXME: commandFiles do not exits in simulation case
    const { darkTheme, currentSimulationCase: { commandFiles } } = this.props

    let assignedCommands

    if (commandFiles && commandFiles.length) {
      assignedCommands = commandFiles[0].assignedCommands
    }

    const visualizationConfig = await getVisualizationConfig(key)

    if (!visualizationConfig) {
      return
    }

    IpcManager.both.send(
      'saveVisualizationConfig',
      JSON.stringify({ ...visualizationConfig.data, darkTheme, assignedCommands }),
      configs.filter(config => config.id === key)[0]?.name,
    )
  }

  private readonly handleEditConfig = (key?: string) => {
    const { openDialog, setEditConfigId } = this.props

    setEditConfigId(key)
    openDialog(EditConfigDialog.NAME)
  }

  private readonly handleOpenVisualization = async () => {
    const {
      setConfig,
      setAppState,
      currentSimulationCase,
      onChange,
      onClose,
    } = this.props

    let config = this.getSelectedConfig()

    this.setState({
      loading: {
        openVis: true,
      },
    })

    setAppState(AppState.Caster)

    if (!config || config === 'default') {
      const fileName = `config_${Date.now()}.json`
      const visData = {
        viewsObject: { [`view_${uuid()}`]: { dashboards: { [`dashboard_${uuid()}`]: { name: 'New Dashboard' } } } },
      }
      const file = new File([ JSON.stringify(visData) ], fileName, { type: 'text/plain', lastModified: Date.now() })

      const visualizationConfig = await uploadVisualizationConfig(
        file,
        currentSimulationCase.projectId,
        AppState.ParamDashboard,
      )

      if (!visualizationConfig) {
        // setError('openDashboard', error.status) // FIXME: error.status is undefined
        this.setState({ loading: { openVis: false } })

        return
      }

      // FIXME: do we need to set this here?
      // setCurrentSimulationCase(response.simulationCase)

      config = visualizationConfig.id
    }

    onChange({ target: { name: 'config', value: config } })

    const visualizationConfig = await getVisualizationConfig(config)

    if (!visualizationConfig) {
      // setError('openDashboard', error.status // FIXME: error.status is not defined

      this.setState({ loading: { openVis: false } })

      return
    }

    setAppState(AppState.ParamDashboard)
    setConfig(visualizationConfig.data)
    onClose()
  }

  private readonly handleChangeConfig = (event: any) => {
    const { onChange } = this.props
    const { value } = event.target

    if (value === 'add') {
      return onChange({ target: { name: 'config', value: 'add' } })
    }

    this.setState({
      selectedConfig: value,
    })
  }

  private readonly getSelectedConfig = () => {
    const { selectedConfig, configs } = this.state

    return Logic.getSelectedConfig(selectedConfig, configs)
  }
  
  public override render () {
    const { loading, configs } = this.state
    const {
      t,
      currentCatalogId,
      currentSimulationCase,
      error,
      featureFlags,
      uploadLoading,
      onDeleteConfig,
      processParametersButtonRef,
      caseVerifications,
      catalogList,
    } = this.props

    const { id: caseId, currentCasterId } = currentSimulationCase

    const selectedCatalog = Logic.getSelectedCatalog(currentCatalogId, catalogList)

    const isCasterVerified = isVerified(caseId, caseVerifications, VerificationType.Caster)
    const isCatalogVerified = isVerified(caseId, caseVerifications, VerificationType.Catalog, selectedCatalog)
    const isParametersVerified = isVerified(caseId, caseVerifications, VerificationType.Parameters)

    const visualizationEditor = FeatureFlags.canEditVisualization(featureFlags)
    const canUploadParameterDashboardConfig = FeatureFlags.canUploadParameterDashboardConfig(featureFlags)
    const canCreateParameterDashboardConfig = FeatureFlags.canCreateParameterDashboardConfig(featureFlags)

    const defaultSelector = canCreateParameterDashboardConfig
      ? {
        value: t(`projectDataDialog.visualization.config.${visualizationEditor ? 'default' : 'viewerDefault'}`),
        disabled: false,
      }
      : null

    const configSelectors = Logic.getSelectors(
      configs,
      'id',
      'name',
      defaultSelector,
    )

    const processParametersDisabled = (configSelectors.length - (defaultSelector ? 1 : 0)) === 0

    if (canUploadParameterDashboardConfig) {
      configSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t('projectDataDialog.visualization.config.add'),
        disabled: !visualizationEditor,
      })
    }

    const casterDisabled = !currentCasterId || uploadLoading.CasterDataIsLoading
    const catalogDisabled = !catalogList?.length || uploadLoading.CasterDataIsLoading

    return (
      <Form $maxWidth='300px' $padding='18px 28px 20px'>
        <Title>
          {t(`${T}.title`)}
          <InfoMarker message={t(`${T}.info`, { returnObjects: true }).join('\n')} x={224} y={-23} />
        </Title>
        <Label>{t(`${T}.verify.label`)}</Label>
        <VerifyButton
          label={t(`${T}.verify.caster.label`)}
          title={
            isCasterVerified
              ? t(
                `${T}.verify.caster.verified`,
                {
                  name: 'n/a',
                  dateTime: 'n/a',
                },
              // FIXME: get user name and date for verification
              // {
              //   name: latestCasterVerification.authorName,
              //   dateTime: TimeUtil.getDisplayDateTime(latestCasterVerification.createdAt),
              // },
              )
              : (casterDisabled
                ? t(`${T}.verify.caster.disabled`)
                : t(`${T}.verify.caster.title`))
          }
          isVerified={isCasterVerified}
          disabled={casterDisabled || !FeatureFlags.canVerifyCaster(featureFlags)}
          onChange={this.handleVerifyCasterData}
        />
        <VerifyButton
          label={t(`${T}.verify.catalog.label`)}
          title={
            isCatalogVerified
              ? t(
                `${T}.verify.catalog.verified`,
                {
                  name: 'n/a',
                  dateTime: 'n/a',
                },
              // FIXME: get user name and date for verification
              // {
              //   name: latestCatalogVerification.authorName,
              //   dateTime: TimeUtil.getDisplayDateTime(latestCatalogVerification.createdAt),
              // },
              )
              : (catalogDisabled
                ? t(`${T}.verify.catalog.disabled`)
                : t(`${T}.verify.catalog.title`))
          }
          isVerified={isCatalogVerified}
          disabled={catalogDisabled || !FeatureFlags.canVerifyCatalogFile(featureFlags)}
          onChange={this.handleVerifyCatalogData}
        />
        <VerifyButton
          label={t(`${T}.verify.processingParameters.label`)}
          title={
            isParametersVerified
              ? t(
                `${T}.verify.processingParameters.verified`,
                {
                  name: 'n/a',
                  dateTime: 'n/a',
                },
              // FIXME: get user name and date for verification
              // {
              //   name: latestParametersVerification.authorName,
              //   dateTime: TimeUtil.getDisplayDateTime(latestParametersVerification.createdAt),
              // },
              )
              : t(`${T}.verify.processingParameters.title`)
          }
          isVerified={isParametersVerified}
          onChange={this.handleVerifyProcessingParameters}
          disabled={processParametersDisabled || !FeatureFlags.canVerifyProcessParameter(featureFlags)}
        />
        <Spacer $h={49} $br />
        <Hr />
        <Input
          name='config'
          type='select'
          title={
            error.config
              ? t([ `error.${error.config}`, 'error.default' ])
              : t('projectDataDialog.visualization.config.title')
          }
          error={error.config && t([ `error.${error.config}`, 'error.default' ])}
          value={this.getSelectedConfig()}
          selectors={configSelectors}
          onChange={this.handleChangeConfig}
          onDelete={FeatureFlags.canDeleteParameterDashboardConfig(featureFlags) && onDeleteConfig}
          onDownload={FeatureFlags.canDownloadParameterDashboard(featureFlags) && this.handleDownloadConfig}
          onEdit={FeatureFlags.canEditParameterDashboard(featureFlags) && this.handleEditConfig}
        />
        <Button
          onClick={this.handleOpenVisualization}
          loading={loading.openVis}
          error={error.openDashboard ? t([ `error.${error.openDashboard}`, 'error.default' ]) : ''}
          title={
            error.openDashboard
              ? t([ `error.${error.openDashboard}`, 'error.default' ])
              : t(`${T}.openDashboard.title`)
          }
          hideError
          ref={processParametersButtonRef}
          disabled={!FeatureFlags.canViewParameterDashboard(featureFlags)}
        >
          {t(`${T}.openDashboard.label`)}
        </Button>
      </Form>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(ProcessParameters)
