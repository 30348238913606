/* eslint-env browser */

import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { getVisualizationConfig } from '@/api/visualization-config'
import { useConfig } from '@/config'
import Button from '@/react/components/Button'
import { ConfirmWrapper } from '@/react/components/Button/styles'
import { Spacer } from '@/react/dialogs/project/OpenProjectDialog/Styles'
import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import { Form, Text } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import { ViewLogic } from '@/react/visualization/dashboard/ViewLogic'
import VisUtil from '@/react/visualization/VisUtil'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import { getElementMapsObject } from '@/store/elements/logic'
import * as VisualizationActions from '@/store/visualization/actions'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeUtil from '@/three/logic/Util'
import type { FilterableElementType } from '@/types/filter'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import BaseDialog from '../BaseDialog'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  appState: state.application.main.appState,
  editConfigId: state.application.main.editConfigId,
  selectedPaths: state.data.selectedPaths,
  visualizationData: state.visualization.data,
  term: state.filter.term,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  currentSimulationCase: state.application.main.currentSimulationCase,
  timestamps: state.timestamps,
  ...getElementMapsObject(state),
}), {
  setDataSources: VisualizationActions.setDataSources,
  setConfig: VisualizationActions.setConfig,
  closeDialog: ApplicationActions.closeDialog,
  setEditConfigId: ApplicationActions.setEditConfigId,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: boolean | string | null
  previousName: string
  name: string
  error: string
  loading: boolean
  previousSelectedSet: string | null
  selectedSet: string
  previousSelectedX: string | null
  selectedX: string
  previousSelectedY: string | null
  selectedY: string
  selectedDataSource: string
  xInputWarning: boolean
  yInputWarning: boolean
  verticalLines: string
  isValid: boolean
}

const T = 'manageDynamicDataSourcesDialog'

export class ManageDynamicDataSourcesDialog extends Component<Props, State> {
  @Identifiable('ManageDynamicDataSourcesDialog') public static readonly NAME: string

  public override state: State = {
    previousName: '',
    name: '',
    error: '',
    loading: false,
    selectedSet: 'allElements',
    selectedX: 'default',
    selectedY: 'default',
    selectedDataSource: 'new',
    previousSelectedSet: null,
    previousSelectedX: null,
    previousSelectedY: null,
    xInputWarning: false,
    yInputWarning: false,
    verticalLines: '',
    isValid: false,
  }
  
  public override componentDidMount () {
    const { Caster, closeDialog, t } = this.props

    if (!Caster) {
      enqueueSnackbar(t(`${T}.casterNeeded`), { autoHideDuration: 3000, variant: 'info' })

      closeDialog(ManageDynamicDataSourcesDialog.NAME)

      // TODO: project dialog?

      return
    }

    // this.handleInit()

    hotkeys('Escape', this.handleClose)
  }
  
  public override componentDidUpdate (_prevProps: Props, prevState: State) {
    // this.handleInit()
    this.updateIsValid(prevState, this.state)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // private readonly handleInit = () => {
  //   // TODO: do we need to save the data with the caster or with the vis config?
  // }

  private readonly handleClose = () => {
    const { closeDialog, setEditConfigId } = this.props

    closeDialog(ManageDynamicDataSourcesDialog.NAME)
    setEditConfigId()
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  private readonly handleKeyDown = (event: any) => {
    if (event.keyCode === 13 && this.state.isValid) {
      this.handleSubmit()
    }
  }

  private readonly handleSubmit = async () => {
    const { name, selectedSet, selectedX, selectedY, selectedDataSource } = this.state
    const { term, visualizationMetaInformation, plotConfigs, setDataSources } = this.props
    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    this.setState({
      loading: true,
    })

    const isVerticalLine = selectedX === 'verticalLine' || selectedY === 'verticalLine'

    try {
      let dataSources: any

      if (selectedDataSource === 'new') {
        const elements = selectedSet === 'currentFilter' || selectedSet === 'allElements' ? [] : this.getElementPaths()
        const filter = selectedSet === 'currentFilter' ? term : null

        const res = await ApiClient.post(
          `${useConfig().apiBaseURL}/dynamic-data-sources`,
          {
            data: {
              name,
              selectedSet,
              selectedX,
              selectedY,
              elements,
              filter,
              isVerticalLine,
              visualizationConfigId: config,
            },
          },
        )

        dataSources = [ ...Object.values(plotConfigs), res ]
      }
      else {
        // split because the id is in format: config_${dataSourceId}
        const dataSourceId = plotConfigs[selectedDataSource].id?.split('_')[1]
        const previousFilter = plotConfigs[selectedDataSource].filter

        if (!dataSourceId) {
          // eslint-disable-next-line no-console
          console.error('Error: dataSourceId is undefined')

          return
        }

        const elements =
          selectedSet === 'previousFilter' || selectedSet === 'currentFilter' || selectedSet === 'allElements'
            ? []
            : this.getElementPaths()

        const filter = selectedSet === 'currentFilter'
          ? term
          : selectedSet === 'previousFilter'
            ? previousFilter
            : null

        const res = await ApiClient.patch(
          `${useConfig().apiBaseURL}/dynamic-data-sources/${dataSourceId}`,
          { data: { name, selectedSet, selectedX, selectedY, elements, filter, isVerticalLine } },
        )

        const oldDataSource = Object.values(plotConfigs).find((config) => config.id === dataSourceId)
        const newDataSource = { ...oldDataSource, ...res }

        dataSources = [ ...Object.values(plotConfigs).filter((config) => config.id !== dataSourceId), newDataSource ]
      }

      setDataSources(dataSources)
      this.handleClose()
    }
    catch (response: any) {
      // eslint-disable-next-line no-console
      console.log(response)

      const { error } = response

      this.setState({
        error: error ? error.status : 'unknown',
        loading: false,
      })
    }
  }

  private readonly handleSetChange = (event: any) => {
    const { value } = event.target

    const { previousSelectedX, previousSelectedY } = this.state
    const previous = value.includes('previous')
    const newSelectedX = previous && previousSelectedX ? previousSelectedX : 'default'
    const newSelectedY = previous && previousSelectedY ? previousSelectedY : 'default'

    this.setState({
      selectedSet: value,
      selectedX: newSelectedX,
      selectedY: newSelectedY,
      xInputWarning: false,
      yInputWarning: false, 
    })
  }

  private readonly handleXChange = (event: any) => {
    const { value } = event.target

    const elementPaths = this.getElementPaths()
    const allAttributes = this.getAttributes(elementPaths)

    const showXWarning = !(value === 'default' || value === 'verticalLine' || value.includes('dataOverTime')) &&
      !allAttributes.find(attr => attr.key === value)

    this.setState({ selectedX: value, xInputWarning: showXWarning })
  }

  private readonly handleYChange = (event: any) => {
    const { value } = event.target

    const elementPaths = this.getElementPaths()
    const allAttributes = this.getAttributes(elementPaths)
    const showYWarning = value !== 'default' &&
    !allAttributes.find(attr => attr.key === value) &&
    !value.endsWith('|all')

    this.setState({ selectedY: value, yInputWarning: showYWarning })
  }

  private readonly handleChosenDataSourceChange = (event: any) => {
    const { plotConfigs } = this.props

    const { value } = event.target 

    const originalConfig = plotConfigs[value] ?? {}

    if (value === 'new' || value === '') {
      this.setState({
        selectedDataSource: value ?? 'new',
        previousSelectedSet: null,
        previousSelectedX: null,
        previousSelectedY: null,
        previousName: '',
        selectedSet: 'allElements',
        selectedX: 'default',
        selectedY: 'default',
        xInputWarning: false,
        yInputWarning: false,
      })
    }
    else {
      // check if there are elements that have the requested values
      let newSelectedSet = 'allElements'

      if (originalConfig.selectedSet === 'currentFilter') {
        newSelectedSet = 'previousFilter'
      }

      this.setState({
        selectedDataSource: value,
        previousSelectedSet: originalConfig.selectedSet,
        previousSelectedX: originalConfig.selectedX,
        previousSelectedY: originalConfig.selectedY,
        previousName: originalConfig.name,
        name: originalConfig.name,
        selectedSet: newSelectedSet,
        selectedX: originalConfig.selectedX,
        selectedY: originalConfig.selectedY,
        xInputWarning: false,
        yInputWarning: false,
      })
    }
  }

  private readonly handleSourceClick = () => {
    const { selectedDataSource } = this.state
    const { plotConfigs } = this.props
    const filter = plotConfigs[selectedDataSource]?.filter

    if (filter) {
      navigator.clipboard.writeText(filter)
    }
  }

  private readonly handleDeleteDataSource = async (inputName: string, plotConfigId: string) => {
    const { plotConfigs, setConfig, setDataSources, visualizationMetaInformation } = this.props
    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    const dataSourceId = plotConfigs[plotConfigId]?.dataSourceId

    if (!dataSourceId) {
      enqueueSnackbar('Error deleting data source', { variant: 'error', autoHideDuration: 4000 })

      return
    }

    try {
      await ApiClient.del(`${useConfig().apiBaseURL}/dynamic-data-sources/${dataSourceId}`)

      const visualizationConfig = await getVisualizationConfig(config)

      if (!visualizationConfig) {
        return
      }

      setConfig(visualizationConfig.data)
      setDataSources(visualizationConfig.dataSources ?? [])
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error)
      enqueueSnackbar('Error deleting data source', { variant: 'error', autoHideDuration: 4000 })
    }
  }

  private readonly getElementPaths = (newSet?: string): any[] => {
    const { selectedSet, selectedDataSource } = this.state
    const { selectedPaths, term, plotConfigs } = this.props
    const elementMaps = getElementMapsObject(this.props)

    if (!this.props.Caster) {
      return []
    }

    const set = newSet ?? selectedSet

    switch (set) {
      case 'allElements':
        return FilterHandler.getAllElementPaths(elementMaps)
      case 'currentSelection':
        return Array.from(selectedPaths) // TODO: maybe the rest of the logic can change to using Set as well
      case 'currentFilter':
      case 'previousFilter':
        {
          const filteredElements = FilterHandler.getFilteredElements(
            elementMaps,
            set === 'currentFilter' ? term : plotConfigs[selectedDataSource].filter,
            false,
          ) as Record<string, FilterableElementType>

          return Object
            .entries(filteredElements)
            .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
            .map(([ path ]) => path)
        }

        break

      default:
        return []
    }
  }

  private readonly getAttributes = (elementPaths: string[]) => {
    const elementMaps = getElementMapsObject(this.props)
    const attributes = []
    const addedAttributes: any[] = []

    for (const path of elementPaths ?? []) {
      const type = ThreeUtil.getElementInfo(path).type
      const element = ElementMapsUtil.getFullCasterElementByPath(path, elementMaps)

      if (!element) {
        continue
      }

      const elementWithAdditionalDataSpread = {
        ...element,
        ...(element.additionalData ?? {}),
      }

      ViewLogic.cleanElementKeys(elementWithAdditionalDataSpread, type)

      const attributeKeys = Object.keys(elementWithAdditionalDataSpread)

      for (const key of attributeKeys) {
        const completeKey = `${type}|${key}`

        if (!addedAttributes.includes(completeKey)) {
          addedAttributes.push(completeKey)
          attributes.push({ key: completeKey, value: key, group: type })
        }
      }
    }
    
    attributes.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    return attributes
  }

  private readonly updateIsValid = (prevState: State, currentState: State) => {
    // compare previous and current state keys used in the updateIsValid function to avoid unnecessary re-renders
    const keys = [
      'name',
      'selectedSet',
      'selectedX',
      'selectedY',
      'selectedDataSource',
      'xInputWarning',
      'yInputWarning',
    ]
    const changedKeys = keys.filter(key => prevState[key] !== currentState[key])

    if (changedKeys.length === 0) {
      return
    }

    const { name, selectedSet, selectedX, selectedY, xInputWarning, yInputWarning, selectedDataSource } = currentState

    const condition = Boolean(name) && selectedSet !== 'default' && selectedX !== 'default' && selectedY !== 'default'

    if (selectedDataSource !== 'new') {
      if (xInputWarning || yInputWarning) {
        return false
      }

      const elementPaths = this.getElementPaths()
      const allAttributes = this.getAttributes(elementPaths)

      const { previousName, previousSelectedSet, previousSelectedX, previousSelectedY } = this.state

      let selectedSetChanged: boolean

      if (previousSelectedSet === 'currentFilter') {
        selectedSetChanged = selectedSet !== 'previousFilter'
      }
      else {
        selectedSetChanged = selectedSet === previousSelectedSet
      }

      const showXWarning = !(selectedX === 'default' || selectedX === 'verticalLine') &&
        !allAttributes.find(attr => attr.key === selectedX)

      const showYWarning = selectedY !== 'default' && !allAttributes.find(attr => attr.key === selectedY)

      const valid = condition &&
        !showXWarning &&
        !showYWarning &&
        (name !== previousName ||
          selectedSetChanged ||
          previousSelectedX !== selectedX ||
          previousSelectedY !== selectedY)

      // if values are the same, do not update the state
      if (
        this.state.isValid === valid &&
            this.state.xInputWarning === showXWarning &&
            this.state.yInputWarning === showYWarning
      ) {
        return
      }

      this.setState({ isValid: valid, xInputWarning: showXWarning, yInputWarning: showYWarning })
    }

    if (this.state.isValid === condition) {
      return
    }
    
    this.setState({ isValid: condition })
  }

  private readonly getSetSelectors = (setSelectors: any[]) => {
    const { selectedDataSource } = this.state
    const { plotConfigs } = this.props

    if (selectedDataSource === 'new') {
      return setSelectors
    }

    const config = plotConfigs[selectedDataSource]
    const previousSet = config.selectedSet

    switch (previousSet) {
      case 'allElements':
        return setSelectors
      case 'currentFilter':
        return [ ...setSelectors, { key: 'previousFilter', value: 'Previous Filter' } ]
      default:
        return setSelectors
    }
  }

  private readonly getXSelectors = (xSelectors: Selector[]) => {
    const { previousSelectedX } = this.state

    if (!previousSelectedX) {
      return xSelectors
    }

    let [ group, value ] = this.getPreviousSelectionSelector(previousSelectedX)

    if (previousSelectedX === 'verticalLine') {
      group = ' '
      value = previousSelectedX
    }

    if (!xSelectors.find(selector => selector.key === previousSelectedX)) {
      return [
        ...xSelectors,
        { group, value, key: previousSelectedX, disabled: true },
      ]
    }

    return xSelectors
  }

  private readonly getYSelectors = (ySelectors: Selector[]) => {
    const { previousSelectedY, selectedX } = this.state

    const [ selectedType, selectedXKey ] = selectedX.split('|')

    if (selectedXKey === 'dataOverTime' && !ySelectors.find(selector => selector.key === 'all')) {
      ySelectors.push({ key: `${selectedType}|all`, value: 'All (table)', group: selectedType })
    }

    if (!previousSelectedY) {
      return ySelectors
    }

    const [ group, value ] = this.getPreviousSelectionSelector(previousSelectedY)

    if (!ySelectors.find(selector => selector.key === previousSelectedY)) {
      return [
        ...ySelectors,
        { group, value, key: previousSelectedY, disabled: true },
      ]
    }

    return ySelectors
  }

  private readonly getPreviousSelectionSelector = (selector: string) => {
    if (selector === 'verticalLine') {
      return selector
    }

    return selector.replace('_', '').split('|')
  }

  // TODO: handle duplicate names, show message and confirm override
  
  public override render () {
    const {
      name,
      error,
      loading,
      selectedSet,
      selectedX,
      selectedY,
      xInputWarning,
      yInputWarning,
    } = this.state
    const {
      t,
      plotConfigs,
      featureFlags,
      visualizationMetaInformation,
      appState,
      currentSimulationCase, 
      term,
    } = this.props

    const setSelectors = [
      // { key: 'default', value: t(`${T}.setSelector.default`) },
      { key: 'allElements', value: t(`${T}.setSelector.allElements`) },
      { key: 'currentFilter', value: t(`${T}.setSelector.currentFilter`) },
      // { key: 'currentSelection', value: t(`${T}.setSelector.currentSelection`) },
    ]

    const elementPaths = this.getElementPaths()
    const allAttributes = this.getAttributes(elementPaths)

    const selectedXAttr = allAttributes.find(attr => attr.key === selectedX)
    const selectedYAttr = allAttributes.find(attr => attr.key === selectedY)

    const xSelectors = [
      { key: 'default', value: t(`${T}.xSelector.default`), group: ' ' },
      ...(
        !selectedYAttr
          ? allAttributes
          : allAttributes.filter(attr => attr.group === selectedYAttr.group && attr.key !== selectedYAttr.key)
      ),
    ]

    const allXSelectors = this.getXSelectors(xSelectors)

    if (currentSimulationCase?.blueprintId && term.includes('realDataUUID') && elementPaths.length === 1) {
      const { type } = ThreeUtil.getElementInfo(elementPaths[0])
      const dateKey = 'dataOverTime'
      const selector = { key: `${type}|${dateKey}`, value: dateKey, group: ' ' }

      allXSelectors.push(selector)
    }

    if (allXSelectors.length > 1 && !allXSelectors.find(selector => selector.key === 'verticalLine')) {
      allXSelectors.splice(1, 0, { key: 'verticalLine', value: 'verticalLine', group: ' ' })
    }

    const ySelectors = [
      { key: 'default', value: t(`${T}.ySelector.default`), group: ' ' },
      ...(
        !selectedXAttr
          ? allAttributes
          : allAttributes.filter(attr => attr.group === selectedXAttr.group && attr.key !== selectedXAttr.key)
      ),
    ]
    const allYSelectors = this.getYSelectors(ySelectors)

    const configIds = Object.keys(plotConfigs ?? {})

    const allDataSources = []

    allDataSources.push(...configIds.reduce((acc: any[], plotConfigId: string) => {
      const config = plotConfigs[plotConfigId]

      if (config.group !== 'dynamicDataSource') {
        return acc
      }

      const { name, _id } = config
      const { value, isVerticalLine } = VisUtil.getConfigInfo(name, _id, _id, 'dynamicDataSource', config)

      return [
        ...acc,
        { key: plotConfigId, value: isVerticalLine ? `(VL) ${value}` : value },
      ]
    }, []))

    // sort data sources alphabetically case unsensitive
    allDataSources.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    if (FeatureFlags.canAddDynamicData(featureFlags, visualizationMetaInformation, appState)) {
      allDataSources.unshift({ key: 'new', value: 'New Dynamic Data Source' })
    }

    const currentDataSourceInfo = plotConfigs[this.state.selectedDataSource]?.filter ?? ''
    const disableEdition = this.state.selectedDataSource !== 'new' &&
      !FeatureFlags.canEditDynamicData(featureFlags, visualizationMetaInformation, appState)

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-server'
        header={t(`${T}.header`)}
        headerWidth='300px'
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='dynamicDataSources'
            type='select'
            label='Dynamic Data'
            title={currentDataSourceInfo}
            value={this.state.selectedDataSource}
            selectors={[ ...allDataSources ]}
            onDelete={
              FeatureFlags.canDeleteDynamicData(featureFlags, visualizationMetaInformation, appState) &&
              this.handleDeleteDataSource
            }
            onChange={this.handleChosenDataSourceChange}
            onLabelRightClick={this.handleSourceClick}
          />
          <Input
            label={t(`${T}.name.label`)}
            title={t(`${T}.name.label`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            disabled={disableEdition}
          />
          <Input
            name='set'
            type='select'
            label={t(`${T}.setSelector.label`)}
            title={t(`${T}.setSelector.title`)}
            value={selectedSet}
            selectors={this.getSetSelectors(setSelectors)}
            onChange={this.handleSetChange}
            disabled={disableEdition}
          />
          <Spacer $h={5} $br />
          {
            (elementPaths && elementPaths.length > 0)
              ? <Text>{t(`${T}.setSelector.elements`, { elements: elementPaths.length })}</Text>
              : <Text>{t(`${T}.setSelector.noElements`)}</Text>
          }
          <Input
            name='x'
            type='select'
            label={t(`${T}.xSelector.label`)}
            title={t(`${T}.xSelector.title`)}
            value={selectedX}
            selectors={allXSelectors}
            onChange={this.handleXChange}
            disabled={(selectedSet.includes('previous') && xSelectors.length <= 1) || disableEdition}
          />
          <Spacer $h={5} $br />
          {
            xInputWarning
              ? <Text style={{ color: 'yellow' }}>The set has no elements</Text>
              : null
          }
          <Input
            name='y'
            type='select'
            label={t(`${T}.ySelector.label`)}
            title={t(`${T}.ySelector.title`)}
            value={selectedY}
            selectors={allYSelectors}
            onChange={this.handleYChange}
            disabled={(selectedSet.includes('previous') && ySelectors.length <= 1) || disableEdition}
          />
          <Spacer $h={5} $br />
          {
            yInputWarning
              ? <Text style={{ color: 'yellow' }}>The set has no elements</Text>
              : null
          }
          <ConfirmWrapper>
            <Button
              title={this.state.selectedDataSource === 'new' ? 'Create' : 'Edit'}
              type='primary'
              disabled={!this.state.isValid}
              onClick={this.handleSubmit} // see if this.state.selectedDataSource is 'New ..' if is, post , else patch
              error={error}
              loading={loading}
              isRef
              half
            >
              {this.state.selectedDataSource === 'new' ? t(`${T}.save`) : 'Update'}
            </Button>
            <Button
              value=''
              onClick={this.handleClose}
              title={t(`${T}.cancel`)}
              half
            >
              {t(`${T}.cancel`)}
            </Button>
          </ConfirmWrapper>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(ManageDynamicDataSourcesDialog)

export default hoistStatics(composedComponent, ManageDynamicDataSourcesDialog)
