import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import InputField from './InputField'
import { FromCompareCasterField } from '../InputStyles'

const connector = connect((state: DefaultState) => ({
  term: state.filter.term,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  hideValue?: boolean
  name: string
  label: any
  elementType: string
  value: string
  t: Translation
  key: any
  elementKey?: string
  error?: boolean
}

export class CompareCasterInput extends Component<Props> {
  private textInput: any = null

  private readonly setTextInputRef = (element: any) => {
    this.textInput = element
  }

  private readonly focusTextInput = () => {
    if (this.textInput) {
      this.textInput.focus()
      this.textInput.select()
    }
  }

  public override render () {
    const {
      value,
      name,
      hideValue,
      label,
      t,
    } = this.props
    const multipleValues = value === t('allInOne.multiValue')
    const shownValue = multipleValues ? '' : value
    const placeholder = (multipleValues && !hideValue) ? 'Multi-Value' : (typeof label === 'string' ? label : name)

    return (
      <FromCompareCasterField>
        <InputField
          isDisabled
          massValue={hideValue ? undefined : shownValue ?? ''}
          placeholder={placeholder}
          onChange={() => null}
          name={t(name)}
          inputRef={this.setTextInputRef}
          style={
            {
              maxWidth: '57px',
              paddingRight: '3px',
              marginRight: '10px',
            }
          }
        />
      </FromCompareCasterField>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(CompareCasterInput)
