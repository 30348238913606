import hoistStatics from 'hoist-non-react-statics'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { ThemeProvider } from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import StyleConfig from '@/react/visualization/dashboard/config/StyleConfig'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { T } from './Logic'
import Nozzles from './Nozzles'
import StrandGuide from './StrandGuide'
import { Title, Wrapper } from './styles'

const connector = connect((state: DefaultState) => ({
  darkTheme: state.application.main.darkTheme,
  authenticationData: state.application.main.authenticationData,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string
  uploadType: string
}

export class InputDataDialog extends Component<Props, State> {
  @Identifiable('InputDataDialog') public static readonly NAME: string

  public override state: State = {
    uploadType: 'strandGuide',
  }

  private readonly handleChangeUploadType = (event: any) =>
    this.setState({ [event.target.name as string]: (event.target.value as string) })
  
  public override render () {
    const { uploadType } = this.state
    const { darkTheme, authenticationData: { featureFlags }, t } = this.props
    let usedUploadType = uploadType

    const canEditRoller = FeatureFlags.canEditRoller(featureFlags)
    const canEditNozzles = FeatureFlags.canEditNozzle(featureFlags)

    const uploadTypeSelectors = []

    if (canEditRoller) {
      uploadTypeSelectors.push({ key: 'strandGuide', value: t(`${T}.uploadType.strandGuide`) })
    }

    if (canEditNozzles) {
      uploadTypeSelectors.push({ key: 'nozzles', value: t(`${T}.uploadType.nozzles`) })
    }

    if (uploadTypeSelectors.length === 1) {
      usedUploadType = uploadTypeSelectors[0].key
    }

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <Wrapper>
          <Title>{t(`${T}.title`)}</Title>
          <Input
            name='uploadType'
            type='select'
            label={t(`${T}.uploadType.label`)}
            title={t(`${T}.uploadType.title`)}
            value={usedUploadType}
            selectors={uploadTypeSelectors}
            onChange={this.handleChangeUploadType}
          />
          {canEditRoller && uploadType === 'strandGuide' && <StrandGuide />}
          {canEditNozzles && uploadType === 'nozzles' && <Nozzles />}
        </Wrapper>
      </ThemeProvider>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(InputDataDialog)

export default hoistStatics(composedComponent, InputDataDialog) 
