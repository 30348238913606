import * as THREE from 'three'

import { StrandSides } from '@/types/elements/enum'
import { Mapping } from '@/Util/mapping/Mapping'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import ThreeRoller from './Roller'
import LodUtil from '../logic/LodUtil'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  rollerBodyPlane: THREE.Mesh
  rollerGroupText: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
  rollerGroupBacksideText: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
  rollerBodyLine: THREE.Line
  [LodUtil.LOD_ROLLER_BODY]: THREE.LOD
}

export default class ThreeRollerBody extends BaseObject<RollerBodySlot, RollerBodyMountLog, ThreeRoller, Objects> {
  private static Geometry3DCache: Record<string, THREE.CylinderGeometry> = {}

  private static LineGeometry2DCache: Record<string, THREE.BufferGeometry> = {}

  private static PlaneGeometry2DCache: Record<string, THREE.PlaneGeometry> = {}

  private static readonly defaultPlaneSectionView = new THREE.MeshBasicMaterial({
    color: '#3a6ce0',
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
  })

  private static readonly defaultLineSectionView = new THREE.LineBasicMaterial({ color: '#4ffaff' })

  private static readonly defaultMaterial1 = new THREE.MeshStandardMaterial({
    color: '#3a6ce0',
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly defaultMaterial2 = new THREE.MeshStandardMaterial({
    color: '#94dfe0',
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly shimAppliedMaterial1 = new THREE.MeshStandardMaterial({
    color: '#da9e3f',
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly shimAppliedMaterial2 = new THREE.MeshStandardMaterial({
    color: '#d8b990',
    roughness: 0.5,
    metalness: 0.5,
  })

  // eslint-disable-next-line max-len
  private static readonly phantomMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    transparent: true,
    opacity: 0.4,
    roughness: 0.5,
    metalness: 0.5,
  })

  // eslint-disable-next-line max-len
  private static readonly selectedMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    roughness: 0.5,
    metalness: 0.5,
  }) // , transparent: true, opacity: 0.75

  private static readonly selectedMaterial2d = new THREE.MeshBasicMaterial({ color: '#7eda41' })
  // , transparent: true, opacity: 0.75

  // eslint-disable-next-line max-len
  private static readonly deletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da131b',
    transparent: true,
    opacity: 0.6,
    roughness: 0.5,
    metalness: 0.5,
  })

  // eslint-disable-next-line max-len
  private static readonly selectedDeletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da4515',
    transparent: true,
    opacity: 0.6,
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly DetailDistance = [ 0, 3, 8 ]

  private static get3DGeometry (radius: number, width: number, i: number, isPhantom?: boolean) {
    const radial = isPhantom ? 9 : 12 - i * 3

    const geometryKey = `${radius}_${width}_${radial}`
    let geometry = ThreeRollerBody.Geometry3DCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.CylinderGeometry(radius, radius, width, radial) // no Buffer!
      geometry.rotateZ(90 * Util.RAD)

      geometry = Util.getSmoothedGeometry(geometry, 80)

      ThreeRollerBody.Geometry3DCache[geometryKey] = geometry
    }

    return geometry
  }

  private static get2DGeometry (bodyWidthStart: number, bodyWidth: number, diameter: number) {
    const lineGeometryKey = `${bodyWidthStart}_${bodyWidth}_${diameter}`
    const planeGeometryKey = `${bodyWidth}_${diameter}`

    let lineGeometry = ThreeRollerBody.LineGeometry2DCache[lineGeometryKey]
    let planeGeometry = ThreeRollerBody.PlaneGeometry2DCache[planeGeometryKey]

    if (!lineGeometry) {
      lineGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      ])

      // TODO: remove old code
      // lineGeometry = new (THREE as any).Geometry()

      // ;(lineGeometry as any).vertices.push(
      //   new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      //   new THREE.Vector3(bodyWidthStart, diameter / 2, 0),
      //   new THREE.Vector3(bodyWidth + bodyWidthStart, diameter / 2, 0),
      //   new THREE.Vector3(bodyWidth + bodyWidthStart, -diameter / 2, 0),
      //   new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      // )

      ThreeRollerBody.LineGeometry2DCache[lineGeometryKey] = lineGeometry
    }

    if (!planeGeometry) {
      planeGeometry = new THREE.PlaneGeometry(bodyWidth, diameter)

      ThreeRollerBody.PlaneGeometry2DCache[planeGeometryKey] = planeGeometry
    }

    return { lineGeometry, planeGeometry }
  }

  private clickableObjects: any

  private readonly parentContainer: any

  private isPhantom?: boolean

  private sectionDetail?: any

  private isSegmentGroupIDEven?: any

  private isSegmentGroupShimApplied = false

  private width?: number

  private startWidth?: number

  private radius?: number

  public constructor (container: any, parent: any, clickableObjects: any, phantomGroup?: THREE.Group) {
    super(container, parent)

    this.clickableObjects = clickableObjects
    this.parentContainer = phantomGroup ?? parent.objects.roller

    if (phantomGroup) {
      const parentRoller = this.parent.objects.roller
      const parentPosition = parentRoller.position
      const parentRotation = parentRoller.rotation

      this.parentContainer.position.copy(parentPosition)
      this.parentContainer.rotation.copy(parentRotation)
    }
  }

  public override dispose (): void {
    super.dispose()

    if (!this.container || !this.container.children || !this.clickableObjects) {
      return
    }

    for (const child of this.container.children) {
      if (!(child instanceof THREE.Mesh)) {
        continue
      }

      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      this.container.remove(child)
    }

    for (const child of this.clickableObjects) {
      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      if (child.parent) {
        child.parent.remove(child)
      }
    }

    this.clickableObjects = []
    this.objects = {} as Objects
  }

  private getRegularBodyColor () {
    return this.isSegmentGroupIDEven
      ? this.isSegmentGroupShimApplied ? ThreeRollerBody.shimAppliedMaterial1 : ThreeRollerBody.defaultMaterial1
      : this.isSegmentGroupShimApplied
      ? ThreeRollerBody.shimAppliedMaterial2
      : ThreeRollerBody.defaultMaterial2
  }

  protected override internalSetValues (data: SetValuesData<RollerBodySlot, RollerBodyMountLog>) {
    super.internalSetValues(data)

    const { elementData, view } = data

    this.isPhantom = data.isPhantom
    this.sectionDetail = view.sectionDetail

    const parentInfo = Util.getParentInfo(this.parent.container.userData.path)
    const segmentGroupMountLogUUID = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[parentInfo?.id]
    const segmentGroupMountLog = view.elementMaps.SegmentGroupMountLog[segmentGroupMountLogUUID]

    this.isSegmentGroupShimApplied = segmentGroupMountLog?.shimApplied ?? false

    if (this.sectionDetail) {
      this.renderSectionDetail(elementData, data.path)
    }
    else {
      this.renderNormal(elementData, data.path, data.isDeleted, data.isHidden)
    }

    this.setSelected((this.objects[LodUtil.LOD_ROLLER_BODY] || this.objects.rollerBodyPlane).userData.selected)
  }

  protected override getValuesHash (data: SetValuesData<RollerBodySlot, RollerBodyMountLog>): string | null {
    const { elementData, view } = data
    const { slotHash, mountLogHash } = elementData
    const parentInfo = Util.getParentInfo(this.parent.container.userData.path)

    if (!slotHash || !mountLogHash) {
      return null
    }

    const segmentGroupMountLogUUID = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[parentInfo?.id] ?? ''
    const segmentGroupMountLog = view.elementMaps.SegmentGroupMountLog[segmentGroupMountLogUUID]
    const shimApplied = segmentGroupMountLog?.shimApplied ?? false
    const additional = { isSegmentGroupShimApplied: shimApplied }

    return `${slotHash}_${mountLogHash}_${JSON.stringify(additional)}`
  }

  public override setSelected (isSelected: boolean) {
    const { deleted } = this.parentContainer.userData

    if (this.isPhantom) {
      return
    }

    if (this.sectionDetail) {
      const material = isSelected
        ? (deleted ? ThreeRollerBody.selectedDeletedMaterial : ThreeRollerBody.selectedMaterial2d)
        : (deleted ? ThreeRollerBody.deletedMaterial : ThreeRollerBody.defaultPlaneSectionView)

      this.objects.rollerBodyPlane.material = material.clone()

      return
    }

    const element = this.objects[LodUtil.LOD_ROLLER_BODY] || this.objects.rollerBodyPlane

    element.userData.selected = isSelected

    element.children.forEach((child: any) => {
      const visible = child.material.visible

      if (child.name.includes('Roller_Body')) {
        return
      }

      const material = isSelected
        ? (deleted ? ThreeRollerBody.selectedDeletedMaterial : ThreeRollerBody.selectedMaterial)
        : (deleted
          ? ThreeRollerBody.deletedMaterial
          : this.getRegularBodyColor())

      child.material = material.clone()
      child.material.visible = visible
    })
  }

  private internalSetVisibility (visible: boolean) {
    const {
      [LodUtil.LOD_ROLLER_BODY]: lod,
      rollerGroupText,
      rollerGroupBacksideText,
      rollerBodyPlane,
      rollerBodyLine,
    } = this.objects

    if (!this.isPhantom && !this.sectionDetail) {
      rollerGroupText.material.visible = visible
      rollerGroupBacksideText.material.visible = visible
    }

    if (lod) {
      lod.visible = visible
    }

    if (rollerBodyPlane && rollerBodyLine) {
      rollerBodyPlane.visible = visible
      rollerBodyLine.visible = visible
    }
  }

  public setVisibility (displayType: number) {
    if (this.isHidden) {
      return
    }
    else if (this.sectionDetail) {
      displayType = ThreeRoller.DisplayTypes.RollerChildren
    }

    switch (displayType) {
      case ThreeRoller.DisplayTypes.All:
        this.internalSetVisibility(true)
        break
      case ThreeRoller.DisplayTypes.Roller:
        this.internalSetVisibility(false)
        break
      case ThreeRoller.DisplayTypes.RollerChildren:
        this.internalSetVisibility(true)
        break
      default:
    }
  }

  public override show () {
    if (this.isHidden) {
      return
    }

    super.show()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => {
          child.material.visible = true
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = true
      }
    })
  }

  public override hide () {
    super.hide()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => {
          child.material.visible = false
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = false
      }
    })
  }

  private renderNormal (rollerBodyData: any, path: string, isDeleted: boolean, isHidden: boolean) {
    // eslint-disable-next-line camelcase
    const { diameter, width, widthCoord, id: bodyId } = rollerBodyData

    this.width = width / 1000
    // eslint-disable-next-line camelcase
    this.startWidth = widthCoord / 1000
    this.radius = diameter / 1000 / 2

    const parentInfo = Util.getParentInfo(this.parent.container.userData.path)

    // TODO: should it not be Mesh type?
    this.isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works

    const material = !this.isPhantom
      ? this.getRegularBodyColor()
      : ThreeRollerBody.phantomMaterial

    const rollerBodyLoD = new THREE.LOD()

    for (let i = 0; i < 3; i++) {
      const geometry = ThreeRollerBody.get3DGeometry(this.radius, this.width, i, this.isPhantom)

      const mesh = new THREE.Mesh(geometry, material.clone())

      mesh.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
      mesh.userData.type = 'RollerBody'
      mesh.userData.path = path

      rollerBodyLoD.addLevel(mesh, ThreeRollerBody.DetailDistance[i])

      const oldElement = this.objects[LodUtil.LOD_ROLLER_BODY]
        ? this.objects[LodUtil.LOD_ROLLER_BODY].getObjectByName(mesh.name)
        : null

      Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
    }

    rollerBodyLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
    rollerBodyLoD.userData.type = 'RollerBody'
    rollerBodyLoD.userData.path = path

    rollerBodyLoD.userData.deleted = isDeleted
    rollerBodyLoD.userData.hidden = isHidden
    rollerBodyLoD.userData.selected = this.objects[LodUtil.LOD_ROLLER_BODY]?.userData?.selected

    this.objects[LodUtil.LOD_ROLLER_BODY] = rollerBodyLoD

    const rollWidth = this.parent.rollerData.rollWidth / 1000 / 2
    const side = this.parent.container.userData.side

    switch (side) {
      case 'right':
        rollerBodyLoD.position.x = -(this.startWidth + this.width / 2 - rollWidth)
        break
      case 'left':
        rollerBodyLoD.position.x = this.startWidth + this.width / 2 - rollWidth
        break
      default:
        rollerBodyLoD.position.x = this.startWidth + this.width / 2
    }

    // TODO: test values for Phong
    // rollerBodyLoD.position.y -= (150 / 1000) * (1 - 2 * Math.random())

    if (!this.isPhantom) {
      const { number } = this.parent.objects.rollerGroupText.userData
      const side = this.parent.container.userData.side
      const textSize = side === StrandSides.Loose ? 0.03 : 0.06

      const rollerGroupText = Util.getText(number, textSize, false, true)

      if (!rollerGroupText) {
        // eslint-disable-next-line no-console
        console.warn('RollerBody: no text found for roller group')

        return
      }

      const rollerGroupBacksideText = rollerGroupText.clone()

      rollerGroupText.name = `Roller_Body_Number_${number}_${bodyId}`
      rollerGroupBacksideText.name = `Roller_Body_Number_Backside_${number}_${bodyId}`

      rollerGroupText.rotateY(Util.RAD90)
      rollerGroupText.position.set(rollerBodyLoD.position.x + this.width / 2 + 0.0001, 0, 0)

      rollerGroupBacksideText.rotateY(-Util.RAD90)
      rollerGroupBacksideText.position.set(rollerBodyLoD.position.x - (this.width / 2 + 0.0001), 0, 0)

      this.objects.rollerGroupText = rollerGroupText
      this.objects.rollerGroupBacksideText = rollerGroupBacksideText

      Util.addOrReplace(this.parentContainer, rollerGroupText)
      Util.addOrReplace(this.parentContainer, rollerGroupBacksideText)
    }

    Util.addOrReplace(this.parentContainer, rollerBodyLoD)
  }

  private renderSectionDetail (rollerBody: any, path: string) {
    const bodyWidthStart = rollerBody.widthCoord / 1000
    const bodyWidth = rollerBody.width / 1000
    const diameter = this.parent.radius * 2

    const { lineGeometry, planeGeometry } = ThreeRollerBody.get2DGeometry(bodyWidthStart, bodyWidth, diameter)

    const line = new THREE.Line(lineGeometry, ThreeRollerBody.defaultLineSectionView)

    line.name = `RollerBodyLine_${path}`

    const plane = new THREE.Mesh(
      planeGeometry,
      ThreeRollerBody.defaultPlaneSectionView,
    )

    plane.name = `RollerBodyPlane_${path}`
    plane.userData.type = 'RollerBody'
    plane.userData.path = path

    const rollWidth = this.parent.rollerData.rollWidth / 1000 / 2
    const side = this.parent.container.userData.side

    switch (side) {
      case 'right':
        line.rotation.z += Util.RAD180
        line.position.x = rollWidth
        plane.position.x = -(bodyWidth / 2 + bodyWidthStart - rollWidth)
        break
      case 'left':
        line.position.x = -rollWidth
        plane.position.x = bodyWidth / 2 + bodyWidthStart - rollWidth
        break
      case 'loose':
        plane.rotation.x += Util.RAD180
        plane.position.x = bodyWidth / 2 + bodyWidthStart
        break
      default:
        plane.position.x = bodyWidth / 2 + bodyWidthStart
    }

    this.objects.rollerBodyPlane = plane
    this.objects.rollerBodyLine = line

    Util.addOrReplace(this.parentContainer, line)
    Util.addOrReplace(this.parentContainer, plane, this.clickableObjects)
  }
}
