// TODO: check if Dashboard with simple key would be enough

import { faCheck, faJedi, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@mui/material/CircularProgress'
import isEqual from 'lodash/isEqual'
import { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { ThemeProvider } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { NetworkStatus } from '@/api/network-event'
import { getVisualizationConfig, setVisualizationConfig } from '@/api/visualization-config'
import { getCurrentDashboardEntry } from '@/App/util'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import * as TemporalDataActions from '@/store/temporalData/actions'
import { getReferenceDate } from '@/store/timestamps'
import * as VisualizationActions from '@/store/visualization/actions'
import FilterHandler from '@/three/logic/FilterHandler'
import type { DefaultState, ElementMaps, TagName } from '@/types/state'
import type { Translation } from '@/types/translation'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { NumericIdMapping } from '@/Util/mapping/NumericIdMapping'
import { ObjectUtil } from '@/Util/ObjectUtil'

import { DashboardWrapper, NetworkStatusDisplay, NoData } from './styles'
import TemporalDataHandler from './temporalDataHandler'
import StyleConfig from '../config/StyleConfig'
import ConfigDialogContent from '../ConfigDialogContent'
import ContextMenu from '../ContextMenu'
import AddDerivedPlotDialog from '../Dialogs/AddDerivedPlotDialog'
import AddPlotDialog from '../Dialogs/AddPlotDialog'
import DeleteDashboardDialog from '../Dialogs/DeleteDashboardDialog'
import DeletePlotDialog from '../Dialogs/DeletePlotDialog'
import { Dialog, DialogBackground } from '../Dialogs/DialogStyles'
import EditDashboardDialog from '../Dialogs/EditDashboardDialog'
import PlotExportDialog from '../Dialogs/PlotExportDialog'
import SelectSourceDialog from '../Dialogs/SelectSourceDialog'
import View from '../View'
import { ViewCompareLogic } from '../ViewCompareLogic'

const Spinner = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 48px - 20px);
  left: calc(50% - 20px);
`

const connector = connect((state: DefaultState) => ({
  viewsObject: state.visualization.viewsObject,
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  openConfigDialogWindow: state.visualization.openConfigDialogWindow,
  openDeleteDialogWindow: state.visualization.openDeleteDialogWindow,
  openAddPlotDialogWindow: state.visualization.openAddPlotDialogWindow,
  openDashboardWindow: state.visualization.openDashboardWindow,
  openDerivePlotDialog: state.visualization.openDerivePlotDialog,
  dashboardToDelete: state.visualization.dashboardToDelete,
  data: state.visualization.data,
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  loadingStatus: state.visualization.loadingStatus,
  dataSource: state.visualization.dataSource,
  plotExport: state.visualization.plotExport,
  isEditModeOn: state.visualization.isEditModeOn,
  networkStatus: state.application.main.networkStatus,
  darkTheme: state.application.main.darkTheme,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isLoggedIn: FeatureFlags.isLoggedIn(state),
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  timestamps: state.timestamps,
  currentDashboard: state.visualization.currentDashboard,
  plotsCompareCasterInformation: state.visualization.plotsCompareCasterInformation,
  authenticationData: state.application.main.authenticationData,
  temporalData: state.temporalData,
}), {
  setTemporalData: TemporalDataActions.setTemporalData,
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setSecondarySize: VisualizationActions.setSecondarySize,
  setLoadingButtonStatus: VisualizationActions.setLoadingButtonStatus,
  openSelectSourceDialog: VisualizationActions.openSelectSourceDialog,
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
  setAppState: ApplicationActions.setAppState,
  openDialog: ApplicationActions.openDialog,
  setPlotsCompareCasterInformation: VisualizationActions.setPlotsCompareCasterInformation,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  dashboardId: string
  t: Translation
}

type State = {
  currentMainView: string | null | undefined
  error: number | string | null
  loading: boolean
  fetchingTemporalData: boolean
}

class Dashboard extends PureComponent<Props, State> {
  private setVisualizationConfigTimeoutId?: number

  public override state: State = {
    currentMainView: null,
    error: null,
    loading: false,
    fetchingTemporalData: true,
  }

  public override componentDidMount () {
    const { viewsObject, setLoadingButtonStatus } = this.props
    const viewsObjectKeys = Object.keys(viewsObject ?? {})

    if (viewsObjectKeys.length) {
      this.setState({
        currentMainView: viewsObjectKeys[0],
      })
    }

    if (!viewsObjectKeys.length) {
      const viewId = `view_${uuid()}`

      this.setState({
        currentMainView: viewId,
      })
    }

    IpcManager.internal.on('setLoading', (event: any, loadingStatus: boolean, type: string) => {
      setLoadingButtonStatus(loadingStatus, type)
    })

    this.getTemporalData()

    this.handleAppStateChange({ appState: AppState.ParamDashboard })
      .then(() => {})
      .catch(() => {})
    this.getCompareCasterInformation()
      .then(() => {})
      .catch(() => {})
  }

  public override componentDidUpdate (prevProps: Props, prevState: State) {
    const {
      viewsObject,
      featureFlags,
      setAppState,
      tileConfigs,
      appState,
      selectedComparisonCaseIds,
    } = this.props
    const { currentMainView } = prevState
    const viewsObjectKeys = Object.keys(viewsObject ?? {})

    if (!viewsObjectKeys.length && !currentMainView) {
      const viewId = `view_${uuid()}`

      this.setState({
        currentMainView: viewId,
      })
    }

    if (
      !ObjectUtil.isEqualWithoutUndefined(prevProps.viewsObject, viewsObject) && 
      Object.keys(tileConfigs ?? {}).length
    ) {
      clearTimeout(this.setVisualizationConfigTimeoutId)

      this.setVisualizationConfigTimeoutId = window.setTimeout(this.updateVisualizationConfig, 1000)
    }

    if (
      (appState === AppState.ParamDashboard && !FeatureFlags.canViewParameterDashboard(featureFlags)) ||
      (appState === AppState.ResultDashboard && !FeatureFlags.canViewResultDashboard(featureFlags))
    ) {
      setAppState(AppState.Caster)
    }

    if ((currentMainView && !viewsObjectKeys.includes(currentMainView)) && (viewsObjectKeys.length)) {
      this.setState({
        currentMainView: viewsObjectKeys[0],
      })
    }

    this.handleAppStateChange(prevProps)

    const currentDashboard = getCurrentDashboardEntry(this.props.currentDashboard, this.props.viewsObject)
    const prevDashboard = getCurrentDashboardEntry(prevProps.currentDashboard, prevProps.viewsObject)

    this.getTemporalData()

    if (
      (
        selectedComparisonCaseIds.length > prevProps.selectedComparisonCaseIds.length &&
        !isEqual(prevProps.selectedComparisonCaseIds, selectedComparisonCaseIds)
      ) ||
      (
        currentDashboard?.dashboardId !== prevDashboard?.dashboardId &&
        selectedComparisonCaseIds.length
      )
    ) {
      this.getCompareCasterInformation()
    }
  }

  public override componentWillUnmount () {
    const { setLoadingButtonStatus } = this.props

    IpcManager.internal.removeListener('setLoading', (event: any, loadingStatus: boolean, type: string) => {
      setLoadingButtonStatus(loadingStatus, type)
    })
  }

  private readonly handleAppStateChange = async (prevProps: Partial<Props>) => {
    const {
      visualizationMetaInformation,
      openDialog,
      currentProject,
      currentSimulationCase,
      appState,
      isLoggedIn,
    } = this.props

    const { config } = visualizationMetaInformation?.[appState] ?? {}

    if ((isLoggedIn && prevProps.appState !== appState) && (currentProject?.id && currentSimulationCase?.id)) {
      if (appState === AppState.Caster && !config) {
        openDialog(ProjectDataDialog.NAME)
        // TODO: open new dialog for selecting/creating a config or automatically create a config
      }
      else {
        await this.handleOpenVisualization()
      }
    }
  }

  private readonly handleOpenVisualization = async () => {
    const { appState, visualizationMetaInformation, setConfig, setDataSources, authenticationData } = this.props
    const { config } = visualizationMetaInformation?.[appState] ?? {}
    const { casterDashboardConfigs } = authenticationData

    if (casterDashboardConfigs?.find((casterDashboardConfig: any) => casterDashboardConfig.id === config)) {
      return
    }

    this.setState({
      error: null,
      loading: true,
    })

    const visualizationConfig = await getVisualizationConfig(config)

    if (!visualizationConfig) {
      this.setState({
        // error: error.status, // FIXME: error.status is undefined
        loading: false,
      })

      return
    }

    setConfig(visualizationConfig.data)
    setDataSources(visualizationConfig.dataSources ?? [])

    this.setState({
      loading: false,
    })
  }

  private readonly handleCloseDataSourceDialog = () => {
    const { openSelectSourceDialog } = this.props

    openSelectSourceDialog(false)
  }

  private readonly handleClosePlotExportDialog = () => {
    const { openPlotExportDialog } = this.props

    openPlotExportDialog(false)
  }

  private readonly handleOpenProjectDialog = () => {
    // TODO: handle open new select dashboard dialog
    const { openDialog } = this.props

    openDialog(ProjectDataDialog.NAME)
  }

  private readonly handleFixData = () => {
    ApiClient.get(`${'Network.URI(deprecated)'}/visualization_fix/data`)
  }

  private readonly updateVisualizationConfig = async () => {
    const { appState, visualizationMetaInformation, plotConfigs, tileConfigs, viewsObject } = this.props

    const dashboardConfigId = visualizationMetaInformation?.[appState]?.config
    const data = { viewsObject, plotConfigs, tileConfigs }

    await setVisualizationConfig(dashboardConfigId, data)
  }

  private readonly getTemporalData = async () => {
    //  go through all plots and see if they have a temporal data source
    //  if they do, request the data
    await TemporalDataHandler.getTemporalData(this.props)
  }

  private readonly getCompareCasterInformation = async () => {
    const {
      selectedComparisonCaseIds,
      timestamps,
      plotConfigs,
      currentDashboard,
      viewsObject,
      tileConfigs,
      plotsCompareCasterInformation,
      currentProject,
      setPlotsCompareCasterInformation,
    } = this.props

    if (!selectedComparisonCaseIds.length) {
      return
    }

    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)

    if (!viewId || !dashboardId) {
      return
    }

    const tileIds = viewsObject[viewId]?.dashboards?.[dashboardId]?.tileIds

    if (!tileIds?.length) {
      return
    }

    const plotIds = tileIds.map(tileId => tileConfigs[tileId]?.configId).filter(Boolean)

    if (!plotIds.length) {
      return
    }

    const types: string[] = []
    const references: string[] = []

    const somePlotHasFilter = plotIds.some(plotId => (plotConfigs[plotId]?.filter ?? false))

    for (const plotId of plotIds) {
      const plotConfig = plotConfigs[plotId]

      if (!plotConfig) {
        continue
      }

      const { filter, selectedX } = plotConfig

      if (filter) {
        const filterTypes = FilterHandler.getFilterElementTypes(filter)

        for (let i = 0; i < filterTypes.length; i++) {
          const type = filterTypes[i]

          if (!types.includes(type)) {
            types.push(type)
          }
        }

        if (filter.includes('#ref')) {
          // split filter by #ref and split by any character that isn't a letter, number or ':'
          const reference = filter.split('ref=')[1]?.split(/[^a-zA-Z0-9:]/)[0]

          if (reference && !references.includes(reference)) {
            references.push(reference)
          }
        }
      }

      if (!selectedX) { // then its a merged plot
        for (const config of plotConfig.configs) {
          const [ type ] = config.selectedX.split('|')

          if (!type || types.includes(type)) {
            continue
          }

          types.push(type)
        }
      }
      else {
        const [ type ] = selectedX.split('|')

        if (!type || types.includes(type)) {
          continue
        }

        types.push(type)
      }
    }

    const cleanTypes = types.filter(type => ElementsUtil.typeIsOfTypeCasterElementName(type)) as TagName[]
    const typesToBeRequestedPerCase: Record<string, TagName[]> = {}

    selectedComparisonCaseIds.forEach(caseId => {
      const currentCaseInfo = plotsCompareCasterInformation[caseId]

      if (!currentCaseInfo) {
        typesToBeRequestedPerCase[caseId] = [ ...cleanTypes ]

        return
      }

      typesToBeRequestedPerCase[caseId] = typesToBeRequestedPerCase[caseId] ?? []

      for (const type of cleanTypes) {
        if (!ElementsUtil.typeIsOfTypeCasterElementName(type)) {
          return
        }

        const mountLogMap = ElementMapsUtil.getMountLogMapByTagName(currentCaseInfo as ElementMaps, type)
        const slotMap = ElementMapsUtil.getSlotMapByTagName(currentCaseInfo as ElementMaps, type)

        if (!Object.keys(mountLogMap).length || !Object.keys(slotMap).length) {
          typesToBeRequestedPerCase[caseId] = [ ...cleanTypes ]

          return
        }

        const requiredMapsPerType = ElementsUtil.getMountLogMapKeysByTagName(type)

        if (
          requiredMapsPerType.some(mapKey => !currentCaseInfo[mapKey]) &&
          !typesToBeRequestedPerCase[caseId].includes(type)
        ) {
          typesToBeRequestedPerCase[caseId].push(type)
        }
      }
    })

    const data = await ApiClient
      .get(`${useConfig().apiBaseURL}/casters/compare-plots`, {
        params: {
          date: getReferenceDate(timestamps).toISOString(),
          typesToBeRequestedPerCase,
          projectId: currentProject.id,
          dataLineRefs: references,
        },
      })

    const normalizedData = ElementsUtil.normalizeCompareCasterInformation(plotsCompareCasterInformation, data)

    for (const caseId in normalizedData) {
      const elementMaps = normalizedData[caseId] as ElementMaps

      NumericIdMapping.build(elementMaps, caseId)

      if (somePlotHasFilter) {
        ViewCompareLogic.buildComparePaths(elementMaps, caseId)
      }
    }

    setPlotsCompareCasterInformation(normalizedData)
  }

  private readonly getSplitLayout = (currentId: string) => {
    const { dashboardId, viewsObject, isEditModeOn } = this.props
    const currentSplitData = viewsObject[currentId]?.split

    if (!currentSplitData) {
      return (
        <div data-id={currentId} className={isEditModeOn ? 'editable' : ''}>
          <View viewId={currentId} view={viewsObject[currentId]} simple dashboardId={dashboardId} />
        </div>
      )
    }

    return null
  }

  public override render () {
    const {
      openConfigDialogWindow,
      openDeleteDialogWindow,
      openAddPlotDialogWindow,
      openDerivePlotDialog,
      dashboardToDelete,
      loadingStatus,
      dataSource,
      plotExport,
      openDashboardWindow,
      networkStatus,
      darkTheme,
      // isEditModeOn,
      plotConfigs,
      t,
      isLoggedIn,
      appState,
      visualizationMetaInformation,
    } = this.props
    const { currentMainView, loading } = this.state

    let icon
    const maxDashboardWidth = window.innerWidth - 390

    switch (networkStatus) {
      case NetworkStatus.Connected:
        icon = <FontAwesomeIcon icon={faCheck} fixedWidth />
        break
      case NetworkStatus.Connecting:
        icon = <FontAwesomeIcon icon={faSyncAlt} fixedWidth spin />
        break
      case NetworkStatus.Disconnected:
        icon = <FontAwesomeIcon icon={faTimes} fixedWidth />
        break
      default:
        icon = <FontAwesomeIcon icon={faJedi} fixedWidth title='!sith' />
        break
    }

    if (loading) {
      return (
        <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
          <div>
            <DashboardWrapper>
              <Spinner disableShrink />
              {
                // FIXME: is edit mode still needed?
                // isEditModeOn &&
                <NetworkStatusDisplay title={networkStatus} className={networkStatus}>
                  {icon}
                </NetworkStatusDisplay>
              }
            </DashboardWrapper>
          </div>
        </ThemeProvider>
      )
    }

    const isParamDB = appState === AppState.ParamDashboard
    const isResultDB = appState === AppState.ResultDashboard
    const { config } = visualizationMetaInformation?.[appState] ?? {}
    const noData = plotConfigs && Object.keys(plotConfigs).length === 0

    if (isLoggedIn && ((noData && isResultDB) || (!config && isParamDB))) {
      return (
        <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
          <div>
            <DashboardWrapper>
              <NoData onClick={this.handleOpenProjectDialog}>
                {dataSource && <SelectSourceDialog onCloseDataSourceDialog={this.handleCloseDataSourceDialog} />}
                {t('dashboard.noData.heading')}
                <br />
                {t('dashboard.noData.secondHeading')}
              </NoData>
            </DashboardWrapper>
          </div>
        </ThemeProvider>
      )
    }

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <div>
          <DashboardWrapper
            id='DashboardWrapper'
            $maxDashboardWidth={maxDashboardWidth}
          >
            {dataSource && <SelectSourceDialog onCloseDataSourceDialog={this.handleCloseDataSourceDialog} />}
            {plotExport && <PlotExportDialog onClosePlotExportDialog={this.handleClosePlotExportDialog} />}
            {
              openConfigDialogWindow &&
              (
                <div>
                  <DialogBackground />
                  <Dialog $width='850px' $height='450px' $noBottomBorder>
                    <ConfigDialogContent />
                  </Dialog>
                </div>
              )
            }
            {openDeleteDialogWindow && <DeletePlotDialog />}
            {openAddPlotDialogWindow && <AddPlotDialog />}
            {openDerivePlotDialog && <AddDerivedPlotDialog />}
            {openDashboardWindow && <EditDashboardDialog />}
            {Object.keys(dashboardToDelete ?? {}).length > 0 && <DeleteDashboardDialog />}
            {!loadingStatus.openVisualizationConfig && currentMainView && this.getSplitLayout(currentMainView)}
            <ContextMenu />
            {
              // FIXME: is edit mode still needed?
              // isEditModeOn &&

              <NetworkStatusDisplay title={networkStatus} className={networkStatus} onClick={this.handleFixData}>
                {icon}
              </NetworkStatusDisplay>

            }
          </DashboardWrapper>
        </div>
      </ThemeProvider>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(Dashboard)
