import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { Translation } from '@/types/translation'

import { HiddenButton, InputForm, NumberSelector } from '../InputStyles'

type Props = {
  isDisabled: boolean
  mass: boolean
  value: string
  min?: number
  max?: number
  step?: number
  decimals?: number
  massValue: string
  name: string
  placeholder: string
  onChange: (e: any) => void
  inputRef: any
  t: Translation
  style?: any
  onlyPositiveNumbers?: boolean
}

class NumberField extends Component<Props> {
  private handleDecimals (value: number) {
    const { decimals } = this.props

    if (decimals === undefined) {
      return value
    }

    const power = Math.pow(10, decimals)

    return Math.round(value * power) / power
  }

  private readonly handleMinMax = (num: number) => {
    const { min, max } = this.props

    if (min !== undefined && num < min) {
      return min
    }

    if (max !== undefined && num > max) {
      return max
    }

    return num
  }

  private readonly handleInputChange = (event: any) => {
    const { onlyPositiveNumbers, onChange, min, step } = this.props
    const { value } = event.target as { value: string }
    const numberedValue = this.handleDecimals(this.handleMinMax(Number(value)))

    if (value === '' || (!onlyPositiveNumbers && (min === undefined || min < 0) && value === '-')) {
      onChange(event)

      return
    }

    const isNumber = !isNaN(numberedValue)

    if (!isNumber) {
      return
    }

    if (onlyPositiveNumbers) {
      const isNegative = value.split('').some(char => char === '-')

      if (isNegative) {
        return
      }
    }

    const amountOfPoints = event.target.value?.match(/\./g)?.length ?? 0
    const pointIsLast = event.target.value?.endsWith('.')

    if (amountOfPoints > 1) {
      return
    }

    if (amountOfPoints && pointIsLast && step && step !== 1) {
      onChange(event)

      return
    }

    event.target.value = String(numberedValue)

    onChange(event)
  }

  private readonly handleArrowClick = (event: any) => {
    const { value: receivedValue, step, mass, onChange, name, onlyPositiveNumbers } = this.props
    const realStep = step ?? 1
    const num = event.currentTarget.value === 'up' ? realStep : -realStep

    if (onlyPositiveNumbers && num < 0 && Number(receivedValue) === 0) {
      return
    }

    const newNumber = this.handleDecimals(this.handleMinMax(Number(receivedValue) + num))
    const value = mass ? '0' : String(newNumber)

    onChange({ target: { value, name } })
  }

  private readonly handleKeyDown = (event: any) => {
    const { key } = event
    const { value, onChange, name } = this.props

    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      return
    }

    event.preventDefault()

    const step = this.props.step ?? 1
    const num = key === 'ArrowUp' ? step : -step
    const newNumber = this.handleDecimals(this.handleMinMax(Number(value) + num))

    onChange({ target: { value: newNumber, name } })
  }

  public override render () {
    const { isDisabled, massValue, placeholder, name, inputRef, t, style, onlyPositiveNumbers } = this.props
    const downButtonDisabled = onlyPositiveNumbers && Number(massValue) <= 0

    return (
      <div style={{ paddingRight: isDisabled ? '17px' : undefined }}>
        <InputForm
          placeholder={placeholder}
          type='text'
          value={massValue}
          title={massValue}
          onChange={this.handleInputChange}
          disabled={isDisabled}
          name={name}
          ref={inputRef}
          style={{ marginRight: '-17px', ...style }}
          onKeyDown={this.handleKeyDown}
        />
        {
          !isDisabled &&
          (
            <NumberSelector>
              <HiddenButton
                title={t('up')}
                value='up'
                onClick={this.handleArrowClick}
              >
                <FontAwesomeIcon icon={faAngleUp} />
              </HiddenButton>
              <HiddenButton
                title={t('down')}
                value='down'
                onClick={this.handleArrowClick}
                disabled={downButtonDisabled}
              >
                <FontAwesomeIcon icon={faAngleDown} />
              </HiddenButton>
            </NumberSelector>
          )
        }
      </div>
    )
  }
}

export default compose<any>(withTranslation('caster'))(NumberField)
