/* eslint-env browser */

import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { v4 as uuid } from 'uuid'

import { getVisualizationConfig, getVisualizationConfigs, uploadVisualizationConfig } from '@/api/visualization-config'
import IpcManager from '@/IpcManager'
import Button from '@/react/components/Button'
import InfoMarker from '@/react/components/InfoMarker'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { ArrayOfTranslations, Translation } from '@/types/translation'

import Logic from './Logic'
import { Hr, Title } from './Styles'
import { EditConfigDialog } from '../EditConfigDialog'
import { Spacer } from '../OpenProjectDialog/Styles'

const T = 'projectDataDialog.visualization'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  darkTheme: state.application.main.darkTheme,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  openDialog: ApplicationActions.openDialog,
  setEditConfigId: ApplicationActions.setEditConfigId,
  setAppState: ApplicationActions.setAppState,
  setConfig: VisualizationActions.setConfig,
  resetViewObject: VisualizationActions.resetViewObject,
  setError: ErrorActions.setError,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onClose: (isClick?: any) => void
  onChange: (event: any) => { type: string, name: string, value: string } | void
  onDeleteConfig: (type: string, key: string) => null | undefined
  onDeleteData: (type: string, key: string) => null | undefined
  onDeleteSchema: (type: string, key: string) => null | undefined
  t: ArrayOfTranslations & Translation
}

type State = {
  loading: any
  selectedConfig: string | null
  selectedData: string | null | undefined
  configs: VisualizationConfig[]
}

export class VisualizationColumn extends Component<Props, State> {
  public override state: State = {
    loading: {
      openVis: false,
    },
    selectedConfig: null,
    selectedData: null,
    configs: [] as VisualizationConfig[],
  }

  public override async componentDidMount () {
    const { visualizationMetaInformation, currentProject: { id } } = this.props
    const meta = visualizationMetaInformation[AppState.ResultDashboard] ?? {}

    const configs = await getVisualizationConfigs(id, AppState.ResultDashboard, { nameOnly: true }) ?? []

    this.setState({
      selectedConfig: meta.config ?? configs[0]?.id ?? null,
      selectedData: meta.data, // FIXME: set default once we have vis data
      configs,
    })
  }

  private readonly handleOpenVisualization = async () => {
    const {
      setConfig,
      setAppState,
      currentSimulationCase,
      setError,
      onChange,
      onClose,
      resetViewObject,
    } = this.props

    let config = this.getSelectedConfig()

    this.setState({
      loading: {
        openVis: true,
      },
    })

    if (!config || config === 'default') {
      const fileName = `config_${Date.now()}.json`
      const visData = {
        viewsObject: { [`view_${uuid()}`]: { dashboards: { [`dashboard_${uuid()}`]: { name: 'New Dashboard' } } } },
      }
      const file = new File([ JSON.stringify(visData) ], fileName, { type: 'text/plain', lastModified: Date.now() })

      const visualizationConfig = await uploadVisualizationConfig(
        file,
        currentSimulationCase.projectId,
        AppState.ResultDashboard,
      )

      if (!visualizationConfig) {
        // setError('openDashboard', error.status) // FIXME: error.status is undefined
        this.setState({ loading: { openVis: false } })

        return
      }

      // FIXME: do we need to set this here?
      // setCurrentSimulationCase(response.simulationCase)

      config = visualizationConfig.id
    }

    resetViewObject()
    onChange({ target: { name: 'config', value: config } })

    setAppState(AppState.ResultDashboard)

    const visualizationConfig = await getVisualizationConfig(config)

    if (!visualizationConfig) {
      // setError('openDashboard', error.status) // FIXME: error.status is undefined

      this.setState({ loading: { openVis: false } })

      return
    }

    setConfig(visualizationConfig.data)

    if (currentSimulationCase.visualizationDataList && currentSimulationCase.visualizationDataList.length) {
      const data = this.getSelectedData()

      const { visualizationData } = await ApiClient
        .get(`${'Network.URI(deprecated)'}/visualization_data/${data}`)
        .catch((error) => {
          setError('openDashboard', error.status)

          return {}
        })

      if (!visualizationData) {
        this.setState({
          loading: {
            openVis: false,
          },
        })

        return
      }

      IpcManager.internal.send('VisualizationData', null, visualizationData)

      onChange({ target: { name: 'data', value: data } })
    }

    onClose()
  }

  private readonly handleDownloadConfig = async (key: string) => {
    const { configs } = this.state
    const { darkTheme } = this.props

    const visualizationConfig = await getVisualizationConfig(key)

    if (!visualizationConfig) {
      return
    }

    IpcManager.both.send(
      'saveVisualizationConfig',
      JSON.stringify({ ...visualizationConfig.data, darkTheme }),
      configs.filter(config => config.id === key)[0]?.name,
    )
  }

  private readonly handleEditConfig = (key: string) => {
    const { openDialog, setEditConfigId } = this.props

    setEditConfigId(key)
    openDialog(EditConfigDialog.NAME)
  }

  private readonly handleChangeConfig = (event: any) => {
    const { onChange } = this.props
    const { value } = event.target

    if (value === 'add') {
      return onChange({ target: { name: 'config', value: 'add' } })
    }

    this.setState({
      selectedConfig: value,
    })
  }

  private readonly handleChangeData = (event: any) => {
    this.setState({
      selectedData: event.target.value,
    })
  }

  private readonly getSelectedData = () => {
    const { selectedData } = this.state
    const { currentSimulationCase: { visualizationDataList } } = this.props
    const dataList = visualizationDataList ?? []

    if (selectedData && !dataList.length) {
      return 'default'
    }

    if (selectedData && dataList.findIndex(data => data.dataId === selectedData) === -1) {
      return dataList[0]?.dataId ?? 'default'
    }

    return selectedData ?? dataList[0]?.dataId ?? 'default'
  }

  private readonly getSelectedConfig = () => {
    const { selectedConfig, configs } = this.state

    return Logic.getSelectedConfig(selectedConfig, configs)
  }
  
  public override render () {
    const { loading, configs } = this.state
    const {
      t,
      visualizationMetaInformation,
      currentSimulationCase,
      featureFlags,
      error,
      onDeleteConfig,
    } = this.props

    const { data } = visualizationMetaInformation[AppState.ResultDashboard] ?? {}

    const visualizationEditor = FeatureFlags.canEditVisualization(featureFlags)
    const { visualizationDataList } = currentSimulationCase
    const hasVisualizationFile = visualizationDataList && visualizationDataList.length && visualizationDataList[0]

    const dataSelectors = Logic.getSelectors(
      (visualizationDataList ?? []).sort((a, b) => a.birthtime - b.birthtime),
      'dataId',
      'name',
      { value: t(`${T}.data.default`), disabled: true },
    )

    const configSelectors = Logic.getSelectors<VisualizationConfig>(
      configs,
      'id',
      'name',
      FeatureFlags.canCreateResultDashboardConfig(featureFlags)
        ? {
          value: t(`${T}.config.${visualizationEditor ? 'default' : 'viewerDefault'}`),
          disabled: false,
        }
        : null,
    )

    if (FeatureFlags.canUploadResultDashboardConfig(featureFlags)) {
      configSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t(`${T}.config.add`),
        disabled: !visualizationEditor,
      })
    }

    const loadDisabled = !visualizationDataList || !visualizationDataList.length

    return (
      <Form $maxWidth='300px' $padding='18px 28px 20px'>
        <Title>
          {t(`${T}.title`)}
          <InfoMarker
            message={t(`${T}.info`, { returnObjects: true }).join('\n')}
            x={224}
            y={-23}
            placement='top-end'
          />
        </Title>
        <Input
          name='data'
          type='select'
          label={t(`${T}.data.label`)}
          title={
            !hasVisualizationFile
              ? t(`${T}.noFile`)
              : t(`${T}.data.title`)
          }
          value={this.getSelectedData()}
          selectors={dataSelectors}
          onChange={this.handleChangeData}
          noDeleteGiven={[ data ]}
        />
        <Spacer $h={141} $br />
        <Spacer $h={17} $br />
        <Hr />
        <Input
          name='config'
          type='select'
          title={
            error.config
              ? t([ `error.${error.config}`, 'error.default' ])
              : t(`${T}.config.title`)
          }
          error={error.config && t([ `error.${error.config}`, 'error.default' ])}
          value={this.getSelectedConfig()}
          selectors={configSelectors}
          onChange={this.handleChangeConfig}
          onDelete={onDeleteConfig}
          onDownload={this.handleDownloadConfig}
          onEdit={this.handleEditConfig}
        />
        <Button
          onClick={this.handleOpenVisualization}
          loading={loading.openVis}
          error={error.openDashboard ? t([ `error.${error.openDashboard}`, 'error.default' ]) : ''}
          title={
            error.openDashboard
              ? t([ `error.${error.openDashboard}`, 'error.default' ])
              : (loadDisabled
                ? t(`${T}.open.disabled`)
                : t(`${T}.open.title`))
          }
          hideError
          disabled={loadDisabled}
        >
          {t(`${T}.open.label`)}
        </Button>
      </Form>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(VisualizationColumn)
