import { request } from '@/api'
import { AuthDataStore } from '@/logic/auth-data-store'
import { Info } from '@/logic/Info'
import { parseJWT } from '@/Util/authUtil'

/**
 * Refreshes the access token using the refresh token. If no token is present, nothing happens.
 */
async function refresh (): Promise<void> {
  const tokenData = AuthDataStore.get()

  if (!tokenData) {
    return
  }

  const authResult = await request<AuthResult>(
    'post',
    'auth/refresh',
    'Failed to refresh token',
    {
      data: {
        /* eslint-disable camelcase */
        refresh_token: tokenData.refreshToken,
        client_id: 'application',
        scopes: 'write',
        grant_type: 'refresh_token',
        /* eslint-enable camelcase */
      },
      skipAuth: true,
    },
    true,
  )

  if (!authResult) {
    return
  }

  // delete featureFlags from authResult as the cookie cannot store this much data
  delete authResult.featureFlags // TODO: do we need to update our stored feature flags?

  AuthDataStore.set(authResult)
}

/**
 * Ensures that the access token is valid and refreshes it if necessary. If no token is present, nothing happens.
 */
export async function ensureValidAccessToken (): Promise<void> {
  const tokenData = AuthDataStore.get()

  if (!tokenData) {
    return
  }

  const accessJWTData = parseJWT(tokenData.accessToken)
  const refreshJWTData = parseJWT(tokenData.refreshToken)

  /**
   * There is a mismatch in the token expiration e.g. the token would expire at 1726048954 seconds since epoch
   * (Wed Sep 11 2024 12:02:34 GMT+0200 (Central European Summer Time))
   * but the auth guard for some reason throws an error
   * Token expired at 2024-09-11T10:02:07.000Z
   * which is almost 30 seconds too early.
   * So we need to check if the token is about to expire in the near future, hopefully 60 seconds is enough.
   */
  const nearFuture = Date.now() / 1000 + 60 // 60 seconds in the future

  // If the access token is about to expire, refresh it
  if (accessJWTData.exp < nearFuture && refreshJWTData.exp > nearFuture) {
    await refresh()

    return
  }

  // If both tokens have expired, clear the stored data and reload the page
  if (accessJWTData.exp < nearFuture && refreshJWTData.exp < nearFuture) {
    AuthDataStore.clear()

    Info.removeRecentlyUsedInfo()

    window.location.reload()
  }
}
