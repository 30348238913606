import isEqual from 'lodash/isEqual'
import * as THREE from 'three'
import { OrthographicCamera, Vector2 } from 'three'

import type { PassedData } from '@/react/Caster'
import FeatureFlags from '@/react/FeatureFlags'
import TextureUtil from '@/three/logic/TextureUtil'
import Util from '@/three/logic/Util'
import { Views } from '@/three/ThreeBase'
import ThreeManager from '@/three/ThreeManager'
import { ElementMaps } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { isTarget } from '@/Util/ElementUtil'

import CalculationUtil from './CalculationUtil'
import DrawHandlers from './DrawHandlers'
import EventHandlers from './EventHandlers'
import Getters from './Getters'
import UiViewHandlers from './UiViewHandlers'
import BaseView from '../BaseView'

export default class UIView extends BaseView {
  public static readonly className = 'UIView'

  public static currentPsslnPosition = 0

  public static readonly barMaterial = new THREE.MeshBasicMaterial({ color: '#BB1B1B' })

  public static readonly disabledBarMaterial = new THREE.MeshBasicMaterial({ color: '#5d0d0d' })

  public static upOrDownButtonDisabled = false

  public static readonly sliderDimensions = {
    width: 20,
    height: 30,
    top: 150,
    bottom: 50,
  }

  public mouseDown?: boolean

  public isSectionActive = false

  public scrollValue: number = 0

  public readonly buttons: any

  public sectionViewDisabled = false

  public switchScrollElementDisabled = false

  public scrollableElements: Record<JumpOverOptions, boolean>

  public readonly selectionRect

  public plHeight?: number

  public scrollBarButtonGroupActive?: boolean

  public buttonUp?: any

  public buttonDown?: any

  public scrollBarButtonGroup?: any

  public scrollBar?: any

  public switchScrollElement: THREE.Mesh | null = null

  private dataChanged?: boolean

  public elementMaps: ElementMaps

  private referenceCasterDate?: Date

  public tooltip?: Record<'SectionView' | 'UIView', Tooltip[]>

  public featureFlags?: Record<string, boolean>

  public override camera: OrthographicCamera

  public minPasslineCoord = 0

  public maxPasslineCoord = 0

  public scrolling = false

  private static readonly jumpOverElementTypes: ('DataPoint' | 'Nozzle' | 'Roller')[] = [
    'Nozzle',
    'Roller',
    'DataPoint',
  ]

  public readonly buttonMaterials: {
    jumpOver: Record<'DataPoint' | 'Nozzle' | 'Roller', THREE.MeshBasicMaterial>
  }

  public constructor (renderer: THREE.WebGLRenderer, views: Views) {
    super(renderer, views)

    UIView.staticClassName = 'UIView'
    this.className = 'UIView'

    this.camera = Getters.getCamera()

    this.sectionViewDisabled = false
    this.switchScrollElementDisabled = false
    this.scrollableElements = {
      Nozzle: true,
      Roller: true,
      DataPoint: true,
    }
    this.buttons = {}
    this.scrolling = false

    this.reset()

    this.selectionRect = Getters.getSelectionRect()
    UiViewHandlers.hideSelectionRect(this.selectionRect)

    this.scene.add(this.selectionRect)

    this.buttonMaterials = {
      jumpOver: {} as any,
    }

    for (let i = 0; i < UIView.jumpOverElementTypes.length; i++) {
      const jumpOverElementType = UIView.jumpOverElementTypes[i]!
      const textureImage = DrawHandlers.textureImageMapper[jumpOverElementType]
      const texture = TextureUtil.load(`textures/ui/${textureImage}`)
      const material = new THREE.MeshBasicMaterial({ map: texture })

      this.buttonMaterials.jumpOver[jumpOverElementType] = material
    }

    this.elementMaps = {} as ElementMaps
  }

  public override reset () {
    this.plHeight = 0

    this.mouseDown = false
    this.isSectionActive = false
    this.scrollValue = 0
    this.scrollBarButtonGroupActive = false
    this.sectionViewDisabled = false
    this.scrollableElements = {
      Nozzle: true,
      Roller: true,
      DataPoint: true,
    }

    if (this.scrollBarButtonGroup) {
      this.scrollBar.visible = false
      this.scrollBarButtonGroup.visible = false
      this.buttonUp.visible = false
      this.buttonDown.visible = false

      if (this.switchScrollElement) {
        this.switchScrollElement.visible = false
      }
    }

    this.elementMaps = {} as ElementMaps
  }

  public setData (data: PassedData) {
    this.dataChanged = !isEqual(this.elementMaps.Caster ?? {}, data.elementMaps.Caster ?? {})

    this.referenceCasterDate = data.referenceCasterDate

    this.tooltip = data.tooltip
    this.elementMaps = data.elementMaps

    let minOrMaxPasslnChanged = false

    if (
      (this.views.sectionView?.minPasslineCoord && this.views.sectionView?.maxPasslineCoord) &&
      (
        this.views.sectionView.minPasslineCoord !== this.minPasslineCoord ||
        this.views.sectionView.maxPasslineCoord !== this.maxPasslineCoord
      )
    ) {
      this.minPasslineCoord = this.views.sectionView.minPasslineCoord
      this.maxPasslineCoord = this.views.sectionView.maxPasslineCoord
      minOrMaxPasslnChanged = true
    }

    const prevSwitchScrollElementDisabled = this.switchScrollElementDisabled

    if (
      prevSwitchScrollElementDisabled !== this.switchScrollElementDisabled ||
      minOrMaxPasslnChanged
    ) {
      DrawHandlers.drawUpDownButtons(this)
    }

    const casterData = data.elementMaps.Caster

    if (this.dataChanged && casterData) {
      UiViewHandlers.handleElementCount(this, data.elementMaps)

      const sections = ElementsUtil.getPasslineSectionsByDate(data.elementMaps, this.referenceCasterDate)

      this.plHeight = CalculationUtil.calcPlHeight(sections)
    }
    else if (!casterData) {
      UiViewHandlers.handleElementCount(this, data.elementMaps)
    }

    if (!isEqual(data.featureFlags, this.featureFlags ?? {})) {
      this.scrollableElements = {
        Nozzle: FeatureFlags.canJumpOverNozzles(data.featureFlags),
        Roller: FeatureFlags.canJumpOverRollers(data.featureFlags),
        DataPoint: FeatureFlags.canJumpOverDataPoints(data.featureFlags),
      }

      if (FeatureFlags.canViewSidesCube(data.featureFlags)) {
        this.views.viewsView?.show()
      }
      else {
        this.views.viewsView?.hide()
      }
    }

    this.featureFlags = data.featureFlags
  }

  public setButtonPos () {
    const { width, height, min, x, top, bottom } = this.scrollBarButtonGroup.userData

    if (
      !this.scrollBarButtonGroup ||
      this.plHeight === undefined ||
      !this.viewport ||
      this.scrollValue === undefined
    ) {
      return
    }

    const max = CalculationUtil.calcMaxPosition(min, top, bottom, height, this.viewport.height)
    const y = CalculationUtil.calcButtonYPos(min, max, this.scrollValue)

    const position = Getters.getPosition(width, height, x, y, this.viewport)

    this.scrollBarButtonGroup.position.copy(position)

    const currentPasslnPosition = Number((this.plHeight * this.scrollValue).toFixed(2))

    if (UIView.currentPsslnPosition !== currentPasslnPosition) {
      window.dispatchEvent(new CustomEvent('CurrentPasslnPositionChanged', { detail: { currentPasslnPosition } }))
      ;(window as any).currentPasslnPosition = currentPasslnPosition
    }

    UIView.currentPsslnPosition = currentPasslnPosition

    DrawHandlers.drawPasslineCoord(this.scrollBarButtonGroup, width)
    DrawHandlers.drawJumpToSectionPlaneButton(this, this.scrollBarButtonGroup, width)

    const upOrDownButtonDisabled = currentPasslnPosition <= this.minPasslineCoord ||
      currentPasslnPosition >= this.maxPasslineCoord

    if (UIView.upOrDownButtonDisabled !== upOrDownButtonDisabled) {
      DrawHandlers.drawUpDownButtons(this)
    }
  }

  public override resize (width: number, height: number) {
    this.viewport = {
      x: 0,
      y: 0,
      width,
      height,
    }

    UiViewHandlers.updateCamera(this, width, height)

    this.updateTransforms()
    DrawHandlers.drawScrollBar(this)
  }

  public override animate () {
    // override
  }

  public updateTransforms () {
    const { width, height } = this.viewport

    if (!(width > 0 && height > 0)) {
      return
    }

    Object.values(this.buttons).forEach(button => {
      UiViewHandlers.updateButton(button, width, height)
    })
  }

  public override handleMouseDown (event: any, mouseOnCanvas: Vector2): any {
    const intersects = super.handleMouseDown(event, mouseOnCanvas)

    this.mouseDown = true

    if (event.shiftKey) {
      if (this.views.mainView) {
        this.views.mainView.controls.enabled = false
        this.views.mainView.controls.update()
      }

      this.selectionRect.visible = true
    }

    if (intersects?.[0]?.object) {
      const { name } = intersects[0].object

      // handling clicks on slide bar or buttons at the slide bar
      EventHandlers.handleMouseDownAction(name, this)

      return false
    }
  }

  public override handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    if (this.scrolling) {
      this.scrolling = false
      this.views.sectionView?.animate()
      // ThreeManager.base.renderScene()
      window.dispatchEvent(new CustomEvent('FinishedScrollingPasslnCoord'))
    }

    const intersects = super.handleMouseUp(event, mouseOnCanvas)

    this.mouseDown = false
    this.scrollBarButtonGroupActive = false

    if (!event.shiftKey && this.views && this.views.mainView) {
      this.views.mainView.controls.enabled = true
    }

    if (this.selectionRect.visible) {
      EventHandlers.handleSelectionEnd(this, mouseOnCanvas)
    }

    if (intersects?.[0]?.object) {
      const { userData } = intersects[0].object
      const { action } = userData

      // handling clicks on buttons that are at the top
      EventHandlers.handleMouseUpAction(action, this)

      return false
    }
  }

  public override handleMouseMove (event: any, mouseOnCanvas: Vector2): boolean {
    const isSectionViewTarget = isTarget(event, ThreeManager.base?.container)

    if (!isSectionViewTarget) {
      return false
    }

    if (this.selectionRect.visible && this.mouseDown && event.shiftKey) {
      EventHandlers.handleSelection(this, mouseOnCanvas)
    }
    else {
      if (this.scrollBarButtonGroupActive) {
        EventHandlers.handleScroll(this, mouseOnCanvas)
      }
    }

    return true
  }

  public override handleKeyDown (_event: any) {}

  public override handleKeyUp (event: any) {
    if (event.keyCode === 16) { // Shift
      UiViewHandlers.hideSelectionRect(this.selectionRect)
    }
  }

  public handleToggleSectionView () {
    if (
      !FeatureFlags.canJumpOverSomeElement(this.featureFlags) ||
      !this.getAmountOfJumpableElements()
    ) {
      return
    }

    this.setButtonPos()
    this.sectionViewHandler(!this.isSectionActive)
  }

  public getAmountOfJumpableElements () {
    return (
      Number(this.scrollableElements.Roller) +
      Number(this.scrollableElements.Nozzle) +
      Number(this.scrollableElements.DataPoint)
    )
  }

  public sectionViewHandler (isSectionActive: boolean) {
    this.isSectionActive = isSectionActive
    this.views.mainView?.updateRoller(Util.RollerMode)

    if (this.views.mainView && this.views.sectionView) {
      if (isSectionActive) {
        this.views.mainView.sectionPlane.visible = true
        this.views.sectionView.showSection(this.viewport.width, this.viewport.height)
        this.scrollBar.visible = true
        this.scrollBarButtonGroup.visible = true
        this.buttonUp.visible = true
        this.buttonDown.visible = true

        if (this.switchScrollElement) {
          this.switchScrollElement.visible = true
        }
      }
      else {
        this.views.mainView.sectionPlane.visible = false
        this.views.sectionView.hideSection()
        this.scrollBar.visible = false
        this.scrollBarButtonGroup.visible = false
        this.buttonUp.visible = false
        this.buttonDown.visible = false

        if (this.switchScrollElement) {
          this.switchScrollElement.visible = false
        }
      }
    }
  }
}
