import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { useConfig } from '@/config'
import Button from '@/react/components/Button'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { OpenProjectDialog } from './OpenProjectDialog'
import { ProjectDataDialog } from './ProjectDataDialog'
import BaseDialog from '../BaseDialog'

const T = 'newProjectDialog'

const connector = connect((state: DefaultState) => ({
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  switchProject: ApplicationActions.switchProject,
  setCurrentProjectCasesMetadata: ApplicationActions.setCurrentProjectCasesMetadata,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: boolean | string
  name: string
  description: string
  error: string
  loading: boolean
}

export class NewProjectDialog extends Component<Props, State> {
  @Identifiable('NewProjectDialog') public static readonly NAME: string

  public override state: State = {
    name: '',
    description: '',
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(NewProjectDialog.NAME)
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  private readonly handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  }

  private readonly handleSubmit = () => {
    const { name, description } = this.state
    const {
      featureFlags,
      openDialog,
      closeDialog,
      setCurrentProject,
      setCurrentSimulationCase,
      switchProject,
      setCurrentProjectCasesMetadata,
      t,
    } = this.props

    if (name && description) {
      this.setState({
        loading: true,
      })

      ApiClient
        .post(`${useConfig().apiBaseURL}/projects`, { data: { name, description } })
        .then(({ project, newCase }: any) => {
          const slimVersion = FeatureFlags.usesSlimVersion(featureFlags)

          switchProject()
          setCurrentProject(project)
          setCurrentProjectCasesMetadata([ { id: newCase.id, createdAt: new Date(newCase.createdAt) } ])
          setCurrentSimulationCase(newCase, slimVersion)

          closeDialog(NewProjectDialog.NAME)

          if (!slimVersion) {
            openDialog(ProjectDataDialog.NAME)
          }
        })
        .catch((response) => {
          // eslint-disable-next-line no-console
          console.error(response)

          const { error } = response

          this.setState({
            error: error ? error.status : 'unknown',
            loading: false,
          })

          enqueueSnackbar(t(`${T}.create.error`), { autoHideDuration: 4000, variant: 'error' })
        })
    }
  }

  private readonly handleSwitchToOpen = () => {
    const { openDialog, closeDialog } = this.props

    closeDialog(NewProjectDialog.NAME)
    openDialog(OpenProjectDialog.NAME)
  }
  
  public override render () {
    const { name, description, error, loading } = this.state
    const { t } = this.props
    const submitDisabled = loading || !(name.length > 0 && description.length > 0)

    return (
      <BaseDialog
        id={DialogID.CreateProject.ID}
        title={t(`${T}.title`)}
        icon='pe-7s-folder'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            id={DialogID.CreateProject.NameInput}
            label={t(`${T}.name.label`)}
            title={t(`${T}.name.label`)}
            placeholder={t(`${T}.name.placeholder`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Input
            id={DialogID.CreateProject.DescriptionInput}
            label={t(`${T}.description.label`)}
            title={t(`${T}.description.label`)}
            placeholder={t(`${T}.description.placeholder`)}
            name='description'
            type='text'
            rows={4}
            value={description}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button
            id={DialogID.CreateProject.CreateButton}
            type='primary'
            title={submitDisabled ? t(`${T}.create.disabled`) : ''}
            disabled={submitDisabled}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            icon='pe-7s-plus'
            isRef
          >
            {t(`${T}.create.label`)}
          </Button>
          <Button
            value=''
            onClick={this.handleSwitchToOpen}
            title={t(`${T}.open`)}
          >
            {t(`${T}.open`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(NewProjectDialog)

export default hoistStatics(composedComponent, NewProjectDialog)
