import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import styled, { css } from 'styled-components'

import { ResizeDetector } from '@/react/ResizeDetector'

const pad = 20

function getIcon ({ icon }: any, color: string) {
  // eslint-disable-next-line no-undef
  return btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon[0]} ${icon[1]}">
      <path fill="${color}" d="${icon[4]}" class=""></path>
    </svg>
  `)
}

export const Wrapper = styled(ResizeDetector)`${({ theme }) =>
  css`
  height: 100%;
  position: relative;
  padding: ${pad}px;
  overflow-x: hidden;
  overflow-y: scroll;

  &:hover {
    &[data-arrow-down="on"]:after,
    &[data-arrow-up="on"]:before {
      content: '';
      position: fixed;
      z-index: 10;
      height: 20px;
      display: inline-block;
    }

    &[data-arrow-up="on"]:before {
      background: url('data:image/svg+xml;base64,${getIcon(faChevronUp, theme.colors.swatch9)}') transparent no-repeat;
      background-position: center center;
      background-size: auto;
      top: 40px;
      width: 20px;
      left: calc(50% - 40px);
    }

    &[data-arrow-down="on"]:after {
      background: url('data:image/svg+xml;base64,${getIcon(faChevronDown, theme.colors.swatch9)}')
        transparent no-repeat;
      background-position: center center;
      background-size: auto;
      bottom: 20px;
      width: 20px;
      left: calc(50% - 40px);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`}`

export const TextContainer = styled.div`${({ theme }) =>
  css`
  display: flex;
  align-items: center;
  line-height: 1.2;
  font-weight: 400;
  border-bottom: solid 2px ${theme.borderColor};
`}`

export const Label = styled.span`${() =>
  css`
  font-size: 15px;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`}`

export const Value = styled.span`${({ theme }) =>
  css`
  flex: 1;
  font-size: 32px;
  text-align: right;
  font-weight: 500;
  color: ${theme.colors.swatch8};
`}`
