import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { Spacer } from '@/react/dialogs/project/OpenProjectDialog/Styles'
import Input from '@/react/specific/Input'
import VisUtil from '@/react/visualization/VisUtil'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import { markers } from './Markers'
import ColorPicker from '../../ColorPicker'
import { CheckListHeader, CheckListItem, FormWrapper, InputWrapper, Marker } from '../../Dialogs/DialogStyles'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean
  t: Translation
}

type State = {
  tileConfig: any
}

class ShowTab extends Component<Props, State> {
  private timeoutRef?: number

  public constructor (props: Props) {
    super(props)

    const { tileConfigs, currentTileId } = props

    this.state = {
      tileConfig: currentTileId ? { ...tileConfigs[currentTileId] } : {},
    }
  }

  public override componentDidUpdate = (pervProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!isEqual(prevState.tileConfig, tileConfig ?? {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        delete this.timeoutRef
      }, 100)
    }
  }

  public override componentWillUnmount () {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
      saveTileConfig(tileConfig)
    }
  }

  private readonly handleCheckList = (event: any) => {
    const { id } = event.target
    const { tileConfig } = this.state
    const legendOptions = tileConfig.legendOptions ?? {}

    this.setState({
      tileConfig: {
        ...tileConfig,
        legendOptions: {
          ...legendOptions,
          [id]: {
            ...(legendOptions?.[id] ?? {}),
            hide: !legendOptions?.[id]?.hide,
          },
        },
      },
    })
  }

  private readonly handleLegendOptions = (event: any, fallbackId?: string) => {
    const { id, name, value } = event.target
    const { tileConfig } = this.state
    const legendOptions = tileConfig.legendOptions ?? {}

    this.setState({
      tileConfig: {
        ...tileConfig,
        legendOptions: {
          ...legendOptions,
          [id || fallbackId]: {
            ...(legendOptions[id || fallbackId] ?? {}),
            [name]: value,
          },
        },
      },
    })
  }

  private readonly handleHideLine = (event: any) => {
    const { id } = event.target
    const { tileConfig } = this.state
    const legendOptions = tileConfig.legendOptions ?? {}
    const markerSymbolConfig = legendOptions?.[id]?.markerSymbol

    this.setState({
      tileConfig: {
        ...tileConfig,
        legendOptions: {
          ...legendOptions,
          [id]: {
            ...legendOptions?.[id] ?? {},
            hideLine: !legendOptions?.[id]?.hideLine,
            markerSymbol: markerSymbolConfig === undefined || markerSymbolConfig === 'none'
              ? 'circle'
              : markerSymbolConfig,
          },
        },
      },
    })
  }
  
  public override render () {
    const { editDialogConfigId, plotConfigs, fullscreen, t } = this.props
    const { tileConfig } = this.state

    if (!tileConfig) {
      return null
    }

    const { configIds } = plotConfigs[editDialogConfigId]
    const { configId, legendOptions } = tileConfig
    const typeIsLine = Boolean(tileConfig.type === 'line')

    let markerSizes: Selector[]
    let markerSymbols: Selector[]

    if (typeIsLine) {
      markerSizes = [ 1, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 36 ].map(number => ({
        key: number,
        value: String(number),
      }))

      markerSymbols = Object
        .keys(markers)
        .map(key => ({
          key,
          value: (
            <span title={key}>
              <svg viewBox='-16 -16 32 32' width='24' height='24' className='ply-icon'>
                <path d={markers[key]} />
              </svg>
            </span>
          ),
        }))

      markerSymbols.unshift({
        key: 'none',
        value: (
          <span title='None'>
            <svg viewBox='0 0 16 16' width='24' height='24' className='ply-icon-none'>
              <line x1='2' y1='14' x2='14' y2='2' />
            </svg>
          </span>
        ),
      })
    }

    return (
      <FormWrapper>
        <CheckListHeader $width='99px'>{t('plotConfig.display')}</CheckListHeader>
        <CheckListHeader $width={typeIsLine ? '190px' : '311px'}>{t('plotConfig.legend')}</CheckListHeader>
        <CheckListHeader $width={typeIsLine ? '190px' : '311px'}>{t('plotConfig.trace')}</CheckListHeader>
        <CheckListHeader $width={typeIsLine ? '51px' : '35px'}>{t('plotConfig.color')}</CheckListHeader>
        {typeIsLine && <CheckListHeader $width='76px'>{t('plotConfig.hideLine')}</CheckListHeader>}
        {typeIsLine && <CheckListHeader>{t('plotConfig.marker')}</CheckListHeader>}
        {
          (configIds ?? [ configId ]).map((id, index) => (
            <InputWrapper key={`${id}-${index}`} $fullscreen={fullscreen}>
              <CheckListItem
                id={id}
                onClick={this.handleCheckList}
                $active={legendOptions?.[id]?.hide}
              >
                {t('plotConfig.index', { index: index + 1 })}
              </CheckListItem>
              <Input
                id={id}
                placeholder={t('plotConfig.label.label')}
                title={t('plotConfig.label.title')}
                name='label'
                type='text'
                value={legendOptions?.[id]?.label ?? ''}
                onChange={this.handleLegendOptions}
              />
              <Input
                id={id}
                placeholder={t('plotConfig.tooltip.label')}
                title={t('plotConfig.tooltip.title')}
                name='tooltip'
                type='text'
                value={legendOptions?.[id]?.tooltip ?? ''}
                onChange={this.handleLegendOptions}
              />
              <ColorPicker
                color={legendOptions?.[id]?.color ?? VisUtil.TRACE_COLORS[index] ?? ''}
                colors={VisUtil.TRACE_COLORS}
                id={id}
                name='color'
                onChange={this.handleLegendOptions}
              />
              {
                typeIsLine && (
                  <Marker>
                    <Spacer $w={30} />
                    <CheckListItem
                      style={{ width: '28px', padding: 0 }}
                      id={id}
                      onClick={this.handleHideLine}
                      $active={!legendOptions?.[id]?.hideLine}
                    />
                    <Spacer $w={30} />
                    <div style={{ width: '64px' }}>
                      <Input
                        name='markerSymbol'
                        title={t('plotConfig.markerSymbol')}
                        type='select'
                        value={legendOptions?.[id]?.markerSymbol ?? 'none'}
                        selectors={markerSymbols}
                        onChange={(event) => this.handleLegendOptions(event, id)}
                      />
                    </div>
                    <Spacer $w={20} />
                    <div style={{ width: '64px' }}>
                      <Input
                        id={id}
                        title={t('plotConfig.markerSize')}
                        name='markerSize'
                        type='select'
                        selectors={markerSizes}
                        value={legendOptions?.[id]?.markerSize ?? 6}
                        onChange={(event) => this.handleLegendOptions(event, id)}
                      />
                    </div>
                    <Spacer $w={15} />
                    <ColorPicker
                      color={
                        legendOptions?.[id]?.markerColor ??
                    legendOptions?.[id]?.color ??
                    VisUtil.TRACE_COLORS[index] ??
                      ''
                      }
                      colors={VisUtil.TRACE_COLORS}
                      id={id}
                      name='markerColor'
                      onChange={this.handleLegendOptions}
                    />
                  </Marker>
                )
              }
            </InputWrapper>
          ))
        }
      </FormWrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(ShowTab)
