import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags'
import DataActions from '@/store/data/actions'
import * as FilterActions from '@/store/filter/actions'
import { DefaultState, ElementName, MountLogMapKey } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import ModuleType from './types'
import { Trigger } from '../type/TypeHeaderStyles'

const StyledModule = styled.div<{ $isSelected: boolean }>`${({ $isSelected }) =>
  css`
width: 100%;
color: #CCCCCC;
border-top: solid 1px #000000;
border-bottom: solid 1px #000000;
margin-bottom: -1px;
position: relative;
background-color: ${$isSelected ? '#0777BD' : '#3c3c3c'};
`}`

export const NameBoxForModules = styled.div<{ $pad: number }>`${({ $pad }) =>
  css`
  display: inline-block;
  height: 50px;
  width: calc(100% - 50px);
  vertical-align: top;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-left: ${$pad}px;
  line-height: 54px;
  user-select: none;
  cursor: pointer;

  span {
    display: inline-block;
    width: calc(100% - 50px);
    text-overflow: ellipsis;
    overflow: hidden;
  }
`}`

export const Filter = styled.span<{ $right?: number, $active?: boolean }>`${({ $right, $active }) =>
  css`
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  right: ${$right ?? 60}px;
  font-size: 20px;
  cursor: pointer;
  
  i {
    font-weight: 600;
  }

  svg {
    color: ${$active ? '#E1071B' : '#CCCCCC'};
  }
`}`

const connector = connect((state: DefaultState) => ({
  moduleMap: state.modules,
  term: state.filter.term,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setTerm: FilterActions.setTerm,
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  moduleName: string
}

type State = {
  isOpen: boolean
  isSelected: boolean
}

export class Module extends React.Component<Props, State> {
  public override state: State = {
    isOpen: false,
    isSelected: false,
  }

  private readonly handleToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  private readonly handleFilterModule = () => {
    const { moduleName, moduleMap, setTerm, setSelectedElementPaths, term } = this.props
    const types = Object.keys(moduleMap[moduleName])
    let filter = ''

    for (const type of types) {
      const realType = ElementMapsUtil.getTagName(type.replace('MountLog', '') as ElementName)

      filter += `${realType}#module=${moduleName} `
    }

    if (term === filter) {
      setTerm('')
    }
    else {
      setTerm(filter)
    }

    setSelectedElementPaths()
  }

  private readonly getIsFilterActive = () => {
    const { moduleName, moduleMap, term } = this.props
    const types = Object.keys(moduleMap[moduleName])
    let filter = ''
    
    for (const type of types) {
      const realType = ElementMapsUtil.getTagName(type.replace('MountLog', '') as ElementName)

      filter += `${realType}#module=${moduleName} `
    }

    return term.trim() === filter.trim()
  }

  public override render () {
    const { moduleName, moduleMap, featureFlags } = this.props
    const { isOpen } = this.state
    const types = Object.keys(moduleMap[moduleName]) as MountLogMapKey[]
    const isFilterActive = this.getIsFilterActive()
    const canFilterPerModule = FeatureFlags.canFilterPerModule(featureFlags)

    return (
      <div>
        <StyledModule $isSelected={false} title={moduleName}>
          <NameBoxForModules $pad={20} onClick={this.handleToggleOpen}>
            {moduleName}
          </NameBoxForModules>
          {
            canFilterPerModule && (
              <Filter onClick={this.handleFilterModule} $active={isFilterActive}>
                <FontAwesomeIcon icon={faFilter} title='Filter Group' />
              </Filter>
            )
          }
          <Trigger onClick={this.handleToggleOpen}>
            <i className={`pe-7s-angle-${isOpen ? 'down' : 'right'}`} />
          </Trigger>
        </StyledModule>
        {isOpen && types.map((type, index) => <ModuleType type={type} module={moduleName} key={index} />)}
      </div>
    )
  }
}

export default compose<any>(connector)(Module)
