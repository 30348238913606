import { request } from '@/api'
import { CasterElementNames } from '@/types/data'

const PATH = 'temporal-data'

export type TemporalDataResponse = Array<Array<any>>
export type TypeAndIdNeededData = `${CasterElementNames}|${string}`

// the function will receive an array of strings, these strings contain the ids of the temporal data that we want to get
// @param neededData: string[] - array of strings - the syntax is: 'type|id'
export function getTemporalData (neededData: TypeAndIdNeededData[]) {
  const errorText = 'Failed to get temporal data' // TODO: translate message

  return request<TemporalDataResponse | null>('get', String(PATH), errorText, { params: { neededData } })
}
