import type { ElementMaps } from '@/types/state'

import { Mapping } from './mapping/Mapping'

export function getMissingSegTypeSegmentGroupMountLogIds (
  elementMaps: ElementMaps,
  selectedPaths: Set<string> = new Set<string>(),
) {
  const { SegmentGroupMountLog } = elementMaps
  const missingSegTypeSegmentGroupMountLogIds = new Set<string>()
  const selectedSegmentGroupMountLogIds = new Set<string>()
  const idStart = 13 // 13 = 'SegmentGroup:'.length or path.indexOf(':') + 1

  for (const path of selectedPaths) {
    if (!/^SegmentGroup:\d+$/.test(path)) {
      continue
    }

    selectedSegmentGroupMountLogIds.add(
      Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[Number(path.substring(idStart))],
    )
  }

  for (const segmentGroupMountLogId in SegmentGroupMountLog) {
    // if selection is given but the current segment group mount log is not selected, skip
    if (selectedSegmentGroupMountLogIds.size && !selectedSegmentGroupMountLogIds.has(segmentGroupMountLogId)) {
      continue
    }

    const { segType } = SegmentGroupMountLog[segmentGroupMountLogId]

    // if the segType fo the current segment group mount log is not present of invalid, set it as missing
    if (!segType || !/^Seg-\d+\/\d+$/.test(segType)) {
      missingSegTypeSegmentGroupMountLogIds.add(segmentGroupMountLogId)
    }
  }

  return missingSegTypeSegmentGroupMountLogIds
}

const avoidedKeys = new Set([ 'mountLogHash', 'createdAt', 'mountedAt', 'removedAt', 'updatedAt', 'removedAt', 'id' ])

export function generateChangeLogs (orderedMountLogs: any[]) {
  // go over all mount logs and create a change log for each change
  const changeLogs: {
    firstInterval: string
    lastInterval: string
    changedKeys: Record<string, { from: any, to: any }>
  }[] = []

  for (let i = 1; i < orderedMountLogs.length; i++) {
    const current = orderedMountLogs[i]
    const previous = orderedMountLogs[i - 1]
    const changeLog = {
      firstInterval: previous.mountedAt,
      lastInterval: current.removedAt ?? 'now',
    } as any

    for (const key in current) {
      if (key === 'additionalData') {
        const uniqueAdditionalDataKeys = new Set([
          ...Object.keys(current.additionalData),
          ...Object.keys(previous.additionalData),
        ])

        for (const additionalDataKey of uniqueAdditionalDataKeys) {
          if (current.additionalData[additionalDataKey] !== previous.additionalData[additionalDataKey]) {
            if (!changeLog.changedKeys) {
              changeLog.changedKeys = {}
            }

            changeLog.changedKeys[additionalDataKey] = {
              from: previous.additionalData[additionalDataKey],
              to: current.additionalData[additionalDataKey],
            }
          }
        }
      }
      else if (!avoidedKeys.has(key) && current[key] !== previous[key]) {
        if (!changeLog.changedKeys) {
          changeLog.changedKeys = {}
        }

        changeLog.changedKeys[key] = {
          from: previous[key],
          to: current[key],
        }
      }
    }

    changeLogs.push(changeLog)
  }

  return changeLogs
}
