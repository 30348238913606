import cloneDeep from 'lodash/cloneDeep'
import hash from 'object-hash'

export default class Util {
  public static readonly defaultPlotConfig = {
    name: 'Plot',
    type: 'line',
    xRangeMin: 0,
    xRangeMax: 40,
    vRangeMin: 0,
    vRangeMax: 2000,
    radiusMin: 0,
    radiusMax: 3,
    radius0: 0,
    radius: 3,
    frequency: 4,
    xValues: [],
    flipAxes: false,
    decimals: 2,
    shapesDisabled: false,
  }

  public static readonly defaultTileConfig = {
    name: '',
    configId: 'Plot',
    additionalYAxes: [],
    shapeIds: [],
    legendOptions: {},
  }

  public static generateConfigHash (...parts: any[]) {
    const id = parts.join('😋')

    return hash.sha1(id)
  }

  public static getConfigIdsHash (parts: any[]) {
    return parts.map(id => id.substring(id.indexOf('_') + 1)).join('_')
  }

  private static getConfig (metaList: any, configs: any, oldNewMappingConfigIds: any = {}) {
    const oldKeys = Object.keys(configs)

    return Object.values(configs ?? {}).reduce((accumulator: any, meta: any) => {
      const newMeta = { ...meta }

      const xCoordinates: any = Object
        .values(metaList)
        .filter(({ group, key }: any) => group === newMeta.group && /xCoordinates/.test(key))[0] ?? {}
      const xDomainMin = (xCoordinates.range ?? [])[0] ?? 0
      const xDomainMax = (xCoordinates.range ?? [])[1] || 40
      const xRangeMax = meta.length || 700
      const vRangeMax = newMeta.group === '2DData_MidThickPlane' ? 1 : Util.defaultPlotConfig.vRangeMax
      let id: any = ''

      if (newMeta.configIds && newMeta.configIds.length > 0) {
        const hash = Util.getConfigIdsHash(newMeta.configIds)

        if (newMeta.group === 'derived') {
          const xValue = newMeta.id.substring(newMeta.id.lastIndexOf('_') + 1)

          id = `merged_${hash}_derived_${String(xValue).replace(/\./g, '🍓')}`
        }
        else {
          id = `merged_${hash}`
        }
      }
      else {
        id = newMeta.dataSourceId
      }

      const newId = `config_${id}`
      const index = oldKeys.indexOf(newMeta.id)

      oldNewMappingConfigIds[oldKeys[index]] = newId

      delete newMeta.id

      const yValueRanges = [
        ...newMeta.yValueRange ?? [],
        ...configs[newId]?.yValueRange ?? [],
      ]

      return ({
        ...accumulator,
        [newId]: {
          ...Util.defaultPlotConfig,
          id: newId,
          xDomainMin,
          xDomainMax,
          xRangeMax,
          vRangeMax,
          ...newMeta,
          yValueRange: [ // TODO: this works but gets overwritten later on
            Math.min(...yValueRanges),
            Math.max(...yValueRanges),
          ],
        },
      })
    }, {}) ?? {}
  }

  private static parseViewsObject (viewsObject: any): any {
    if (typeof viewsObject === 'object') {
      const keys = Object.keys(viewsObject ?? {})
      const tileIds = keys.filter(key => /^tile_/.test(key))

      if (tileIds.length > 0) {
        tileIds.forEach(key => delete viewsObject[key])

        viewsObject.tileIds = tileIds

        return viewsObject
      }
      else {
        return keys.forEach(key => Util.parseViewsObject(viewsObject[key]))
      }
    }

    return viewsObject
  }

  public static updateConfig (params: any) {
    const { plotConfigs, tileConfigs, viewsObject } = params ?? {}

    let newPlotConfig = cloneDeep(plotConfigs ?? {})

    Object.values(newPlotConfig).forEach((pConfig: any) => {
      if (newPlotConfig[pConfig.id].configIds && pConfig.configIds) {
        newPlotConfig[pConfig.id].configIds = pConfig.configIds.map((id: string) => {
          const { key } = plotConfigs[id]

          return key
        })
      }
    })

    const oldNewMappingConfigIds: any = {}

    newPlotConfig = Util.getConfig(Object.values(newPlotConfig), newPlotConfig, oldNewMappingConfigIds)

    const newTileConfig = cloneDeep(tileConfigs)

    Object.values(tileConfigs ?? {}).forEach((tileConfig: any) => {
      const tileConfigId = tileConfig.id ?? tileConfig.tileId

      if (tileConfigs[tileConfigId]) {
        const newConfigId = oldNewMappingConfigIds[tileConfig.configId]

        newTileConfig[tileConfigId] = {
          ...tileConfigs[tileConfigId],
          configId: newConfigId || tileConfigs[tileConfigId].configId,
          id: tileConfig.id,
        }
      }
    })

    const newViewsObject = cloneDeep(viewsObject)

    Util.parseViewsObject(newViewsObject)

    return {
      ...params,
      viewsObject: newViewsObject,
      plotConfigs: newPlotConfig,
      tileConfigs: newTileConfig,
    }
  }

  public static plotConfigMapping (metaList: any, prevPlotConfigs: any) {
    const prevPlotConfigList: any = Util.getConfig(metaList, prevPlotConfigs)
    const plotConfigs: any = Util.getConfig(metaList, metaList)

    return { ...plotConfigs, ...prevPlotConfigList } // TODO: this overrides changed made by getConfig!!!
  }

  private static internalGetCurrentDashboards (
    key: string,
    view: any,
    viewsObject: any,
    currentDashboard: Record<string, string | undefined>,
  ) {
    if (!view) {
      return
    }

    const split = view.split ? (view.split.vertical || view.split.horizontal) : null

    if (split) {
      Util.internalGetCurrentDashboards(split[0], viewsObject[split[0]], viewsObject, currentDashboard)
      Util.internalGetCurrentDashboards(split[1], viewsObject[split[1]], viewsObject, currentDashboard)

      return
    }

    const dashboardKeys = Object.keys(view.dashboards)

    if (!currentDashboard[key] || !dashboardKeys.includes(currentDashboard[key] as any)) {
      currentDashboard[key] = dashboardKeys[0]
    }
  }

  public static getCurrentDashboards (viewsObject: any, currentDashboard: Record<string, string | undefined>) {
    if (!viewsObject) {
      return ({
        error: true,
      })
    }

    const keys = Object.keys(viewsObject)

    if (!viewsObject[keys[0]]) {
      return ({
        error: true,
      })
    }

    Util.internalGetCurrentDashboards(keys[0], viewsObject[keys[0]], viewsObject, currentDashboard)

    return currentDashboard
  }

  public static removeDynamicDataSourceFromConfigs (oldConfigs: any, keepKeys: string[]) {
    for (const key in oldConfigs) {
      if (oldConfigs[key].group === 'dynamicDataSource' || oldConfigs[key].isMergedDynamicDataSource) {
        if (
          keepKeys.includes(key) ||
          keepKeys.includes(oldConfigs[key].key) ||
          (oldConfigs[key].isMergedDynamicDataSource && oldConfigs[key]
            .configs
            .find((cnf: any) => keepKeys.includes(cnf.key)))
        ) {
          continue
        }

        delete oldConfigs[key]
      }
    }
  }

  public static handleNoConfigIds (origConfig: any) {
    origConfig.configIds = [ origConfig.id ]

    const hash = origConfig.id.substring(origConfig.id.lastIndexOf('_') + 1)

    origConfig.id = `config_merged_${hash}` // TODO: check if correct
    delete origConfig.key
  }

  public static getVRange (plotType: string) {
    const vRangeMin = plotType === 'contour' ? 500 : 0
    const vRangeMax = plotType === 'contour' ? 1600 : 2000

    return { vRangeMin, vRangeMax }
  }

  public static deleteTile (data: any, tileId: string) {
    if (data instanceof Array && data.includes(tileId)) {
      data.splice(data.indexOf(tileId), 1)
    }

    if (data instanceof Object) {
      Object.keys(data).forEach(key => {
        Util.deleteTile(data[key], tileId)
      })
    }
  }
}
