import hoistStatics from 'hoist-non-react-statics'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import IpcManager from '@/IpcManager'
import Button from '@/react/components/Button'
import BaseDialog from '@/react/dialogs/BaseDialog'
import FeatureFlags from '@/react/FeatureFlags'
import { Form, Text } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { ArrayOfTranslations, Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

const T = 'downloadXMLDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  params: state.application.main.params,
  executionStateDictionary: state.data.executionStateDictionary,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: ArrayOfTranslations & Translation
}

export class DownloadXMLDialog extends React.Component<Props> {
  @Identifiable('DownloadXMLDialog') public static readonly NAME: string

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(DownloadXMLDialog.NAME)
  }

  private readonly handleDownloadXML = () => {
    IpcManager.send('saveCasterXML')
  }

  public override render () {
    const { params, t } = this.props
    const { name, downloadCasterDescription } = (params?.definition ?? {}) as ExecutableDefinition

    return (
      <BaseDialog
        title={name}
        icon='pe-7s-settings'
        header={name}
        onClose={this.handleClose}
        hasScroll
        small
        contentMinHeight={150}
        headerWidth='390px'
      >
        <Form $minHeight={150}>
          <Text>
            {downloadCasterDescription ?? 'Download Caster XML'}
          </Text>
          <Button
            title={t(`${T}.button.download`)}
            type='primary'
            onClick={this.handleDownloadXML}
          >
            {t(`${T}.button.download`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(DownloadXMLDialog)

export default hoistStatics(composedComponent, DownloadXMLDialog)
