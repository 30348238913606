import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Button from '@/react/components/Button'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { FixedButton } from '@/react/dialogs/DialogStyles'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

const T = 'projectMatrixDialog.cloneDialog'

const connector = connect((state: DefaultState) => ({
  cloneSimulationCaseId: state.matrix.cloneSimulationCaseId,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  cloneCount: string
  error: string
  loading: boolean
}

export class CloneDialog extends Component<Props, State> {
  @Identifiable('CloneDialog') public static readonly NAME: string

  public override state: State = {
    cloneCount: '1',
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CloneDialog.NAME)
  }

  private readonly handleCloneCountChange =
    (event: any) => this.setState({ cloneCount: String(event.target.value).replace('-', '') })

  private readonly handleCloneSubmit = () => {
    const { cloneCount } = this.state
    const { cloneSimulationCaseId, setCurrentProject } = this.props

    this.setState({ loading: true })

    ApiClient
      .post(`${'Network.URI(deprecated)'}/simulation_case/clone/${cloneSimulationCaseId}`, { data: { cloneCount } })
      .then(({ project }) => {
        if (project && project.id) {
          setCurrentProject(project)

          this.handleClose()

          const content = document.querySelector('.data-grid-container')?.parentNode as HTMLElement | null | undefined

          if (content) {
            content.scrollTop = content.scrollHeight
          }
        }

        this.setState({ loading: false })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        this.setState({ loading: false, error: 'ERROR' })
      })
  }
  
  public override render () {
    const { cloneCount, error, loading } = this.state
    const { t } = this.props

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-photo-gallery'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            autoFocus
            label={t(`${T}.label`)}
            title={t(`${T}.name`)}
            name='name'
            type='number'
            value={cloneCount}
            onChange={this.handleCloneCountChange}
          />
          <FixedButton>
            <Button
              type='primary'
              disabled={!cloneCount || cloneCount === '0'}
              onClick={this.handleCloneSubmit}
              error={error}
              loading={loading}
              icon='pe-7s-photo-gallery'
              isRef
            >
              {t(`${T}.clone`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(CloneDialog)

export default hoistStatics(composedComponent, CloneDialog)
