import { FC } from 'react'
import { withTranslation } from 'react-i18next'
import { type ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { openDialog } from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Vector2D } from '@/types/visualization'

import CreateRealDataDialog from '../dialogs/project/CreateRealDataDialog'
import { MenuID } from '../driver/DriverID'
import FeatureFlags from '../FeatureFlags'

const ContextMenuBackdrop = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10000;
`

const ContextMenu = styled.div<{ $mousePosition: Vector2D }>`${({ $mousePosition }) => css`
  position: absolute;
  top: ${$mousePosition.y}px;
  left: ${$mousePosition.x}px;
  z-index: 10001;
  background-color: #34393e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #373f49;
`}`

const MenuEntry = styled.button`${({ disabled }) => css`
  padding: 10px 12px 8px 12px;
  color: white;
  background-color: #34393e;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  border: none;

  &:not(:first-child) {
    border-top: 1px solid #373f49;
  }

  &:hover {
    background-color: #40444b;
  }

  ${disabled && css`
    color: #666;
    cursor: not-allowed;
  `}
`}`

const connector = connect(
  (state: DefaultState) => ({
    currentSimulationCase: state.application.main.currentSimulationCase,
    featureFlags: FeatureFlags.getRealFeatureFlags(state),
  }), 
  {
    openDialog,
  },
)

type Props = {
  mousePosition: Vector2D | null
  onClose: () => void
}

type FullProps = ConnectedProps<typeof connector> & Props & { t: (key: string) => string }

const { Backdrop, CreateRealData } = MenuID.MenuTreeView
const T = 'treeView.contextMenu'

const TreeViewContextMenu: FC<FullProps> = (props) => {
  const { mousePosition, currentSimulationCase, featureFlags, onClose: handleClose, openDialog, t } = props

  if (!mousePosition) {
    return null
  }

  const canViewCreateRealData = FeatureFlags.canViewCreateRealDataContextMenuOption(featureFlags)

  const canViewContextMenu = canViewCreateRealData // || ...

  if (!canViewContextMenu) {
    return null
  }

  // if blueprintId is set, it's a real data case
  const realDataDisabled = (
    !currentSimulationCase?.currentCasterId ||
    Boolean(currentSimulationCase?.blueprintId) ||
    !FeatureFlags.canUseCreateRealDataContextMenuOption(featureFlags)
  )

  function handleCreateRealData () {
    openDialog(CreateRealDataDialog.NAME)
    handleClose()
  }

  return (
    <ContextMenuBackdrop id={Backdrop} onClick={handleClose} onContextMenu={handleClose}>
      <ContextMenu $mousePosition={mousePosition}>
        {
          canViewCreateRealData && (
            <MenuEntry id={CreateRealData} disabled={realDataDisabled} onClick={handleCreateRealData}>
              {t(`${T}.createRealData`)}
            </MenuEntry>
          )
        }
      </ContextMenu>
    </ContextMenuBackdrop>
  )
}

export default compose<FC<Props>>(withTranslation('application'), connector)(TreeViewContextMenu)
