import TWEEN from '@tweenjs/tween.js'
import * as THREE from 'three'
import { Material, PerspectiveCamera, Vector3 } from 'three'

import FilterHandler from '@/three/logic/FilterHandler'
import Util from '@/three/logic/Util'
import { FilterableElementType } from '@/types/filter'
import type { ElementMaps } from '@/types/state'
import { dispatchTestingEvent } from '@/Util/testingUtil'

import MainView from '.'
import CalculationUtil from './CalculationUtil'
import ConditionUtil from './ConditionUtil'

export default class Getters {
  public static getContainer (
    view: MainView,
    container: any,
    caster: THREE.Group,
    path: string,
    type: string,
    element: any,
  ) {
    if (type === 'SegmentGroup' || type === 'Strand') {
      container = caster
    }

    if (type === 'Strand') {
      if (!view.containerList[type]) {
        view.containerList[type] = new THREE.Group()
        view.containerList[type].userData = {
          type,
        }
      }

      container.add(view.containerList[type])

      return view.containerList[type]
    }

    if (!view.containerList[type]) {
      view.containerList[type] = {}
    }

    if (!view.containerList[type][path]) {
      view.containerList[type][path] = new THREE.Group()
      view.containerList[type][path].userData = {
        path,
        type,
      }

      if (type === 'Segment') {
        view.containerList[type][path].userData.side = element.side
        view.containerList[type][path].userData.angle = Util.sides2Angles(element.side)
        Getters.generateSideLabel(view, Util.sides2Angles(element.side))
      }

      if (container.userData.type === type) {
        const parentInfo = Util.getParentInfo(path)
        const parentContainer = view.containerList[parentInfo.type][parentInfo.path]
        const childContainer = view.containerList[type][path]

        if (parentContainer && childContainer) {
          parentContainer.add(childContainer)
        }
      }
      else {
        container.add(view.containerList[type][path])
      }
    }

    return view.containerList[type][path]
  }

  public static getSelectedElementsPaths (
    camera: PerspectiveCamera,
    min: Coord,
    max: Coord,
    clickableObjects: THREE.Object3D[],
  ) {
    const frustum = new THREE.Frustum()
    const cameraViewProjectionMatrix = new THREE.Matrix4()

    camera.updateMatrixWorld()
    // camera.matrixWorldInverse.getInverse(camera.matrixWorld)
    camera.matrixWorldInverse = camera.matrixWorld.clone().invert()

    cameraViewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
    // ;(frustum as any).setFromMatrix(cameraViewProjectionMatrix)
    frustum.setFromProjectionMatrix(cameraViewProjectionMatrix)

    return clickableObjects
      .filter(object => frustum.intersectsObject(object))
      .filter(object => Util.isVisible(object))
      .map(object => {
        const vector = new THREE.Vector3()

        object.updateMatrixWorld()
        vector.setFromMatrixPosition(object.matrixWorld)

        vector.project(camera)
        ;(object as any).posOnScreen = {
          x: vector.x,
          y: -vector.y,
        }

        return object
      })
      .filter(({ posOnScreen }: any) => ConditionUtil.selectionIsOnScreen(min, max, posOnScreen))
      .filter(object => object.userData.type !== 'Strand' && object.userData.type !== 'SegmentLabel')
      .map(object => object.userData.path)
  }

  private static generateSideName (font: any, fontMaterial: Material, plHeight: number, n: number) {
    const sideName = Util.getText(Util.angles2Sides(n.toString()), 0.15, Boolean(font), Boolean(fontMaterial))

    if (!sideName) {
      return null
    }

    sideName.position.set(0, plHeight + 0.45 + 0.5, 0)

    return sideName
  }

  private static generateAngleText (font: any, fontMaterial: Material, plHeight: number, n: number) {
    const angleText = Util.getText(`${n}°`, 0.3, Boolean(font), Boolean(fontMaterial))

    if (!angleText) {
      return null
    }

    angleText.position.set(0.06, plHeight + 0.45, 0)

    return angleText
  }

  private static generateLine (labelLineMaterial: Material, plHeight: number) {
    return Util.getLine(
      [
        new THREE.Vector3(0, 0, -1),
        new THREE.Vector3(0, plHeight + 0.25, -1),
      ],
      labelLineMaterial,
    )

    // TODO: remove old code
    // const geometry = new (THREE as any).Geometry()

    // geometry.vertices.push(
    //   new THREE.Vector3(0, 0, -1),
    //   new THREE.Vector3(0, plHeight + 0.25, -1),
    // )

    // return new THREE.Line(geometry, labelLineMaterial)
  }

  private static generateGroupWithSideLabel (
    font: any,
    fontMaterial: Material,
    plHeight: number,
    n: number,
    labelLineMaterial: Material,
    side: string,
  ) {
    const group = new THREE.Group()

    group.visible = false
    group.name = `SideLabel_${side}`

    const name = Getters.generateSideName(font, fontMaterial, plHeight, n)

    if (name) {
      group.add(name)
    }

    const angle = Getters.generateAngleText(font, fontMaterial, plHeight, n)

    if (angle) {
      group.add(angle)
    }

    group.add(Getters.generateLine(labelLineMaterial, plHeight))

    return group
  }

  public static getSegmentsFromSegmentGroup (
    elementMaps: ElementMaps,
    term: string,
    containerList: any,
  ) {
    const filteredElements = FilterHandler
      .getFilteredElements(elementMaps, term, false) as Record<string, FilterableElementType>

    const segmentElements = Object
      .entries(filteredElements)
      .filter(([ _path, type ]) => type === 'Segment')
      .map(([ path ]) => ({ path }))

    return segmentElements.map((element: any) => containerList.Segment[element.path])
  }

  public static getCameraTween (
    view: MainView,
    pos: Vector3,
    target: Vector3,
    xAngle: number | undefined,
    yAngle: number | undefined,
    center: Vector3,
    position: Vector3,
    duration: number,
    callback: () => void,
  ) {
    const calc = CalculationUtil.calcTween(pos, target, xAngle, yAngle, center, position)

    return new TWEEN.Tween(calc.tweenData)
      .to(calc.tweenTargetData, duration)
      .onUpdate(() => {
        const { xC, yC, zC, len, xR, yR } = calc.tweenData
        const c = new THREE.Vector3(xC, yC, zC)
        let p = calc.currentPosInCenter.clone()

        p.applyAxisAngle(Util.yAxis, -calc.currentRotations.y)
        p.applyAxisAngle(Util.xAxis, xR)
        p.applyAxisAngle(Util.yAxis, yR + calc.currentRotations.y)

        p = p.setLength(len).add(c).clone()

        view.setCameraPosition(p, c, view.camera, view.controls)
      })
      .onStop(() => {
        dispatchTestingEvent('cameraTweenStopped')

        callback()
      })
      .onComplete(() => {
        if (view.camera) {
          view.oldCameraPosition = view.camera.position.clone()
        }

        view.tweenActive = false

        dispatchTestingEvent('cameraTweenComplete')

        callback()
      })
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  private static generateSideLabel (view: MainView, n: number) {
    const side = Util.angles2Sides(n.toString())

    if (view.sideLabels[side]) {
      view.scene.remove(view.sideLabels[side])
    }

    const group = Getters.generateGroupWithSideLabel(
      view.font,
      view.fontMaterial,
      view.plHeight,
      n,
      view.labelLineMaterial,
      side,
    )

    Util.addOrReplace(view.scene, group)
    view.sideLabels[side] = group
  }
}
