import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { Translation } from '@/types/translation'

const Wrapper = styled.div`${({ theme }) =>
  css`
  height: 250px;
  width: 595px;
  overflow: auto;
  font-size: 14px;
  font-family: 'Roboto',sans-serif;
  font-weight: 300;

  caption {
    font-size: 20px;
    color: ${theme.colors.swatch15};
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 30px;

    i {
      font-size: 25px;
      vertical-align: bottom;
    }

    span {
      padding-bottom: 3px;
      border-bottom: 2px solid ${theme.colors.swatch15};
    }
  }

  th {
    text-transform: uppercase;
    padding: 0 5px 10px 5px;
  }

  td {
    padding: 0 5px;
    vertical-align: top;
  }

  td:not(:last-of-type) {
    white-space: nowrap;
    color: ${theme.colors.swatch15};
    font-weight: 900;
  }

  td:last-of-type {
    color: ${theme.colors.swatch9};
  }
`}`

const Yellow = styled.span`${({ theme }) =>
  css`
  color: ${theme.colors.swatch18};
`}`

const FUNCTIONS: Record<string, string> = {
  abs: 'abs(a)',
  acos: 'acos(a)',
  acosh: 'acosh(a)',
  asin: 'asin(a)',
  asinh: 'asinh(a)',
  atan: 'atan(a)',
  atanh: 'atanh(a)',
  atan2: 'atan2(a, b)',
  cbrt: 'cbrt(a)',
  ceil: 'ceil(a)',
  clz32: 'clz32(a)',
  cos: 'cos(a)',
  cosh: 'cosh(a)',
  exp: 'exp(a)',
  expm1: 'expm1(a)',
  floor: 'floor(a)',
  fround: 'fround(a)',
  hypot: 'hypot([a[, b[, …]]])',
  imul: 'imul(a, b)',
  log: 'log(a)',
  log1p: 'log1p(a)',
  log10: 'log10(a)',
  log2: 'log2(a)',
  max: 'max([a[, b[, …]]])',
  min: 'min([a[, b[, …]]])',
  pow: 'pow(a, b)',
  random: 'random()',
  round: 'round(a)',
  sign: 'sign(a)',
  sin: 'sin(a)',
  sinh: 'sinh(a)',
  sqrt: 'sqrt(a)',
  tan: 'tan(a)',
  tanh: 'tanh(a)',
  trunc: 'trunc(a)',
}

const CONSTANTS = [
  'E',
  'LN10',
  'LN2',
  'LOG10E',
  'LOG2E',
  'PI',
  'SQRT1_2',
  'SQRT2',
]

type Props = {
  t: Translation
}

class DynamicFormulaInfo extends Component<Props> {
  private getHighlightedFunction (functionName: string) {
    const match = FUNCTIONS[functionName]?.match(/^(\w+\()(.*)(\))$/)

    if (!match) {
      return functionName
    }

    return (
      <span>
        {match[1]}
        <Yellow>{match[2]}</Yellow>
        {match[3]}
      </span>
    )
  }
  
  public override render () {
    const { t } = this.props

    return (
      <Wrapper>
        <table cellPadding='0' cellSpacing='0'>
          <caption>
            <i className='pe-7s-graph1' /> <span>{t('dynamicFormulaInfo.function.heading')}</span>
          </caption>
          <thead>
            <tr>
              <th>{t('dynamicFormulaInfo.function.function')}</th>
              <th>{t('dynamicFormulaInfo.function.description')}</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(FUNCTIONS).map(functionName => (
                <tr key={functionName}>
                  <td>{this.getHighlightedFunction(functionName)}</td>
                  <td>{t(`dynamicFormulaInfo.${functionName}`)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <br />
        <table cellPadding='0' cellSpacing='0'>
          <caption>
            <i className='pe-7s-lock' /> <span>{t('dynamicFormulaInfo.constant.heading')}</span>
          </caption>
          <thead>
            <tr>
              <th>{t('dynamicFormulaInfo.constant.constant')}</th>
              <th>{t('dynamicFormulaInfo.constant.description')}</th>
            </tr>
          </thead>
          <tbody>
            {
              CONSTANTS.map(constant => (
                <tr key={constant}>
                  <td>{constant}</td>
                  <td>{t(`dynamicFormulaInfo.${constant}`)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </Wrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'))(DynamicFormulaInfo)
