import styled, { css, keyframes } from 'styled-components'

export const DashboardWrapper = styled.div<{ $maxDashboardWidth?: number }>`${({ theme, $maxDashboardWidth }) =>
  css`
  position: absolute;
  top: 43px;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 500px;
  max-width: ${$maxDashboardWidth}px;
  z-index: 100;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background-color: ${theme.mainBackground};
  border: solid ${theme.colors.swatch1};
  border-width: 16px;
  border-top: none;
`}`

const fade = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const pulse = css`${({ theme }) =>
  keyframes`
  0% {
    color: ${theme.mainFontColor};
  }
  40% {
    color: ${theme.colors.swatch15};
  }
  60% {
    color: ${theme.colors.swatch15};
  }
  100% {
    color: ${theme.mainFontColor};
  }
`}`

export const NetworkStatusDisplay = styled.div`${({ theme }) =>
  css`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  z-index: 100000;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background-color: ${theme.colors.swatch3};
  border: 1px solid ${theme.colors.swatch1};
  opacity: 0;

  &.connected {
    color: ${theme.colors.swatch12};
    opacity: 1;
  }

  &.connecting {
    opacity: 1;
    animation: ${pulse} 3s infinite;
  }

  &.disconnected {
    color: ${theme.colors.swatch16};
    animation: ${fade} 2s linear;
  }
`}`

export const NoData = styled.div`${({ theme }) =>
  css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 600;
  color: ${theme.mainFontColor};
  user-select: none;
  cursor: pointer;
  text-align: center;
  
  :hover {
    color: ${theme.primary.main};
  }
`}`
