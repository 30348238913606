import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hoistStatics from 'hoist-non-react-statics'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import IpcManager from '@/IpcManager'
import Input from '@/react/specific/Input'
import * as ApplicationActions from '@/store/application/main/actions'
import * as UtilActions from '@/store/util/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

import {
  Bottom,
  Button,
  Dialog,
  DialogBackground,
  Form,
  Header,
  SourceErrorMessage,
  Tab,
  TabsWrapper,
  Title,
} from './DialogStyles'

const connector = connect((state: DefaultState) => ({
  loadingStatus: state.visualization.loadingStatus,
  plotConfigs: state.visualization.plotConfigs,
  hdf5Schema: state.visualization.hdf5Schema,
  hdf5Schemas: state.visualization.hdf5Schemas,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  setHdf5Schema: VisualizationActions.setHdf5Schema,
  setAuthenticationData: ApplicationActions.setAuthenticationData,
  setUserSettings: UtilActions.setUserSettings,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onCloseDataSourceDialog: () => void
  t: Translation
}

type State = {
  errorMessage: string | null | undefined
  errorTooltip: string | null | undefined
}

export class SelectSourceDialog extends Component<Props, State> {
  @Identifiable('SelectSourceDialog') public static readonly NAME: string

  public override state: State = {
    errorMessage: null,
    errorTooltip: null,
  }
  
  public override componentDidMount () {
    IpcManager.both.on('errorOccurred', (event: any, errorMessage: string) => {
      this.setState({
        errorMessage: 'error',
        errorTooltip: errorMessage,
      })
    })
  }

  private readonly handleButtonTask = (type?: string) => {
    const { onCloseDataSourceDialog, hdf5Schema, currentSimulationCase } = this.props

    this.setState({
      errorMessage: null,
      errorTooltip: null,
    })

    switch (type) {
      case 'data':
        IpcManager.both.send('setHdf5Schema', hdf5Schema)
        IpcManager.both.send('openVisualizationFile', currentSimulationCase)
        break
      case 'config':
        IpcManager.both.send('openVisualizationConfigFile')
        break
      default:
        onCloseDataSourceDialog()
    }
  }

  private readonly handleDataFileDialog = this.handleButtonTask.bind(this, 'data')

  private readonly handleConfigFileDialog = this.handleButtonTask.bind(this, 'config')

  private readonly handleConnectServer = this.handleButtonTask.bind(this, 'connect')

  private readonly handleCloseDialog = this.handleButtonTask.bind(this)

  private readonly handleChangeSchema = (event: any): void => {
    const { setHdf5Schema, t } = this.props

    this.setState({
      errorMessage: null,
      errorTooltip: null,
    })

    if (event.target.value === t('selectSourceDialog.data.hdf5.newSchema')) {
      IpcManager.both.send('openHdf5SchemaFile')
    }
    else {
      setHdf5Schema(event.target.value)

      IpcManager.both.send('setHdf5Schema', event.target.value)
    }
  }
  
  private setFocus (ref?: any) {
    if (ref) {
      ref.focus()
    }
  }
  
  public override render () {
    const { loadingStatus, hdf5Schema, hdf5Schemas, t } = this.props
    const { errorMessage, errorTooltip } = this.state

    const connectTabs = [ { title: 'data', icon: 'pe-7s-file' } ]

    return (
      <div>
        <DialogBackground />
        <Dialog $height='420px' $noBottomBorder $half>
          <Header>
            <Title>{t('selectSourceDialog.label')}</Title>
          </Header>
          <Form>
            <Input
              name='hdf5conf'
              type='select'
              label={t('selectSourceDialog.data.hdf5.label')}
              title={t('selectSourceDialog.data.hdf5.title')}
              value={hdf5Schema}
              selectors={[ 'default', ...hdf5Schemas, t('selectSourceDialog.data.hdf5.newSchema') ]}
              onChange={this.handleChangeSchema}
            />
            {
              errorMessage &&
              (
                <SourceErrorMessage>
                  {t(`selectSourceDialog.${errorMessage}`)}
                  {
                    errorTooltip &&
                      <FontAwesomeIcon icon={faInfoCircle} title={t(`selectSourceDialog.${errorTooltip}`)} />
                  }
                </SourceErrorMessage>
              )
            }
            <Button
              onClick={this.handleDataFileDialog}
              $isLoading={loadingStatus.VisualizationIsLoading}
              title={t('selectSourceDialog.data.hdf5File.title')}
            >
              {t('selectSourceDialog.data.hdf5File.label')}
            </Button>
            <Button
              onClick={this.handleConfigFileDialog}
              $isLoading={loadingStatus.openVisualizationConfig}
              title={t('selectSourceDialog.data.configFile.title')}
            >
              {t('selectSourceDialog.data.configFile.label')}
            </Button>
            <Button
              disabled={Object.values(loadingStatus).includes(true)}
              onClick={() => this.handleCloseDialog()}
              ref={this.setFocus}
              title={t('selectSourceDialog.data.done.title')}
            >
              {t('selectSourceDialog.data.done.label')}
            </Button>
          </Form>
          <Bottom>
            <TabsWrapper>
              {
                connectTabs.map((tab, i) => (
                  <Tab
                    key={i}
                    title={t(`selectSourceDialog.tabs.${tab.title}`)}
                    $activeTab={Boolean(0)}
                    id={i.toString()}
                  >
                    <i className={tab.icon} />
                  </Tab>
                ))
              }
            </TabsWrapper>
          </Bottom>
        </Dialog>
      </div>
    )
  }
}

const composedComponent = compose<any>(withTranslation('visualization'), connector)(SelectSourceDialog)

export default hoistStatics(composedComponent, SelectSourceDialog)
