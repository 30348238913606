import { faFile, faListAlt } from '@fortawesome/free-regular-svg-icons'
import { faCrosshairs, faFilter, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enqueueSnackbar } from 'notistack'
import { createRef, PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { useConfig } from '@/config'
import EditSegmentGroupDetailFilterDialog from '@/react/dialogs/EditSegmentGroupDetailFilterDialog'
import HistoryDetailDialog from '@/react/dialogs/HistoryDetailDialog'
import { TreeViewID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { updateElement } from '@/store/elements/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import Util from '@/three/logic/Util'
import ThreeManager from '@/three/ThreeManager'
import SectionView from '@/three/views/SectionView'
import type { DefaultState, ElementName } from '@/types/state'
import { Translation } from '@/types/translation'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import ChildrenSelector from './ChildrenSelector'
import { Actions, Elements, RenameButton, RenameInput, Spacer, TargetButton } from '../ElementGroupStyles'

const connector = connect((state: DefaultState) => ({
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  currentSimulationCase: state.application.main.currentSimulationCase,
  elementMaps: getElementMapsObject(state),
}), {
  updateElement,
  openDialog: ApplicationActions.openDialog,
  setActiveDetailDialogFilter: ApplicationActions.setActiveDetailDialogFilter,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  type?: string
  elementName: string
  element: any
  selected: boolean
  childrenElement: any
  name: string
  visible: boolean
  active: boolean
  target: boolean
  hasChildren: boolean
  depth: number
  fullPath: string
  onClick: (event: any, path: string, element?: any, name?: string) => void
  onFilter: (ctrl: boolean, name: string, fullPath: string) => void
  onTarget: (name: string, fullPath: string) => void
  t: Translation
}

type State = {
  isRenaming: boolean
  element: any
  nameValue: string
}

class ElementsComponent extends PureComponent<Props, State> {
  private readonly inputRef: React.RefObject<HTMLInputElement>

  public constructor (props: Props) {
    super(props)
    this.inputRef = createRef()
    this.state = {
      isRenaming: false,
      element: props.element,
      nameValue: String(props.elementName || props.name),
    }
  }

  public override componentDidUpdate (prevProps: Props) {
    if (prevProps.element !== this.props.element) {
      this.setState({
        element: this.props.element,
        isRenaming: false,
      })
    }
  }

  private readonly handleClick = (event: any) => {
    event.stopPropagation()

    if (event.target === event.currentTarget) {
      const { onClick, name, element, hasChildren, fullPath } = this.props

      hasChildren ? onClick(event, fullPath, element, `${name}:${element.id}`) : onClick(event, fullPath)
    }
  }

  private readonly handleFilter = (event: any) => {
    event.stopPropagation()

    const { onFilter, name, fullPath } = this.props

    const ctrl = event.ctrlKey || event.metaKey

    onFilter(ctrl, name, fullPath)
  }

  private readonly handleTarget = (event: any) => {
    event.stopPropagation()

    const { onTarget, name, fullPath } = this.props

    onTarget(name, fullPath)
  }

  private readonly handleSetRenaming = (e: any) => {
    e.stopPropagation()

    if (this.state.isRenaming) {
      this.setState({ isRenaming: false })
    }
    else {
      this.setState({ isRenaming: true })

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus()
          }
        })
      })
    }
  }

  private readonly handleOpenTableDialog = () => {
    const { element, setActiveDetailDialogFilter, openDialog } = this.props

    const mountLogId = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[element.id]

    if (!mountLogId) {
      return
    }

    setActiveDetailDialogFilter(element.historyDetailDialogFilter, mountLogId)

    if (!element.historyDetailDialogFilter) {
      openDialog(EditSegmentGroupDetailFilterDialog.NAME)

      return
    }

    openDialog(HistoryDetailDialog.NAME)
  }

  private readonly handleBlur = () => {
    this.setState({ isRenaming: false, nameValue: this.getElementName() })
  }

  private readonly handleRenameInputClick = (e: any) => {
    e.stopPropagation()
  }

  private readonly handleRenameInputChange = (e: any) => {
    this.setState({ nameValue: e.target.value })
  }

  private readonly handleSubmitNameChange = async (newName: string) => {
    const { fullPath, updateElement } = this.props
    const { type, id } = Util.getElementInfo(fullPath)

    if (!type) {
      return
    }

    if (!newName) {
      enqueueSnackbar('Error: empty new name', { variant: 'error', autoHideDuration: 2000 })

      return
    }

    const typeURL = type === 'SegmentGroup' ? 'segment-groups' : 'segments'
    const mountLogId = Mapping.mountLogIdByTypeAndNumericId[type][id]

    if (!mountLogId) {
      enqueueSnackbar('Error: could not find mountLogId', { variant: 'error', autoHideDuration: 2000 })

      return
    }

    try {
      await ApiClient.patch(
        `${useConfig().apiBaseURL}/${typeURL}/${mountLogId}/rename`,
        { data: { name: newName } },
      )
      // update name in redux

      const mountLogType = `${type}MountLog`

      if (mountLogType !== 'SegmentMountLog' && mountLogType !== 'SegmentGroupMountLog') {
        return
      }

      updateElement(mountLogType, mountLogId, { name: newName })

      if (type === 'SegmentGroup') {
        const { elementMaps } = this.props
        const segmentGroupMountLog = elementMaps.SegmentGroupMountLog[mountLogId]

        if (!segmentGroupMountLog) {
          return
        }

        const segmentPaths = segmentGroupMountLog
          .segmentMountLogs
          ?.map((segmentMountLogId) => Mapping.elementPathByMountLogId[segmentMountLogId])
          .filter((segmentId) => Boolean(segmentId)) as string[]

        if (!segmentPaths) {
          return
        }

        if (SectionView.currentSegmentGroup.id === id) {
          SectionView.currentSegmentGroupNameChanged = true
        }

        ThreeManager.base.updateSegments(segmentPaths, newName)
      }
    }
    catch (error: any) {
      // display error
      enqueueSnackbar('Error: could not rename element', { variant: 'error', autoHideDuration: 2000 })
      // eslint-disable-next-line no-console
      console.error('error', error)
    }
  }

  private readonly handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      this.setState({ isRenaming: false })
      this.handleSubmitNameChange(e.target.value)
    }
  }

  private readonly getElementNameField = () => {
    const { isRenaming } = this.state
    const { hasChildren, visible, element, t } = this.props
    const arrow = visible
      ? <Spacer onClick={this.handleClick}>&#9662;</Spacer>
      : <Spacer onClick={this.handleClick}>&#9656;</Spacer>

    return (
      <div
        className='elementName'
        onClick={this.handleClick}
        title={this.getElementName() + this.getElementDescription()}
      >
        {hasChildren ? arrow : <Spacer />}
        {
          isRenaming && (
            <RenameInput
              id={TreeViewID.RenameSegmentGroupInput}
              onChange={this.handleRenameInputChange}
              onClick={this.handleRenameInputClick}
              onBlur={this.handleBlur}
              onKeyUp={this.handleKeyUp}
              ref={this.inputRef}
              value={this.state.nameValue}
            />
          )
        }
        {`${!isRenaming ? this.getElementName() : ''}${this.getElementDescription()}`}
        {
          // window.open doesn't work in Electron, it opens a new electron window
          element?.eDocHRef && (
            <FontAwesomeIcon
              icon={faFile}
              className={TreeViewID.EDocHRefButton}
              style={{ paddingLeft: '10px', cursor: 'pointer' }}
              onClick={() => window.open(element.eDocHRef)}
              title={t('treeView.openEDoc')}
            />
          )
        }
      </div>
    )
  }

  private readonly getElementName = () => {
    const { elementName, name } = this.props

    const tagName = ElementMapsUtil.getTagName(name as ElementName)

    return String(elementName || tagName)
  }

  private readonly getElementDescription = () => {
    const { element, type, name } = this.props

    if (type === 'Segment' && Boolean(name)) {
      const side = element.side

      return ` [${side}]`
    }
    else if (type === 'SegmentGroup') {
      return ''
    }

    return `:${element.id}`
  }
  
  public override render () {
    const {
      selected,
      target,
      active,
      depth,
      t,
      type,
      featureFlags,
      fullPath,
      element,
    } = this.props

    const canRenameElements = FeatureFlags.canRenameElements(featureFlags)
    const canJumpToElement = FeatureFlags.canJumpToElement(featureFlags)
    const canSetFilterAndJumpToElement = FeatureFlags.canSetFilterAndJumpToElement(featureFlags)
    const canViewAndUseHistoryDialogButton = FeatureFlags.canViewAndUseHistoryDialogButton(featureFlags)

    return (
      <Elements
        $selected={selected}
        onClick={this.handleClick}
        $spacer={depth * 15}
        $targetButtonFilterAndJump={active}
        $targetButtonJump={target}
        $renameButton={(type === 'Segment' || type === 'SegmentGroup') && canRenameElements}
      >
        {this.getElementNameField()}
        <Actions>
          {
            canViewAndUseHistoryDialogButton && (
              <TargetButton>
                <FontAwesomeIcon
                  icon={faListAlt}
                  className={TreeViewID.OpenHistoryDetailDialogButton}
                  onClick={this.handleOpenTableDialog}
                  title={
                    element.historyDetailDialogFilter
                      ? t('treeView.openElementHistoryDialog', { filter: element.historyDetailDialogFilter })
                      : t('treeView.addElementHistoryDialogFilter')
                  }
                />
              </TargetButton>
            )
          }
          {
            (type === 'Segment' || type === 'SegmentGroup') && canRenameElements && (
              <RenameButton
                className={TreeViewID.RenameSegmentGroupButton}
                onClick={this.handleSetRenaming}
                $active={this.state.isRenaming}
                title='Rename'
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </RenameButton>
            )
          }
          {
            canJumpToElement && (
              <TargetButton 
                className={TreeViewID.JumpToElementButton}
                onClick={this.handleTarget}
                $active={target}
                title={t('treeView.jumpToElement')}
              >
                <FontAwesomeIcon icon={faCrosshairs} />
              </TargetButton>
            )
          }
          {
            canSetFilterAndJumpToElement && (
              <TargetButton
                className={TreeViewID.SetFilterButton}
                onClick={this.handleFilter}
                $active={active}
                title={t('treeView.setFilter')}
              >
                <FontAwesomeIcon icon={faFilter} />
              </TargetButton>
            )
          }
          <ChildrenSelector path={fullPath} />
        </Actions>
      </Elements>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(ElementsComponent)
