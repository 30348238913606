import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Component } from 'react'
import { connect, type ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags'
import DataActions from '@/store/data/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import { DefaultState, ElementName, MountLogMapKey } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import { Filter, NameBoxForModules } from './Module'
import { Trigger } from '../type/TypeHeaderStyles'

const StyledModuleType = styled.div`${() =>
  css`
width: 100%;
color: #CCCCCC;
border-top: solid 1px #000000;
border-bottom: solid 1px #000000;
margin-bottom: -1px;
position: relative;
background-color: #4f4f4f;
`}`

const StyledElement = styled.div`${() =>
  css`
width: 100%;
color: #CCCCCC;
border-top: solid 1px #000000;
border-bottom: solid 1px #000000;
margin-bottom: -1px;
position: relative;
background-color: #636363;
`}`

const connector = connect((state: DefaultState) => ({
  moduleMap: state.modules,
  term: state.filter.term,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  elementMaps: getElementMapsObject(state),
}), {
  setTerm: FilterActions.setTerm,
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  type: MountLogMapKey
  module: string
}

type State = {
  isOpen: boolean
}

export class ModuleType extends Component<Props, State> {
  public override state: State = { isOpen: false }

  private readonly handleFilter = (newTerm: string) => {
    const { setTerm, setSelectedElementPaths, term } = this.props

    if (term === newTerm) {
      setTerm('')
    }
    else {
      setTerm(newTerm)
    }

    setSelectedElementPaths()
  }

  private readonly handleFilterPerType = () => {
    const { type, module } = this.props

    const realType = ElementMapsUtil.getTagName(type.replace('MountLog', '') as ElementName)

    this.handleFilter(`${realType}#module=${module}`)
  }

  private readonly handleToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  private getMountLog (id: string) {
    const { type, elementMaps } = this.props

    return elementMaps[type][id]
  }

  private getElementComponent (element: any) {
    const { type, term } = this.props
    const realType = ElementMapsUtil.getTagName(type.replace('MountLog', '') as ElementName)
    const numericId = Mapping.numericIdByMountLogId[element.id] ?? -1
    const filterActive = term === `${realType}:${numericId}`
    const elementTypeAndId = `${realType}:${numericId}`

    return (
      <StyledElement key={`${type}_${numericId}`} title={element.name || elementTypeAndId}>
        <NameBoxForModules $pad={60} onClick={() => false}>
          <span>{element.name || elementTypeAndId}</span>
        </NameBoxForModules>
        <Filter onClick={() => this.handleFilter(`${realType}:${numericId}`)} $active={filterActive}>
          <FontAwesomeIcon icon={faFilter} title='Filter Element' />
        </Filter>
      </StyledElement>
    )
  }

  private readonly getIsFilterActive = () => {
    const { module, type, term } = this.props
    
    const realType = ElementMapsUtil.getTagName(type.replace('MountLog', '') as ElementName)

    return term === `${realType}#module=${module}`
  }

  public override render () {
    const { type, module, moduleMap, featureFlags } = this.props
    // https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    const elements = Object
      .keys(moduleMap[module][type])
      .map(id => this.getMountLog(id))
      .sort((a, b) => collator.compare(a.name ?? '', b.name ?? ''))
    const { isOpen } = this.state
    const isFilterActive = this.getIsFilterActive()
    const canFilterPerModuleType = FeatureFlags.canFilterPerModuleElementType(featureFlags)

    const realType = ElementMapsUtil.getTagName(type.replace('MountLog', '') as ElementName)

    return (
      <div>
        <StyledModuleType title={realType}>
          <NameBoxForModules $pad={40} onClick={this.handleToggleOpen}>
            {realType}
          </NameBoxForModules>
          {
            canFilterPerModuleType && (
              <Filter onClick={this.handleFilterPerType} $active={isFilterActive}>
                <FontAwesomeIcon icon={faFilter} title='Filter Element Type' />
              </Filter>
            )
          }
          <Trigger onClick={this.handleToggleOpen}>
            <i className={`pe-7s-angle-${isOpen ? 'down' : 'right'}`} />
          </Trigger>
        </StyledModuleType>
        {isOpen && elements.map(element => (this.getElementComponent(element)))}
      </div>
    )
  }
}

export default compose<any>(connector)(ModuleType)
