import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'

import FastGage from './FastGage'
import logic from './logic'

const connector = connect((state: DefaultState) => ({
  tileConfigs: state.visualization.tileConfigs,
  plotConfigs: state.visualization.plotConfigs,
  data: state.visualization.data,
}), {
  openContextMenu: VisualizationActions.openContextMenu,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  width: number
  height: number
  tileId: string
  /**
   * PlotConfig ID
   */
  configId: string
  viewId: string
  xLabel: string
  yLabel: string
  type: 'area' | 'bar' | 'contour' | 'gage' | 'line' | 'pie' | 'text'
  configIds: number[]
  yDomain: number[]
  xDomain: number[]
  xRange: number[]
  valueRange: number[]
  shapeIds?: number[]
  radiusDomain?: any[]
  radius0?: number
  radius?: number
  frequency: number
  flipAxes: boolean
  networkStatus: string // FIXME: this looks like it should come from redux, is this used?
}

class GageWrapper extends Component<Props> {
  private value: Array<any>

  private readonly gage: FastGage

  private vRangeMax: number | null

  public constructor (props: Props) {
    super(props)
    this.value = []
    this.vRangeMax = null

    this.gage = new FastGage({
      secondaryBackgroundColor: '#72C670',
      aimMinColor: '#ff7f0e',
      aimMaxColor: '#ff7f0e',
    })
  }
  
  public override componentDidUpdate (prevProps: Props) {
    logic.connect(prevProps, this.props, this.setData)

    this.setGage()
  }
  
  private readonly setGage = () => {
    // frequency
    const { width, height, plotConfigs, configId, tileConfigs, tileId } = this.props
    const tConf = tileConfigs[tileId]
    const conf = plotConfigs[configId]
    const defaultLabels: string[] = []

    this.value.forEach(val => {
      if (val === undefined) {
        return
      }

      if ((conf && conf.key) || (plotConfigs[val.name] && plotConfigs[val.name].key)) {
        defaultLabels.push((conf.key ? conf : plotConfigs[val.name]).key.split('_').join(' '))
      }
    })

    const parts = (conf.key ? conf : plotConfigs[conf.configIds[0]]).key.split('_')
    const unit = parts.splice(-1)[0].replace(':', '/')

    const defaultLabel = (defaultLabels[0] ?? '').replace('[C]', '[°C]')

    const min = this.value[1] ?? tConf.vRangeMin ?? 0
    const max = this.value[2] || tConf.vRangeMax || this.vRangeMax || this.value[0] * 1.25

    if (!this.vRangeMax) {
      this.vRangeMax = max
    }

    const decimals = tConf.decimals || tConf.decimals === 0 ? tConf.decimals : conf.decimals

    this.gage.update({
      id: `gage_${tileId}`,
      currentValue: this.value[0] ?? min ?? 0,
      min,
      max,
      aimMin: this.value[3] || min,
      aimMax: this.value[4] || max,
    }, {
      width,
      height,
      unit: (unit ?? '').replace('[C]', '[°C]'),
      label: conf.gageLabel ?? defaultLabel,
      currentValueDecimals: decimals,
      minValueDecimals: decimals,
      maxValueDecimals: decimals,
    })
  }

  private readonly setData = ({ tileId, definitions }: { tileId: string, definitions: any[] }) => {
    const { tileConfigs, plotConfigs } = this.props
    const { group } = plotConfigs[definitions[0].id]

    if (group !== 'ProcessParameters_ActualMinMaxAimminAimmax') {
      if (definitions.length > 1) {
        const { xRangeMin } = plotConfigs[tileConfigs[tileId].configId]

        this.value = definitions.map(def => def.data[xRangeMin ?? 0])
      }
      else {
        this.value = definitions[0].data.length > 1 ? definitions[0].data : [ definitions[0].data[0] ]
      }
    }
    else {
      this.value = definitions[0].data
    }

    this.setGage()
  }
  
  public override render () {
    const { tileId } = this.props

    return <div id={`gage_${tileId}`} />
  }
}

export default compose<any>(connector)(GageWrapper)
