import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Logic, { StyledTooltip } from '.'
import Editor from '../Editor'

export default class Renderers {
  public static valueRenderer = (
    { value, valueType, title, titleList }: { value: any, valueType: string, title: string, titleList: any },
  ) => {
    if (valueType === 'icon') {
      return <FontAwesomeIcon {...value} />
    }

    const valueComponent = value ? <span>{value}</span> : <span>&nbsp;</span>

    if (title || (titleList && titleList.length)) {
      return (
        <StyledTooltip
          disableInteractive={titleList && titleList.length === 1}
          placement='bottom'
          title={
            title || (titleList
              ? (
                <table>
                  <tbody>
                    {
                      titleList.map((titleEntry: any, i: number) => {
                        let value: JSX.Element | JSX.Element[] = <td>{titleEntry}</td>

                        if (titleEntry instanceof Array) {
                          value = titleEntry.map((titleEntryPart, j) => <td key={j}>{titleEntryPart}</td>)
                        }

                        return <tr key={i}>{value}</tr>
                      })
                    }
                  </tbody>
                </table>
              )
              : '')
          }
        >
          {valueComponent}
        </StyledTooltip>
      )
    }

    return valueComponent
  }

  public static renderDataEditor = (editorProps: any, selectedCellData: any) => {
    const { grid } = Logic.dialogInstance.props
    const { row } = editorProps
    const { id, simulationStartedAt } = grid[row].find((cell: any) => cell.key === 'simulation_case')?.data ?? {}

    return (
      <Editor
        {...editorProps}
        selectedCellData={selectedCellData}
        simulationCaseId={id}
        simulationStartedAt={simulationStartedAt}
        onClose={Logic.handleDataEditor}
      />
    )
  }
}
