import * as THREE from 'three'

import ThreeNozzle from '@/three/objects/Nozzle'
import type { Views } from '@/three/ThreeBase'
import { StrandSides } from '@/types/elements/enum'
import type { ElementMaps } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import Util from './Util'
import Mold from '../objects/Mold'
import type ThreeRoller from '../objects/Roller'
import SectionView from '../views/SectionView'

export default class SectionLogic {
  public static handleRoller (views: Views, elementMaps: ElementMaps) {
    const { mainView, sectionView, uiView } = views

    if (!mainView || !mainView?.elementList?.Roller || !sectionView || !uiView) {
      return
    }

    const nextRoller = Object
      .values(mainView.elementList.Roller)
      .filter((roller) => {
        if (sectionView.statusPrevNext) {
          return roller.plCoord < sectionView.plHeight * uiView.scrollValue
        }

        return roller.plCoord > sectionView.plHeight * uiView.scrollValue
      })
      .sort((rollerOne: any, rollerTwo: any) => {
        if (sectionView.statusPrevNext) {
          return rollerTwo.plCoord - rollerOne.plCoord
        }

        return rollerOne.plCoord - rollerTwo.plCoord
      })
      .filter((roller: any, _, rollerArray: any[]) =>
        rollerArray[0].plCoord === roller.plCoord && !mainView.hideList?.includes(roller.path)
      )

    sectionView.rollerGroup = new THREE.Group()
    sectionView.rollerGroup.visible = false
    sectionView.rollerGroup.name = 'PrevOrNextElements'

    nextRoller.forEach(roller => SectionLogic.drawSectionViewRoller(roller, views, elementMaps))

    Util.addOrReplace(sectionView.sectionPlaneFolded, sectionView.rollerGroup)
  }

  private static drawSectionViewRoller (roller: ThreeRoller, views: any, elementMaps: ElementMaps) {
    const { thickness, width } = Mold

    const rollerBearingMountLogs: RollerBearingMountLog[] =
      roller.rollerData.rollerBearingMountLogs?.map((id: string) =>
        ElementMapsUtil.getMountLogById<RollerBearingMountLog>('RollerBearing', id, elementMaps)
      ) ?? []

    const rollerBodyMountLogs: RollerBodyMountLog[] =
      roller.rollerData.rollerBodyMountLogs?.map((id: string) =>
        ElementMapsUtil.getMountLogById<RollerBodyMountLog>('RollerBody', id, elementMaps)
      ) ?? []

    const { diameter: rawDiameter } = roller.rollerData
    const diameter = rawDiameter / 1000

    rollerBodyMountLogs.forEach((rollerBodyMountLog, index: number) => {
      const rollerBodySlot = elementMaps.RollerBodySlot[rollerBodyMountLog.slotId]
      const bodyWidth = (rollerBodyMountLog?.width ?? 0) / 1000
      const bodyWidthStart = (rollerBodySlot.widthCoord ?? 0) / 1000
      const bodyMid = bodyWidthStart + bodyWidth / 2

      // FIXME: fix paths
      // if (views.mainView.hideList.includes(`${roller.path}/RollerBody:${rollerBody.id}`)) {
      //   return
      // }

      const geometry = new THREE.PlaneGeometry(diameter, bodyWidth)

      const plane = new THREE.Mesh(geometry, SectionView.rollerBodyMaterial)

      // TODO: remove old code
      // TODO: Geometry is deprecated in new THREE releases
      // const lineGeometry = new (THREE as any).Geometry()

      // lineGeometry.vertices.push(
      //   new THREE.Vector3(0, -bodyWidth / 2, 0),
      //   new THREE.Vector3(diameter, -bodyWidth / 2, 0),
      //   new THREE.Vector3(diameter, bodyWidth / 2, 0),
      //   new THREE.Vector3(0, bodyWidth / 2, 0),
      //   new THREE.Vector3(0, -bodyWidth / 2, 0),
      // )

      // const line = new THREE.Line(lineGeometry, SectionView.rollerLineMaterial)

      const line = Util.getLine(
        [
          new THREE.Vector3(0, -bodyWidth / 2, 0),
          new THREE.Vector3(diameter, -bodyWidth / 2, 0),
          new THREE.Vector3(diameter, bodyWidth / 2, 0),
          new THREE.Vector3(0, bodyWidth / 2, 0),
          new THREE.Vector3(0, -bodyWidth / 2, 0),
        ],
        SectionView.rollerLineMaterial,
      )

      plane.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001
      line.position.z = views.sectionView.statusPrevNext ? 0.00041 : 0.000011

      const { type, id } = Util.getParentInfo(roller.objects.roller.userData.path)
      const uuid = Mapping.mountLogIdByTypeAndNumericId[type][id]
      const { side } = views.sectionView.elementMaps[`${type}MountLog`][uuid]

      switch (side) {
        case StrandSides.Fixed:
          plane.position.x = -(thickness + diameter / 2)
          plane.position.y = bodyMid

          line.position.x = -thickness - diameter
          line.position.y = bodyMid
          break
        case StrandSides.Loose:
          plane.position.x += diameter / 2
          plane.position.y = bodyMid

          line.position.y = bodyMid
          break
        case StrandSides.Left:
          plane.rotation.z += Util.RAD90
          plane.position.y = (width + diameter) / 2
          plane.position.x = bodyMid - thickness

          line.rotation.z += Util.RAD270
          line.position.y = width / 2 + diameter
          line.position.x = bodyMid - thickness
          break
        case StrandSides.Right:
          plane.rotation.z += Util.RAD90
          plane.position.y = -(width + diameter) / 2
          plane.position.x = bodyMid - thickness

          line.rotation.z += Util.RAD270
          line.position.y = -width / 2
          line.position.x = bodyMid - thickness
          break
        default:
      }

      plane.name = `rollerBodyPlane_${side}_${index}`
      line.name = `rollerBodyLine_${side}_${index}`
      Util.addOrReplace(views.sectionView.rollerGroup, plane)
      Util.addOrReplace(views.sectionView.rollerGroup, line)
    })

    rollerBearingMountLogs.forEach((rollerBearingMountLog, index: number) => {
      const rollerBearingSlot = elementMaps.RollerBearingSlot[rollerBearingMountLog.slotId]
      const bearingWidth = (rollerBearingMountLog?.width ?? 0) / 1000
      const bearingWidthStart = (rollerBearingSlot.widthCoord ?? 0) / 1000
      const bearingMid = bearingWidthStart + bearingWidth / 2

      const geometry = new THREE.PlaneGeometry(diameter * 0.8, bearingWidth)

      // FIXME: fix paths
      // if (views.mainView.hideList.includes(`${roller.path}/RollerBearing:${rollerBearing._id}`)) {
      //   return
      // }
      const rollerBearingPlane = new THREE.Mesh(geometry, SectionView.rollerBearingMaterial)

      // TODO: remove old code
      // // TODO: Geometry is deprecated in new THREE releases
      // const lineGeometry = new (THREE as any).Geometry()
      // lineGeometry.vertices.push(
      //   new THREE.Vector3(diameter * 0.1, -bearingWidth / 2, 0),
      //   new THREE.Vector3(diameter * 0.9, -bearingWidth / 2, 0),
      //   new THREE.Vector3(diameter * 0.9, bearingWidth / 2, 0),
      //   new THREE.Vector3(diameter * 0.1, bearingWidth / 2, 0),
      //   new THREE.Vector3(diameter * 0.1, -bearingWidth / 2, 0),
      // )
      // const rollerBearingLine = new THREE.Line(lineGeometry, SectionView.rollerBearingLineMaterial)
      const rollerBearingLine = Util.getLine(
        [
          new THREE.Vector3(diameter * 0.1, -bearingWidth / 2, 0),
          new THREE.Vector3(diameter * 0.9, -bearingWidth / 2, 0),
          new THREE.Vector3(diameter * 0.9, bearingWidth / 2, 0),
          new THREE.Vector3(diameter * 0.1, bearingWidth / 2, 0),
          new THREE.Vector3(diameter * 0.1, -bearingWidth / 2, 0),
        ],
        SectionView.rollerBearingLineMaterial,
      )

      rollerBearingPlane.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001
      rollerBearingLine.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001

      const { type, id } = Util.getParentInfo(roller.objects.roller.userData.path)
      const uuid = Mapping.mountLogIdByTypeAndNumericId[type][id]
      const { side } = views.sectionView.elementMaps[`${type}MountLog`][uuid]

      rollerBearingPlane.position.y = bearingMid

      switch (side) {
        case StrandSides.Fixed:
          rollerBearingPlane.position.x = -(thickness + diameter / 2)

          rollerBearingLine.position.x = -thickness - diameter
          rollerBearingLine.position.y = bearingMid
          break
        case StrandSides.Loose:
          rollerBearingPlane.position.y = bearingMid
          rollerBearingPlane.position.x += diameter / 2

          rollerBearingLine.position.y = bearingMid
          break
        case StrandSides.Left:
          rollerBearingPlane.rotation.z += Util.RAD90
          rollerBearingPlane.position.y = (width + diameter) / 2
          rollerBearingPlane.position.x = bearingMid - thickness

          rollerBearingLine.rotation.z += Util.RAD270
          rollerBearingLine.position.y = width / 2 + diameter
          rollerBearingLine.position.x = bearingMid - thickness

          break
        case StrandSides.Right:
          rollerBearingPlane.rotation.z += Util.RAD90
          rollerBearingPlane.position.y = -(width + diameter) / 2
          rollerBearingPlane.position.x = bearingMid - thickness

          rollerBearingLine.rotation.z += Util.RAD90
          rollerBearingLine.position.y = -(width / 2 + diameter)
          rollerBearingLine.position.x = bearingMid - thickness

          break
        default:
      }

      rollerBearingLine.name = `rollerBearingLine_${side}_${index}`
      rollerBearingPlane.name = `rollerBearingPlane_${side}_${index}`

      Util.addOrReplace(views.sectionView.rollerGroup, rollerBearingLine)
      Util.addOrReplace(views.sectionView.rollerGroup, rollerBearingPlane)
    })
  }

  public static handleNozzle (views: Views) {
    if (!views.sectionView) {
      // eslint-disable-next-line no-console
      console.warn('SectionView is not available')

      return
    }

    const selectedSide: any[] = []

    for (const side in views.sectionView.side) {
      if (Object.prototype.hasOwnProperty.call(views.sectionView.side, side)) {
        selectedSide.push(
          ...Object
            .values(views.sectionView.elementList.Nozzle ?? {})
            .filter((nozzle: any) => nozzle.container.userData.side === side),
        )
      }
    }

    const plCoord = Number((views.sectionView.plHeight * (views.uiView?.scrollValue ?? 0)).toFixed(5))

    const passlnCoord = selectedSide
      .filter(nozzle => {
        if (views.sectionView?.statusPrevNext) {
          return nozzle.plCoord.toFixed(5) < plCoord
        }

        return nozzle.plCoord.toFixed(5) > plCoord
      })
      .sort((nozzleOne, nozzleTwo) => {
        if (views.sectionView?.statusPrevNext) {
          return nozzleTwo.plCoord - nozzleOne.plCoord
        }

        return nozzleOne.plCoord - nozzleTwo.plCoord
      })
      .filter(nozzle => nozzle.plCoord !== plCoord)[0] ?? []

    const nextNozzle = Object
      .values(views.sectionView.elementList.Nozzle ?? {})
      .filter((nozzle: any) => passlnCoord.plCoord === nozzle.plCoord)

    views.sectionView.nozzleGroup = new THREE.Group()
    views.sectionView.nozzleGroup.visible = false
    views.sectionView.nozzleGroup.name = 'PrevOrNextElements'

    for (let index = 0; index < nextNozzle.length; index++) {
      const nozzle = nextNozzle[index] as ThreeNozzle

      if (!nozzle.objects?.nozzle?.visible) {
        return
      }

      const wireFrame = nozzle.objects.nozzle.getObjectByName('WireFrame')?.clone()

      if (!wireFrame) {
        return
      }

      wireFrame.position.copy(nozzle.objects.nozzle.position)

      const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')

      const { side } = ElementMapsUtil
        .getSlotByPath<NozzleSlot>(nozzle.objects.nozzle.userData.path, views.sectionView.elementMaps) ?? {}

      const x = wireFrame.position.x

      switch (side) {
        case StrandSides.Fixed:
          newSectionRotation.set(Util.RAD90, Util.RAD90, 0)
          wireFrame.position.x = -Mold.thickness
          wireFrame.position.y = x
          wireFrame.position.z = 0

          break
        case StrandSides.Loose:
          newSectionRotation.set(Util.RAD90, -Util.RAD90, 0)
          wireFrame.position.x = 0
          wireFrame.position.y = x
          wireFrame.position.z = 0

          break
        case StrandSides.Left:
          newSectionRotation.set(Util.RAD90, 0, 0)
          wireFrame.position.y = Mold.width / 2
          wireFrame.position.x = wireFrame.position.z - Mold.thickness
          wireFrame.position.z = 0

          break
        case StrandSides.Right:
          newSectionRotation.set(Util.RAD90, Util.RAD180, 0)
          wireFrame.position.y = -Mold.width / 2
          wireFrame.position.x = wireFrame.position.z - Mold.thickness
          wireFrame.position.z = 0

          break
        default:
      }

      wireFrame.name = `NozzleWireFrame_${side}_${index}`

      wireFrame.rotation.copy(newSectionRotation)

      views.sectionView.nozzleGroup.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001

      Util.addOrReplace(views.sectionView.nozzleGroup, wireFrame)
    }

    Util.addOrReplace(views.sectionView.sectionPlaneFolded, views.sectionView.nozzleGroup)
  }
}
