import Util from '@/logic/Util'

type Props = {
  XMLDataBase: CasterXMLDataBase
  marginLeft?: string
  marginRight?: string
}

const CompareXMLDataBaseInfoSpan = ({ XMLDataBase, marginLeft, marginRight }: Props) => {
  const activeColor = 'green'
  const inactiveColor = '#b23d0f'
  const { rgc, shim, strain } = Util.getXMLDataBaseShimStrainAndRGCValues(XMLDataBase)

  const RGCManagerSpan = (
    <span
      style={
        {
          color: rgc === '1' ? activeColor : inactiveColor,
          marginRight: '2px',
          fontWeight: '700', 
          marginLeft,
        }
      }
    >
      R
    </span>
  )
  const AlignmentManagerSpan = (
    <span
      style={
        {
          color: strain === '1' ? activeColor : inactiveColor,
          marginRight: '2px',
          fontWeight: '700', 
        }
      }
    >
      A
    </span>
  )
  const ShimManagerSpan = (
    <span
      style={{ color: shim === '1' ? activeColor : inactiveColor, fontWeight: '700' }}
    >
      S
    </span>
  )

  return (
    <span style={{ width: '50px', marginRight }}>
      {RGCManagerSpan} | {AlignmentManagerSpan} | {ShimManagerSpan}
    </span>
  )
}

export default CompareXMLDataBaseInfoSpan
