/* eslint-disable no-undef */
import { Checkbox, Grid, List, ListItem, ListItemSecondaryAction, Typography } from '@mui/material'
import hoistStatics from 'hoist-non-react-statics'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import BaseDialog from '@/react/dialogs/BaseDialog'
import SubmitButton from '@/react/specific/SubmitButton'
import * as ApplicationActions from '@/store/application/main/actions'
import { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { Bottom, InfoBox, StyledListItem, Wrapper } from './styles'

const T = 'projectCreateCommandFileDialog'

const connector = connect((state: DefaultState) => ({
  nonexistentCommandFiles: state.application.main.nonexistentCommandFiles,
  nonexistentCommandFileMapping: state.application.main.nonexistentCommandFileMapping,
  latestUploadedVisualizationConfigId: state.application.main.latestUploadedVisualizationConfigId,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedCommandFiles: string[]
  confirmDialogOpen: boolean
}

export class CreateCommandFileDialog extends Component<Props, State> {
  @Identifiable('CreateCommandFileDialog') public static readonly NAME: string

  public override state: State = {
    selectedCommandFiles: [] as any[],
    confirmDialogOpen: false,
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CreateCommandFileDialog.NAME)
  }

  private readonly handleChecked = (event: any) => {
    const { value } = event.target

    const { selectedCommandFiles } = this.state

    const newSelectedCommandFiles = [ ...selectedCommandFiles ]

    if (newSelectedCommandFiles.includes(value)) {
      newSelectedCommandFiles.splice(newSelectedCommandFiles.indexOf(value), 1)
    }
    else {
      newSelectedCommandFiles.push(value)
    }

    this.setState({
      selectedCommandFiles: newSelectedCommandFiles,
    })
  }

  private readonly handleOpenConfirm = () => {
    const { nonexistentCommandFiles } = this.props
    const { selectedCommandFiles } = this.state

    if (selectedCommandFiles.length === Object.keys(nonexistentCommandFiles).length) {
      this.handleSubmit()
    }
    else {
      this.setState({
        confirmDialogOpen: true,
      })
    }
  }

  private readonly handleCloseConfirm = () => {
    this.setState({
      confirmDialogOpen: false,
    })
  }

  private readonly handleSubmit = () => {
    const {
      nonexistentCommandFiles,
      // nonexistentCommandFileMapping,
      // currentSimulationCase,
      // latestUploadedVisualizationConfigId,
    } = this.props
    const { selectedCommandFiles } = this.state
    const commandIdsMapping = {}

    let firstFileId = ''

    for (const fileId in nonexistentCommandFiles) {
      const { commands, fileName } = nonexistentCommandFiles[fileId]
      const createFile = selectedCommandFiles.includes(fileId)

      const commandFile = {}
      
      if (createFile) {
        // eslint-disable-next-line no-console
        console.log(fileName)
        // FIXME: use new API once implemented
        // commandFile = await ApiClient.post(`${'Network.URI(deprecated)'}/visualization_command/create`, {
        //   data: {
        //     name: fileName,
        //     commands: commands,
        //     simulationCaseId: currentSimulationCase.id,
        //   },
        // })

        if (!Object.keys(commandIdsMapping).length) {
          commands.forEach((command, i) => {
            ;(commandIdsMapping as any)[command.prevCommandId] = (((((commandFile as any)
              .commands ?? {}) as any)[i] ?? {}) as any)
              ._id
          })
        }
      }

      firstFileId = firstFileId || (commandFile ? (commandFile as any)._id : fileId)

      // FIXME: use new API once implemented
      // await ApiClient.patch(
      //   `${'Network.URI(deprecated)'
      //    }/visualization_config/update_config_by_command_file/${latestUploadedVisualizationConfigId}`,
      //   {
      //     data: {
      //       type: createFile ? 'CREATE' : 'DELETE',
      //       commandFile,
      //       oldFileId: fileId,
      //     },
      //   },
      // )
    }

    // FIXME: use new API once implemented
    // if (Object.keys(commandIdsMapping).length && nonexistentCommandFileMapping) {
    //   await ApiClient.post(`${'Network.URI(deprecated)'}/visualization_command/file/${firstFileId}`, {
    //     data: {
    //       assignedCommands: nonexistentCommandFileMapping.map(id => (commandIdsMapping as any)[id] || id),
    //       simulationCaseId: currentSimulationCase.id,
    //     },
    //   })
    // }

    this.handleClose()
  }

  public override render () {
    const { t, nonexistentCommandFiles } = this.props
    const { selectedCommandFiles, confirmDialogOpen } = this.state

    return (
      <BaseDialog
        small
        title={t(`${T}.title`)}
        icon='pe-7s-albums'
        header={t(`${T}.header`)}
        hideCloseButton
      >
        <Wrapper>
          <Grid item xs={12} spacing={2}>
            <List>
              {
                Object.keys(nonexistentCommandFiles).map(fileId => (
                  <ListItem key={fileId}>
                    <StyledListItem>
                      {nonexistentCommandFiles[fileId].fileName}
                    </StyledListItem>
                    <ListItemSecondaryAction>
                      {/* TODO: value here and in handleChecked makes no sense, we need to check this! */}
                      <Checkbox
                        edge='end'
                        color='primary'
                        value={fileId}
                        checked={selectedCommandFiles.includes(fileId)}
                        onChange={this.handleChecked}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
            </List>
            <Bottom>
              {
                confirmDialogOpen
                  ? (
                    <div>
                      <InfoBox>
                        <Typography variant='caption'>{t(`${T}.info`)}</Typography>
                      </InfoBox>
                      <div>
                        <SubmitButton $half $type='error' onClick={this.handleCloseConfirm}>
                          {t(`${T}.abort`)}
                        </SubmitButton>
                        <SubmitButton $half onClick={this.handleSubmit}>
                          {t(`${T}.apply`)}
                        </SubmitButton>
                      </div>
                      {/* eslint-disable-next-line react/jsx-closing-tag-location */}
                    </div>
                  )
                  : <SubmitButton onClick={this.handleOpenConfirm}>{t(`${T}.apply`)}</SubmitButton>
              }
            </Bottom>
          </Grid>
        </Wrapper>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(CreateCommandFileDialog)

export default hoistStatics(composedComponent, CreateCommandFileDialog)
