import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import * as ApplicationActions from '@/store/application/main/actions'
import { Translation } from '@/types/translation'

import { ResetSimulationDialog } from '../ResetSimulationDialog'

const T = 'projectMatrixDialog'

const connector = connect(null, {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isSelected: boolean
  resetSimulationLoading: boolean
  isSelectedAndSimulated: boolean
  setSelections: any // TODO: remove
  t: Translation
}

class ResetSelected extends Component<Props> {
  private readonly handleClick = () => {
    const { openDialog } = this.props

    openDialog(ResetSimulationDialog.NAME)
  }

  private readonly getTitle = () => {
    const { isSelected, resetSimulationLoading, isSelectedAndSimulated, t } = this.props

    if (!isSelected) {
      return t(`${T}.titles.resetSelected.nothingSelected`)
    }

    if (resetSimulationLoading) {
      return t(`${T}.titles.resetSelected.loading`)
    }

    if (!isSelectedAndSimulated) {
      return t(`${T}.titles.resetSelected.notSimulated`)
    }

    return t(`${T}.titles.resetSelected.default`)
  }
  
  public override render () {
    // restProps should not contain currentProject
    const { isSelected, resetSimulationLoading, isSelectedAndSimulated, ...restProps } = this.props
    const disabled = !isSelected || resetSimulationLoading || !isSelectedAndSimulated

    return (
      <Button {...restProps} title={this.getTitle()} disabled={disabled} onClick={this.handleClick}>
        <FontAwesomeIcon icon={faUndo} />
      </Button>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(ResetSelected)
