import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import Button from '@/react/specific/Button'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'

import Input from './Input'
import { FromField } from './InputStyles'
import Section from '../Section'

export const Warning = styled(FromField as any)`${() =>
  css`
  font-size: 12px;
  color: yellow;
`}`

const connector = connect(({ data, loading }: DefaultState) => ({
  gapWarnings: data.gapWarnings,
  loading: loading.loading,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  type: string
  direction: string
  copyMode: string
  copies: number
  offset: number
  gapOffset: number
  paths: string[]
  selectedParentIds: { Segment: number, SegmentGroup: number }
  handleInput: (event: any) => void
  handlePatternApply: () => void
  handleUndo: (event: any) => void
  t: Translation
}

class MirrorAndRepeat extends Component<Props> {
  public override render () {
    const {
      type,
      direction,
      copyMode,
      handleInput,
      handleUndo,
      copies,
      offset,
      gapOffset,
      paths,
      handlePatternApply,
      gapWarnings,
      loading,
      t,
    } = this.props

    const isOffset = copyMode === 'offset' || type !== 'Nozzle'

    return (
      <div>
        <Section name='Pattern' closed>
          {
            type === 'Nozzle' &&
            (
              <Input
                key='copyMode'
                name='copyMode'
                label={t('mirrorAndRepeat.copyMode.label')}
                title={t('mirrorAndRepeat.copyMode.title')}
                type='select'
                value={copyMode}
                elementType={type}
                options={[ 'offset', 'gap offset' ]}
                handleUndo={handleUndo}
                onChange={handleInput}
                disableFilter
              />
            )
          }
          <Input
            key='copies'
            name='copies'
            label={t('mirrorAndRepeat.copies.label')}
            title={t('mirrorAndRepeat.copies.title')}
            type='number'
            value={copies}
            elementType={type}
            handleUndo={handleUndo}
            onChange={handleInput}
            disableFilter
            onlyPositiveNumbers
          />
          <Input
            name={isOffset ? 'offset' : 'gapOffset'}
            title={t(`mirrorAndRepeat.${isOffset ? 'offset' : 'gapOffset'}.title`)}
            label={t(`mirrorAndRepeat.${isOffset ? 'offset' : 'gapOffset'}.label`)}
            type='number'
            value={isOffset ? offset : gapOffset}
            elementType={type}
            handleUndo={handleUndo}
            onChange={handleInput}
            disableFilter
            onlyPositiveNumbers
          />
          <Input
            key='direction'
            name='direction'
            label={t('mirrorAndRepeat.direction.label')}
            title={t('mirrorAndRepeat.direction.title')}
            type='select'
            value={direction}
            elementType={type}
            options={[ 'positive', 'negative' ]}
            handleUndo={handleUndo}
            onChange={handleInput}
            disableFilter
          />
          {
            !isOffset && gapWarnings > 0 && (
              <Warning>
                {t('mirrorAndRepeat.warning', { gapWarnings })}
              </Warning>
            )
          }
          <Button
            onClick={handlePatternApply}
            value={t('mirrorAndRepeat.apply')}
            loading={(loading as any)?.[type]?.pattern}
            disabled={
              copies <= 0 ||
              (isOffset && offset === 0) ||
              (!isOffset && gapOffset === 0) ||
              !paths?.length
            }
          />
        </Section>
      </div>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(MirrorAndRepeat)
